import React from "react";

function ShareIcon({ fill, width, height }) {
  return (
    <svg
      version="1.0"
      width={width || "24px"}
      height={height || "24px"}
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill={fill || "var(--dark-primary)"}
        stroke="none"
      >
        <path
          d="M3850 4889 c-47 -5 -148 -25 -225 -44 -203 -50 -2345 -768 -2536
-849 -540 -230 -834 -524 -856 -858 -7 -101 6 -179 46 -276 100 -248 378 -474
806 -659 105 -46 233 -92 550 -197 143 -48 265 -90 271 -94 6 -4 63 -163 127
-354 64 -191 135 -391 158 -445 190 -448 397 -710 651 -827 98 -46 177 -60
296 -54 173 9 315 81 473 239 143 143 275 358 394 639 72 171 804 2363 844
2528 80 326 59 601 -62 837 -31 60 -64 102 -132 171 -123 125 -249 192 -428
230 -90 18 -270 25 -377 13z m336 -379 c187 -48 291 -165 331 -371 19 -102 13
-249 -16 -388 -25 -115 -754 -2309 -823 -2475 -142 -342 -310 -578 -464 -652
-162 -77 -327 12 -500 271 -128 192 -195 350 -358 839 l-107 320 445 445 c437
439 444 447 451 493 10 66 0 107 -35 149 -51 62 -134 86 -207 59 -15 -6 -233
-216 -483 -466 l-456 -455 -299 101 c-440 149 -605 221 -796 351 -161 110
-279 262 -279 361 0 192 259 411 695 591 196 81 2349 796 2475 822 152 31 314
33 426 5z"
        />
      </g>
    </svg>
  );
}

export default ShareIcon;
