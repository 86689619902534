import React from "react";
import { Parah, ParahScrollLink } from "../ContentsStyles";

const PP_8 = () => {
  return (
    <>
      <Parah>
        You must be at least 13 years old to use the Platform. If you believe
        that there is a user who is below this minimum age, please contact us
        using the details set out in the{" "}
        <ParahScrollLink to="contactUs" smooth={true} duration={500}>
          “Contact Us”
        </ParahScrollLink>{" "}
        section below.
      </Parah>
      <Parah>
        To provide users younger than 18 with an age-appropriate experience,
        certain features are not available.
      </Parah>
    </>
  );
};

export default PP_8;
