import {
  getPostSaveCollection,
  getPostcomments,
} from "../Functions/getFetchFunctions";
import {
  handleCommentPost,
  handleCreateSaveCollection,
  handleCreateShareSubmit,
  handleDeletePost,
  handlePinning,
  handleRemovePin,
  handleSaveToCollection,
  handleUnSaveToCollection,
} from "../Functions/getPostShareHandler";
import { setAlertDetails } from "../ReduxToolKit/Slices/utilsSlice";

export function useHandleCreateShare({
  values,
  setValues,
  dispatch,
  navigate,
  currentUserId,
  page,
  setPage,
  data,
  setIsFullScreen,
  activeIndex,
  setActiveIndex,
  //comment section
  commentValues,
  setCommentValues,
  setCommentLoading,
  setIsCmtOpen,
  setCmtIndex,
  commentData,
  setCommentData,
  //save collection
  setSCIndex,
  saveCollectionList,
  setSaveCollectionList,
  setSaveCollectionLoading,
  setIsSCOpen,
  collectionName,
  setCollectionName,
}) {
  // Handling form submit and navigation ------------------------------

  const handleNext = () => {
    setPage((currentPage) => currentPage + 1);
  };

  const handleFormSubmit = () => {
    handleCreateShareSubmit(values, dispatch, navigate, currentUserId);
  };

  // Handling form values ---------------------------------------------

  const handleRatingClick = (ratingValue) => {
    setValues({
      ...values,
      ownRating: ratingValue,
    });
  };

  const handleMainCategoryChange = (selectedOption) => {
    setValues({
      ...values,
      mainCategory: selectedOption ? selectedOption.value : "",
      mainCategoryId: selectedOption ? selectedOption.id : "",
      subCategory: "",
      subCategoryId: "",
      skill: "",
      skillId: "",
      level: "",
    });
  };

  const handleSubCategoryChange = (selectedOption) => {
    setValues({
      ...values,
      subCategory: selectedOption ? selectedOption.value : "",
      subCategoryId: selectedOption ? selectedOption.id : "",
      skill: "",
      skillId: "",
      level: "",
    });
  };

  const handleSkillChange = (selectedOption) => {
    setValues({
      ...values,
      skill: selectedOption ? selectedOption.value : "",
      skillId: selectedOption ? selectedOption.id : "",
      level: "",
    });
  };

  const handleLevelChange = (selectedOption) => {
    setValues({
      ...values,
      level: selectedOption ? selectedOption.value : "",
    });
  };

  const handleLangChange = (
    selectedOption,
    mainArraySize,
    subArraySize,
    itsFor
  ) => {
    if (itsFor === "primaryLang") {
      setValues({
        ...values,
        primaryLanguage: selectedOption ? selectedOption.value : "",
        primaryLanguageId: selectedOption ? selectedOption.id : "",
        secondaryLanguage: "",
        secondaryLanguageId: "",
      });
    } else if (itsFor === "secondaryLang") {
      setValues({
        ...values,
        secondaryLanguage: selectedOption ? selectedOption.value : "",
        secondaryLanguageId: selectedOption ? selectedOption.id : "",
      });
    }
  };

  const handleCountryChange = (selectedOption) => {
    setValues({
      ...values,
      country: selectedOption ? selectedOption.value : "",
    });
  };

  const handleStateChange = (selectedOption) => {
    setValues({
      ...values,
      state: selectedOption ? selectedOption.value : "",
    });
  };

  const handleNormalPeopleTag = (item) => {
    const newTag = {
      _id: item._id,
      firstName: item.personalInfo.firstName,
      lastName: item.personalInfo.lastName,
      profileImage: item.personalInfo.profileImage,
    };

    setValues({
      ...values,
      peopleTag: [...values.peopleTag, newTag],
    });
  };

  const handleSelectedTagClose = (_id) => {
    setValues({
      ...values,
      peopleTag: values.peopleTag.filter((v) => v._id !== _id),
    });
  };

  const handleCreditChange = (event) => {
    setValues({
      ...values,
      isCreditWork: event.target.value === "credit" ? true : false,
    });
  };

  const handleCreditTempTag = (item) => {
    const newTag = {
      _id: item._id,
      firstName: item.personalInfo.firstName,
      lastName: item.personalInfo.lastName,
      profileImage: item.personalInfo.profileImage,
    };

    setValues({
      ...values,
      creditTempTags: [...values.creditTempTags, newTag],
    });
  };

  const handleTempCreditTagClose = (_id) => {
    setValues({
      ...values,
      creditTempTags: values.creditTempTags.filter((v) => v._id !== _id),
    });
  };

  const handleCreditAdd = () => {
    const newCreditTag = {
      creditTitle: values.creditTempTitle,
      peopleTag: values.creditTempTags,
    };
    setValues({
      ...values,
      credit: [...values.credit, newCreditTag],
      creditTempTags: [],
      creditTempTitle: "",
    });
  };

  const handleCreditDelete = (index) => {
    setValues({
      ...values,
      credit: values.credit.filter((v, i) => i !== index),
    });
  };

  const handleToolLevelChange = (selectedOption) => {
    setValues({
      ...values,
      toolTempLevel: selectedOption ? selectedOption.value : "",
    });
  };

  const handleToolsAdd = () => {
    const newTool = {
      toolName: values.toolTempTitle,
      level: values.toolTempLevel,
    };
    setValues({
      ...values,
      toolsUsed: [...values.toolsUsed, newTool],
      toolTempTitle: "",
      toolTempLevel: "",
    });
  };

  const handleToolsDelete = (index) => {
    setValues({
      ...values,
      toolsUsed: values.toolsUsed.filter((v, i) => i !== index),
    });
  };

  // Handling multi post in feed  ---------------------------------------------

  const handleNextMultiPostMedia = (index, isSampleNav) => {
    let lengthNum = 0;
    if (isSampleNav) {
      lengthNum = 3 - 1;
    } else {
      lengthNum = data[index].media.length - 1;
    }
    const newIndices = [...activeIndex];
    newIndices[index] = Math.min(newIndices[index] + 1, lengthNum);
    setActiveIndex(newIndices);
  };

  const handlePrevMultiPostMedia = (index) => {
    const newIndices = [...activeIndex];
    newIndices[index] = Math.max(newIndices[index] - 1, 0);
    setActiveIndex(newIndices);
  };

  const handleBestWorkClick = (isBestWork, userId, postId, forThis) => {
    if (isBestWork) {
      handleRemovePin(userId, postId, dispatch, 1, 5, forThis);
    } else {
      handlePinning(userId, postId, dispatch, 1, 5, forThis);
    }
  };

  const handleDeleteClick = (userId, postId, forThis) => {
    handleDeletePost(userId, postId, dispatch, 1, 20, forThis);
  };

  // handling single post actions -----------------------------------------

  const handleNextSingleMedia = () => {
    let index = activeIndex + 1;
    setActiveIndex(index);
  };

  const handlePrevSingleMedia = () => {
    setActiveIndex(activeIndex - 1);
  };

  // Handling multi comments and single comments --------------------------

  const handleMultiCommentChange = (postId, comment) => {
    const index = data.findIndex((post) => post._id === postId);
    if (index !== -1) {
      const newComments = [...commentValues];
      newComments[index] = {
        ...newComments[index],
        postId: postId,
        comment: comment,
      };
      setCommentValues(newComments);
    }
  };

  const handleMultiCommentSubmit = (postId) => {
    const index = data.findIndex((post) => post._id === postId);
    if (index !== -1) {
      handleCommentPost(
        dispatch,
        currentUserId,
        commentValues[index].postId,
        commentValues[index].comment
      );
      const newComments = [...commentValues];
      newComments[index] = {
        ...newComments[index],
        postId: postId,
        comment: "",
      };
      setCommentValues(newComments);
    }
  };

  const handleMultiCommentOpen = (postId) => {
    const index = data.findIndex((post) => post._id === postId);
    console.log("handle open index", index);
    const newIsOpen = [...activeIndex];
    newIsOpen[index] = {
      ...newIsOpen[index],
      postId: postId,
      isOpen: true,
    };
    setIsCmtOpen(newIsOpen);
    setCmtIndex(index);
    getPostcomments(
      currentUserId,
      postId,
      1,
      20,
      dispatch,
      setCommentData,
      setCommentLoading
    );
  };

  const handleMultiCommentClose = (postId) => {
    const index = data.findIndex((post) => post._id === postId);
    const newIsOpen = [...activeIndex];
    newIsOpen[index] = {
      ...newIsOpen[index],
      postId: null,
      isOpen: false,
    };
    setIsCmtOpen(newIsOpen);
    setCmtIndex(null);
    setCommentData([]);
  };

  // single comment function

  const handleCommentChange = (_, comment) => {
    const cleanedInput = comment.replace(/\s+/g, " ");
    setCommentValues(cleanedInput);
  };

  const handleCommentSubmit = (postId) => {
    handleCommentPost(
      dispatch,
      currentUserId,
      postId,
      commentValues,
      commentData,
      setCommentData
    );
    setCommentValues("");
  };

  // multi fullscreen function ----------------------------------------
  const handleMultiFullScreen = (postId) => {
    const index = data.findIndex((post) => post._id === postId);
    console.log("handle open index", index);
    const newIsFScr = [...activeIndex];
    newIsFScr[index] = {
      ...newIsFScr[index],
      postId: postId,
      isFScr: true,
    };
    setIsFullScreen(newIsFScr);
  };

  const handleMultiMinScreen = (postId) => {
    const index = data.findIndex((post) => post._id === postId);
    console.log("handle open index", index);
    const newIsFScr = [...activeIndex];
    newIsFScr[index] = {
      ...newIsFScr[index],
      postId: null,
      isFScr: false,
    };
    setIsFullScreen(newIsFScr);
  };

  // multi save collection modal function --------------------------------
  const handleMultiSaveColelctionOpen = (postId) => {
    const index = data.findIndex((post) => post._id === postId);
    console.log("handle open index", index);
    const newIsOpen = [...activeIndex];
    newIsOpen[index] = {
      ...newIsOpen[index],
      postId: postId,
      isOpen: true,
    };
    setIsSCOpen(newIsOpen);
    setSCIndex(index);
    getPostSaveCollection(
      currentUserId,
      postId,
      dispatch,
      setSaveCollectionList,
      setSaveCollectionLoading
    );
  };

  const handleMultiSaveColelctionClose = (postId) => {
    const index = data.findIndex((post) => post._id === postId);
    const newIsOpen = [...activeIndex];
    newIsOpen[index] = {
      ...newIsOpen[index],
      postId: null,
      isOpen: false,
    };
    setIsSCOpen(newIsOpen);
    setSCIndex(null);
  };

  // more detail post save modal function
  const handleSaveColelctionOpenClose = (postId, isSCOpen) => {
    if (isSCOpen === false) {
      setIsSCOpen(true);
      getPostSaveCollection(
        currentUserId,
        postId,
        dispatch,
        setSaveCollectionList,
        setSaveCollectionLoading
      );
    } else {
      setIsSCOpen(false);
    }
  };

  const handleSaveCollectionChange = (value) => {
    setCollectionName(value);
  };

  const handleSaveCollectionSubmit = () => {
    handleCreateSaveCollection(
      dispatch,
      currentUserId,
      collectionName,
      saveCollectionList,
      setSaveCollectionList
    );
    setCollectionName("");
  };

  const handleSaveToSpecificCollection = async (
    collectionId,
    collectionName,
    postId,
    status,
    onClose,
    setStatusIndi
  ) => {
    console.log(status);
    try {
      if (!status) {
        await handleSaveToCollection(
          collectionId,
          collectionName,
          postId,
          dispatch,
          setSaveCollectionList,
          currentUserId,
          onClose
        );
      } else {
        await handleUnSaveToCollection(
          collectionId,
          postId,
          dispatch,
          setSaveCollectionList,
          currentUserId
        );
      }
    } catch {
      dispatch(
        setAlertDetails({
          alertDetails: {
            isOpen: true,
            type: "warning",
            message: "Couldn't Do the action",
          },
        })
      );
    }
  };

  return {
    handleNext,
    handleFormSubmit,
    handleRatingClick,
    handleMainCategoryChange,
    handleSubCategoryChange,
    handleSkillChange,
    handleLevelChange,
    handleLangChange,
    handleCountryChange,
    handleStateChange,
    handleNormalPeopleTag,
    handleSelectedTagClose,
    handleCreditChange,
    handleCreditTempTag,
    handleTempCreditTagClose,
    handleCreditAdd,
    handleCreditDelete,
    handleToolLevelChange,
    handleToolsAdd,
    handleToolsDelete,
    // feed post handler
    handleNextMultiPostMedia,
    handlePrevMultiPostMedia,
    handleBestWorkClick,
    handleDeleteClick,
    handleMultiFullScreen,
    handleMultiMinScreen,
    //single post handler
    handleNextSingleMedia,
    handlePrevSingleMedia,
    // comment handler
    handleMultiCommentChange,
    handleMultiCommentSubmit,
    handleMultiCommentOpen,
    handleMultiCommentClose,
    handleCommentSubmit,
    handleCommentChange,
    //save collection handler
    handleMultiSaveColelctionOpen,
    handleMultiSaveColelctionClose,
    handleSaveCollectionChange,
    handleSaveCollectionSubmit,
    handleSaveToSpecificCollection,
    handleSaveColelctionOpenClose,
  };
}
