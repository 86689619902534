import React from "react";

function ImageFolderIcon({ width, height, fill }) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width || "24px"}
      height={height || "24px"}
      viewBox="0 0 504.000000 405.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,405.000000) scale(0.100000,-0.100000)"
        fill={fill || "var(--dark-primary)"}
        stroke="none"
      >
        <path
          d="M2950 3670 c-492 -165 -913 -305 -935 -311 -22 -5 -400 -129 -840
-274 -611 -202 -810 -271 -841 -294 -59 -44 -94 -134 -94 -247 0 -54 41 -180
356 -1100 196 -571 370 -1066 387 -1099 101 -192 274 -259 512 -198 79 20
3061 1016 3120 1042 25 11 72 45 103 75 96 92 132 187 132 351 0 136 -16 193
-169 633 -121 346 -176 512 -406 1227 -48 149 -98 291 -111 316 -29 59 -81
104 -162 142 -120 56 -57 72 -1052 -263z m255 -950 c54 -26 99 -69 124 -119
25 -49 218 -632 235 -711 14 -65 -46 -183 -111 -218 -30 -16 -340 -125 -743
-259 -290 -97 -319 -105 -376 -101 -75 5 -144 43 -187 104 -31 44 -230 616
-248 712 -22 117 47 239 166 292 49 22 891 306 960 324 40 10 136 -3 180 -24z"
        />
        <path
          d="M3030 2724 c-8 -3 -226 -76 -485 -161 -258 -86 -486 -166 -506 -179
-48 -30 -94 -86 -108 -133 -24 -80 -15 -120 100 -468 103 -310 114 -339 151
-379 22 -24 46 -44 52 -44 13 0 83 140 170 340 90 206 104 213 287 126 63 -30
117 -52 120 -50 4 2 35 86 70 186 69 199 93 238 148 238 22 0 93 -43 275 -164
188 -126 246 -160 246 -146 0 10 -43 146 -96 302 -52 156 -102 304 -110 330
-36 116 -131 196 -241 204 -32 2 -65 1 -73 -2z m-374 -340 c39 -18 69 -63 79
-114 4 -26 0 -46 -17 -79 -73 -141 -288 -84 -275 72 5 57 30 95 78 119 42 22
91 23 135 2z"
        />
        <path
          d="M2500 2342 c-35 -34 -40 -46 -40 -84 0 -123 136 -179 223 -92 49 48
48 120 -2 176 -23 26 -36 32 -84 36 -55 4 -58 3 -97 -36z"
        />
        <path
          d="M3003 2170 c-29 -12 -30 -14 -108 -235 -37 -104 -68 -191 -69 -193
-2 -1 -59 25 -128 58 -124 59 -149 66 -184 53 -16 -6 -254 -474 -254 -499 0
-15 34 -24 93 -24 45 0 322 82 527 155 41 15 178 63 303 106 126 43 246 89
268 101 41 23 82 81 93 133 6 30 4 32 -220 180 -194 128 -270 173 -294 174 -3
0 -15 -4 -27 -9z"
        />
        <path
          d="M1545 3675 c-144 -40 -1312 -437 -1349 -459 -23 -13 -56 -44 -72 -67
-25 -36 -30 -54 -33 -120 -3 -72 16 -175 38 -204 5 -5 25 -59 46 -120 21 -60
41 -114 45 -118 4 -5 11 16 14 46 10 75 43 136 91 168 24 16 367 135 858 299
449 149 817 275 817 279 0 3 -62 68 -138 144 -117 117 -145 140 -187 152 -57
18 -65 18 -130 0z"
        />
      </g>
    </svg>
  );
}

export default ImageFolderIcon;
