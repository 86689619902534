import React from "react";

function GraduationIcon({ fill, width, height }) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width || "24px"}
      height={height || "24px"}
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill={fill || "var(--dark-primary)"}
        stroke="none"
      >
        <path
          d="M2443 4479 c-34 -4 -88 -18 -120 -31 -60 -25 -2003 -1151 -2059
-1193 -51 -39 -84 -117 -84 -194 0 -80 22 -131 76 -182 22 -20 173 -114 337
-209 l297 -172 0 -402 c0 -220 5 -432 10 -471 7 -47 24 -98 51 -154 70 -141
96 -161 585 -443 236 -135 461 -262 499 -281 85 -42 210 -83 302 -97 75 -12
267 -15 329 -5 185 30 287 72 564 231 124 72 330 191 459 265 276 159 326 201
390 330 27 56 44 107 51 154 5 39 10 250 10 471 l0 401 130 76 130 75 0 -579
0 -580 -29 -25 c-17 -14 -42 -50 -57 -80 -23 -48 -26 -63 -22 -124 19 -263
357 -332 480 -97 35 66 32 166 -7 236 -16 29 -40 60 -52 68 l-23 15 0 669 0
668 28 15 c40 21 99 86 117 128 8 21 15 66 15 102 0 83 -36 156 -100 202 -25
17 -488 289 -1030 603 -737 428 -1001 577 -1050 591 -81 23 -148 29 -227 19z
m149 -297 c47 -17 1938 -1112 1938 -1122 -1 -4 -1163 -681 -1745 -1016 -208
-120 -242 -131 -324 -110 -29 7 -419 227 -1004 567 -526 305 -956 556 -956
559 -1 3 54 37 122 76 67 39 499 289 959 557 460 267 852 490 870 496 49 14
88 12 140 -7z m-837 -2187 c297 -172 565 -322 595 -332 81 -27 265 -25 341 4
31 11 169 86 305 165 654 381 823 478 838 484 15 6 16 -23 16 -317 0 -192 -4
-339 -10 -361 -25 -88 -48 -105 -490 -361 -467 -269 -522 -298 -635 -328 -112
-30 -288 -30 -400 0 -112 30 -166 58 -635 329 -442 255 -465 272 -490 360 -6
22 -10 169 -10 361 0 304 1 323 18 317 9 -4 260 -148 557 -321z"
        />
      </g>
    </svg>
  );
}

export default GraduationIcon;
