import React from "react";
import styled from "styled-components";

const RatingBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  justify-content: center;
  width: 19rem;
`;

const RatingBarWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 10px;
  p {
    color: ${(props) => props.theme.main};
    width: 3.5rem;
    font-size: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    &:nth-child(3n) {
      width: 1rem;
      display: grid;
      place-content: center;
    }
  }
`;

const RatingBar = styled.div`
  position: relative;
  width: 90%;
  height: 5px;
  background: ${(props) => props.theme.mediumBg};
  border-radius: 5px;
  span {
    position: absolute;
    height: 100%;
    width: ${({ percentage }) => percentage && percentage};
    background: ${(props) => props.theme.yellow};
    border-radius: 5px;
  }
`;

const HorzBar = ({ ratingCounts }) => {
  return (
    <RatingBarContainer>
      {ratingCounts.map((item, index) => (
        <RatingBarWrap key={index}>
          <p>
            {item.no} {item.no === 1 ? "Star" : "Stars"}
          </p>
          <RatingBar percentage={`${item.percentage}%`}>
            <span></span>
          </RatingBar>
          <p>{item.value}</p>
        </RatingBarWrap>
      ))}
    </RatingBarContainer>
  );
};

export default HorzBar;
