import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { FadeLoader } from "react-spinners";
import { AllPostWrapper, UserDetailHeading } from "../Overview/OverviewStyle";
import { AllPostIcon } from "../../../Assets/SVG";
import { getProfileAllposts } from "../../../Functions/getFetchFunctions";
import { PostHorizOverview } from "../../../Components";
import { EmptyModalDataWrap } from "../../../Components/Modals/ModalStyles";
import { LoaderStyles } from "../../../Components/Common/CommonStyle";

const AllPosts = () => {
  const { userId } = useParams();
  const dispatch = useDispatch();
  const currentUserId = localStorage.getItem("currentUserId");
  let pageNo = 1;
  let pageSize = 20;
  const userAllPostsById = useSelector((state) => state.posts.userAllPostsById);
  const userAllPostIds = Object.keys(userAllPostsById);
  const userAllPosts = userAllPostIds.map(
    (allPostId) => userAllPostsById[allPostId]
  );
  userAllPosts.sort(
    (a, b) => new Date(b.publishedAt) - new Date(a.publishedAt)
  );
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getProfileAllposts(
      currentUserId,
      userId,
      pageNo,
      pageSize,
      dispatch,
      setIsLoading
    );
  }, [userId, dispatch, currentUserId, pageNo, pageSize]);

  return (
    <div style={{ maxWidth: "1300px" }}>
      <UserDetailHeading style={{ marginBottom: "1rem" }}>
        <span>
          <AllPostIcon />
          {!isLoading && (
            <>
              {userAllPosts.length < 10
                ? userAllPosts.length === 0
                  ? "No"
                  : `0${userAllPosts.length}`
                : userAllPosts.length}
            </>
          )}
          &nbsp;&nbsp;Posts
        </span>
      </UserDetailHeading>
      {isLoading ? (
        <EmptyModalDataWrap height={"20rem"}>
          <FadeLoader
            color="#747474"
            height={8.5}
            width={2}
            radius={1}
            margin={-9}
            speedMultiplier={1.5}
            loading={true}
            cssOverride={LoaderStyles}
          />
        </EmptyModalDataWrap>
      ) : (
        <>
          {userAllPosts.length > 0 ? (
            <AllPostWrapper>
              {userAllPosts.map((post, index) => (
                <PostHorizOverview
                  key={index}
                  postId={post._id}
                  about={post.about}
                  isBestWork={post.isBestWork}
                  numberOfRating={post.numberOfRating}
                  postRating={post.postRating}
                  author={post.author}
                  media={post.media}
                  timeAgo={post.timeAgo}
                />
              ))}
            </AllPostWrapper>
          ) : (
            <EmptyModalDataWrap height={"20rem"} style={{ gap: "5px" }}>
              <AllPostIcon width={"3rem"} height={"3rem"} />
              No Posts added yet
            </EmptyModalDataWrap>
          )}
        </>
      )}
    </div>
  );
};

export default AllPosts;
