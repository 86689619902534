import React from "react";
import {
  CommentEmoji,
  CommentInputField,
  CommentSendButton,
  CommentWrap,
} from "./InputStyle";
import { EmojiIcon, SendIcon } from "../../Assets/SVG";

const CommentInput = ({
  id,
  name,
  value,
  onChange,
  postId,
  onSubmit,
  isModal,
  placeHolder,
  isEmoji,
}) => {
  const handleInputChange = (e) => {
    if (name === "saveCollection") {
      onChange(e.target.value);
    } else {
      onChange(postId, e.target.value);
    }
  };

  const handleSubmit = () => {
    onSubmit && onSubmit(postId);
  };

  return (
    <CommentWrap>
      {isEmoji && (
        <CommentEmoji>
          <EmojiIcon width={"19px"} height={"19px"} />
        </CommentEmoji>
      )}
      <CommentInputField
        id={id}
        name={name}
        type="text"
        placeholder={placeHolder}
        value={value}
        isValue={value}
        onChange={handleInputChange}
        isModal={isModal}
      />
      {value && (
        <CommentSendButton onClick={handleSubmit}>
          <SendIcon width={"18px"} height={"18px"} />
        </CommentSendButton>
      )}
    </CommentWrap>
  );
};

export default CommentInput;
