import React from "react";

function QuillIcon({ fill, width, height }) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width || "24px"}
      height={height || "24px"}
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill={fill || "var(--dark-primary)"}
        stroke="none"
      >
        <path
          d="M4755 5114 c-12 -3 -73 -44 -137 -92 -156 -116 -288 -198 -466 -287
-176 -88 -272 -127 -667 -275 -408 -153 -573 -223 -820 -344 -153 -76 -228
-119 -278 -161 -212 -176 -363 -494 -422 -890 l-8 -50 -13 50 c-18 69 -32 271
-27 386 5 83 2 101 -16 136 -31 61 -74 88 -142 88 -46 0 -63 -5 -95 -30 -68
-50 -367 -356 -455 -465 -528 -651 -676 -1301 -468 -2058 l31 -112 -68 -78
c-210 -235 -493 -617 -534 -720 -27 -70 8 -155 80 -192 97 -50 155 -17 267
149 124 183 316 427 455 579 l39 42 82 -15 c360 -66 729 -80 1055 -40 981 122
1746 720 2239 1752 240 503 412 1103 508 1767 26 182 65 588 65 684 0 121 -93
201 -205 176z m-152 -606 c-128 -1084 -463 -1999 -950 -2593 -390 -475 -891
-764 -1473 -850 -245 -36 -693 -36 -869 1 l-24 5 24 26 c41 43 268 245 384
340 339 277 749 550 1159 768 156 84 181 111 181 195 0 67 -31 116 -91 145
-59 28 -103 16 -264 -70 -568 -306 -1066 -658 -1517 -1074 l-132 -121 -10 37
c-52 195 -63 543 -23 742 64 315 216 622 450 907 114 139 150 179 155 174 2
-3 12 -48 22 -100 52 -274 189 -498 371 -605 68 -40 145 -41 196 -3 60 46 68
76 68 250 0 486 130 876 344 1036 120 89 468 244 966 432 519 195 790 315 965
429 33 22 65 40 71 40 7 1 7 -33 -3 -111z"
        />
      </g>
    </svg>
  );
}

export default QuillIcon;
