import React, { useEffect, useState } from "react";
import {
  CreateEditPostSection,
  FormContainer,
  FormFooter,
  FormHeader,
  FormSectionWrapper,
} from "./UploadStyle";

import MediaUploadPrev from "./MediaUploadPrev";
import {
  PrimaryBtn,
  SecondaryBtn,
  SectionFooterWrapper,
  StepWrapper,
} from "../../Components/Common/CommonStyle";

import AboutForm from "./AboutForm";
import CreditForm from "./CreditForm";
import { useHandleCreateShare } from "../../Hooks/useCreateShareHandler";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getCountries,
  getLanguages,
  getMainCategories,
  getSkills,
  getStates,
  getSubCategories,
} from "../../Functions/getFetchFunctions";
import { useHandleValues } from "../../Hooks/useEventHandler";
import useDateSelector from "../../Hooks/useDateSelector";
import { Footer } from "../../Components";

const Upload = () => {
  const [page, setPage] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUserId = localStorage.getItem("currentUserId");

  const [values, setValues] = useState({
    type: "POST",
    userId: currentUserId,
    mediaValues: [],
    // About form section
    website: "",
    title: "",
    description: "",
    mainCategory: "",
    mainCategoryId: "",
    subCategory: "",
    subCategoryId: "",
    skill: "",
    skillId: "",
    level: "",
    primaryLanguage: "",
    primaryLanguageId: "",
    secondaryLanguage: "",
    secondaryLanguageId: "",
    ownRating: 0,
    keywords: "",
    country: "",
    countryId: "",
    state: "",
    stateId: "",
    peopleTag: [],
    // Credit form section
    productionName: "",
    productionDate: "",
    isCreditWork: null,
    credit: [],
    creditTempTitle: "",
    creditTempTags: [],
    toolsUsed: [],
    toolTempTitle: "",
    toolTempLevel: "",
  });

  const [errors, setErrors] = useState("");
  const {
    years,
    months,
    days,
    year,
    month,
    day,
    handleYearChange,
    handleMonthChange,
    handleDayChange,
  } = useDateSelector({ values, setValues, errors, setErrors });

  useEffect(() => {
    if (values.mainCategoryId) {
      getSubCategories(values.mainCategoryId, dispatch);
    }
  }, [dispatch, values.mainCategoryId]);

  useEffect(() => {
    if (values.subCategoryId) {
      getSkills(values.subCategoryId, dispatch);
    }
  }, [dispatch, values.subCategoryId]);

  useEffect(() => {
    getCountries(dispatch);
    getLanguages(dispatch);
    getMainCategories(dispatch);
    document.title = "Create Post | Talents Explore";
  }, [dispatch]);

  useEffect(() => {
    if (values.country) {
      getStates(values.country, dispatch);
    }
  }, [dispatch, values.country]);

  const { handleDropdownChange, handleSkillSetChange, handleInputChange } =
    useHandleValues({
      values,
      setValues,
      errors,
      setErrors,
    });

  const {
    handleRatingClick,

    handleCreditChange,
    handleNext,
    handleFormSubmit,
    handleNormalPeopleTag,
    handleSelectedTagClose,
    handleCreditTempTag,
    handleTempCreditTagClose,
    handleCreditAdd,
    handleCreditDelete,
    handleToolLevelChange,
    handleToolsAdd,
    handleToolsDelete,
  } = useHandleCreateShare({
    values,
    setValues,
    dispatch,
    navigate,
    currentUserId,
    page,
    setPage,
  });

  const postForm = [
    {
      form: (
        <AboutForm
          values={values}
          setValues={setValues}
          handleSkillSetChange={handleSkillSetChange}
          handleDropdownChange={handleDropdownChange}
          handleRatingClick={handleRatingClick}
          handleInputChange={handleInputChange}
          handleNormalPeopleTag={handleNormalPeopleTag}
          handleSelectedTagClose={handleSelectedTagClose}
        />
      ),
    },
    {
      form: (
        <CreditForm
          values={values}
          setValues={setValues}
          onCheckChange={handleCreditChange}
          handleCreditTempTag={handleCreditTempTag}
          handleTempCreditTagClose={handleTempCreditTagClose}
          handleInputChange={handleInputChange}
          handleCreditAdd={handleCreditAdd}
          handleCreditDelete={handleCreditDelete}
          handleToolLevelChange={handleToolLevelChange}
          handleToolsAdd={handleToolsAdd}
          handleToolsDelete={handleToolsDelete}
          years={years}
          months={months}
          days={days}
          year={year}
          month={month}
          day={day}
          handleYearChange={handleYearChange}
          handleMonthChange={handleMonthChange}
          handleDayChange={handleDayChange}
          //
          handleDropdownChange={handleDropdownChange}
        />
      ),
    },
  ];

  return (
    <SectionFooterWrapper>
      <CreateEditPostSection>
        <FormHeader isMob={true}>
          <h1>Create Post</h1>
          <StepWrapper padding={"0.3rem 1rem"}>
            Step
            <span>
              0{page + 1}/0{postForm.length}
            </span>
          </StepWrapper>
        </FormHeader>
        <MediaUploadPrev values={values} setValues={setValues} />
        <FormContainer>
          <FormSectionWrapper>
            <FormHeader>
              <h1>Create Post</h1>
              <StepWrapper padding={"0.3rem 1rem"}>
                Step
                <span>
                  0{page + 1}/0{postForm.length}
                </span>
              </StepWrapper>
            </FormHeader>
            <>{postForm[page].form}</>
          </FormSectionWrapper>
          <FormFooter>
            {postForm.length - 1 === page ? (
              <PrimaryBtn onClick={handleFormSubmit}>Upload</PrimaryBtn>
            ) : (
              <PrimaryBtn onClick={() => handleNext()}>Next</PrimaryBtn>
            )}

            {page > 0 && (
              <SecondaryBtn
                onClick={() => setPage((currentPage) => currentPage - 1)}
              >
                Back
              </SecondaryBtn>
            )}
          </FormFooter>
        </FormContainer>
      </CreateEditPostSection>
      <Footer />
    </SectionFooterWrapper>
  );
};

export default Upload;
