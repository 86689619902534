import {
  loginAction,
  personalInfoEditAction,
  personalInfoPostAction,
  registerAction,
  talentRegisterAction,
} from "../ReduxToolKit/APIs/AuthAPI";
import { setAlertDetails, setIsAbout } from "../ReduxToolKit/Slices/utilsSlice";
import { setLogin } from "../ReduxToolKit/Slices/authSlice";
import { getFileUpload } from "./getFileUpload";

export function handlePersonalInfoSubmit(
  userId,
  currentUser,
  values,
  profilePhotoValue,
  coverPhoto,
  dispatch,
  navigate,
  setIsLoading
) {
  console.log(values);
  setIsLoading(true);
  try {
    const formData = {
      firstName: values.firstName,
      lastName: values.lastName,
      dob: values.dob,
      gender: values.gender,
      professional: values.professionId,
      languageKnown: values.knownLanguageId,
      location: "trincomalee, srilanka",
      latitude: 8.5668,
      longitude: 81.2253,
      profileImage: profilePhotoValue,
      coverImage: coverPhoto,
      shortBio: values.quotes,
    };
    console.log(formData, "formdata");
    dispatch(personalInfoPostAction({ userId, formData })).then(
      (actionResult) => {
        const responseData = actionResult.payload;
        if (parseInt(responseData.status) === 7400) {
          console.log(responseData, "resp0onse data---------------");
          setIsLoading(false);
          dispatch(setIsAbout({ isAbout: true }));
          navigate(`/profile/${values.firstName}_${values.lastName}/${userId}`);
          dispatch(
            setAlertDetails({
              alertDetails: {
                isOpen: true,
                type: "success",
                message: "Your Account Created Successfully!",
              },
            })
          );
        } else {
          setIsLoading(false);
          dispatch(setIsAbout({ isAbout: false }));
          dispatch(
            setAlertDetails({
              alertDetails: {
                isOpen: true,
                type: "warning",
                message: "Something went wrong!",
              },
            })
          );
        }
      }
    );
  } catch {}
}

export function handleLogin(values, dispatch, navigate) {
  try {
    const formData = {
      email: values.email,
      password: values.password,
      deviceId: "wepApp",
      appVersion: "2.0",
    };
    dispatch(loginAction(formData)).then((actionResult) => {
      const responseData = actionResult.payload;
      console.log(responseData, "login", formData);

      if (parseInt(responseData.status) === 7400) {
        if (responseData.response) {
          localStorage.setItem("currentUserId", responseData.response.user);
          dispatch(
            setLogin({
              currentUserId: responseData.response.user,
              token: responseData.response.accessToken,
            })
          );
          navigate("/feed");
        }
      } else {
        dispatch(
          setAlertDetails({
            alertDetails: {
              isOpen: true,
              type: "warning",
              message: "Invalid email address or password",
            },
          })
        );
      }
    });
  } catch {}
}

export function handleSignup(values, dispatch, setIsOpen) {
  try {
    console.log(values);
    const formData = {
      userName: values.userName,
      email: values.email,
      password: values.password,
    };
    dispatch(registerAction(formData)).then((actionResult) => {
      const responseData = actionResult.payload;
      if (parseInt(responseData.status) === 7400) {
        if (responseData.response) {
          localStorage.setItem("currentUserId", responseData.response.user);
          dispatch(
            setLogin({
              currentUserId: responseData.response,
            })
          );
          setIsOpen(true);
        }
      } else {
        dispatch(
          setAlertDetails({
            alertDetails: {
              isOpen: true,
              type: "danger",
              message:
                "This email already exist. Please try again with another email address.",
            },
          })
        );
      }
    });
  } catch {}
}

export async function handleTalentRegister(
  moreInfoValues,
  addedSkillValues,
  addedLangValues,
  addedEduValues,
  addedAwardValues,
  dispatch,
  navigate,
  currentUserId,
  currentUser,
  setIsLoading
) {
  try {
    const formData = {
      moreInfo: {
        bio: moreInfoValues.description,
        ethnic: moreInfoValues.ethnicId,
        featuredPhotos: [],
        socialLinks: moreInfoValues.socialMediaLinks,
      },
      skillSets: [],
      languageKnown: [],
      education: [],
      awardsOrCertificate: [],
    };

    for (let i = 0; i < moreInfoValues.myPhotos.length; i++) {
      const myPhotosKey = await getFileUpload(moreInfoValues.myPhotos[i].data);
      formData.moreInfo.featuredPhotos.push(myPhotosKey);
      console.log(myPhotosKey, "myphotos--key");
    }

    for (let i = 0; i < addedSkillValues.length; i++) {
      const skillsHaveArray = [];

      for (let j = 0; j < addedSkillValues[i].skillsHave.length; j++) {
        const skillHave = {
          skillId: addedSkillValues[i].skillsHave[j].skillId,
          level: addedSkillValues[i].skillsHave[j].level,
        };
        skillsHaveArray.push(skillHave);
      }

      const skillSet = {
        mainCategoryId: addedSkillValues[i].mainCategoryId,
        subCategoryId: addedSkillValues[i].subCategoryId,
        skillsHave: skillsHaveArray, // Use the array of skillHave objects
        description: addedSkillValues[i].skillDescription,
      };

      formData.skillSets.push(skillSet); // Add the skill set to formData's skillSets array
    }

    for (let i = 0; i < addedLangValues.length; i++) {
      const languageKnown = {
        languageId: addedLangValues[i].languageId,
        level: addedLangValues[i].level,
      };
      formData.languageKnown.push(languageKnown);
    }

    for (let i = 0; i < addedEduValues.length; i++) {
      const education = {
        course: addedEduValues[i].course,
        institution: addedEduValues[i].institution,
        from: addedEduValues[i].from,
        to: addedEduValues[i].to,
      };
      formData.education.push(education);
    }

    for (let i = 0; i < addedAwardValues.length; i++) {
      const awardsOrCertificate = {
        name: addedAwardValues[i].awardName,
        givenBy: addedAwardValues[i].givenBy,
        year: addedAwardValues[i].when,
      };
      formData.awardsOrCertificate.push(awardsOrCertificate);
    }

    dispatch(talentRegisterAction({ currentUserId, formData })).then(
      (actionResult) => {
        const responseData = actionResult.payload;
        console.log(responseData, "data register");
        console.log(responseData.status);
        console.log(formData, "form data--- talent form");
        if (parseInt(responseData.status) === 7400) {
          setIsLoading(false);
          dispatch(
            setAlertDetails({
              alertDetails: {
                isOpen: true,
                type: "success",
                message: "Your account upgraded successfully",
              },
            })
          );
          navigate(
            `/profile/${currentUser?.personalInfo.firstName}_${currentUser?.personalInfo.lastName}/${currentUserId}`
          );
        } else {
          dispatch(
            setAlertDetails({
              alertDetails: {
                isOpen: true,
                type: "danger",
                message: "Something went wrong. Please try again later.",
              },
            })
          );
        }
      }
    );
  } catch (error) {
    console.error(error);
  }
}

export async function handleProfileEdit(
  currentUserId,
  values,
  profileImgKey,
  coverImgKey,
  dispatch,
  navigate,
  setIsLoading,
  user
) {
  await setIsLoading(true);
  try {
    const formData = {
      firstName: values.firstName,
      lastName: values.lastName,
      dob: values.dob,
      gender: values.gender,
      languageKnown: values.knownLanguageId,
      profileImage: profileImgKey,
      coverImage: coverImgKey,
      shortBio: values.quotes,
      professional: values.professionId,
      moreInfo: {
        bio: values.bio,
        ethnic: values.ethnicId,
        featuredPhotos: [],
        socialLinks: [],
      },
    };

    // if my photos value exist already or with ""
    for (let i = 0; i < values.myPhotos.length; i++) {
      let myPhoto = null;
      if (values.myPhotos[i] === "" || values.myPhotos[i] === null) {
        myPhoto = null;
      } else {
        myPhoto = values.myPhotos[i];
        formData.moreInfo.featuredPhotos.push(myPhoto);
      }
    }

    // if new my photos uploads
    for (let i = 0; i < values.myNewPhotos.length; i++) {
      const myPhotosKey = await getFileUpload(values.myNewPhotos[i].data);
      formData.moreInfo.featuredPhotos.push(myPhotosKey);
    }

    dispatch(personalInfoEditAction({ currentUserId, formData })).then(
      (actionResult) => {
        const responseData = actionResult.payload;
        console.log(responseData, "profile edit function", formData);

        if (parseInt(responseData.status) === 7400) {
          dispatch(
            setAlertDetails({
              alertDetails: {
                isOpen: true,
                type: "success",
                message: "Your profile edited successfully",
              },
            })
          );
          navigate(
            `/profile/${values.firstName}_${values.lastName}/${currentUserId}`
          );
        } else {
          console.log("fail to edit profile");
          dispatch(
            setAlertDetails({
              alertDetails: {
                isOpen: true,
                type: "warning",
                message: "Fail to edit. Please try again later.",
              },
            })
          );
          navigate(
            `/profile/${user?.personalInfo?.firstName}_${user?.personalInfo?.lastName}/${currentUserId}`
          );
        }
      }
    );
  } catch {}
}
