import React, { useCallback } from "react";
import styled from "styled-components";

export const useRateFollowCountFormat = () => {
  const formatNumber = useCallback((countValue) => {
    if (countValue >= 1000 && countValue < 1000000) {
      if (countValue % 1000 === 0) {
        return countValue / 1000 + "k";
      } else {
        const formatted = (countValue / 1000).toFixed(1);
        return formatted.endsWith(".0")
          ? formatted.slice(0, -2) + "K"
          : formatted + "K";
      }
    } else if (countValue >= 1000000) {
      if (countValue % 1000000 === 0) {
        return countValue / 1000000 + "M";
      } else {
        const formatted = (countValue / 1000000).toFixed(1);
        return formatted.endsWith(".0")
          ? formatted.slice(0, -2) + "M"
          : formatted + "M";
      }
    } else return countValue;
  }, []);

  return formatNumber;
};

export const addLineBreaks = (text) => {
  // Regular expression to find URLs
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  // const urlRegex = /(?:https?|ftp):\/\/[\n\S]+|www.[\n\S]+/g;
  const DescLink = styled.a`
    cursor: pointer;
    text-decoration: none;
    color: ${({ theme }) => (theme.mode === "light" ? "#0a52bd" : theme.dark)};
    color: #0a52bd;

    font-size: 13px;
    font-weight: 500;
    &:hover {
      color: #083f91;
    }
  `;
  return text.split("\n").map((line, index) => {
    return (
      <React.Fragment key={index}>
        {line.split(urlRegex).map((part, index) => {
          if (part.match(urlRegex)) {
            return (
              <DescLink
                key={index}
                href={part}
                target="_blank"
                rel="noopener noreferrer"
              >
                {part}
              </DescLink>
            );
          } else {
            return <React.Fragment key={index}>{part}</React.Fragment>;
          }
        })}
        <br />
      </React.Fragment>
    );
  });
};
