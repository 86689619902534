import { useState, useMemo, useEffect } from "react";

const useDateSelector = ({ values, setValues, errors, setErrors, present }) => {
  const currentYear = new Date().getFullYear();
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [monthId, setMonthId] = useState("");
  const [day, setDay] = useState("");

  const years = useMemo(() => {
    const years = [];
    if (present) {
      years.push({ value: "Present", label: 0 });
    }
    for (let i = currentYear; i > 1900; i--) {
      years.push({ value: i, label: i.toString() });
    }
    return years;
  }, [currentYear, present]);

  const presentAddedYears = useMemo(() => {
    const presentAddedYears = [];
    presentAddedYears.push({ value: "Present", label: 0 });
    presentAddedYears.push(...years);
    return presentAddedYears;
  }, [years]);

  const months = useMemo(() => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    return monthNames.map((name, index) => ({
      value: (index + 1).toString().padStart(2, "0"),
      label: name,
    }));
  }, []);

  const getDaysInMonth = (year, month) => {
    const isLeapYear = year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0);
    const daysInMonth = {
      January: 31,
      February: isLeapYear ? 29 : 28,
      March: 31,
      April: 30,
      May: 31,
      June: 30,
      July: 31,
      August: 31,
      Septeber: 30,
      October: 31,
      November: 30,
      December: 31,
    };
    return daysInMonth[month];
  };

  const [days, setDays] = useState([]);
  useEffect(() => {
    const daysInMonth = getDaysInMonth(parseInt(year), month);
    const days = [];
    for (let i = 1; i <= daysInMonth; i++) {
      days.push({ value: i.toString().padStart(2, "0"), label: i.toString() });
    }
    setDays(days);
  }, [year, month]);

  const handleYearChange = (option) => {
    setYear(option.value);
    console.log(option, "year");
    setMonth("");
    setMonthId("");
    setDay("");
    if (parseInt(currentYear) - parseInt(option.value) <= 13) {
      setErrors({
        ...errors,
        dob: "Your age must be above 13 years or equal",
      });
    } else {
      setErrors({
        ...errors,
        dob: "",
      });
    }
  };

  const handleMonthChange = (option) => {
    setMonth(option.value);
    setMonthId(option.label);
    setDay("");
    if (parseInt(currentYear) - parseInt(year) <= 13) {
      setErrors({
        ...errors,
        dob: "Your age must be above 13 years or equal",
      });
    } else {
      setErrors({
        ...errors,
        dob: "",
      });
    }
  };

  const handleDayChange = (option, valueName) => {
    setDay(option.value);
    const selectedYear = year;
    const selectedMonthId = monthId;
    const selectedDay = option.value;
    const dateString = `${selectedYear}-${selectedMonthId}-${selectedDay}T08:56:11.274Z`;
    setValues({ ...values, [valueName]: dateString });
    if (parseInt(currentYear) - parseInt(year) <= 13) {
      setErrors({
        ...errors,
        [valueName]: "Your age must be above 13 years or equal",
      });
    } else {
      setErrors({
        ...errors,
        [valueName]: "",
      });
    }
  };

  return {
    years,
    presentAddedYears,
    months,
    days,
    handleYearChange,
    handleMonthChange,
    handleDayChange,
    year,
    month,
    day,
  };
};

export default useDateSelector;

export const useDateConvertor = (timestamp) => {
  const dt = new Date(timestamp);

  const year = dt.getUTCFullYear();
  const month = dt.getUTCMonth() + 1; // Add 1 since months are zero-based
  const day = dt.getUTCDate();

  // setDob({
  //   ...dob,
  //   year: year,
  //   month: month,
  //   day: day,
  // });

  return `${year}-${month < 10 ? "0" : ""}${month}-${
    day < 10 ? "0" : ""
  }${day}`;
};

export const getFormattedDate = (date) => {
  const dt = new Date(date);

  const year = dt.getUTCFullYear();
  const month = dt.toLocaleString("default", { month: "short" });
  const day = dt.getUTCDate();

  return `${year}-${month < 10 ? "0" : ""}${month}-${
    day < 10 ? "0" : ""
  }${day}`;
};
