import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const followAction = createAsyncThunk(
  "connect-user/follow",
  async (data) => {
    try {
      const response = await axios.post("/connect-user/follow", data);
      console.log(response, "follow");
      return response.data;
    } catch {
      const response = {
        data: { status: 400 },
      };
      return response.data;
    }
  }
);

export const unfollowAction = createAsyncThunk(
  "connect-user/unfollow",
  async (data) => {
    try {
      const response = await axios.post("/connect-user/unfollow", data);
      console.log(response, "unfollow");
      return response.data;
    } catch {
      const response = {
        data: { status: 400 },
      };
      return response.data;
    }
  }
);

export const followersViewAction = createAsyncThunk(
  "connect-user/followerList",
  async ({ userId, currentUserId }) => {
    try {
      const response = await axios.get(
        `connect-user/followerList?userId=${userId}&viewerId=${currentUserId}&pageNo=${1}`
      );
      return response.data;
    } catch {
      const response = {
        data: { status: 400 },
      };
      return response.data;
    }
  }
);

export const followingsViewAction = createAsyncThunk(
  "connect-user/followingList",
  async ({ userId, currentUserId }) => {
    try {
      const response = await axios.get(
        `connect-user/followingList?userId=${userId}&viewerId=${currentUserId}&pageNo=${1}`
      );
      return response.data;
    } catch {
      const response = {
        data: { status: 400 },
      };
      return response.data;
    }
  }
);

export const rateProfileAction = createAsyncThunk(
  "user/rating",
  async (data) => {
    try {
      const response = await axios.post("/user/rating", data);
      console.log(response, "rate-profile");
      return response.data;
    } catch {
      const response = {
        data: { status: 400 },
      };
      return response.data;
    }
  }
);

export const profileRatingsOverallViewAction = createAsyncThunk(
  "user/rating/splitup/{userId}",
  async (userId) => {
    try {
      const response = await axios.get(`/user/rating/splitup/${userId}`);
      return response.data;
    } catch {
      const response = {
        data: { status: 400 },
      };
      return response.data;
    }
  }
);

export const profileRatingsViewAction = createAsyncThunk(
  "user/rating/list",
  async (userId) => {
    try {
      const response = await axios.get(
        `user/rating/list?userId=${userId}&pageNo=${1}`
      );
      return response.data;
    } catch {
      const response = {
        data: { status: 400 },
      };
      return response.data;
    }
  }
);

export const taggingSearchAction = createAsyncThunk(
  "user/tagging/search",
  async ({ currentUserId, filter }) => {
    try {
      const response = await axios.get(
        `user/tagging/search?userId=${currentUserId}&pageNo=${1}&pageSize=${20}&filter=${filter}`
      );
      return response.data;
    } catch {
      const response = {
        data: { status: 400 },
      };
      return response.data;
    }
  }
);
