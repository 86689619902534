import { useEffect, useRef, useState } from "react";

export function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
// Modal Open and Close

export function useModalOpen() {
  const [isOpen, setIsOpen] = useState(false);
  const clickOutRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (clickOutRef.current && !clickOutRef.current.contains(event?.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [isOpen]);

  return { isOpen, setIsOpen, clickOutRef };
}

export function useIndividualModalOpen() {
  const [isIndividualModalOpen, setIsIndividualModalOpen] = useState({});
  const modalClickOutRef = useRef(null);

  const handleIndividualModalOpen = (id) => {
    setIsIndividualModalOpen((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        modalClickOutRef.current &&
        !modalClickOutRef.current.contains(event?.target)
      ) {
        setIsIndividualModalOpen(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return {
    modalClickOutRef,
    isIndividualModalOpen,
    setIsIndividualModalOpen,
    handleIndividualModalOpen,
  };
}

// Handling Values

export function useHandleValues({ values, setValues, errors, setErrors }) {
  const handleDropdownChange = ({
    selectedOption,
    name,
    idName,
    index,
    childIndex,
    isHasLevel,
    isMultiple,
  }) => {
    // is the dropdown having array of object values ----------------------------
    if (isMultiple) {
      // is the [{}] dropdown having level as child
      if (isHasLevel) {
        if (idName !== "") {
          if (selectedOption.id !== 400)
            setValues([
              {
                ...values[index],
                [idName]: selectedOption.id,
                [name]: selectedOption.value,
                level: "",
              },
            ]);
        } else {
          setValues([
            {
              ...values[index],
              [name]: selectedOption.value,
              level: "",
            },
          ]);
        }
        // is the [{}] dropdown NOT having level as child
      } else {
        if (idName !== "") {
          if (selectedOption.id !== 400)
            setValues([
              {
                ...values[index],
                [idName]: selectedOption.id,
                [name]: selectedOption.value,
              },
            ]);
        } else {
          setValues([
            {
              ...values[index],
              [name]: selectedOption.value,
            },
          ]);
        }
      }
    } else {
      // is the {} dropdown values are having level as child
      if (isHasLevel) {
        if (idName) {
          if (selectedOption.id !== 400)
            setValues({
              ...values,
              [idName]: selectedOption.id,
              [name]: selectedOption.value,
              level: "",
            });
        } else {
          setValues({
            ...values,
            [name]: selectedOption.value,
            level: "",
          });
        }
        // is the {} dropdown values NOT having level as child
      } else {
        if (idName) {
          if (selectedOption.id !== 400)
            setValues({
              ...values,
              [idName]: selectedOption.id,
              [name]: selectedOption.value,
            });
        } else {
          setValues({
            ...values,
            [name]: selectedOption.value,
          });
        }
      }
      // error handling for {} dropdown values
      setErrors &&
        setErrors({
          ...errors,
          [name]: selectedOption ? "" : errors[name],
        });
    }
  };

  // Handling Values
  const handleSkillSetChange = ({
    isMultiple,
    isFor,
    selectedOption,
    index,
    childIndex,
  }) => {
    if (isMultiple) {
      // if the skill set is inside an array --------------------------------------------------- {
      if (isFor === "mainCat") {
        if (selectedOption.id !== 400)
          setValues([
            {
              ...values[index],
              mainCategoryId: selectedOption.id,
              mainCategory: selectedOption.value,
              subCategoryId: "",
              subCategory: "",
              skillsHave: [
                {
                  skill: "",
                  skillId: "",
                  level: "",
                },
              ],
            },
          ]);
        console.log(childIndex);
      } else if (isFor === "subCat") {
        if (selectedOption.id !== 400)
          setValues([
            {
              ...values[index],
              subCategoryId: selectedOption.id,
              subCategory: selectedOption.value,
              skillsHave: [
                {
                  skill: "",
                  skillId: "",
                  level: "",
                },
              ],
            },
          ]);
      } else if (isFor === "skillsHave") {
        if (selectedOption.id !== 400)
          setValues((prevSkillValues) => {
            const newSkillValues = [...prevSkillValues];
            newSkillValues[index].skillsHave[childIndex] = {
              ...newSkillValues[index].skillsHave[childIndex],
              skill: selectedOption ? selectedOption.value : "",
              skillId: selectedOption ? selectedOption.id : "",
              level: "",
            };
            return newSkillValues;
          });
      } else if (isFor === "skillLevel") {
        if (selectedOption.id !== 400)
          setValues((prevSkillValues) => {
            const newSkillValues = [...prevSkillValues];
            newSkillValues[index].skillsHave[childIndex] = {
              ...newSkillValues[index].skillsHave[childIndex],
              level: selectedOption ? selectedOption.value : "",
            };
            return newSkillValues;
          });
      }
    } else {
      if (isFor === "mainCat") {
        if (selectedOption.id !== 400)
          setValues({
            ...values,
            mainCategoryId: selectedOption.id,
            mainCategory: selectedOption.value,
            subCategoryId: "",
            subCategory: "",
            skillsHave: [
              {
                skill: "",
                skillId: "",
                level: "",
              },
            ],
            skill: "",
            skillId: "",
            level: "",
          });
        setErrors({
          ...errors,
          skillError: {
            ...errors.skillError,
            mainCategory: selectedOption.value
              ? ""
              : errors.skillError.mainCategory,
          },
        });
        console.log(childIndex);
      } else if (isFor === "subCat") {
        if (selectedOption.id !== 400)
          setValues({
            ...values,
            subCategoryId: selectedOption.id,
            subCategory: selectedOption.value,
            skillsHave: [
              {
                skill: "",
                skillId: "",
                level: "",
              },
            ],
            skill: "",
            skillId: "",
            level: "",
          });
        setErrors({
          ...errors,
          skillError: {
            ...errors.skillError,
            subCategory: selectedOption.value
              ? ""
              : errors.skillError.subCategory,
          },
        });
      } else if (isFor === "skillsHave") {
        if (selectedOption.id !== 400)
          setValues((prevSkillValues) => {
            const newSkillValues = { ...prevSkillValues };
            newSkillValues.skillsHave[childIndex] = {
              ...newSkillValues.skillsHave[childIndex],
              skill: selectedOption ? selectedOption.value : "",
              skillId: selectedOption ? selectedOption.id : "",
              level: "",
            };
            setErrors({
              ...errors,
              skillError: {
                ...errors.skillError,
                skill: selectedOption.value ? "" : errors.skillError.skill,
              },
            });
            return newSkillValues;
          });
      } else if (isFor === "skillLevel") {
        if (selectedOption.id !== 400)
          setValues((prevSkillValues) => {
            const newSkillValues = { ...prevSkillValues };
            newSkillValues.skillsHave[childIndex] = {
              ...newSkillValues.skillsHave[childIndex],
              level: selectedOption ? selectedOption.value : "",
            };
            setErrors({
              ...errors,
              skillError: {
                ...errors.skillError,
                level: selectedOption.value ? "" : errors.skillError.level,
              },
            });
            return newSkillValues;
          });
      } else if (isFor === "singleSkill") {
        if (selectedOption.id !== 400)
          setValues({
            ...values,
            skillId: selectedOption.id,
            skill: selectedOption.value,
            level: "",
          });
      } else if (isFor === "singleSkillLevel") {
        if (selectedOption.id !== 400)
          setValues({
            ...values,
            level: selectedOption ? selectedOption.value : "",
          });
      }
    }
  };

  // Handling user typing inputs
  const handleInputChange = (event, isCapital, isSmall) => {
    // console.log(event.target, isCapital);
    const { name, value } = event.target;
    if (isCapital) {
      const capitalizedValue = capitalizeFirstLetter(value);
      setValues({
        ...values,
        [name]: capitalizedValue,
      });
    } else {
      const normalValue = value;
      setValues({
        ...values,
        [name]: normalValue,
      });
      setErrors({
        ...errors,
        [name]: normalValue ? "" : errors[name],
      });
    }
  };

  // Handling gender value
  const handleGenderOptionChange = (event) => {
    setValues({
      ...values,
      gender: event.target.value,
    });
    setErrors({
      ...errors,
      gender: event.target.value ? "" : errors.gender,
    });
  };

  // Handling language object values
  const handleKnownLanguageMultiSelectChange = (selectedValue) => {
    const updatedValues = selectedValue.map((language) => language._id);
    setValues({
      ...values,
      knownLanguages: updatedValues,
    });
    setErrors({
      ...errors,
      knownLanguages: "",
    });
    console.log(selectedValue, "hookcall test", updatedValues);
  };

  return {
    handleDropdownChange,
    handleSkillSetChange,
    handleInputChange,
    handleGenderOptionChange,
    handleKnownLanguageMultiSelectChange,
  };
}

export function useCommonHandle() {
  // handling link copy
  const handleCopyLink = (url) => {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        console.log(url, "link copied");
      })
      .catch((error) => {
        console.error("Failed to copy link: ", error);
      });
  };

  return { handleCopyLink };
}

export function useProfileEditHandle({
  valuess,
  setValuess,
  errors,
  setErrors,
}) {
  const handleInputChange = () => {};
  return { handleInputChange };
}

export function useTrimSpace() {
  const [trimmedValue, setTrimmedValue] = useState("");

  const handleInputChange = (value) => {
    const trimmed = value.trim();
    setTrimmedValue(trimmed);
  };
  return [trimmedValue, handleInputChange];
}
