import React, { useRef } from "react";
import {
  CloseButtonWrapper,
  ModalBg,
  PhotoActionButton,
  PhotoActionContainer,
} from "./ModalStyles";
import { IoMdClose } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { BiPlus } from "react-icons/bi";

const PhotoActionModal = ({ setIsOpen, isProfile, onChange, onDelete }) => {
  const profilePhotoRef = useRef(null);

  const handlePhotoDelete = () => {
    onDelete && onDelete();
    setIsOpen(false);
  };

  return (
    <ModalBg>
      <PhotoActionContainer>
        <CloseButtonWrapper onClick={() => setIsOpen(false)} isCorner={true}>
          <IoMdClose />
        </CloseButtonWrapper>
        <header>{isProfile ? "Profile Photo" : "Cover Photo"}</header>
        <input
          type="file"
          ref={profilePhotoRef}
          hidden
          onChange={onChange}
          accept="image/jpeg,image/png,image/webp"
        />
        <PhotoActionButton isRed={true} onClick={handlePhotoDelete}>
          <MdDelete />
          <p>Delete {isProfile ? "Profile" : "Cover"} Photo</p>
        </PhotoActionButton>
        <PhotoActionButton onClick={() => profilePhotoRef.current.click()}>
          <BiPlus />
          <p>Choose {isProfile ? "Profile" : "Cover"} Photo</p>
        </PhotoActionButton>
      </PhotoActionContainer>
    </ModalBg>
  );
};

export default PhotoActionModal;
