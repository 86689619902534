import React from "react";
import {
  DetailSectionContainer,
  LEACSubheading,
  SocialMediaLink,
  SocialMediaWrapper,
  UserDetailHeading,
} from "../OverviewStyle";
import { QuillIcon } from "../../../../Assets/SVG";
import { SocialMediaData } from "../../../../Utils/Others/Data";
import { useSelector } from "react-redux";

const SocialMedia = () => {
  const user = useSelector((state) => state.auth.user);

  return (
    <DetailSectionContainer>
      <UserDetailHeading>
        <span>
          <QuillIcon width={"21px"} />
          Contact & Social Media
        </span>
      </UserDetailHeading>
      <SocialMediaWrapper>
        <LEACSubheading>Contact</LEACSubheading>
        <div>{user?.email}</div>
        <LEACSubheading>Social Media</LEACSubheading>
        {SocialMediaData.map((item, index) => (
          <React.Fragment key={index}>
            {user?.morePersonalInfo?.socialLinks.map((data, dataIndex) => (
              <React.Fragment key={dataIndex}>
                {data.url !== "" && item.name === data.type && (
                  <SocialMediaLink href={data.url}>
                    {item.icon}
                    <p>{data.url}</p>
                  </SocialMediaLink>
                )}
              </React.Fragment>
            ))}
          </React.Fragment>
        ))}
      </SocialMediaWrapper>
    </DetailSectionContainer>
  );
};

export default SocialMedia;
