import React from "react";

function ExploreIcon({ fill, width, height }) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width || "24px"}
      height={height || "24px"}
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill={fill || "var(--dark-primary)"}
        stroke="none"
      >
        <path
          d="M2240 5085 c-58 -7 -133 -18 -165 -24 -58 -12 -84 -19 -275 -74 -189
-55 -456 -183 -645 -310 -607 -407 -1013 -1066 -1101 -1787 -23 -187 -15 -562
15 -736 39 -231 127 -497 236 -714 189 -379 497 -726 855 -964 335 -223 649
-339 1114 -411 158 -25 524 -17 686 15 311 60 493 119 729 237 422 211 735
492 1007 904 70 106 206 385 243 499 18 52 35 102 40 110 10 18 51 195 82 355
27 136 38 511 19 652 -35 268 -117 571 -204 756 -20 42 -36 80 -36 84 0 18
-163 291 -202 338 -9 11 -45 58 -79 105 -143 194 -454 474 -688 618 -231 143
-559 272 -821 322 -204 39 -595 51 -810 25z m660 -381 c181 -31 236 -45 380
-96 112 -39 295 -121 355 -159 332 -208 536 -400 721 -679 74 -110 144 -238
177 -320 14 -36 33 -78 41 -95 23 -46 86 -269 107 -376 45 -241 45 -559 0
-799 -121 -641 -527 -1190 -1111 -1507 -41 -22 -104 -53 -140 -67 -36 -15 -78
-33 -95 -41 -16 -7 -70 -25 -120 -40 -49 -15 -110 -33 -135 -40 -153 -46 -466
-72 -674 -55 -157 12 -300 34 -361 55 -22 7 -82 25 -133 39 -52 15 -117 37
-146 51 -29 14 -56 25 -60 25 -18 0 -218 107 -306 163 -318 204 -601 518 -766
850 -161 324 -249 751 -225 1087 9 119 41 321 67 425 60 241 209 545 373 760
241 317 592 576 973 716 144 53 182 63 358 96 265 48 464 50 720 7z"
        />
        <path
          d="M2865 3511 c-560 -280 -807 -409 -823 -428 -12 -16 -200 -385 -418
        -820 l-397 -793 5 -55 c8 -97 84 -165 185 -165 49 0 104 26 840 394 476 237
        799 405 817 422 17 18 185 341 422 817 368 735 394 791 394 840 0 103 -68 178
        -168 185 l-57 3 -800 -400z m184 -692 l-243 -484 -478 -240 c-264 -132 -482
        -241 -486 -243 -4 -1 101 215 233 480 l241 483 485 245 c266 135 485 244 487
        244 1 -1 -106 -219 -239 -485z"
        />
      </g>
    </svg>
  );
}
export default ExploreIcon;
