import React from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { MdClose } from "react-icons/md";
import { AiOutlineLogout } from "react-icons/ai";
import styled from "styled-components";
import {
  LogoutBtn,
  RedIndicator,
  SbarLogoWrapper,
  SidebarHeader,
  SidebarLinks,
  SidebarLogo,
  SidebarMenu,
} from "./SidebarStyle";
import { SidebarItemList } from "../../Utils/Others/Data";
import { LightLogo } from "../../Assets/Images";
import { setLogout } from "../../ReduxToolKit/Slices/authSlice";
import {
  setBackNavigate,
  setIsAbout,
  setIsProfileEdit,
  setIsTalent,
} from "../../ReduxToolKit/Slices/utilsSlice";

const SidebarWrapper = styled.section`
  height: 100%;
  width: 100%;
  display: flex;
  position: fixed;
  transition: 0.2s ease-in-out;
  top: 0;
  left: 0;
  z-index: 99;
  opacity: ${({ isOpen }) => (isOpen ? "100%" : "0")};
  left: ${({ isOpen }) => (isOpen ? "0" : "100%")};
  background: ${(props) => props.theme.otpBg};
`;

const SidebarContainer = styled.aside`
  height: 100%;
  width: 16rem;
  background: ${(props) => props.theme.altBg};
  position: relative;
  transition: 0.2s ease-in-out;
  right: ${({ isOpen }) => (isOpen ? "0" : "200%")};
  padding: 0.5rem 1rem 2rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const SidebarClose = styled(MdClose)`
  position: absolute;
  font-size: 1.5rem;
  color: white;
  top: 1.5rem;
  right: -1rem;
  background: ${(props) => props.theme.altBg};
  border-radius: 100%;
  width: 2.5rem;
  height: 2.5rem;
  padding: 0.5rem;
  cursor: pointer;
`;

const MobileSidebar = ({ isOpen, setIsOpen }) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(setIsAbout({ isAbout: false }));
    dispatch(setIsTalent({ isTalent: false }));
    dispatch(setIsProfileEdit({ isProfileEdit: false }));
    dispatch(setLogout());
  };

  const handleLinkClick = () => {
    setIsOpen(false);
    dispatch(setBackNavigate({ backNavigate: 0 }));
  };

  return (
    <SidebarWrapper isOpen={isOpen}>
      <SidebarContainer isOpen={isOpen}>
        <SidebarClose onClick={() => setIsOpen(false)} />
        <SidebarHeader>
          <SbarLogoWrapper>
            <SidebarLogo src={LightLogo} alt="logo" />
          </SbarLogoWrapper>
          <SidebarMenu>
            {SidebarItemList.map((item, index) => (
              <SidebarLinks
                to={item.link}
                key={index}
                isActive={
                  pathname === `${item.link}` ||
                  pathname.startsWith(`${item.link}`)
                }
                onClick={handleLinkClick}
              >
                {item.icon}
                {item.name}
                {(pathname === `${item.link}` ||
                  pathname.startsWith(`${item.link}`)) && <RedIndicator />}
              </SidebarLinks>
            ))}
          </SidebarMenu>
        </SidebarHeader>
        <LogoutBtn onClick={handleLogout}>
          <AiOutlineLogout />
          <p>Logout</p>
        </LogoutBtn>
      </SidebarContainer>
    </SidebarWrapper>
  );
};

export default MobileSidebar;
