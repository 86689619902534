import {
  commentPostAction,
  createSaveCollectionAction,
  createSharePostAction,
  homeFeedViewAction,
  pinnedPostViewAction,
  postDeleteAction,
  postPinningAction,
  postRatingAction,
  postRemovePinAction,
  profileAllpostViewAction,
  saveToSpecificCollectionAction,
  unsaveToSpecificCollectionAction,
} from "../ReduxToolKit/APIs/PostAPI";
import {
  setUpdatePost,
  setUpdateUserAllPost,
  setUserPinnedPostsById,
} from "../ReduxToolKit/Slices/postSlice";
import { setAlertDetails } from "../ReduxToolKit/Slices/utilsSlice";
import {
  getPostMoreDetails,
  getPostSaveCollection,
  getProfileAllposts,
  getProfilePinnedPost,
  getViewAllPost,
} from "./getFetchFunctions";
import { getMediaUpload } from "./getFileUpload";

export async function handleCreateShareSubmit(
  values,
  dispatch,
  navigate,
  currentUserId
) {
  try {
    const formData = {
      type: values.type,
      userId: values.userId,
      media: [
        // {
        //   mediaType: "image",
        //   thumbnailUrl: "",
        //   thumbnailUrlKey: "",
        //   url: "https://te-files-media.s3.eu-west-2.amazonaws.com/657160e126fd60915f674078-1711952627365.jpeg",
        //   urlKey: "657160e126fd60915f674078-1711952627365.jpeg",
        // },
      ],
      website: null,
      shareLinkThumbnail: null,
      about: {
        title: values.title,
        description: values.description,
        mainCategoryId: values.mainCategoryId,
        subCategoryId: values.subCategoryId,
        skillId: values.skillId,
        level: values.level,
        primaryLanguage: values.primaryLanguageId,
        secondaryLanguage: values.secondaryLanguageId,
        ownRating: values.ownRating,
        keywords: "",
        country: values.country,
        state: values.state,
        peopleTag: [],
        productionName: values.productionName,
        // productionDate: "",
        productionDate: values.productionDate,
      },
      // isCreditWork: false,
      isCreditWork: values.isCreditWork,
      credit: [],
      toolsUsed: [],
    };

    // media uplaod
    for (let i = 0; i < values.mediaValues.length; i++) {
      const postShareMediaKey = await getMediaUpload(
        values.mediaValues[i].croppedData,
        currentUserId
      );
      const media = {
        mediaType: values.mediaValues[i].mediaType,
        url: postShareMediaKey.url,
        urlKey: postShareMediaKey.key,
        thumbnailUrl: "",
        thumbnailUrlKey: "",
      };
      formData.media.push(media);
    }

    // people tag
    for (let i = 0; i < values.peopleTag.length; i++) {
      const peopleTags = values.peopleTag[i]._id;
      console.log(peopleTags);
      formData.about.peopleTag.push(peopleTags);
    }

    // credit title and people tags
    for (let i = 0; i < values.credit.length; i++) {
      const creditPeopleTag = [];

      for (let j = 0; j < values.credit[i].peopleTag.length; j++) {
        const creditPeopleTags = values.credit[i].peopleTag[j]._id;
        creditPeopleTag.push(creditPeopleTags);
      }
      const newCredit = {
        creditTitle: values.credit[i].creditTitle,
        peopleTag: creditPeopleTag,
      };
      formData.credit.push(newCredit);
    }

    // tools names and levels
    for (let i = 0; i < values.toolsUsed.length; i++) {
      const newUsedTools = {
        toolName: values.toolsUsed[i].toolName,
        level: values.toolsUsed[i].level,
      };
      formData.toolsUsed.push(newUsedTools);
    }

    console.log(
      "-------------------------------------------------------------"
    );
    console.log(formData);
    await dispatch(createSharePostAction(formData)).then((actionResult) => {
      const responseData = actionResult.payload;
      console.log(responseData, "createSharePostAction");
      if (responseData && parseInt(responseData.status) === 7400) {
        navigate("/feed");
        dispatch(
          setAlertDetails({
            alertDetails: {
              isOpen: true,
              type: "success",
              message: "Post Uploaded Successfully!",
            },
          })
        );
      } else {
        dispatch(
          setAlertDetails({
            alertDetails: {
              isOpen: true,
              type: "warning",
              message: "Couldn't upload the post",
            },
          })
        );
      }
    });
    // dispatch the new post into the feed
  } catch {}
}

export async function handlePostRating(
  currentUserId,
  postId,
  postOwnerId,
  ratingValue,
  dispatch,
  pageNo,
  pageSize,
  forThis,
  setPostData
) {
  try {
    const formData = {
      userId: currentUserId,
      postId: postId,
      postOwnerId: postOwnerId,
      rating: ratingValue,
    };
    const userId = currentUserId;
    dispatch(postRatingAction(formData)).then((actionResult) => {
      const responseData = actionResult.payload;
      if (parseInt(responseData.status) === 7400) {
        if (forThis === "forMoreDetailsPost") {
          getPostMoreDetails(postId, userId, dispatch, setPostData);
        } else {
          dispatch(
            homeFeedViewAction({ currentUserId, userId, pageNo, pageSize })
          ).then((actionResult) => {
            const responseData = actionResult.payload;

            if (responseData && parseInt(responseData.status) === 7400) {
              const posts = responseData.response;

              // Find the post with the matching postId
              const updatedPost = posts.find((post) => post._id === postId);
              if (updatedPost) {
                console.log(
                  "============ if update",
                  updatedPost,
                  posts[postId]
                );
                dispatch(setUpdatePost(updatedPost));
              }
            }
          });
        }
      }
    });
  } catch {}
}

export async function handlePinning(
  userId,
  postId,
  dispatch,
  pageNo,
  pageSize,
  forThis
) {
  try {
    const formData = {
      userId: userId,
      postId: postId,
    };
    const currentUserId = userId;
    dispatch(postPinningAction(formData)).then((actionResult) => {
      const responseData = actionResult.payload;
      if (parseInt(responseData.status) === 7400) {
        // when the pin is pinned on the feed section
        if (forThis === "forPost") {
          dispatch(
            homeFeedViewAction({ currentUserId, userId, pageNo, pageSize })
          ).then((actionResult) => {
            const responseData = actionResult.payload;

            if (responseData && parseInt(responseData.status) === 7400) {
              const posts = responseData.response;
              const updatedPost = posts.find((post) => post._id === postId);
              if (updatedPost) {
                dispatch(setUpdatePost(updatedPost));
              }
            }
          });
          // when the pin is pinned on the profile pinned posts section
        } else if (forThis === "forUserAllPost") {
          dispatch(
            profileAllpostViewAction({
              currentUserId,
              userId,
              pageNo,
              pageSize,
            })
          ).then((actionResult) => {
            const responseData = actionResult.payload;

            if (responseData && parseInt(responseData.status) === 7400) {
              const posts = responseData.response;
              const updatedPost = posts.find((post) => post._id === postId);
              if (updatedPost) {
                dispatch(setUpdateUserAllPost(updatedPost));
              }
            }
          });
        }
      }
    });
  } catch {}
}

export async function handleRemovePin(
  userId,
  postId,
  dispatch,
  pageNo,
  pageSize,
  forThis
) {
  try {
    const formData = {
      userId: userId,
      postId: postId,
    };
    const currentUserId = userId;
    dispatch(postRemovePinAction(formData)).then((actionResult) => {
      const responseData = actionResult.payload;
      if (parseInt(responseData.status) === 7400) {
        // when the pin is remove from the feed section
        if (forThis === "forPost") {
          dispatch(
            homeFeedViewAction({ currentUserId, userId, pageNo, pageSize })
          ).then((actionResult) => {
            const responseData = actionResult.payload;

            if (responseData && parseInt(responseData.status) === 7400) {
              const posts = responseData.response;
              const updatedPost = posts.find((post) => post._id === postId);
              if (updatedPost) {
                dispatch(setUpdatePost(updatedPost));
              }
            }
          });
          // when the pin is remove from the profile pinned posts section
        } else if (forThis === "forUserAllPost") {
          dispatch(
            profileAllpostViewAction({
              currentUserId,
              userId,
              pageNo,
              pageSize,
            })
          ).then((actionResult) => {
            const responseData = actionResult.payload;
            if (responseData && parseInt(responseData.status) === 7400) {
              const posts = responseData.response;
              const updatedPost = posts.find((post) => post._id === postId);
              if (updatedPost) {
                dispatch(setUpdateUserAllPost(updatedPost));
              }
            }
          });
          // when the pin is remove from the user all posts section
        } else if (forThis === "forPinned") {
          dispatch(
            pinnedPostViewAction({ currentUserId, userId, pageNo, pageSize })
          ).then((actionResult) => {
            const responseData = actionResult.payload;
            if (responseData && parseInt(responseData.status) === 7400) {
              const posts = responseData.response;
              dispatch(setUserPinnedPostsById({ userPinnedPostsById: posts }));
            }
          });
        }
      }
    });
  } catch {}
}

export async function handleDeletePost(
  userId,
  postId,
  dispatch,
  pageNo,
  pageSize,
  forThis
) {
  try {
    const currentUserId = userId;
    dispatch(postDeleteAction(postId)).then((actionResult) => {
      const responseData = actionResult.payload;
      if (parseInt(responseData.status) === 7400) {
        // when delete post from the feed section
        if (forThis === "forFeedPost") {
          getViewAllPost(currentUserId, userId, pageNo, pageSize, dispatch);
          // when delete post from the profile pinned posts section
        } else if (forThis === "forUserAllPost") {
          getProfileAllposts(currentUserId, userId, pageNo, pageSize, dispatch);
          // when delete post from the user all posts section
        } else if (forThis === "forPinned") {
          getProfilePinnedPost(
            currentUserId,
            userId,
            pageNo,
            pageSize,
            dispatch
          );
        }
      }
    });
  } catch {}
}

export async function handleCommentPost(
  dispatch,
  currentUserId,
  postId,
  comment,
  commentData,
  setCommentData
) {
  try {
    const formData = {
      userId: currentUserId,
      postId: postId,
      comment: comment,
    };
    dispatch(commentPostAction(formData)).then((actionResult) => {
      const responseData = actionResult.payload;
      console.log("responseData-of-comment", responseData);
      if (parseInt(responseData.status) === 7400) {
        dispatch(
          setAlertDetails({
            alertDetails: {
              isOpen: true,
              type: "success",
              message: "Comment added to the post",
            },
          })
        );
        setCommentData &&
          setCommentData([responseData.response, ...commentData]);
        // when delete post from the feed section
        // if (forThis === "forFeedPost") {
        //   getViewAllPost(currentUserId, userId, pageNo, pageSize, dispatch);
        //   // when delete post from the profile pinned posts section
        // } else if (forThis === "forUserAllPost") {
        //   getProfileAllposts(currentUserId, userId, pageNo, pageSize, dispatch);
        //   // when delete post from the user all posts section
        // } else if (forThis === "forPinned") {
        //   getProfilePinnedPost(
        //     currentUserId,
        //     userId,
        //     pageNo,
        //     pageSize,
        //     dispatch
        //   );
        // }
      }
    });
  } catch {}
}

export async function handleCreateSaveCollection(
  dispatch,
  currentUserId,
  collectionName,
  saveCollectionList,
  setSaveCollectionList
) {
  try {
    const data = {
      userId: currentUserId,
      collectionName: collectionName,
    };
    dispatch(createSaveCollectionAction(data)).then((actionResult) => {
      const responseData = actionResult.payload;
      console.log("responseData-of-comment", responseData);
      if (parseInt(responseData.status) === 7400) {
        dispatch(
          setAlertDetails({
            alertDetails: {
              isOpen: true,
              type: "success",
              message: "New Collection Created",
            },
          })
        );
        var collectionObj = {
          _id: responseData.response.id,
          collectionName: responseData.response.collectionName,
          status: false,
        };
        setSaveCollectionList([...saveCollectionList, collectionObj]);
      }
    });
  } catch {
    dispatch(
      setAlertDetails({
        alertDetails: {
          isOpen: true,
          type: "warning",
          message: "Couldn't create a collection",
        },
      })
    );
  }
}

export async function handleSaveToCollection(
  collectionId,
  collectionName,
  postId,
  dispatch,
  setSaveCollectionList,
  currentUserId,
  onClose
) {
  try {
    dispatch(saveToSpecificCollectionAction({ collectionId, postId })).then(
      (actionResult) => {
        const responseData = actionResult.payload;
        console.log("responseData-of-saved", responseData);
        if (parseInt(responseData.status) === 7400) {
          dispatch(
            setAlertDetails({
              alertDetails: {
                isOpen: true,
                type: "success",
                message: `Post Saved to ${collectionName}`,
              },
            })
          );
          getPostSaveCollection(
            currentUserId,
            postId,
            dispatch,
            setSaveCollectionList
          ).then(() => {
            onClose && onClose(false);
          });
        } else {
          dispatch(
            setAlertDetails({
              alertDetails: {
                isOpen: true,
                type: "warning",
                message: "Couldn't Save the post",
              },
            })
          );
        }
      }
    );
  } catch {}
}

export async function handleUnSaveToCollection(
  collectionId,
  postId,
  dispatch,
  setSaveCollectionList,
  currentUserId
) {
  try {
    dispatch(unsaveToSpecificCollectionAction({ collectionId, postId })).then(
      (actionResult) => {
        const responseData = actionResult.payload;
        console.log("responseData-of-unsaved", responseData);
        if (parseInt(responseData.status) === 7400) {
          dispatch(
            setAlertDetails({
              alertDetails: {
                isOpen: true,
                type: "success",
                message: "Post Unsaved",
              },
            })
          );
          getPostSaveCollection(
            currentUserId,
            postId,
            dispatch,
            setSaveCollectionList
          );
        }
      }
    );
  } catch {}
}
