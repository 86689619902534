import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  AuthButton,
  AuthError,
  AuthForm,
  AuthFormFooter,
  AuthLink,
  AuthPage,
  AuthPasswordIconWrapper,
  AuthPasswordWrapper,
  ForgotPwdBtn,
  ForgotPwdWrap,
  HR,
  PasswordEyeIcon,
} from "./AuthStyle";
import OTPVerification from "./OTPVerification";
import {
  loginFormValidation,
  signupFormValidation,
} from "../../Utils/Validations/AuthValidations";
import ForgotPassword from "./ForgotPassword";
import usePasswordToggle from "../../Hooks/usePasswordToggle";
import { handleLogin, handleSignup } from "../../Functions/getAuthHandler";
import { useHandleValues, useModalOpen } from "../../Hooks/useEventHandler";
import { AuthInput } from "../../Components/Inputs/InputStyle";
import { Helmet } from "react-helmet";
import { DarkLogo } from "../../Assets/Images";

const Auth = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isLogin = pathname === "/login";
  const { isOpen, setIsOpen } = useModalOpen();
  const [openForgotPwdModal, setForgotPwdModal] = useState(false);

  const { PasswordInputType, ToggleIcon, togglePasswordVisibility } =
    usePasswordToggle();
  const [values, setValues] = useState({
    userName: "",
    email: "",
    password: "",
    deviceId: "z",
    appVersion: "web-2.0.0",
  });
  const [errors, setErrors] = useState([]);
  const { handleInputChange } = useHandleValues({
    values,
    setValues,
    errors,
    setErrors,
  });

  const validateField = (fieldName) => {
    if (isLogin) {
      const error = loginFormValidation({ [fieldName]: values[fieldName] })[
        fieldName
      ];
      setErrors((prevState) => ({
        ...prevState,
        [fieldName]: error,
      }));
    } else {
      const error = signupFormValidation({ [fieldName]: values[fieldName] })[
        fieldName
      ];
      setErrors((prevState) => ({
        ...prevState,
        [fieldName]: error,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isLogin) {
      const formErrors = loginFormValidation(values);
      setErrors(formErrors);

      if (Object.keys(formErrors).length === 0) {
        handleLogin(values, dispatch, navigate);
      }
    } else {
      const formErrors = signupFormValidation(values);
      setErrors(formErrors);
      if (Object.keys(formErrors).length === 0) {
        handleSignup(values, dispatch, setIsOpen);
      }
    }
  };

  useEffect(() => {
    console.log(
      <img
        src="/Public_Images/default_profile_pic.png"
        alt=""
        width={"100rem"}
        height={"100rem"}
        style={{ border: "1px solid red" }}
      />,
      `${process.env.REACT_APP_API_URL}/joinus`
    );
  }, []);

  return (
    <>
      {isLogin ? (
        <Helmet>
          <title>Login | Talents Explore</title>
          <meta property="og:image" content={`${DarkLogo}`} />
          <meta name="description" content="Login into your account" />
          <meta charSet="utf-8" />
          <link
            rel="canonical"
            content={`${process.env.REACT_APP_API_URL}/login`}
          />
          {/* <meta property="og:type" content="website" /> */}
        </Helmet>
      ) : (
        <Helmet>
          <title>Joinus | Talents Explore</title>
          <meta
            property="og:image"
            content={`Public_Images/default_profile_pic.png}`}
          />
          <meta name="description" content="Create your free account" />
          <link
            rel="canonical"
            content={`${process.env.REACT_APP_API_URL}/joinus`}
          />
          {/* <meta
            property="og:url"
            name="url"
            content={`${process.env.REACT_APP_API_URL}/joinus`}
          /> 
          <meta property="og:type" content="website" />
          */}
        </Helmet>
      )}

      <AuthPage>
        <AuthForm onSubmit={handleSubmit}>
          {isLogin ? (
            <>
              <h1>
                Welcome Back <br />
                to Talents Explore!
              </h1>
              <h4>Please Log In to Your Account</h4>
            </>
          ) : (
            <>
              <h1>
                Sign Up & <br /> Start Exploring
              </h1>
              <h4>Your Adventure Awaits!</h4>
            </>
          )}
          {!isLogin && (
            <>
              <AuthInput
                type="text"
                placeholder="Username"
                id="userName"
                name="userName"
                value={values.userName}
                error={errors.userName}
                onChange={handleInputChange}
                onBlur={() => validateField("userName")}
              />
              {errors.userName && <AuthError>{errors.userName}</AuthError>}
            </>
          )}
          <AuthInput
            type="email"
            id="email"
            name="email"
            placeholder={isLogin ? "Enter your email" : "sample@gmail.com"}
            value={values.email}
            error={errors.email}
            onChange={handleInputChange}
            onBlur={() => validateField("email")}
          />
          {errors.email && <AuthError>{errors.email}</AuthError>}

          <AuthPasswordWrapper>
            <AuthInput
              type={PasswordInputType}
              placeholder="Password"
              id="password"
              name="password"
              value={values.password}
              error={errors.password}
              onChange={handleInputChange}
              onBlur={() => validateField("password")}
            />
            <AuthPasswordIconWrapper>
              <PasswordEyeIcon onClick={togglePasswordVisibility}>
                {ToggleIcon}
              </PasswordEyeIcon>
            </AuthPasswordIconWrapper>
          </AuthPasswordWrapper>
          {errors.password && <AuthError>{errors.password}</AuthError>}

          {isLogin && (
            <ForgotPwdWrap>
              <ForgotPwdBtn onClick={() => setForgotPwdModal(true)}>
                forgot password?
              </ForgotPwdBtn>
            </ForgotPwdWrap>
          )}
          <AuthButton>{isLogin ? "Sign in" : "Join us"}</AuthButton>
          <HR />
          <AuthFormFooter>
            {isLogin ? (
              <>
                Don't Have an Account?
                <AuthLink to="/joinus">Join us</AuthLink>
              </>
            ) : (
              <>
                Already Have an Account?{" "}
                <AuthLink to="/login">Sign in</AuthLink>
              </>
            )}
          </AuthFormFooter>
        </AuthForm>
        {isOpen && <OTPVerification isOpen={isOpen} closeModal={setIsOpen} />}
        {openForgotPwdModal && (
          <ForgotPassword closeModal={setForgotPwdModal} />
        )}
      </AuthPage>
    </>
  );
};

export default Auth;
