import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mode: "light",
  isAbout: false,
  isTalent: false,
  isProfileEdit: false,
  isProfileEditSubmit: false,
  alertDetails: {},
  isLoading: false,
  backNavigate: 0,
};

export const utilsSlice = createSlice({
  name: "utils",
  initialState,
  reducers: {
    setMode: (state) => {
      state.mode = state.mode === "light" ? "dark" : "light";
    },
    setIsAbout: (state, action) => {
      state.isAbout = action.payload.isAbout;
    },
    setIsTalent: (state, action) => {
      state.isTalent = action.payload.isTalent;
    },
    setAlertDetails: (state, action) => {
      state.alertDetails = action.payload.alertDetails;
    },
    setIsProfileEdit: (state, action) => {
      state.isProfileEdit = action.payload.isProfileEdit;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload.isLoading;
    },
    setBackNavigate: (state, action) => {
      state.backNavigate = action.payload.backNavigate;
    },
    setIsProfileEditSubmit: (state, action) => {
      state.isProfileEditSubmit = action.payload.isProfileEditSubmit;
    },
  },
});

export const {
  setMode,
  setIsAbout,
  setIsTalent,
  setAlertDetails,
  setIsProfileEdit,
  setIsLoading,
  setBackNavigate,
  setIsProfileEditSubmit,
} = utilsSlice.actions;
export default utilsSlice.reducer;

// setIsProfileEditSubmit: (state) => {
// state.isProfileEditSubmit =
// state.isProfileEditSubmit === true ? false : true;
// },
