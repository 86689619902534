import React from "react";
import { AddAnotherWrapper, InfoSectionWrapper } from "./TalentsInfoStyle";
import {
  FormPageDataWrapper,
  TerritoryBtn,
} from "../../Components/Common/CommonStyle";
import { DropDown } from "../../Components";
import AddedInfoSection from "./AddedInfoSection";
import useDateSelector from "../../Hooks/useDateSelector";
import { FormInput } from "../../Components";

const AwardsCertificateInfo = ({
  values,
  errors,
  handleInputChange,
  handleDropdownChange,
  handleAddNewAward,
  addedAwardValues,
  handleAddedAwardDelete,
}) => {
  const { years } = useDateSelector({});
  const labelWidth = "14rem";
  return (
    <InfoSectionWrapper>
      <FormPageDataWrapper width="55%">
        <FormInput
          name="awardName"
          label="Award or Certificate Name"
          type="text"
          placeholder="Name of the Award or Certificate"
          value={values.awardName}
          onChange={handleInputChange}
          validation={errors.awardName && errors.awardName}
          isHorz={true}
          isItemStart={true}
          labelWidth={labelWidth}
        />
        <FormInput
          name="givenBy"
          label="Given By"
          type="text"
          placeholder="Relavent Authority Name"
          value={values.givenBy}
          onChange={handleInputChange}
          validation={errors.givenBy && errors.givenBy}
          isHorz={true}
          isItemStart={true}
          labelWidth={labelWidth}
        />

        <DropDown
          name="when"
          label="Year"
          labelWidth={labelWidth}
          defaultOption="Given on"
          options={years.map(({ value, label }) => ({
            value,
            label,
          }))}
          value={values.when}
          onChange={handleDropdownChange}
          validation={errors.when && errors.when}
          isSingle={true}
          isHorz={true}
          isItemStart={true}
        />

        <AddAnotherWrapper>
          <TerritoryBtn onClick={() => handleAddNewAward(values)} width="auto">
            Add
          </TerritoryBtn>
        </AddAnotherWrapper>
      </FormPageDataWrapper>

      <AddedInfoSection
        addedInfoValues={addedAwardValues}
        addedType={"addedAward"}
        handleAddedAwardDelete={handleAddedAwardDelete}
      />
    </InfoSectionWrapper>
  );
};

export default AwardsCertificateInfo;
