import React from "react";

function NoUserFoundSVG({ pmClr, subClr, width, height }) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width || "1660.000000pt"}
      height={height || "552.000000pt"}
      viewBox="0 0 1660.000000 552.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,552.000000) scale(0.100000,-0.100000)"
        // fill="#000000"
        stroke="none"
      >
        <path
          d="M892 5070 c-109 -29 -187 -74 -272 -160 -87 -86 -131 -163 -160 -276
-20 -75 -20 -117 -20 -1879 0 -1762 0 -1804 20 -1879 29 -113 73 -190 160
-276 86 -87 163 -131 276 -160 77 -20 140 -20 7384 -20 7244 0 7307 0 7384 20
113 29 190 73 276 160 87 86 131 163 160 276 20 75 20 117 20 1879 0 1762 0
1804 -20 1879 -29 113 -73 190 -160 276 -86 87 -163 131 -276 160 -77 20 -139
20 -7388 19 -7166 0 -7312 0 -7384 -19z m14790 -217 c93 -44 151 -100 196
-191 l37 -76 0 -1831 0 -1831 -37 -76 c-45 -91 -103 -147 -196 -191 l-67 -32
-7335 0 -7335 0 -67 32 c-93 44 -151 100 -196 191 l-37 76 0 1830 0 1831 32
67 c17 37 50 87 72 111 44 48 135 101 197 116 23 5 2970 8 7354 8 l7315 -2 67
-32z"
          fill={subClr || "#000"}
        />
        <path
          d="M2860 4319 c-364 -47 -668 -196 -925 -453 -162 -163 -271 -325 -349
-520 -153 -380 -153 -802 0 -1182 58 -144 165 -322 262 -434 l39 -44 18 34
c40 79 151 201 245 272 107 80 287 158 440 189 52 11 120 25 150 31 74 16 526
16 600 0 30 -6 98 -21 151 -32 96 -20 258 -76 308 -108 200 -124 312 -232 388
-376 9 -16 16 -12 62 44 376 452 474 1063 255 1606 -141 351 -436 665 -777
828 -267 128 -587 181 -867 145z m358 -574 c108 -26 198 -78 287 -169 44 -44
87 -94 95 -111 8 -16 17 -32 20 -35 9 -9 40 -81 40 -93 0 -7 7 -25 15 -41 25
-47 37 -201 25 -301 -6 -47 -15 -94 -20 -103 -4 -9 -18 -46 -30 -82 -13 -36
-30 -76 -39 -90 -9 -14 -23 -38 -31 -54 -28 -55 -153 -171 -231 -213 -211
-115 -398 -116 -613 -4 -124 64 -268 232 -306 356 -7 22 -16 45 -20 50 -11 15
-28 102 -35 180 -13 148 38 343 119 454 90 123 235 224 364 255 77 19 281 20
360 1z"
          fill={subClr || "#000"}
        />
        <path
          d="M2931 3549 c-51 -10 -126 -47 -187 -93 -63 -47 -124 -150 -152 -256
-14 -51 -7 -213 11 -260 3 -8 11 -33 17 -55 29 -96 141 -219 245 -270 106 -52
283 -44 385 18 56 34 130 107 167 166 95 148 99 410 9 546 -41 63 -108 128
-155 152 -20 10 -39 21 -42 26 -9 13 -141 37 -199 36 -30 -1 -75 -5 -99 -10z"
          fill={subClr || "#000"}
        />
        <path
          d="M5625 3746 c-42 -18 -83 -69 -91 -112 -3 -19 -4 -62 -2 -94 5 -64 23
-98 72 -134 l27 -21 4589 0 4589 0 27 21 c59 43 69 68 69 164 0 96 -10 121
-69 164 l-27 21 -4577 2 c-3790 2 -4582 0 -4607 -11z"
          fill={subClr || "#000"}
        />
        <path
          d="M5625 2936 c-42 -18 -83 -69 -91 -112 -3 -19 -4 -62 -2 -94 5 -64 23
-98 72 -134 l27 -21 2244 0 2244 0 27 21 c59 43 69 68 69 164 0 96 -10 121
-69 164 l-27 21 -2232 2 c-1840 2 -2237 0 -2262 -11z"
          fill={subClr || "#000"}
        />
        <path
          d="M2890 2013 c-261 -15 -488 -89 -640 -209 -95 -75 -210 -232 -196
-269 8 -21 212 -153 311 -200 427 -205 933 -205 1360 0 106 51 305 180 305
198 -1 27 -44 104 -92 163 -86 106 -221 198 -349 238 -30 9 -61 21 -71 26 -28
16 -178 40 -308 50 -120 9 -202 10 -320 3z"
          fill={subClr || "#000"}
        />
        <circle cx="14500" cy="525" r="950" fill={pmClr || "#6c63ff"} />
      </g>
    </svg>
  );
}

export default NoUserFoundSVG;
