import styled from "styled-components";

export const EditProfileContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 5rem 13rem 1rem 13rem;
  width: 100%;
  max-width: 1700px;

  @media screen and (max-width: 1550px) {
    padding: 5rem 5rem 1rem 5rem;
  }
  @media screen and (max-width: 950px) {
    padding: 5rem 0rem 4rem 0;
  }
`;

export const EditTitle = styled.h1`
  font-size: 1.8rem;
  color: ${(props) => props.theme.dark};
  margin-bottom: -1.8rem;
  @media screen and (max-width: 950px) {
    font-size: 1.3rem;
    display: grid;
    place-items: center;
    /* border-bottom: 1px solid ${(props) => props.theme.borderColor}; */
    padding-bottom: 10px;
  }
`;

export const EditDetailSection = styled.section`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(28rem, 28rem));
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;

  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-between;
  @media screen and (max-width: 650px) {
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
`;

export const EditFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 90%;
  margin-top: 1rem;
  @media screen and (max-width: 750px) {
    width: 100%;
  }
`;

export const EditPhotosWrapper = styled.div`
  width: 100%;
  max-width: 1300px;
  padding: 1rem 0 3.5rem 0;
  position: relative;
  @media screen and (max-width: 1300px) {
    /* width: 80%; */
  }
  @media screen and (max-width: 950px) {
    width: 100%;
  }
`;

export const GenderOptionWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  max-width: 19.5rem;
`;

export const EditProfileFooter = styled.footer`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  @media screen and (max-width: 650px) {
    flex-direction: column;
    padding: 0 1rem;
  }
`;
