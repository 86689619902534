import React, { useRef, useState } from "react";
import {
  ProfilePhotoContainerWrapper,
  PrsnlProfileImg,
} from "./PersonalInfoStyle";
import { CameraIcon } from "../../Assets/SVG";
import useImageInputType from "../../Hooks/useImageInputType";
import CropProfileCoverPhoto from "../../Components/Modals/CropProfileCoverPhoto";
import { useModalOpen } from "../../Hooks/useEventHandler";
import { CameraButton } from "../../Components/Common/CommonStyle";
import ErrorModal from "../../Components/Modals/ErrorModal";
import { MdEdit } from "react-icons/md";
import { PhotoActionModal } from "../../Components";
import { DefaultProfilePic } from "../../Assets/Images";

const ProfilePhoto = ({
  currentImg,
  onFinalProfilePhotoChange,
  values,
  setValues,
}) => {
  const profilePhotoRef = useRef(null);
  const { handleImageInput, image, setImage, error, setError } =
    useImageInputType();
  const { isOpen, setIsOpen } = useModalOpen();
  const [finalCropImage, setFinalCropImage] = useState(null);
  const [isActionOpen, setIsActionOpen] = useState(false);

  const handleInputChange = (e) => {
    handleImageInput(e);
    setIsOpen(true);
    setIsActionOpen(false);
  };

  const handleCropComplete = async (img) => {
    console.log("handle crop complete -", img);
    setFinalCropImage(img.croppedPrev);
    onFinalProfilePhotoChange(img.croppedData);
  };

  const handleCurrentPhotoDelete = () => {
    setValues({
      ...values,
      profilePhoto: null,
    });
    setFinalCropImage(null);
  };

  return (
    <ProfilePhotoContainerWrapper>
      <input
        type="file"
        ref={profilePhotoRef}
        hidden
        onChange={handleInputChange}
        accept="image/jpeg,image/png,image/webp"
      />

      {finalCropImage ? (
        <PrsnlProfileImg src={finalCropImage} alt="profile_pic" />
      ) : (
        <>
          {currentImg ? (
            <PrsnlProfileImg src={currentImg} alt="profile_pic" />
          ) : (
            <PrsnlProfileImg src={DefaultProfilePic} alt="profile_pic" />
          )}
        </>
      )}
      {currentImg || finalCropImage ? (
        <CameraButton padding={"0.3rem"} onClick={() => setIsActionOpen(true)}>
          <MdEdit fill="white" />
        </CameraButton>
      ) : (
        <CameraButton
          onClick={() => profilePhotoRef.current.click()}
          padding={"0.3rem"}
        >
          <CameraIcon fill={"white"} />
        </CameraButton>
      )}

      {image && isOpen && !error ? (
        <CropProfileCoverPhoto
          image={image}
          setImage={setImage}
          handleCropComplete={handleCropComplete}
          setIsOpen={setIsOpen}
          isProfile={true}
        />
      ) : (
        ""
      )}
      {error && (
        <ErrorModal
          error={error}
          setError={setError}
          image={image}
          setImage={setImage}
        />
      )}
      {isActionOpen && (
        <PhotoActionModal
          setIsOpen={setIsActionOpen}
          isProfile={true}
          onChange={handleInputChange}
          onDelete={handleCurrentPhotoDelete}
        />
      )}
    </ProfilePhotoContainerWrapper>
  );
};

export default ProfilePhoto;
