import React from "react";

function PauseIcon({ fill, width, height }) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width || "24px"}
      height={height || "24px"}
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill={fill}
        stroke="none"
      >
        <path
          d="M1321 4784 c-169 -45 -301 -180 -346 -351 -23 -86 -23 -3660 0 -3746
45 -173 178 -307 350 -352 80 -20 310 -20 390 0 172 45 305 179 350 352 23 86
23 3660 0 3746 -45 173 -178 307 -350 352 -78 20 -318 20 -394 -1z"
        />
        <path
          d="M3401 4784 c-169 -45 -301 -180 -346 -351 -23 -86 -23 -3660 0 -3746
45 -173 178 -307 350 -352 80 -20 310 -20 390 0 172 45 305 179 350 352 23 86
23 3660 0 3746 -45 173 -178 307 -350 352 -78 20 -318 20 -394 -1z"
        />
      </g>
    </svg>
  );
}

export default PauseIcon;
