import React, { useEffect, useState } from "react";
import {
  CloseButtonWrapper,
  CountingModalHeader,
  CountingModalWapper,
  CountingSearchWrapper,
  CountingTitle,
  EmptyModalDataWrap,
  MobileClose,
  ModalBg,
  UserListContainer,
  UserNameDpWrapper,
  UserOverallRatingListWrapper,
  UserProfileThumb,
} from "./ModalStyles";
import {
  getUserFollowerList,
  getUserFollowingList,
  getUserRatingList,
  getUserRatingOverall,
} from "../../Functions/getFetchFunctions";
import { useDispatch, useSelector } from "react-redux";

import {
  LoaderStyles,
  SmallPrimaryBtn,
  SmallSecondaryBtn,
} from "../Common/CommonStyle";
import {
  handleFollow,
  handleUnfollow,
} from "../../Functions/getConnectHandler";
import { IoMdClose } from "react-icons/io";
import { HiOutlineArrowSmLeft } from "react-icons/hi";
import { SearchOutlineIcon, StarIcon } from "../../Assets/SVG";
import HorzBar from "../StarRating/HorzBar";
import RadialBar from "../StarRating/RadialBar";
import { PostAvgRating } from "../Post/PostStyle";
import { setBackNavigate } from "../../ReduxToolKit/Slices/utilsSlice";
import { FadeLoader } from "react-spinners";
import { DefaultProfilePic_2 } from "../../Assets/Images";

const CountingModal = ({
  openModal,
  setOpenModal,
  currentBox,
  setCurrentBox,
}) => {
  const [connectList, setConnectList] = useState([]);
  const [ratersList, setRatersList] = useState([]);
  const [overallList, setOverallList] = useState({
    avgRating: null,
    numberOfRating: 0,
  });
  const [searchUser, setSearchUser] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const backNavigate = useSelector((state) => state.utils.backNavigate);
  const currentUserId = localStorage.getItem("currentUserId"); //tediueucet
  const user = useSelector((state) => state.auth.user);
  let userId = user._id;
  const dispatch = useDispatch();

  const handleConnectClick = async (isFollow, selectedId) => {
    if (isFollow === 1) {
      await handleUnfollow({ currentUserId, selectedId, dispatch, userId });
    } else {
      await handleFollow({ currentUserId, selectedId, dispatch, userId });
    }
  };

  const handleNavigateClick = () => {
    dispatch(setBackNavigate({ backNavigate: backNavigate + 1 }));
    setOpenModal(false);
  };

  useEffect(() => {
    if (currentBox === 1) {
      getUserRatingList(userId, dispatch, setRatersList, setIsLoading);
      getUserRatingOverall(userId, dispatch, setOverallList, setIsLoading);
      setConnectList([]);
    } else if (currentBox === 2) {
      getUserFollowerList(
        userId,
        currentUserId,
        dispatch,
        setConnectList,
        setIsLoading
      );
      setRatersList([]);
    } else if (currentBox === 3) {
      getUserFollowingList(
        userId,
        currentUserId,
        dispatch,
        setConnectList,
        setIsLoading
      );
      setRatersList([]);
    }
    setIsLoading(true);
  }, [currentBox, userId, currentUserId, dispatch]);

  useEffect(() => {
    if (openModal === false) {
      setConnectList([]);
      setRatersList([]);
      setOverallList({});
    }
  }, [openModal]);

  const overallRatnigForHorzBar = [
    {
      no: 5,
      value: overallList?.fiveStar,
      percentage: (overallList?.fiveStar / overallList?.numberOfRating) * 100,
    },
    {
      no: 4,
      value: overallList?.fourStar,
      percentage: (overallList?.fourStar / overallList?.numberOfRating) * 100,
    },
    {
      no: 3,
      value: overallList?.threeStar,
      percentage: (overallList?.threeStar / overallList?.numberOfRating) * 100,
    },
    {
      no: 2,
      value: overallList?.twoStar,
      percentage: (overallList?.twoStar / overallList?.numberOfRating) * 100,
    },
    {
      no: 1,
      value: overallList?.oneStar,
      percentage: (overallList?.oneStar / overallList?.numberOfRating) * 100,
    },
  ];

  return (
    <ModalBg>
      <CountingModalWapper>
        <MobileClose onClick={() => setOpenModal(false)}>
          <span>
            <HiOutlineArrowSmLeft />
          </span>
        </MobileClose>
        <CountingModalHeader>
          {user.isTalent && (
            <>
              <CountingTitle
                onClick={() => setCurrentBox(1)}
                isActive={currentBox === 1}
              >
                Ratings
              </CountingTitle>
              <CountingTitle
                onClick={() => setCurrentBox(2)}
                isActive={currentBox === 2}
              >
                Followers
              </CountingTitle>
            </>
          )}
          <CountingTitle
            onClick={() => setCurrentBox(3)}
            isActive={currentBox === 3}
          >
            Followings
          </CountingTitle>
        </CountingModalHeader>

        {isLoading ? (
          <EmptyModalDataWrap height={"80%"}>
            <FadeLoader
              color="#747474"
              height={8.5}
              width={2}
              radius={1}
              margin={-9}
              speedMultiplier={1.5}
              loading={true}
              cssOverride={LoaderStyles}
            />
          </EmptyModalDataWrap>
        ) : (
          <>
            {((ratersList.length === 0 && connectList.length > 10) ||
              (ratersList.length > 10 && connectList.length === 0)) && (
              <CountingSearchWrapper>
                <input
                  type="text"
                  value={searchUser}
                  onChange={(e) => setSearchUser(e.target.value)}
                  placeholder="Search user"
                />
                <span>
                  {searchUser === "" ? (
                    <SearchOutlineIcon width={"20px"} />
                  ) : (
                    <IoMdClose onClick={() => setSearchUser("")} />
                  )}
                </span>
              </CountingSearchWrapper>
            )}

            <section>
              {currentBox === 1 && (
                <>
                  <UserOverallRatingListWrapper>
                    <RadialBar
                      avgRating={
                        overallList.avgRating === null
                          ? 0
                          : (
                              Math.round(overallList.avgRating * 10) / 10
                            ).toFixed(1)
                      }
                      peopleCount={overallList.numberOfRating || 0}
                    />
                    <HorzBar ratingCounts={overallRatnigForHorzBar} />
                  </UserOverallRatingListWrapper>
                  {ratersList
                    .filter(
                      (list) =>
                        list.firstName
                          .toString()
                          .toLowerCase()
                          .includes(searchUser.toLowerCase()) +
                        list.lastName
                          .toString()
                          .toLowerCase()
                          .includes(searchUser.toLowerCase()) +
                        list.userName
                          .toString()
                          .toLowerCase()
                          .includes(searchUser.toLowerCase())
                    )
                    .map((user, index) => (
                      <UserListContainer key={index}>
                        <UserNameDpWrapper
                          to={`/profile/${user.firstName}_${user.lastName}/${user._id}`}
                          onClick={handleNavigateClick}
                        >
                          <UserProfileThumb
                            isTag={true}
                            bgPosition={user.profileImage === null}
                            style={{
                              backgroundImage: `url(${
                                user.profileImage === null
                                  ? DefaultProfilePic_2
                                  : user.profileImage
                              })`,
                            }}
                          />
                          <h3>
                            {user.firstName}&nbsp;{user.lastName}
                            <p>@{user.userName}</p>
                          </h3>
                        </UserNameDpWrapper>
                        <PostAvgRating>
                          <StarIcon width={"12px"} />
                          <span>{user.rating}</span>
                        </PostAvgRating>
                      </UserListContainer>
                    ))}
                </>
              )}
              {connectList
                .filter(
                  (list) =>
                    list.name
                      .toString()
                      .toLowerCase()
                      .includes(searchUser.toLowerCase()) +
                    list.userName
                      .toString()
                      .toLowerCase()
                      .includes(searchUser.toLowerCase())
                )
                .map((user, index) => (
                  <UserListContainer key={index}>
                    <UserNameDpWrapper
                      to={`/profile/${user.name}/${user._id}`}
                      onClick={handleNavigateClick}
                    >
                      <UserProfileThumb
                        isTag={true}
                        bgPosition={user.profileImage === null}
                        style={{
                          backgroundImage: `url(${
                            user.profileImage === null
                              ? DefaultProfilePic_2
                              : user.profileImage
                          })`,
                        }}
                      />
                      <h3>
                        {user.name}
                        <p>@{user.userName}</p>
                      </h3>
                    </UserNameDpWrapper>

                    {currentUserId === user._id && (
                      <SmallSecondaryBtn style={{ cursor: "default" }}>
                        You
                      </SmallSecondaryBtn>
                    )}

                    {currentUserId !== user._id && (
                      <>
                        {user.isFollow === 0 ? (
                          <SmallPrimaryBtn
                            onClick={() =>
                              handleConnectClick(user.isFollow, user._id)
                            }
                          >
                            Follow
                          </SmallPrimaryBtn>
                        ) : (
                          <SmallSecondaryBtn
                            onClick={() =>
                              handleConnectClick(user.isFollow, user._id)
                            }
                          >
                            Following
                          </SmallSecondaryBtn>
                        )}
                      </>
                    )}
                  </UserListContainer>
                ))}
            </section>
          </>
        )}

        <CloseButtonWrapper onClick={() => setOpenModal(false)}>
          <IoMdClose />
        </CloseButtonWrapper>
      </CountingModalWapper>
    </ModalBg>
  );
};

export default CountingModal;
