import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { FadeLoader } from "react-spinners";
import {
  AwardsCertificates,
  BioDetails,
  LanguageEducations,
  MyPhotos,
  PinnedPosts,
} from "./Components";
import { getProfilePinnedPost } from "../../../Functions/getFetchFunctions";
import { EmptyModalDataWrap } from "../../../Components/Modals/ModalStyles";
import { LoaderStyles } from "../../../Components/Common/CommonStyle";
import SocialMedia from "./Components/SocialMedia";

const OverviewWrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const ElementWrapper = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;
  @media screen and (max-width: 1250px) {
    gap: 2rem;
  }
  @media screen and (max-width: 950px) {
    gap: 1rem;
    padding: 0 1rem;
  }
  @media screen and (max-width: 500px) {
    padding: 0;
  }
`;

const PhotosMoreInfoWrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const Overview = () => {
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const currentUserId = localStorage.getItem("currentUserId");
  const { userId } = useParams();
  let isCurrentUser = currentUserId === userId;
  const [isLoading, setIsLoading] = useState(true);
  let photoValues = user?.morePersonalInfo?.featuredPhotos;
  const hasPhotos = user?.morePersonalInfo?.featuredPhotos.some(
    (photo) => photo && photo
  );

  useEffect(() => {
    getProfilePinnedPost(currentUserId, userId, 1, 3, dispatch, setIsLoading);
  }, [userId, dispatch, currentUserId]);

  return (
    <OverviewWrapper>
      {user?.isTalent && (
        <>
          {isLoading ? (
            <EmptyModalDataWrap height={"18rem"}>
              <FadeLoader
                color="#747474"
                height={8.5}
                width={2}
                radius={1}
                margin={-9}
                speedMultiplier={1.5}
                loading={true}
                cssOverride={LoaderStyles}
              />
            </EmptyModalDataWrap>
          ) : (
            <PinnedPosts />
          )}
        </>
      )}
      <ElementWrapper>
        <BioDetails user={user} />
        {user?.isTalent && (
          <>
            <PhotosMoreInfoWrapper>
              {isCurrentUser ? (
                <MyPhotos values={photoValues} hasPhotos={hasPhotos} />
              ) : (
                <>
                  {hasPhotos && (
                    <MyPhotos values={photoValues} hasPhotos={hasPhotos} />
                  )}
                </>
              )}
            </PhotosMoreInfoWrapper>
            <AwardsCertificates />
            <SocialMedia />
            <LanguageEducations />
          </>
        )}
      </ElementWrapper>
    </OverviewWrapper>
  );
};

export default Overview;
