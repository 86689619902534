import styled, { css, keyframes } from "styled-components";
import { Link, Link as LinkS } from "react-router-dom";
import { TbSunHigh } from "react-icons/tb";
import { IoMdMoon } from "react-icons/io";

export const Nav = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  backdrop-filter: blur(80px);
  height: ${({ showLogo, isAuth }) =>
    showLogo ? (isAuth ? "8.5vh" : "80px") : "70px"};
  background: ${({ showLogo, isAuth, theme, isHome }) =>
    showLogo
      ? isAuth
        ? isHome
          ? "var(--linear-background--light)"
          : theme.mainBg
        : "var(--linear-background--light)"
      : theme.mode === "light"
      ? theme.mainBg
      : theme.glassBg};

  position: ${({ isSticky, isPolicy }) =>
    isPolicy ? (isSticky ? "sticky" : "relative") : "sticky"};
  margin-top: ${({ isSticky, isPolicy }) =>
    isPolicy ? (isSticky ? "-80px" : "0px") : "-80px"};
  background: ${({ isSticky, isPolicy, theme }) =>
    isPolicy
      ? isSticky
        ? "var(--linear-background--light)"
        : theme.mainBg
      : theme.mainBg};
  border-bottom: ${({ isSticky, isPolicy, theme }) =>
    isPolicy ? (isSticky ? "none" : `1px solid ${theme.borderColor}`) : "none"};

  top: 0;
  z-index: 77;
  width: 100%;

  @media screen and (max-width: 1450px) {
    height: ${({ showLogo, isAuth }) =>
      showLogo ? (isAuth ? "70px" : "80px") : "60px"};
  }
  @media screen and (max-width: 650px) {
    margin-top: ${({ isSticky, isPolicy }) =>
      isPolicy ? (isSticky ? "-70px" : "0px") : "-70px"};
    height: 70px;
  }
`;

export const NavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  z-index: 1;
  width: 100%;
  max-width: ${({ showLogo }) => (showLogo ? "1450px" : "")};
  padding: 0 1.2rem;
  /* max-width: 1350px; */
  @media screen and (max-width: 1450px) {
    /* padding: ${({ showLogo }) => (showLogo ? "0 4rem" : "0 2rem")}; */
  }
  @media screen and (max-width: 1100px) {
    /* padding: ${({ showLogo }) => (showLogo ? "0 1.5rem" : "0 2rem")}; */
  }
  @media screen and (max-width: 650px) {
    /* padding: ${({ showLogo }) => (showLogo ? "0 1.5rem" : "0 1.2rem")}; */
  }
`;

export const NavLogo = styled.div`
  color: var(--white);
  justify-self: flex-start;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  font-weight: bold;
  text-decoration: none;
`;

export const NavImg = styled.img`
  height: 50px;
  width: 170px;
  @media screen and (max-width: 700px) {
    height: 40px;
    width: 140px;
  }
`;

// <---------------------- navbar items after login ---------------------->

export const NavTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  color: ${(props) => props.theme.main};
  font-size: 1.3rem;
  span {
    display: none;
    cursor: pointer;
    &:hover {
      color: ${(props) => props.theme.dark};
    }
  }
  @media screen and (max-width: 1200px) {
    span {
      display: grid;
      place-items: center;
    }
  }
  @media screen and (max-width: 650px) {
    gap: 10px;
  }
`;

export const NavMobileLogo = styled.img`
  display: none;
  @media screen and (max-width: 950px) {
    display: grid;
    height: 40px;
    width: 140px;
  }
  @media screen and (max-width: 650px) {
    height: 35px;
    width: 120px;
  }
`;

// <---------------------- navbar search styles ---------------------->

export const SearchWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SearchBtn = styled.button`
  position: absolute;
  right: 1px;
  top: 1px;
  cursor: pointer;
  fill: ${(props) => props.theme.main};
  display: grid;
  place-content: center;
  align-items: center;
  text-decoration: none;
  height: 2.4rem;
  width: 2.5rem;
  border-radius: 50%;
  cursor: pointer;
  border: none;
  background: none;
  padding-right: 1px;

  &:hover {
    background: ${(props) => props.theme.mediumBg};
    fill: ${(props) => props.theme.dark};
  }
`;

export const SearchInput = styled.input`
  padding: 0.5rem 2.5rem 0.5rem 1rem;
  width: 34rem;
  width: 16rem;
  height: 2.5rem;
  border-radius: 30px;
  border: none;
  border: 1px solid ${(props) => props.theme.mediumMain};
  background: none;
  color: ${(props) => props.theme.dark};

  &:focus {
    outline: none;
  }
  &::placeholder {
    color: var(--placeholder-color);
    opacity: 1;
    font-size: 12px;
    font-weight: 400;
  }
  @media screen and (max-width: 950px) {
    display: none;
  }
`;

// <---------------------- navbar items after login ---------------------->

export const NavMenu = styled.ul`
  display: flex;
  list-style: none;
  text-align: center;
  align-items: center;
  justify-content: space-between;
`;

export const ProfileLink = styled(LinkS)`
  border: 1px solid
    ${({ isPolicy, theme }) => (isPolicy ? "#616161" : theme.mediumMain)};
  color: ${({ active, theme }) => (active ? theme.dark : theme.main)};
  padding: 0.2rem;
  border-radius: 50px;
  display: flex;
  gap: 0.3rem;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  position: relative;
  cursor: pointer;

  p {
    min-width: 2.5rem;
    max-width: 3.5rem;
    margin-right: 0.3rem;
    font-size: 13px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &:hover {
    background: ${({ theme, isPolicy }) =>
      isPolicy ? "none" : theme.mediumBg};
    color: ${(props) => props.theme.dark};
  }
  @media screen and (max-width: 950px) {
    p {
      display: none;
    }
  }
  @media screen and (max-width: 650px) {
    place-items: center;
    padding: 5px;
    border: ${({ isTopNav }) => !isTopNav && "none"};
    &:hover {
      background: none;
    }
  }
`;

export const NavItems = styled.li`
  margin: 0 0.3rem;
  height: 100%;
  border-radius: 50%;
  fill: ${({ active, theme }) => (active ? theme.dark : theme.main)};
  @media screen and (max-width: 650px) {
    margin: 0 2px;
    ${ProfileLink} {
      display: ${({ isTopNav, isPolicy }) =>
        isTopNav ? (isPolicy ? "grid" : "none") : "grid"};
    }
  }
`;

export const NavLinks = styled(LinkS)`
  display: grid;
  place-content: center;
  align-items: center;
  text-decoration: none;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  cursor: pointer;
  border: 1px solid ${(props) => props.theme.mediumMain};
  &:hover {
    background: ${(props) => props.theme.mediumBg};
    fill: ${(props) => props.theme.dark};
  }
  @media screen and (max-width: 650px) {
    border: none;
    width: 2.1rem;
    &:hover {
      background: none;
    }
  }
`;

export const NavLogout = styled.p`
  color: ${(props) => props.theme.main};
  display: grid;
  place-content: center;
  font-size: 1.2rem;
  text-decoration: none;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  cursor: pointer;
  border: 1px solid ${(props) => props.theme.mediumMain};
  &:hover {
    background: ${(props) => props.theme.mediumBg};
    color: ${(props) => props.theme.dark};
  }
`;

const NavButtonLinkStyle = `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.5rem;
  height: 2.1rem;
  text-decoration: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  transition: 0.2s all ease;
`;

// <---------------------- navbar items auth styles ---------------------->

export const LoginLink = styled(LinkS)`
  ${NavButtonLinkStyle};
  color: ${({ theme, isSticky }) =>
    isSticky ? theme.defaultLight : theme.dark};
  margin-right: 1.5rem;
  &:hover {
    color: ${(props) => props.theme.defaultLight};
    background: ${({ theme, isSticky }) => (isSticky ? "none" : theme.dark)};
  }
`;

export const SignupLink = styled(LinkS)`
  ${NavButtonLinkStyle};
  color: ${(props) => props.theme.defaultLight};
  background: var(--primary);
  &:hover {
    background: ${({ theme, isSticky }) =>
      isSticky ? theme.primary : theme.dark};
  }
`;

export const NavbarIcons = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  object-fit: cover;
  background-color: ${(props) => props.theme.defaultMain};
  @media screen and (max-width: 650px) {
    width: 28px;
    height: 28px;
  }
`;

export const DarkModeButton = styled.div`
  color: ${(props) =>
    props.theme.mode === "light" ? props.theme.main : props.theme.yellow};
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: ${({ showLogo }) => (showLogo ? "0.5rem 0.8rem" : "0")};
  gap: 0.5rem;
  min-height: ${({ showLogo }) => (showLogo ? "2.8rem" : "2.5rem")};
  min-width: 2.5rem;
  border-radius: 100px;
  cursor: pointer;
  border: 1px solid ${(props) => props.theme.mediumMain};
  p {
    display: ${({ showLogo }) => (showLogo ? "flex" : "none")};
    color: ${(props) => props.theme.main};
    font-size: 13px;
  }
  &:hover {
    background: ${(props) => props.theme.mediumBg};
    color: ${(props) => props.theme.dark};
    p {
      color: ${(props) => props.theme.dark};
    }
  }
  @media screen and (max-width: 800px) {
    p {
      display: none;
    }
  }
  @media screen and (max-width: 650px) {
    border: none;
    min-width: 2rem;
    &:hover {
      background: none;
    }
  }
`;

const sunRotateAnimation = keyframes`
  0% {
    transform: rotate(-45deg);
    opacity: 0
  }
  100% {
    transform: rotate(0);
    opacity: 1
  }
`;

export const SunIcon = styled(TbSunHigh)`
  font-size: 1.2rem;
  ${(props) =>
    props.active &&
    css`
      animation: ${sunRotateAnimation} 0.5s ease-in-out;
      transform: rotate(0);
    `}
`;

const moonRotateAnimation = keyframes`
  0% {
    transform: rotate(45deg);
    opacity: 0;
  }
  100% {
    transform: rotate(0);
    opacity: 1;
  }
`;

export const MoonIcon = styled(IoMdMoon)`
  font-size: 1.2rem;
  ${(props) =>
    props.active &&
    css`
      animation: ${moonRotateAnimation} 0.5s ease-in-out;
      transform: rotate(0);
    `}
`;

// <---------------------- bottom bar styles ---------------------->

export const BotContainer = styled.nav`
  display: none;
  @media screen and (max-width: 650px) {
    display: flex;
    align-items: center;
    justify-content: space-around;
    /* gap: 3.8rem; */
    height: 3.5rem;
    width: 100%;
    backdrop-filter: blur(80px);
    background: ${({ theme }) =>
      theme.mode === "light" ? theme.mainBg : theme.glassBg};
    position: fixed;
    bottom: 0;
    z-index: 88;
  }
`;

export const BotLinks = styled(Link)`
  display: grid;
  place-items: center;
  padding: 5px;
  position: relative;
  fill: ${({ isActive, theme }) => (isActive ? theme.dark : theme.sidebarText)};
  color: ${({ isActive, theme }) =>
    isActive ? theme.dark : theme.sidebarText};
  &:hover {
    fill: ${(props) => props.theme.dark};
  }
`;

export const RedIndicator = styled.span`
  position: absolute;
  width: 1.4rem;
  height: 3px;
  background: ${(props) => props.theme.primary};
  border-radius: 10px;
  bottom: -4px;
  left: 50%;
  transform: translateX(-50%);
`;

export const BgIndicator = styled.span`
  position: absolute;
  background: ${(props) => props.theme.primary};
  height: 2rem;
  width: 2rem;
  border-radius: 100px;
  z-index: 0;
`;

export const BackButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  background: none;
  border: none;
  font-size: 14px;
  color: ${(props) => props.theme.main};
  cursor: pointer;
  span {
  }
  &:hover {
    color: ${(props) => props.theme.dark};
  }
`;
