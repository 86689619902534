import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const createSharePostAction = createAsyncThunk(
  "/te-post",
  async (formData) => {
    try {
      const response = await axios.post("te-post", formData);
      return response.data;
    } catch {
      const response = {
        data: { status: 400 },
      };
      return response.data;
    }
  }
);

export const homeFeedViewAction = createAsyncThunk(
  "/feeds/home/",
  async ({ currentUserId, userId, pageNo, pageSize }) => {
    try {
      const response = await axios.get(
        `feeds/home?userId=${userId}&viewUserId=${currentUserId}&pageNo=${pageNo}&pageSize=${pageSize}`
      );
      // viewUserId - who is watching the feed && userId - who's feed is being watch
      console.log(response, "axiosfeedsViewAction");
      return response.data;
    } catch {
      const response = {
        data: { status: 400 },
      };
      return response.data;
    }
  }
);

export const homeSharedFeedViewAction = createAsyncThunk(
  "/feeds/home/",
  async ({ currentUserId, userId, pageNo, pageSize }) => {
    try {
      const response = await axios.get(
        `feeds/sharelinks?userId=${userId}&viewUserId=${currentUserId}&pageNo=${pageNo}&pageSize=${pageSize}`
      );
      // viewUserId - who is watching the feed && userId - who's feed is being watch
      console.log(response, "axiosSharefeedsViewAction");
      return response.data;
    } catch {
      const response = {
        data: { status: 400 },
      };
      return response.data;
    }
  }
);

export const postRatingAction = createAsyncThunk(
  "te-post/rating/",
  async (formData) => {
    try {
      const response = await axios.post("/te-post/rating", formData);
      return response.data;
    } catch {
      const response = {
        data: { status: 400 },
      };
      return response.data;
    }
  }
);

export const postPinningAction = createAsyncThunk(
  "te-post/addToBestWork/",
  async (formData) => {
    try {
      const response = await axios.post("/te-post/addToBestWork", formData);
      return response.data;
    } catch {
      const response = {
        data: { status: 400 },
      };
      return response.data;
    }
  }
);

export const postRemovePinAction = createAsyncThunk(
  "te-post/removeFromBestWork/",
  async (formData) => {
    try {
      const response = await axios.post(
        "/te-post/removeFromBestWork",
        formData
      );
      return response.data;
    } catch {
      const response = {
        data: { status: 400 },
      };
      return response.data;
    }
  }
);

export const pinnedPostViewAction = createAsyncThunk(
  "feeds/profile/best-work/",
  async ({ currentUserId, userId, pageNo, pageSize }) => {
    try {
      const response = await axios.get(
        `feeds/profile/best-work?userId=${userId}&viewUserId=${currentUserId}&pageNo=${pageNo}&pageSize=${pageSize}`
      );
      // viewUserId - who is watching the pinned post && userId - who's pinned post is being watch
      console.log(response, "axios-best-post-ViewAction");
      return response.data;
    } catch {
      const response = {
        data: { status: 400 },
      };
      return response.data;
    }
  }
);

export const profileAllpostViewAction = createAsyncThunk(
  "feeds/profile/all/",
  async ({ currentUserId, userId, pageNo, pageSize }) => {
    try {
      const response = await axios.get(
        `feeds/profile/all?userId=${userId}&viewUserId=${currentUserId}&pageNo=${pageNo}&pageSize=${pageSize}`
      );
      // viewUserId - who is watching the all post && userId - who's all post is being watch
      console.log(response, "axios-all-post-ViewAction");
      return response.data;
    } catch {
      const response = {
        data: { status: 400 },
      };
      return response.data;
    }
  }
);

export const postMoreDetailsViewAction = createAsyncThunk(
  "feeds/{postId}/{userId}/",
  async ({ postId, userId }) => {
    try {
      const response = await axios.get(`feeds/${postId}/${userId}`);
      // viewUserId - who is watching the all post && userId - who's all post is being watch
      console.log(response, "axios-moredetail-post-ViewAction");
      return response.data;
    } catch {
      const response = {
        data: { status: 400 },
      };
      return response.data;
    }
  }
);

export const postDeleteAction = createAsyncThunk(
  "te-post/{postId}",
  async (postId) => {
    try {
      const response = await axios.delete(`te-post/${postId}`);
      console.log(response, "axios-delete-post-action");
      return response.data;
    } catch {
      const response = {
        data: { status: 400 },
      };
      return response.data;
    }
  }
);

export const commentPostAction = createAsyncThunk(
  "comments",
  async (formData) => {
    try {
      const response = await axios.post("comments", formData);
      console.log(response, "axios-comment-action");
      return response.data;
    } catch {
      const response = {
        data: { status: 400 },
      };
      return response.data;
    }
  }
);

export const commentViewAction = createAsyncThunk(
  "comments",
  async ({ currentUserId, postId, pageNo, pageSize }) => {
    try {
      const response = await axios.get(
        `comments?userId=${currentUserId}&postId=${postId}&pageNo=${pageNo}&pageSize=${pageSize}`
      );
      console.log(response, "axios-comment-view-action");
      return response.data;
    } catch {
      const response = {
        data: { status: 400 },
      };
      return response.data;
    }
  }
);

export const createSaveCollectionAction = createAsyncThunk(
  "collection",
  async (data) => {
    try {
      const response = await axios.post("/collection", data);
      console.log(response, "axios-save-collection-create-action");
      return response.data;
    } catch {
      const response = {
        data: { status: 400 },
      };
      return response.data;
    }
  }
);

export const postSaveCollectionViewAction = createAsyncThunk(
  "collection/{userId}/{postId}",
  async ({ currentUserId, postId }) => {
    try {
      const response = await axios.get(`collection/${currentUserId}/${postId}`);
      console.log(response, "axios-save-collection-view-action");
      return response.data;
    } catch {
      const response = {
        data: { status: 400 },
      };
      return response.data;
    }
  }
);

export const saveToSpecificCollectionAction = createAsyncThunk(
  "collection/post/{collectionId}/{postId}",
  async ({ collectionId, postId }) => {
    try {
      const response = await axios.post(
        `collection/post/${collectionId}/${postId}`
      );
      console.log(response, "axios-save-to-collection-action");
      return response.data;
    } catch {
      const response = {
        data: { status: 400 },
      };
      return response.data;
    }
  }
);

export const unsaveToSpecificCollectionAction = createAsyncThunk(
  "collection/post/{collectionId}/{postId}",
  async ({ collectionId, postId }) => {
    try {
      const response = await axios.delete(
        `collection/post/${collectionId}/${postId}`
      );
      console.log(response, "axios-un-save-to-collection-action");
      return response.data;
    } catch {
      const response = {
        data: { status: 400 },
      };
      return response.data;
    }
  }
);

export const savedFoldersPostViewAction = createAsyncThunk(
  "feeds/collection/{collectionId}",
  async ({ collectionId, currentUserId, pageNo, pageSize }) => {
    try {
      const response = await axios.get(
        `feeds/collection/${collectionId}?userId=${currentUserId}&viewUserId=${currentUserId}&pageNo=${pageNo}&pageSize=${pageSize}`
      );
      console.log(response, "axios-un-save-to-collection-action");
      return response.data;
    } catch {
      const response = {
        data: { status: 400 },
      };
      return response.data;
    }
  }
);
