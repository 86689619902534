import React from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "../../Profile.css";
import { InstaIcon, YoutubeIcon, LinkedinIcon } from "../../../../Assets/Icons";

const SwiperStyle = styled.div`
  width: 55%;
  margin-left: 3rem;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SliderImage = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  object-fit: cover;
`;

const SocialLink = styled.a`
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background-color: var(--light-gray);
  cursor: pointer;
  margin: 5px;
`;

const SocialMediaSlider = () => {
  return (
    <SwiperStyle>
      <Swiper slidesPerView={"auto"}>
        <SwiperSlide className="social-swiper">
          <SocialLink href="https://www.instagram.com/" target="_blank">
            <SliderImage src={InstaIcon} alt="image" />
          </SocialLink>
        </SwiperSlide>
        <SwiperSlide className="social-swiper">
          <SocialLink href="https://www.youtube.com/" target="_blank">
            <SliderImage src={YoutubeIcon} alt="image" />
          </SocialLink>
        </SwiperSlide>{" "}
        <SwiperSlide className="social-swiper">
          <SocialLink href="https://www.linkedin.com/" target="_blank">
            <SliderImage src={LinkedinIcon} alt="image" />
          </SocialLink>
        </SwiperSlide>
        <SwiperSlide className="social-swiper">
          <SocialLink href="https://www.instagram.com/" target="_blank">
            <SliderImage src={InstaIcon} alt="image" />
          </SocialLink>
        </SwiperSlide>
        <SwiperSlide className="social-swiper">
          <SocialLink href="https://www.youtube.com/" target="_blank">
            <SliderImage src={YoutubeIcon} alt="image" />
          </SocialLink>
        </SwiperSlide>{" "}
        <SwiperSlide className="social-swiper">
          <SocialLink href="https://www.linkedin.com/" target="_blank">
            <SliderImage src={LinkedinIcon} alt="image" />
          </SocialLink>
        </SwiperSlide>{" "}
        <SwiperSlide className="social-swiper">
          <SocialLink href="https://www.instagram.com/" target="_blank">
            <SliderImage src={InstaIcon} alt="image" />
          </SocialLink>
        </SwiperSlide>
      </Swiper>
    </SwiperStyle>
  );
};

export default SocialMediaSlider;
