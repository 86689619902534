import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineLogout } from "react-icons/ai";
import {
  SidebarMenu,
  SidebarContainer,
  SidebarLinks,
  SidebarHeader,
  SidebarLogo,
  SbarLogoWrapper,
  LogoutBtn,
  RedIndicator,
  SidebarModalLink,
} from "./SidebarStyle";
import {
  setCurrentUser,
  setLogout,
  setUser,
} from "../../ReduxToolKit/Slices/authSlice";
import { LightLogo } from "../../Assets/Images";
import {
  setBackNavigate,
  setIsAbout,
  setIsProfileEdit,
  setIsTalent,
} from "../../ReduxToolKit/Slices/utilsSlice";
import { SidebarItemList } from "../../Utils/Others/Data";
import {
  setAddPosts,
  setUserAllPostsById,
  setUserPinnedPostsById,
} from "../../ReduxToolKit/Slices/postSlice";
import { persistor } from "../../ReduxToolKit/store";
import UploadModal from "../Modals/UploadModal";

const Sidebar = ({ activeLink }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleLogout = async () => {
    try {
      dispatch(setIsAbout({ isAbout: false }));
      dispatch(setIsTalent({ isTalent: false }));
      dispatch(setIsProfileEdit({ isProfileEdit: false }));
      dispatch(setCurrentUser({ currentUser: null }));

      dispatch(setUser({ user: null }));
      dispatch(setAddPosts({ postsById: null }));
      dispatch(setUserPinnedPostsById({ userPinnedPostsById: null }));
      dispatch(setUserAllPostsById({ userAllPostsById: null }));
      dispatch(setBackNavigate({ backNavigate: 0 }));
      // clear the persist in the local storage
      persistor.purge();
      dispatch(setLogout());
    } catch {}
  };

  return (
    <>
      <SidebarContainer>
        <SidebarHeader>
          <SbarLogoWrapper>
            <SidebarLogo src={LightLogo} alt="logo" />
          </SbarLogoWrapper>
          <SidebarMenu>
            {SidebarItemList.map((item, index) => (
              <React.Fragment key={index}>
                {item.isLink ? (
                  <SidebarLinks
                    to={item.link}
                    key={index}
                    isActive={
                      activeLink === `${item.link}` ||
                      activeLink.startsWith(`${item.link}`)
                    }
                    onClick={() =>
                      dispatch(setBackNavigate({ backNavigate: 0 }))
                    }
                  >
                    {item.icon}
                    {item.name}
                    {(activeLink === `${item.link}` ||
                      activeLink.startsWith(`${item.link}`)) && (
                      <RedIndicator />
                    )}
                  </SidebarLinks>
                ) : (
                  <SidebarModalLink
                    isActive={activeLink === `${item.link}`}
                    onClick={() => setIsModalOpen(true)}
                  >
                    {item.icon}
                    {item.name}
                    {activeLink === `${item.link}` && <RedIndicator />}
                  </SidebarModalLink>
                )}
              </React.Fragment>
            ))}
          </SidebarMenu>
        </SidebarHeader>
        <LogoutBtn onClick={handleLogout}>
          <AiOutlineLogout />
          <p>Logout</p>
        </LogoutBtn>
      </SidebarContainer>
      {isModalOpen && (
        <UploadModal
          setIsOpen={setIsModalOpen}
          isTalent={currentUser?.isTalent}
        />
      )}
    </>
  );
};

export default Sidebar;
