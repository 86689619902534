import React, { useEffect, useState } from "react";
import {
  AddAnotherWrapper,
  AddFormSubHeading,
  InfoSectionWrapper,
} from "./TalentsInfoStyle";
import { useSelector } from "react-redux";
import { convertLanguagesToArray } from "../../Hooks/useArrayConvertor";
import {
  DropdownFlexWrap,
  FormPageDataWrapper,
  TerritoryBtn,
} from "../../Components/Common/CommonStyle";
import { DropDown, FormInput } from "../../Components";
import { Levels, forEmptyLangLevels } from "../../Utils/Others/Data";
import AddedInfoSection from "./AddedInfoSection";
import {
  FormInputWrapper,
  StyledLabel,
} from "../../Components/Inputs/InputStyle";
import useDateSelector from "../../Hooks/useDateSelector";

const LanguageEducationInfo = ({
  handleDropdownChange,
  handleInputChange,
  values,
  langError,
  handleAddNewLanguage,
  addedLangValues,
  handleAddedLangDelete,
  eduValues,
  eduError,
  handleAddNewEducation,
  handleAddedEduDelete,
  addedEduValues,
}) => {
  const languages = useSelector((state) => state.content.languages);
  const [langValues, setLangValues] = useState([]);

  useEffect(() => {
    const convertedValues = convertLanguagesToArray(languages);
    setLangValues(convertedValues);
  }, [languages]);

  const { years, presentAddedYears } = useDateSelector({});
  useEffect(() => {
    console.log(langError);
  });
  return (
    <InfoSectionWrapper>
      <FormPageDataWrapper width="57%">
        <AddFormSubHeading>Language Info</AddFormSubHeading>
        <FormInputWrapper isHorz={true} isItemStart={true}>
          <StyledLabel labelWidth="17rem">Known Language</StyledLabel>
          <DropdownFlexWrap>
            <DropDown
              name="language"
              idName="languageId"
              defaultOption="Select Language"
              options={langValues}
              value={values.language}
              onChange={handleDropdownChange}
              validation={langError?.language}
              isHasLevel={true}
              isSingle={true}
            />
            <DropDown
              name="level"
              defaultOption="Level"
              options={values.language ? Levels : forEmptyLangLevels}
              value={values.level}
              onChange={handleDropdownChange}
              validation={langError?.level}
              isLevel={true}
              isSingle={true}
            />
          </DropdownFlexWrap>
        </FormInputWrapper>

        <AddAnotherWrapper>
          <TerritoryBtn onClick={handleAddNewLanguage} width={"auto"}>
            Add
          </TerritoryBtn>
        </AddAnotherWrapper>

        {/* Education info form section */}

        <AddFormSubHeading>
          Education Info<p>(Optional)</p>
        </AddFormSubHeading>

        <FormInput
          name="course"
          label="Course / Subject"
          type="text"
          placeholder="Name of the course"
          value={eduValues.course}
          onChange={handleInputChange}
          validation={eduError?.course}
          isHorz={true}
          isItemStart={true}
          labelWidth="16rem"
        />

        <FormInput
          name="institution"
          label="University / College"
          type="text"
          placeholder="Name of the institution"
          value={eduValues.institution}
          onChange={handleInputChange}
          validation={eduError?.institution}
          isHorz={true}
          isItemStart={true}
          labelWidth="16rem"
        />

        <FormInputWrapper isHorz={true} isItemStart={true}>
          <StyledLabel labelWidth="16rem">Year</StyledLabel>
          <DropdownFlexWrap>
            <DropDown
              name="from"
              defaultOption="From"
              options={years.map(({ value, label }) => ({ value, label }))}
              value={eduValues.from}
              onChange={handleDropdownChange}
              validation={eduError?.from}
              isSingle={true}
            />
            <DropDown
              name="to"
              defaultOption="To"
              options={presentAddedYears.map(({ value, label }) => ({
                value,
                label,
              }))}
              value={eduValues.to}
              onChange={handleDropdownChange}
              validation={eduError?.to}
              isSingle={true}
            />
          </DropdownFlexWrap>
        </FormInputWrapper>

        <AddAnotherWrapper>
          <TerritoryBtn onClick={handleAddNewEducation} width={"auto"}>
            Add
          </TerritoryBtn>
        </AddAnotherWrapper>
      </FormPageDataWrapper>

      <AddedInfoSection
        addedInfoValues={addedLangValues}
        addedInfoValuesTwo={addedEduValues}
        addedType={"addedLang"}
        handleAddedLangDelete={handleAddedLangDelete}
        handleAddedEduDelete={handleAddedEduDelete}
      />
    </InfoSectionWrapper>
  );
};

export default LanguageEducationInfo;
