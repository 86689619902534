import React, { useEffect, useState } from "react";
import {
  DetailSectionContainer,
  UserDetailHeading,
} from "../Profile/Overview/OverviewStyle";
import { QuillIcon } from "../../Assets/SVG";
import { DropDown, TextArea } from "../../Components";
import { convertEthnicitiesToArray } from "../../Hooks/useArrayConvertor";
import { useSelector } from "react-redux";
import { EditFormWrapper } from "./EditProfileStyles";

const MoreDetailEdit = ({
  values,
  handleInputChange,
  handleDropdownChange,
}) => {
  const ethnicities = useSelector((state) => state.content.ethnicities);
  const [ethnicValues, setEthnicValues] = useState([]);

  useEffect(() => {
    const convertedValues = convertEthnicitiesToArray(ethnicities);
    setEthnicValues(convertedValues);
  }, [ethnicities]);

  return (
    <DetailSectionContainer>
      <UserDetailHeading>
        <span>
          <QuillIcon width={"21px"} /> More Info
        </span>
      </UserDetailHeading>

      <EditFormWrapper>
        <DropDown
          name="ethnic"
          label="Ethnic"
          idName="ethnicId"
          defaultOption="Select your ethnicity"
          options={ethnicValues}
          value={values.ethnic}
          isHorz={true}
          isSingle={true}
          onChange={handleDropdownChange}
        />

        <TextArea
          name="bio"
          label="Description"
          value={values.bio}
          placeholder="Tell us about you and your profession..."
          maxLength={800}
          onChange={handleInputChange}
          height={"15rem"}
          invert={true}
        />
      </EditFormWrapper>
    </DetailSectionContainer>
  );
};

export default MoreDetailEdit;
