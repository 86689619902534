import React, { useState } from "react";
import styled from "styled-components";
import ShareFeed from "./ShareFeed";
import PostFeed from "./PostFeed";

const FeedWrapper = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4.5rem;
  gap: 10px;
`;

const TabWrapper = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 650px) {
    width: 100%;
  }
`;

const TabTitle = styled.h2`
  display: grid;
  place-items: center;
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme, isActive }) => (isActive ? theme.dark : theme.main)};
  padding: 10px 0;
  width: 14rem;
  border-bottom: ${({ theme, isActive }) =>
    isActive ? `1px solid ${theme.main}` : `1px solid ${theme.borderColor}`};
  cursor: pointer;
  @media screen and (max-width: 650px) {
    width: 12rem;
    font-size: 14px;
  }
  @media screen and (max-width: 385px) {
    width: 10rem;
  }
`;

const Feed = () => {
  const homeTabs = [
    {
      id: 0,
      title: "Posts",
      content: <PostFeed />,
    },
    {
      id: 1,
      title: "Shared",
      content: <ShareFeed />,
    },
  ];
  const [currentTab, SetCurrentTab] = useState(homeTabs[0]);

  return (
    <FeedWrapper>
      <TabWrapper>
        {homeTabs.map((tab, index) => (
          <TabTitle
            key={index}
            isActive={currentTab.id === tab.id}
            onClick={() => {
              if (currentTab.id !== tab.id) {
                SetCurrentTab(tab);
              }
            }}
          >
            {tab.title}
          </TabTitle>
        ))}
      </TabWrapper>
      <>{currentTab.content}</>
    </FeedWrapper>
  );
};

export default Feed;
