import React from "react";

function FullScreenIcon({ width, height, fill }) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width || "24px"}
      height={height || "24px"}
      viewBox="0 0 504.000000 405.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,405.000000) scale(0.100000,-0.100000)"
        fill={fill || "var(--dark-primary)"}
        stroke="none"
      >
        <path
          d="M3099 4667 c-24 -13 -57 -43 -74 -66 -27 -39 -30 -50 -30 -120 0 -68
3 -82 27 -117 15 -21 44 -50 65 -64 l37 -25 420 -3 420 -3 -581 -582 c-633
-635 -609 -606 -601 -718 10 -142 160 -230 296 -174 25 11 216 195 614 592
l577 577 3 -420 3 -420 25 -37 c14 -21 43 -50 64 -65 35 -24 49 -27 116 -27
67 0 81 3 116 27 21 15 50 44 64 65 l25 37 0 715 0 716 -27 40 c-15 22 -44 51
-65 65 l-37 25 -706 3 -706 2 -45 -23z"
        />
        <path
          d="M2040 2324 c-22 -9 -245 -225 -612 -591 l-577 -577 -3 420 -3 420
-25 37 c-14 21 -43 50 -64 65 -35 24 -49 27 -117 27 -70 0 -81 -3 -120 -30
-23 -16 -53 -50 -66 -74 l-23 -45 2 -706 3 -706 25 -37 c14 -21 43 -50 64 -65
l39 -27 717 0 717 0 39 27 c21 15 50 44 64 65 21 31 25 48 25 113 0 65 -4 82
-25 113 -14 21 -43 50 -64 65 l-39 27 -421 3 -420 3 581 582 c633 635 609 606
601 718 -8 111 -94 189 -207 189 -31 -1 -72 -8 -91 -16z"
        />
      </g>
    </svg>
  );
}

export default FullScreenIcon;
