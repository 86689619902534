import React, { useEffect, useState } from "react";
import {
  AddProInfoContainer,
  AddProInfoForm,
  CloseButtonWrapper,
  HeaderWrapper,
  MobileClose,
  ModalBg,
  ModalHeader,
} from "./ModalStyles";
import { IoMdClose } from "react-icons/io";
import DropDown from "../Inputs/DropDown";
import useDateSelector from "../../Hooks/useDateSelector";
import { PrimaryBtn, SecondaryBtn } from "../Common/CommonStyle";
import { useDispatch, useSelector } from "react-redux";
import { convertLanguagesToArray } from "../../Hooks/useArrayConvertor";
import { Levels, forEmptyLangLevels } from "../../Utils/Others/Data";
import { HiOutlineArrowSmLeft } from "react-icons/hi";
import { useProfileEdit } from "../../Hooks/useProfileEdit";
import { useHandleValues } from "../../Hooks/useEventHandler";
import FormInput from "../Inputs/FormInput";

const AddProInfoModal = ({
  type,
  setIsOpen,
  langArray,
  setLangArray,
  eduArray,
  setEduArray,
  awardArray,
  setAwardArray,
  selectedIndex,
}) => {
  const isAward = type === "awards";
  const isAwardEdit = type === "awardEdit";
  const isLang = type === "languages";
  const isLangEdit = type === "langEdit";
  const isEdu = type === "educations";
  const isEduEdit = type === "eduEdit";
  const dispatch = useDispatch();
  const currentUserId = localStorage.getItem("currentUserId");

  const [values, setValues] = useState({
    languageId: (langArray && langArray[selectedIndex]?.languageId) || "",
    languageName: (langArray && langArray[selectedIndex]?.languageName) || "",
    level: (langArray && langArray[selectedIndex]?.level) || "",
    // education
    course: (eduArray && eduArray[selectedIndex]?.course) || "",
    institution: (eduArray && eduArray[selectedIndex]?.institution) || "",
    from: (eduArray && eduArray[selectedIndex]?.from) || "",
    to: (eduArray && eduArray[selectedIndex]?.to) || "",
    // awards
    name: (awardArray && awardArray[selectedIndex]?.name) || "",
    givenBy: (awardArray && awardArray[selectedIndex]?.givenBy) || "",
    year: (awardArray && awardArray[selectedIndex]?.year) || "",
  });
  const [errors, setErrors] = useState([]);

  const { years } = useDateSelector({});
  const languages = useSelector((state) => state.content.languages);
  const [langOptions, setLangOptions] = useState([]);

  useEffect(() => {
    const convertedValues = convertLanguagesToArray(languages);
    setLangOptions(convertedValues);
  }, [languages]);

  const { handleLangCreate, handleLangEdit } = useProfileEdit({
    dispatch,
    currentUserId,
    type,
    langArray,
    setLangArray,
    setIsOpen,
    selectedIndex,
    //
    values,
    setValues,
  });

  const { handleDropdownChange, handleInputChange } = useHandleValues({
    values,
    setValues,
    errors,
    setErrors,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isLang) {
      handleLangCreate();
    } else if (isLangEdit) {
      handleLangEdit();
    } else if (isEdu) {
      console.log(values);
    } else if (isAward) {
      console.log(values);
    } else if (isAwardEdit) {
      console.log(values);
    }
    setIsOpen(false);
  };

  return (
    <ModalBg>
      <AddProInfoContainer>
        <HeaderWrapper>
          <MobileClose onClick={() => setIsOpen(false)}>
            <span>
              <HiOutlineArrowSmLeft />
            </span>
          </MobileClose>
          <ModalHeader>
            {isAward && "Add Awards & Certificate"}
            {isAwardEdit && "Add Awards & Certificate"}
            {isLang && "Add Languages & Level"}
            {isLangEdit && "Edit Languages & Level"}
            {isEdu && "Add Education & Info"}
            {isEduEdit && "Edit Education & Info"}
          </ModalHeader>
          <AddProInfoForm>
            {(isAward || isAwardEdit) && (
              <>
                <FormInput
                  name="name"
                  placeholder="Award or Certificate Name"
                  value={values.name}
                  onChange={handleInputChange}
                />
                <FormInput
                  name="givenBy"
                  placeholder="Relavent Authority Name"
                  value={values.givenBy}
                  onChange={handleInputChange}
                />
                <DropDown
                  name="year"
                  defaultOption="Year"
                  options={years.map(({ value, label }) => ({ value, label }))}
                  value={values.year}
                  onChange={handleDropdownChange}
                />
              </>
            )}

            {(isLang || isLangEdit) && (
              <>
                <DropDown
                  name="languageName"
                  idName="languageId"
                  defaultOption="Select Language"
                  options={langOptions}
                  value={values.languageName}
                  onChange={handleDropdownChange}
                />

                <DropDown
                  name="level"
                  defaultOption="Level"
                  options={values.languageName ? Levels : forEmptyLangLevels}
                  value={values.level}
                  onChange={handleDropdownChange}
                />
              </>
            )}

            {(isEdu || isEduEdit) && (
              <>
                <FormInput
                  name="course"
                  value={values.course}
                  placeholder="Course / Subject Name"
                  onChange={handleInputChange}
                />

                <FormInput
                  name="institution"
                  value={values.institution}
                  placeholder="Institute / College Name"
                  onChange={handleInputChange}
                />
                <DropDown
                  name="from"
                  defaultOption="From"
                  options={years.map(({ value, label }) => ({ value, label }))}
                  value={values.from}
                  onChange={handleDropdownChange}
                />
                <DropDown
                  name="to"
                  defaultOption="To"
                  options={years.map(({ value, label }) => ({ value, label }))}
                  value={values.to}
                  onChange={handleDropdownChange}
                />
              </>
            )}
          </AddProInfoForm>
        </HeaderWrapper>

        <footer>
          <PrimaryBtn isWidthFull={true} onClick={handleSubmit}>
            {isLang || isEdu || isAward ? "Add" : "Save Changes"}
          </PrimaryBtn>
          <SecondaryBtn onClick={() => setIsOpen(false)} isWidthFull={true}>
            Cancel
          </SecondaryBtn>
        </footer>
        <CloseButtonWrapper onClick={() => setIsOpen(false)}>
          <IoMdClose />
        </CloseButtonWrapper>
      </AddProInfoContainer>
    </ModalBg>
  );
};

export default AddProInfoModal;
