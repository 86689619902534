import React from "react";

function ReplayIcon({ fill, width, height }) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width || "24px"}
      height={height || "24px"}
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill={fill || "var(--dark-primary)"}
        stroke="none"
      >
        <path
          d="M2703 5109 c-23 -4 -55 -15 -72 -23 -38 -20 -668 -739 -688 -786 -22
-51 -18 -133 9 -183 29 -53 732 -670 797 -699 152 -70 323 79 288 251 -9 43
-52 95 -147 176 -70 60 -185 161 -220 192 -34 32 -35 33 -10 33 99 -1 347 -52
484 -100 616 -218 1073 -753 1191 -1397 23 -124 31 -399 15 -531 -57 -489
-309 -932 -700 -1233 -101 -78 -256 -172 -360 -218 -74 -33 -260 -103 -270
-101 -3 0 -36 -7 -74 -15 -39 -9 -88 -19 -110 -22 -23 -3 -61 -9 -86 -13 -72
-14 -356 -10 -459 6 -270 41 -518 132 -731 269 -391 250 -675 644 -790 1096
-73 285 -67 678 15 964 13 44 26 94 30 112 18 89 -66 202 -167 222 -131 27
-217 -33 -263 -184 -73 -241 -99 -417 -98 -675 1 -292 34 -481 131 -745 189
-515 521 -920 977 -1190 246 -146 581 -262 830 -290 28 -3 68 -8 90 -11 113
-15 407 -9 532 11 76 12 99 15 123 20 14 3 34 7 45 9 11 2 40 9 65 16 25 7 53
14 63 16 22 4 229 77 282 99 239 101 475 258 673 449 359 346 588 792 663
1290 28 183 30 457 5 631 -133 928 -793 1660 -1696 1879 -111 27 -273 53 -353
57 -26 2 -46 6 -45 10 2 4 55 66 118 138 136 155 160 195 160 263 0 72 -34
128 -110 185 -26 19 -92 30 -137 22z"
        />
      </g>
    </svg>
  );
}

export default ReplayIcon;
