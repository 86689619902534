import styled from "styled-components";

export const CreateEditPostSection = styled.section`
  display: flex;
  padding: 1rem 1rem 0rem 3rem;
  width: 100%;
  max-width: 1400px;
  margin-top: 5rem;
  gap: 5rem;
  color: ${(props) => props.theme.dark};
  margin-bottom: 3rem;
  @media screen and (max-width: 1450px) {
    padding: 0.5rem 1rem 0rem 1rem;
    gap: 3rem;
    margin-top: 5rem;
  }
  @media screen and (max-width: 1350px) {
    padding: 0.5rem 1rem 0rem 1rem;
    gap: 3rem;
  }
  @media screen and (max-width: 1200px) {
    justify-content: center;
    align-items: flex-start;
  }
  @media screen and (max-width: 930px) {
    flex-direction: column;
    align-items: center;
    padding: 1rem 1rem 4rem 1rem;
    gap: 2rem;
  }
`;

// create edit post media styles ----------------------------------------------------------------

export const MediaInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  position: sticky;
  top: 5.5rem;
  height: max-content;
  max-width: 28rem;
  max-height: max-content;
  @media screen and (max-width: 1450px) {
    width: 25rem;
    top: 5rem;
  }
  @media screen and (max-width: 930px) {
    position: static;
    width: 96%;
  }
`;

export const MediaFilesInfo = styled.p`
  display: flex;
  justify-content: space-between;
  padding: 0 5px 10px 5px;
  width: 100%;
  border-bottom: ${({ isBorder, theme }) =>
    isBorder && `1px solid ${theme.borderColor}`};
  @media screen and (max-width: 450px) {
    /* width: 22rem; */
  }
`;

export const MediaInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 28rem;
  height: 24rem;
  border-radius: 20px;
  cursor: pointer;
  background: ${(props) => props.theme.mediumBg};
  color: ${(props) => props.theme.main};
  fill: ${(props) => props.theme.main};
  @media screen and (max-width: 1450px) {
    width: 25rem;
    height: 18rem;
  }
  @media screen and (max-width: 1200px) {
    width: 24rem;
    height: 20rem;
  }
  @media screen and (max-width: 450px) {
    width: 22rem;
    height: 18rem;
  }
`;

export const WhiteDot = styled.span`
  position: absolute;
  content: "";
  background: #fff;
  width: 2rem;
  height: 2rem;
  bottom: 35%;
  left: 35%;
  z-index: -1;
`;

export const MediaPrev = styled.div`
  display: grid;
  place-items: center;
  position: relative;
  width: 28rem;
  max-width: 28rem;
  min-height: 16rem;
  /* max-height: 38rem; */
  height: ${(props) => props.height};
  border-radius: 20px;
  background: ${(props) => props.theme.mediumBg};
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 20px;
    z-index: 2;
  }
  @media screen and (max-width: 1450px) {
    width: 25rem;
    max-height: 34rem;
  }
  @media screen and (max-width: 1200px) {
    height: auto;
  }
  @media screen and (max-width: 450px) {
    width: 22rem;
    min-height: 0rem;
  }
`;

export const PreviewContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 9px;
  padding: 11px;
  @media screen and (max-width: 650px) {
    padding: 10px;
  }
`;

export const PreviewContent = styled.img`
  /* width: 28rem; */
  /* min-height: 20rem; */
  height: ${(props) => props.height || "auto"};
  min-height: 16rem;
  width: 100%;
  /* height: 100%; */
  border-radius: 20px;
  object-fit: cover;
  -o-object-fit: cover;
  /* height: auto; */
  @media screen and (max-width: 1450px) {
    width: 25rem;
    max-height: 34rem;
  }
  @media screen and (max-width: 1200px) {
    /* width: 24rem; */
    height: auto;
  }
  @media screen and (max-width: 450px) {
    width: 22rem;
    min-height: 0rem;
  }
`;

export const PrevActionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  position: absolute;
  top: 1rem;
  left: 1rem;
  @media screen and (max-width: 650px) {
    top: 10px;
    left: 10px;
  }
`;

export const IconWrapper = styled.div`
  height: 1.8rem;
  width: 1.8rem;
  padding: ${({ padR, padN }) =>
    padR ? "0 2px 0 0" : padN ? "0" : "0 0 0 2px"};
  display: grid;
  place-items: center;
  border-radius: 100px;
  font-size: 1.2rem;
  cursor: pointer;
  color: ${(props) => props.theme.main};
  background: ${({ theme }) =>
    theme.mode === "light" ? theme.mediumBg : "#3b3b3b"};
  z-index: 2;
  backdrop-filter: blur(10px);
  &:hover {
    color: ${(props) => props.theme.dark};
  }
  @media screen and (max-width: 1450px) {
    font-size: 1.1rem;
    height: 1.7rem;
    width: 1.7rem;
  }
`;

export const MediaPrevArrayWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: flex-start;
  padding: 0 5px;
  width: 100%;
  @media screen and (max-width: 930px) {
    padding-bottom: 1rem;
    border-bottom: 1px solid ${(props) => props.theme.borderColor};
  }
`;

export const PrevArrayImg = styled.img`
  height: 4.5rem;
  width: 4.5rem;
  object-fit: cover;
  -o-object-fit: cover;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 5px;
    background: var(--linear-background--dark);
    z-index: 2;
  }
`;

// create edit post form styles --------------------------------

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  width: 36rem;
  min-height: 87vh;

  @media screen and (max-width: 1450px) {
    margin-top: 10px;
    width: 34rem;
  }
  @media screen and (max-width: 1350px) {
    width: 32rem;
  }
  @media screen and (max-width: 1250px) {
    width: 30rem;
  }
  @media screen and (max-width: 1230px) {
    width: 28rem;
  }
  @media screen and (max-width: 1200px) {
    width: 30rem;
  }
  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const FormHeader = styled.header`
  display: ${({ isMob }) => (isMob ? "none" : "flex")};
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  @media screen and (max-width: 1450px) {
    margin-bottom: -3px;
    h1 {
      font-size: 21px;
    }
  }
  @media screen and (max-width: 930px) {
    display: ${({ isMob }) => (isMob ? "flex" : "none")};
    width: ${({ isMob }) => (isMob ? "60%" : "auto")};
  }
  @media screen and (max-width: 680px) {
    width: ${({ isMob }) => (isMob ? "75%" : "auto")};
  }
  @media screen and (max-width: 500px) {
    width: ${({ isMob }) => (isMob ? "98%" : "auto")};
  }
`;

export const FormSectionWrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const FormFooter = styled.footer`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const TitleInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border: 1px solid red;
  @media screen and (max-width: 1450px) {
    gap: 0.5rem;
  }
`;

export const CPRatingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: ${(props) => props.theme.dark};
`;

export const CPLabelWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: -7px;
  margin-top: 2px;
  position: relative;
  span {
    color: ${(props) => props.theme.main};
    font-size: 13px;
  }
`;

export const InfoTooltip = styled.span`
  /* position: relative; */
  display: grid;
  place-items: center;
  cursor: pointer;
  color: ${(props) => props.theme.main};

  &:hover::after {
    content: attr(data-tooltip);
    position: absolute;
    top: 5px;
    left: -12.5rem;
    padding: 0.3rem;
    background: ${(props) => props.theme.mediumBg};
    color: ${(props) => props.theme.main};
    border-radius: 8px 0 8px 8px;
    padding: 10px;
    pointer-events: none;
    font-size: 12px;
    z-index: 30;
    width: 10rem;
  }
  &:hover::before {
    content: "";
    position: absolute;
    transform: rotate(90deg);
    top: 8px;
    left: -1.5rem;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-radius: 50px;
    border-bottom: 8px solid ${(props) => props.theme.mediumBg};
    z-index: 31;
  }

  &:hover {
    color: ${(props) => props.theme.dark};
  }
`;

export const SkillLevelWrappper = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
`;

// Elements styles in credit form section --------------------

export const CreditFormDesc = styled.p`
  font-size: 13px;
  padding-top: 8px;
  border-top: 1px solid ${(props) => props.theme.borderColor};
  margin-top: 5px;
`;

export const RatioContainer = styled.div`
  display: flex;
  gap: 1.1rem;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 1.5rem;
`;

export const RatioOptionButton = styled.div`
  color: ${(props) => props.theme.dark};
  gap: 5px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  cursor: pointer;
  p {
    font-size: 13px;
    padding: 0;
    margin-bottom: -5px;
  }
`;

export const RatioSpanIcon = styled.span`
  content: "";
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border-radius: 3px;
  border: 2px solid ${(props) => props.theme.defaultMain};
  background: ${(props) => props.theme.mediumMain};
`;

export const ProductionDateWrap = styled.div`
  display: flex;
  gap: 1rem;
  width: 100%;
  max-width: 36rem;
  align-items: flex-start;
  justify-content: space-between;
`;

export const AddedCreditTagsWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  width: ${({ isHalf }) => (isHalf ? "20rem" : "100%")};
  color: ${(props) => props.theme.dark};
  padding: 5px 10px 10px 10px;
  border-radius: 5px;
  background-color: ${(props) => props.theme.mediumBg};
  p {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
    font-size: 13px;
  }
  span {
    font-size: 1.3rem;
    color: ${(props) => props.theme.dark};
    cursor: pointer;
  }
  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const UsedToolsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 95%;
`;

export const UsedToolsBox = styled.div`
  display: flex;
  padding: 10px;
  background-color: ${(props) => props.theme.mediumBg};
  border-radius: 5px;
  width: max-content;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  p {
    display: flex;
    align-items: center;
    font-size: 13px;
    color: ${(props) => props.theme.main};
  }
  span {
    display: grid;
    place-items: center;
    color: ${(props) => props.theme.dark};
    cursor: pointer;
    font-size: 1.2rem;
  }
  @media screen and (max-width: 400px) {
    width: 100%;
  }
`;
