import React, { useEffect, useRef } from "react";
import {
  DetailSectionContainer,
  MyPhotoContent,
  MyPhotoImg,
  MyPhotoWrapper,
  MyPhotosContainer,
  UserDetailHeading,
} from "../Profile/Overview/OverviewStyle";
import { CameraIcon } from "../../Assets/SVG";
import { MdDelete } from "react-icons/md";
import useImageInputType from "../../Hooks/useImageInputType";
import { BiPlus } from "react-icons/bi";
import { MyPhotoButton } from "../../Components/Common/CommonStyle";

const MyPhotosEdit = ({ values, setValues, hasPhotos }) => {
  const myPhotoRefs = useRef([]);
  myPhotoRefs.current = Array.from(
    { length: 3 },
    (_, index) => myPhotoRefs.current[index] || React.createRef()
  );
  const handlePhotoAction = (itemIndex, isMyNewPhotos) => {
    if (isMyNewPhotos) {
      setValues((prevValues) => {
        const newPhotos = prevValues.myNewPhotos.filter(
          (_, index) => index !== itemIndex
        );
        return {
          ...prevValues,
          myNewPhotos: newPhotos,
        };
      });
    } else {
      setValues((prevValues) => {
        const newPhotos = prevValues.myPhotos.filter(
          (_, index) => index !== itemIndex
        );
        return {
          ...prevValues,
          myPhotos: newPhotos,
        };
      });
    }
  };
  const { handleImageInput, image, setImage } = useImageInputType();

  useEffect(() => {
    if (image && image.data) {
      setValues((prevValues) => ({
        ...prevValues,
        myNewPhotos: [...values.myNewPhotos, image],
      }));
    }
    setImage("");
  }, [image, setImage, setValues, values.myNewPhotos]);

  return (
    <DetailSectionContainer>
      <UserDetailHeading>
        <span>
          <CameraIcon width={"21px"} /> My Photos
        </span>
      </UserDetailHeading>
      <MyPhotosContainer>
        {values.myPhotos.map((item, index) => (
          <React.Fragment key={index}>
            {item !== "" && item !== null && (
              <>
                <MyPhotoWrapper onClick={() => handlePhotoAction(index)}>
                  <MyPhotoImg src={item} />
                  <MyPhotoContent isEdit={true}>
                    <MdDelete />
                  </MyPhotoContent>
                </MyPhotoWrapper>
              </>
            )}
          </React.Fragment>
        ))}

        {values.myNewPhotos.length > 0 &&
          values.myNewPhotos.map((item, index) => (
            <MyPhotoWrapper
              key={index}
              onClick={() => handlePhotoAction(index, true)}
            >
              <MyPhotoImg src={item.preview} />
              <MyPhotoContent isEdit={true}>
                <MdDelete />
              </MyPhotoContent>
            </MyPhotoWrapper>
          ))}

        {Array.from({
          length:
            3 -
            (hasPhotos
              ? values.myPhotos.length + values.myNewPhotos.length
              : 0 + values.myNewPhotos.length),
        }).map((item, index) => (
          <React.Fragment key={index}>
            <input
              type="file"
              ref={myPhotoRefs.current[index]}
              hidden
              onChange={(e) => handleImageInput(e, index)}
              accept="image/jpeg,image/png,image/webp"
            />
            <MyPhotoButton
              onClick={() => myPhotoRefs.current[index].current.click()}
            >
              <BiPlus />
              <p>Add Photo</p>
            </MyPhotoButton>
          </React.Fragment>
        ))}
      </MyPhotosContainer>
    </DetailSectionContainer>
  );
};

export default MyPhotosEdit;

// hasPhotos &&
