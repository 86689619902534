import React from "react";
import { useDispatch } from "react-redux";
import { BsDot } from "react-icons/bs";
import {
  ActionMenuWrap,
  PostHorzContiner,
  PostHorzDesc,
  PostHorzDetailWrap,
  PostHorzMediaCard,
  PostHorzPin,
  PostHorzSubtitle,
  PostHorzTitle,
} from "./PostStyle";
import ActionMenu from "./ActionMenu";
import { useHandleCreateShare } from "../../Hooks/useCreateShareHandler";
import { MdLocationPin } from "react-icons/md";
import StarDisplay from "../StarRating/StarDisplay";
import { MultipostIcon, PinIcon } from "../../Assets/SVG";
import { Image100, Video100 } from "../Common/CommonStyle";
import { useCommonHandle } from "../../Hooks/useEventHandler";

const PostHorizOverview = ({
  postId,
  about,
  isBestWork,
  numberOfRating,
  postRating,
  author,
  media,
  timeAgo,
}) => {
  const dispatch = useDispatch();
  const { handleBestWorkClick, handleDeleteClick } = useHandleCreateShare({
    dispatch,
  });
  const { handleCopyLink } = useCommonHandle();

  return (
    <PostHorzContiner>
      <PostHorzMediaCard to={`/post/${postId}`}>
        <PostHorzPin isDisplay={isBestWork || media?.length > 1}>
          {isBestWork && <PinIcon width={"18px"} fill={"white"} />}
          {media?.length > 1 && <MultipostIcon width={"18px"} fill={"white"} />}
        </PostHorzPin>
        {media[0]?.mediaType === "image" ? (
          <Image100 src={media[0]?.url} br={"15px"} alt="" />
        ) : (
          <Video100
            src={media[0]?.url}
            alt=""
            loop={false}
            // autoPlay={false}
            muted
            br={"15px"}
            playsInline
            webkit-playsinline
            controlsList="nodownload nofullscreen"
            preload="metadata"
          />
        )}
      </PostHorzMediaCard>

      <PostHorzDetailWrap>
        <PostHorzTitle to={`/post/${postId}`}>{about?.title}</PostHorzTitle>
        <PostHorzSubtitle>
          <span>
            <MdLocationPin />
          </span>
          <p>
            {about?.country}&nbsp;-&nbsp;{about?.state}
          </p>
        </PostHorzSubtitle>
        <PostHorzDesc>{about?.description}</PostHorzDesc>
        <PostHorzSubtitle>
          <p>{timeAgo}</p>
          <BsDot style={{ fontSize: "1.2rem" }} />
          <p>
            {about?.mainCategory}&nbsp;-&nbsp;{about?.skill}
          </p>
        </PostHorzSubtitle>
        <StarDisplay
          peopleCount={numberOfRating}
          rating={postRating ? postRating : null}
          isAllPost={true}
        />
      </PostHorzDetailWrap>

      <ActionMenuWrap>
        <ActionMenu
          forUserAllPost={true}
          top={"10px"}
          right={"20px"}
          postId={postId}
          authorId={author?._id}
          isBestWork={isBestWork}
          onPin={handleBestWorkClick}
          onDelete={handleDeleteClick}
          onCopyLink={handleCopyLink}
        />
      </ActionMenuWrap>
    </PostHorzContiner>
  );
};

export default PostHorizOverview;
