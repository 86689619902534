import React, { useEffect, useRef, useState } from "react";
import { CoverPhotoContainer, PrsnlCoverPhoto } from "./PersonalInfoStyle";
import { CameraIcon } from "../../Assets/SVG";
import useImageInputType from "../../Hooks/useImageInputType";
import { CameraButton } from "../../Components/Common/CommonStyle";
import { useModalOpen } from "../../Hooks/useEventHandler";
import CropProfileCoverPhoto from "../../Components/Modals/CropProfileCoverPhoto";
import ErrorModal from "../../Components/Modals/ErrorModal";
import { MdEdit } from "react-icons/md";
import { PhotoActionModal } from "../../Components";

const ProfileCoverPhoto = ({
  onFinalCoverPhotoChange,
  isEdit,
  currentImg,
  values,
  setValues,
}) => {
  const coverPhotoRef = useRef(null);
  const { handleImageInput, image, setImage, error, setError } =
    useImageInputType();
  const { isOpen, setIsOpen } = useModalOpen();
  const [finalCropImage, setFinalCropImage] = useState(null);
  const [width, setWidth] = useState(null);
  const [isActionOpen, setIsActionOpen] = useState(false);

  useEffect(() => {
    if (image) {
      const img = new Image();
      img.src = image;
      img.onload = () => {
        let w = img.naturalWidth;
        if (w < 900) {
          setWidth(w * 2);
        } else if (w < 1600) {
          setWidth(w);
        } else if (w <= 2100) {
          setWidth(w / 1.5);
        } else if (w <= 2500) {
          setWidth(w / 2);
        } else if (w <= 3000) {
          setWidth(w / 3);
        } else {
          setWidth(w / 3.6);
        }
        console.log("w", w);
      };
    }
    console.log("width", width);
  }, [image, width]);

  const handleInputChange = (e) => {
    handleImageInput(e);
    setIsOpen(true);
    setIsActionOpen(false);
  };

  const handleCropComplete = (img) => {
    setFinalCropImage(img.croppedPrev);
    onFinalCoverPhotoChange(img.croppedData);
  };

  const handleCurrentPhotoDelete = () => {
    setValues({
      ...values,
      coverPhoto: null,
    });
    setFinalCropImage(null);
  };

  return (
    <CoverPhotoContainer isEdit={isEdit}>
      <input
        type="file"
        ref={coverPhotoRef}
        hidden
        onChange={handleInputChange}
        accept="image/jpeg,image/png,image/webp,image"
      />
      {finalCropImage ? (
        <PrsnlCoverPhoto src={finalCropImage} alt="profile_cover" />
      ) : (
        currentImg && <PrsnlCoverPhoto src={currentImg} alt="profile_cover" />
      )}

      {currentImg || finalCropImage ? (
        <CameraButton onClick={() => setIsActionOpen(true)}>
          <MdEdit fill="white" />
          <p>Edit Cover Photo</p>
        </CameraButton>
      ) : (
        <CameraButton onClick={() => coverPhotoRef.current.click()}>
          <CameraIcon fill={"white"} />
          <p>Add Cover Photo</p>
        </CameraButton>
      )}

      {image && isOpen && !error ? (
        <CropProfileCoverPhoto
          image={image}
          setImage={setImage}
          handleCropComplete={handleCropComplete}
          setIsOpen={setIsOpen}
          isCover={true}
          width={width}
        />
      ) : (
        ""
      )}
      {error && (
        <ErrorModal
          error={error}
          setError={setError}
          image={image}
          setImage={setImage}
        />
      )}
      {isActionOpen && (
        <PhotoActionModal
          setIsOpen={setIsActionOpen}
          onChange={handleInputChange}
          onDelete={handleCurrentPhotoDelete}
        />
      )}
    </CoverPhotoContainer>
  );
};

export default ProfileCoverPhoto;
