import styled from "styled-components";

export const InfoSectionWrapper = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-top: 1rem;
  gap: 2rem;
  @media screen and (max-width: 950px) {
    flex-direction: column;
  }
`;

export const AddFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: center; */
  gap: 1.5rem;

  @media screen and (max-width: 1450px) {
    gap: 1rem;
  }
  @media screen and (max-width: 1100px) {
    width: 95%;
    padding-right: 1rem;
  }
`;

export const AddFormSubHeading = styled.h2`
  font-size: 16px;
  font-weight: 500;
  display: flex;
  /* justify-content: flex-start; */

  align-items: center;
  gap: 0.8rem;
  padding: 0.5rem 0;
  border: 1px solid ${(props) => props.theme.borderColor};
  border-left: 0;
  border-right: 0;
  p {
    font-size: 12px;
    color: ${(props) => props.theme.main};
  }
`;

export const AddedInfoViewer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 39%;
  @media screen and (max-width: 1450px) {
    max-height: 55vh;
  }
  @media screen and (max-width: 950px) {
    width: 100%;
  }
`;

export const EmptyAddedContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;
  fill: ${(props) => props.theme.main};
  color: ${(props) => props.theme.main};
  height: ${(props) => props.height || "15rem"};
  width: 100%;
`;

export const AddedInfoTitle = styled.h2`
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.borderColor};
  padding-bottom: 0.5rem;
  span {
    background: ${(props) => props.theme.mediumBg};
    height: 1.5rem;
    width: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
  }
`;

export const AddedInfoCard = styled.article`
  background: ${(props) => props.theme.mediumBg};
  /* padding: 1rem; */
  border-radius: 10px;
`;

export const AddedEditDeleteBtnWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-size: 1.2rem;
  color: ${(props) => props.theme.dark};
  fill: ${(props) => props.theme.dark};
  cursor: pointer;
`;

export const AddedSkillHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0.6rem;
  border-bottom: 1px solid ${(props) => props.theme.mediumMain};
  font-weight: 500;
  font-size: 14px;
`;

export const AddAnotherWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  /* margin-top: 5px; */
  width: 100%;
`;

export const AddAnother = styled.span`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: max-content;
  cursor: pointer;
  bottom: 1rem;
  right: 0;
  font-size: 12px;
  color: ${(props) =>
    props.theme.mode === "light" ? props.theme.secondary : props.theme.yellow};
`;

export const AddedLanguageWrapper = styled.article`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.6rem 0.8rem;
  section {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }
`;

export const AddedEducationWrapper = styled.header`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.6rem 0.8rem;
  header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
`;

export const SocialMediaInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;
  width: 100%;
`;

export const SocialMediaInputWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  gap: 1rem;
  fill: ${({ isValue, theme }) => (isValue ? theme.dark : theme.offSIcon)};
`;

export const CompleteTextwrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const CompleteText = styled.p`
  max-width: 600px;
  text-align: left;
  margin-top: -3px;
`;
