import React, { useEffect, useState } from "react";
import {
  RadioInputWrapper,
  PrsnlHeadingWrapper,
  PrsnlSubHeading,
  PrsnlInfoSection,
  PrsnlButtonWrapper,
  PrsnlInputContainer,
  PrslInputWrapper,
  PrsnlIntruct,
  PrsnlForm,
} from "./PersonalInfoStyle";
import { FormInput } from "../../Components";
import {
  FormInputContainer,
  PrimaryBtn,
  FormError,
  DropdownFlexWrap,
} from "../../Components/Common/CommonStyle";
import ProfileCoverPhoto from "./ProfileCoverPhoto";
import ProfilePhoto from "./ProfilePhoto";
import { Gender } from "../../Utils/Others/Data";
import { DropDown, TextArea, RadioInput, Loader } from "../../Components";
import { useHandleValues, useModalOpen } from "../../Hooks/useEventHandler";
import useDateSelector from "../../Hooks/useDateSelector";
import { PersonalFormValidation } from "../../Utils/Validations/ProfileValidation";
import AccountOption from "../../Components/Modals/AccountOption";
import { useDispatch, useSelector } from "react-redux";
import {
  getLanguages,
  getProfessions,
} from "../../Functions/getFetchFunctions";
import {
  convertLanguagesToArray,
  convertProfessionsToArray,
} from "../../Hooks/useArrayConvertor";
import { handlePersonalInfoSubmit } from "../../Functions/getAuthHandler";
import { getFileUpload } from "../../Functions/getFileUpload";
import { useNavigate } from "react-router-dom";
import { StyledLabel } from "../../Components/Inputs/InputStyle";
import { useCoverProfilePhoto } from "../../Hooks/useImageInputType";
import { EditPhotosWrapper } from "../../Pages/EditProfile/EditProfileStyles";
import { Helmet } from "react-helmet";

const PersonalInfo = () => {
  const currentUserId = localStorage.getItem("currentUserId");
  const currentUser = useSelector((state) => state.auth.currentUser);
  const languages = useSelector((state) => state.content.languages);
  const professions = useSelector((state) => state.content.professions);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, setIsOpen } = useModalOpen();
  const [langValues, setLangValues] = useState([]);
  const [professionValues, setProfessionValues] = useState([]);

  // useEffect(() => {
  //   const loadingTimeout = setTimeout(() => {
  //     setIsLoading(false);
  //   }, 1500);

  //   return () => clearTimeout(loadingTimeout);
  // }, [dispatch]);

  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    knownLanguage: "",
    knownLanguageId: "",
    profession: "",
    professionId: "",
    dob: "",
    gender: "",
    quotes: "",
    coverPhoto: null,
    profilePhoto: null,
  });
  const [errors, setErrors] = useState([]);

  const {
    years,
    months,
    days,
    year,
    month,
    day,
    handleYearChange,
    handleMonthChange,
    handleDayChange,
  } = useDateSelector({ values, setValues, errors, setErrors });
  const { handleInputChange, handleDropdownChange, handleGenderOptionChange } =
    useHandleValues({
      values,
      setValues,
      errors,
      setErrors,
    });
  const { handleCoverChange, handleProfileChange } = useCoverProfilePhoto(
    values,
    setValues
  );

  // fetch the languges and professions
  useEffect(() => {
    getLanguages(dispatch);
    getProfessions(dispatch);
  }, [dispatch]);

  useEffect(() => {
    const convertedValues = convertLanguagesToArray(languages);
    setLangValues(convertedValues);
  }, [languages]);

  useEffect(() => {
    const convertedValues = convertProfessionsToArray(professions);
    setProfessionValues(convertedValues);
  }, [professions]);

  // validation for fields when clicking outside
  // const validateField = (fieldName) => {
  //   const error = PersonalFormValidation({ [fieldName]: values[fieldName] })[
  //     fieldName
  //   ];
  //   setErrors((prevState) => ({
  //     ...prevState,
  //     [fieldName]: error,
  //   }));
  // };

  // form submission
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const formErrors = PersonalFormValidation(values, year, month, day);
    setErrors(formErrors);
    console.log(values);

    if (Object.keys(formErrors).length === 0) {
      setIsLoading(true);
      let profileImgKey = values.profilePhoto;
      let coverImgKey = values.coverPhoto;
      if (values.profilePhoto?.name) {
        profileImgKey = await getFileUpload(
          values.profilePhoto?.name && values.profilePhoto
        );
      }

      if (values.coverPhoto?.name) {
        coverImgKey = await getFileUpload(
          values.coverPhoto?.name && values.coverPhoto
        );
      }

      handlePersonalInfoSubmit(
        currentUserId,
        currentUser,
        values,
        profileImgKey,
        coverImgKey,
        dispatch,
        navigate,
        setIsLoading
      );
    }
  };

  return (
    <>
      <Helmet>
        <title>Complete Your Profile</title>
      </Helmet>
      {isLoading ? (
        <Loader isFull={true} />
      ) : (
        <PrsnlInfoSection>
          <section>
            <PrsnlHeadingWrapper>
              Let's Create a Public Profile for You
              <PrsnlSubHeading>
                Complete your profile by uploading a profile photo and cover
                photos, as well as filling out the form below. Providing more
                information will help others to trust and connect with you.
              </PrsnlSubHeading>
            </PrsnlHeadingWrapper>

            <EditPhotosWrapper>
              <ProfileCoverPhoto
                isEdit={true}
                currentImg={values.coverPhoto}
                values={values}
                setValues={setValues}
                onFinalCoverPhotoChange={handleCoverChange}
              />
              <ProfilePhoto
                currentImg={values.profilePhoto}
                values={values}
                setValues={setValues}
                onFinalProfilePhotoChange={handleProfileChange}
              />
            </EditPhotosWrapper>

            <PrsnlForm>
              <PrsnlInputContainer isRow={true}>
                <FormInput
                  type="text"
                  name="firstName"
                  label="First Name"
                  placeholder="John"
                  onChange={handleInputChange}
                  value={values.firstName}
                  validation={errors?.firstName}
                  isItemStart={true}
                />

                <FormInput
                  type="text"
                  name="lastName"
                  label="Last Name"
                  placeholder="Wick"
                  onChange={handleInputChange}
                  value={values.lastName}
                  validation={errors?.lastName}
                  isItemStart={true}
                />
              </PrsnlInputContainer>

              <PrsnlInputContainer>
                <PrslInputWrapper>
                  <label>Gender</label>
                  <RadioInputWrapper>
                    {Gender.map((gender) => (
                      <RadioInput
                        key={gender.id}
                        label={gender.label}
                        name={gender.name}
                        checked={values.gender === gender.val}
                        onChange={handleGenderOptionChange}
                        value={gender.val}
                      />
                    ))}
                  </RadioInputWrapper>
                  {errors.gender && <FormError>{errors.gender}</FormError>}
                </PrslInputWrapper>
              </PrsnlInputContainer>

              <FormInputContainer
                style={{ flexDirection: "column", gap: "5px" }}
              >
                <StyledLabel>Date Of Birth</StyledLabel>
                <DropdownFlexWrap width="60%">
                  <DropDown
                    name="year"
                    defaultOption="Year"
                    options={years.map(({ value, label }) => ({
                      value,
                      label,
                    }))}
                    value={year}
                    onChange={(option) =>
                      handleYearChange(option.selectedOption)
                    }
                  />
                  <DropDown
                    name="month"
                    defaultOption="Month"
                    value={month}
                    onChange={(option) =>
                      handleMonthChange(option.selectedOption)
                    }
                    options={months.map(({ label, value }) => ({
                      value: label,
                      label: value,
                    }))}
                  />
                  <DropDown
                    name="day"
                    value={day}
                    onChange={(option) =>
                      handleDayChange(option.selectedOption, "dob")
                    }
                    options={days.map(({ value, label }) => ({
                      value,
                      label,
                    }))}
                    defaultOption="Day"
                  />
                </DropdownFlexWrap>
                {errors.dob && <FormError>{errors.dob}</FormError>}
              </FormInputContainer>

              <PrsnlInputContainer>
                <PrslInputWrapper>
                  <DropDown
                    name="knownLanguage"
                    label="Known Language"
                    idName="knownLanguageId"
                    defaultOption="Select your native language"
                    options={langValues}
                    value={values.knownLanguage}
                    onChange={handleDropdownChange}
                    validation={errors?.knownLanguage}
                    isSingle={true}
                    isItemStart={true}
                    labelWidth="auto"
                  />
                </PrslInputWrapper>
                <PrsnlIntruct>
                  Let us know the language you speak so we can connect you with
                  people who share your interests and culture.
                </PrsnlIntruct>
              </PrsnlInputContainer>

              <PrsnlInputContainer>
                <PrslInputWrapper>
                  <DropDown
                    name="profession"
                    label="Profession"
                    idName="professionId"
                    defaultOption="Select your profession"
                    options={professionValues}
                    value={values.profession}
                    validation={errors?.profession}
                    onChange={handleDropdownChange}
                    isSingle={true}
                    isItemStart={true}
                    labelWidth="auto"
                  />
                </PrslInputWrapper>
                <PrsnlIntruct>
                  This information helps us understand your professional
                  background and can be used to provide you with relevant
                  content and services.
                </PrsnlIntruct>
              </PrsnlInputContainer>

              <PrsnlInputContainer>
                <PrslInputWrapper>
                  <TextArea
                    name="quotes"
                    label="Quotes"
                    isOptional={true}
                    placeholder="What's on your mind...?"
                    maxLength={100}
                    value={values.quotes}
                    onChange={handleInputChange}
                    height={"4.5rem"}
                    invert={true}
                  />
                </PrslInputWrapper>
                <PrsnlIntruct>
                  Keep it positive: Let's create a supportive community by
                  sharing constructive and respectful messages.
                </PrsnlIntruct>
              </PrsnlInputContainer>
              <PrsnlButtonWrapper>
                <PrimaryBtn
                  onClick={handleFormSubmit}
                  width={"10rem"}
                  isWidthFull={true}
                >
                  Complete Profile
                </PrimaryBtn>
              </PrsnlButtonWrapper>
            </PrsnlForm>
          </section>
          {isOpen && <AccountOption closeModal={setIsOpen} />}
        </PrsnlInfoSection>
      )}
    </>
  );
};

export default PersonalInfo;
