import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow } from "swiper";
import styled from "styled-components";

import "swiper/css";
import "swiper/css/effect-coverflow";

const SwiperStyle = styled.div`
  width: 90%;
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

const SliderImage = styled.img`
  width: 11.5rem;
  height: 16rem;
  object-fit: cover;
  border-radius: 15px;
  border: 2px solid white;
  background-color: var(--light-gray);
`;

const UserPhotoSlider = () => {
  return (
    <SwiperStyle>
      <Swiper
        loop={false}
        loopFillGroupWithBlank={true}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={3}
        modules={[EffectCoverflow]}
        effect={"coverflow"}
        coverflowEffect={{
          rotate: 0,
          stretch: 10,
          depth: 150,
          modifier: 3,
          slideShadows: false,
        }}
      >
        <SwiperSlide className="swiper-slide">
          {/* <SliderImage src={Bridge} alt="image" /> */}
        </SwiperSlide>
        <SwiperSlide className="swiper-slide">
          {/* <SliderImage src={Desktop} alt="image" /> */}
        </SwiperSlide>{" "}
        <SwiperSlide className="swiper-slide">
          {/* <SliderImage src={Drink} alt="image" /> */}
        </SwiperSlide>
      </Swiper>
    </SwiperStyle>
  );
};

export default UserPhotoSlider;

//   <Swiper
//     spaceBetween={50}
//     slidesPerView={3}
//     onSlideChange={() => console.log("slide change")}
//     onSwiper={(swiper) => console.log(swiper)}
//   >
//     <SwiperSlide>Slide 1</SwiperSlide>
//     <SwiperSlide>Slide 2</SwiperSlide>
//     <SwiperSlide>Slide 3</SwiperSlide>
//     <SwiperSlide>Slide 4</SwiperSlide>
//   </Swiper>;

//   <Swiper modules={[EffectCoverflow]} effect="coverflow">
//     {[...Array(5)].map((i, el) => {
//       return (
//         <SwiperSlide key={i}>
//           <img src={Book} alt="" width={"150px"} />
//           {el}
//         </SwiperSlide>
//       );
//     })}
//   </Swiper>

// pagination={{
//   clickable: true,
// }}
// autoplay={{ delay: 3000 }}
// navigation={true}
// breakpoints={{
//   640: {
//     slidesPerView: 1,
//     spaceBetween: 20,
//   },
//   768: {
//     slidesPerView: 2,
//     spaceBetween: 30,
//   },
//   1024: {
//     slidesPerView: 3,
//     spaceBetween: 150,
//   },
// }}
