import React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

const SharedLinks = () => {
  const { userId } = useParams();
  const dispatch = useDispatch();
  const currentUserId = localStorage.getItem("currentUserId");
  let pageNo = 1;
  let pageSize = 20;
  return <div>SharedLinks</div>;
};

export default SharedLinks;
