import styled from "styled-components";
import { TbDotsVertical } from "react-icons/tb";

export const ProfileWrap = styled.section`
  display: flex;
  flex-direction: column;
  /* display: grid;
  place-items: center; */
  gap: 1rem;
  padding: ${({ isTalent }) =>
    isTalent ? "5rem 12rem 1rem 12rem" : "5rem 12rem 1rem 12rem"};
  width: 100%;
  /* padding: 5rem 0 1rem 0; */
  max-width: 1600px;

  @media screen and (max-width: 1550px) {
    padding: ${({ isTalent }) =>
      isTalent ? "5rem 8rem 2rem 8rem" : "5rem 12rem 1rem 12rem"};
  }
  @media screen and (max-width: 1400px) {
    padding: 5rem 4rem 1rem 4rem;
  }
  @media screen and (max-width: 950px) {
    padding: 5rem 0 3rem 0;
  }
  @media screen and (max-width: 650px) {
    padding: 4rem 0 3rem 0;
  }
`;

export const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100vw;
  /* border: 1px solid red; */
  max-width: 1300px;
  /* padding: 5rem 1rem 1rem 1rem; */
`;

export const NoUserFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
  gap: 0;
  h1 {
    color: ${(props) => props.theme.dark};
    font-size: 2rem;
    margin-bottom: -1rem;
  }
  p {
    position: relative;
    color: ${(props) => props.theme.main};
    font-size: 14px;
  }
  span {
    display: grid;
    place-items: center;
    font-size: 1.6rem;
    color: var(--white);
    position: absolute;
    top: -2.1rem;
    right: -5.22rem;
  }
`;

export const ProfileHeaderContainer = styled.header`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--white);
`;

// profile header cover photo container

export const ProfileHeaderWrapper = styled.section`
  width: 100%;
  height: max-content;
  color: var(--white);
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: ${({ isTalent }) =>
      isTalent ? "20px" : "0px 0px 20px 20px"};
    background: ${({ isTalent, theme }) =>
      isTalent
        ? theme.mode === "light"
          ? "var(--linear-background--light)"
          : "var(--linear-background--dark)"
        : theme.mode === "light"
        ? "var(--linear-background--light--user)"
        : "var(--linear-background--dark--user)"};
    z-index: 2;
  }

  @media screen and (max-width: 1450px) {
    height: 19rem;
  }
  @media screen and (max-width: 950px) {
    &::before {
      border-radius: 0px;
    }
  }

  @media screen and (max-width: 650px) {
    height: 14rem;
    &::before {
      background: var(--linear-background--dark--650);
    }
  }
`;

export const ProfileBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  border: 1px solid red;
  border-radius: ${({ isTalent }) => (isTalent ? "20px" : "0px 0px 20px 20px")};
  @media screen and (max-width: 950px) {
    border-radius: 0px;
  }
`;

export const CoverPhotoBg = styled.img`
  width: 100%;
  max-height: 21rem;
  border-radius: ${({ isTalent }) => (isTalent ? "20px" : "0px 0px 20px 20px")};
  -o-object-fit: cover;
  object-fit: cover;
  @media screen and (max-width: 1450px) {
    height: 19rem;
  }
  @media screen and (max-width: 950px) {
    border-radius: 0px;
  }
  @media screen and (max-width: 650px) {
    height: 14rem;
  }
`;

// <---------------- profile detail and dp photo container ---------------->

export const ProfileHeaderDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  padding: 1rem;
  bottom: ${({ isTalent }) => (isTalent ? "0" : "-7rem")};
  top: ${({ isTalent }) => isTalent && "0"};
  left: ${({ isTalent }) => (isTalent ? "0" : "0.5rem")};
  z-index: 3;
  @media screen and (max-width: 1450px) {
    height: auto;
    padding-bottom: 0;
    top: ${({ isTalent }) => (isTalent ? "0" : "67%")};
  }
  @media screen and (max-width: 650px) {
    top: ${({ isTalent }) => (isTalent ? "45%" : "70%")};
    left: ${({ isTalent }) => (isTalent ? "0rem" : "0.5rem")};
    padding: ${({ isTalent }) => (isTalent ? "1rem" : "0.5rem")};
  }
  @media screen and (max-width: 550px) {
    top: ${({ isTalent }) => (isTalent ? "45%" : "72%")};
  }
  @media screen and (max-width: 450px) {
    left: ${({ isTalent }) => (isTalent ? "0" : "0.5rem")};
  }
`;

export const ProfileHeaderDetails = styled.header`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`;

export const ProfilePhotoNameWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const ProfilePhotoContainer = styled.div`
  background: ${(props) => props.theme.darkGray};
  position: relative;
  /* background-image: url("../Public_Images/default_profile_pic.png");
  background-repeat: no-repeat;
  background-size: cover; */
  /* border: none;
  outline: none; */
  object-fit: cover;
  /* height: ${({ isTalent }) => (isTalent ? "6rem" : "9rem")}; */
  width: ${({ isTalent }) => (isTalent ? "5rem" : "6rem")};
  margin-top: ${({ isTalent }) => !isTalent && "-5px"};
  border-radius: 10px;
  cursor: ${({ isCursor }) => isCursor && "pointer"};
  @media screen and (max-width: 550px) {
    width: ${({ isTalent }) => (isTalent ? "5rem" : "5rem")};
    height: ${({ isTalent }) => (isTalent ? "6rem" : "6rem")};
    border-radius: 6px;
  }
`;

export const ProfilePhoto = styled.img`
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 10px;
  @media screen and (max-width: 550px) {
    border-radius: 6px;
  }
`;

export const ProfileNameWrapper = styled.section`
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  h1 {
    margin-top: -10px;
    margin-bottom: -5px;
  }
  h3 {
    color: var(--gray);
  }
  span {
    color: var(--gray);
  }
  div {
    display: flex;
    flex-direction: column;
  }
  @media screen and (max-width: 650px) {
    h1 {
      font-size: 20px;
      margin-top: -5px;
      margin-bottom: -2px;
    }
    h3 {
      font-size: 13px;
      font-weight: 400;
    }
    span {
      font-size: 13px;
    }
    div {
      flex-direction: row;
      gap: 1rem;
    }
  }
`;

// <---------------------- profile countings container ---------------------->

export const CountBoxLine = styled.span`
  content: "";
  width: 1px;
  border-radius: 5px;
  background: var(--gray);
  height: 30px;
`;

export const ProfileCountsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin: 1rem 0;
  @media screen and (max-width: 1400px) {
    margin: 0.8rem 0 0.5rem 0;
  }
  @media screen and (max-width: 650px) {
    position: absolute;
    left: ${({ isTalent }) => (isTalent ? "6.5rem" : "0.5rem")};
    top: ${({ isTalent }) => (isTalent ? "3rem" : "6rem")};
    gap: 5px;
  }
  @media screen and (max-width: 550px) {
    top: ${({ isTalent }) => (isTalent ? "3rem" : "3rem")};
  }
  @media screen and (max-width: 450px) {
    gap: 5px;
    left: ${({ isTalent }) => (isTalent ? "6.5rem" : "10px")};
  }
`;

export const ProfileCountsBox = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  padding: 0.2rem 0.5rem;
  border-radius: 5px;
  margin-left: ${({ isTalent }) => !isTalent && "-0.6rem"};
  flex-direction: ${({ isTalent }) => (isTalent ? "column" : "row")};
  font-size: ${({ isTalent }) => (isTalent ? "13px" : "16px")};
  gap: ${({ isTalent }) => (isTalent ? "0.2rem" : "1rem")};
  color: ${({ isTalent, theme }) =>
    isTalent ? theme.defaultLight : theme.dark};
  span {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: 500;
    font-size: 15px;
  }
  @media screen and (max-width: 650px) {
    margin-top: ${({ isTalent }) => !isTalent && "4rem"};
  }
`;

export const ProfileQuotesWrapper = styled.p`
  font-size: ${({ isTalent }) => (isTalent ? "12px" : "13px")};
  width: ${({ isTalent }) => (isTalent ? "16rem" : "25rem")};
  text-align: ${({ isTalent }) => (isTalent ? "justify" : "left")};
  margin-top: ${({ isTalent }) => (isTalent ? "0" : "1.3rem")};
  color: ${({ isTalent, theme }) =>
    isTalent ? theme.defaultLight : theme.main};
  @media screen and (max-width: 950px) {
    width: ${({ isTalent }) => (isTalent ? "16rem" : "22rem")};
  }
  @media screen and (max-width: 650px) {
    margin-top: 1rem;
    width: ${({ isTalent }) => (isTalent ? "25rem" : "maxContent")};
    color: ${(props) => props.theme.main};
  }
  @media screen and (max-width: 550px) {
    width: ${({ isTalent }) => (isTalent ? "22rem" : "20rem")};
  }
  @media screen and (max-width: 550px) {
    width: ${({ isTalent }) => (isTalent ? "22rem" : "16rem")};
  }
`;

// <---------------------- profile actions container ---------------------->

export const ProfileActionContainer = styled.section`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: ${({ isTalent }) =>
    isTalent ? "space-between" : "flex-end"};
  width: 100%;
  @media screen and (max-width: 950px) {
    padding: 0 0.8rem;
  }
  @media screen and (max-width: 800px) {
    margin-top: ${({ isTalent }) => (isTalent ? "2.5rem" : "5.5rem")};
  }
  @media screen and (max-width: 650px) {
    justify-content: center;
    flex-direction: column;
    margin-top: ${({ isTalent }) => (isTalent ? "2.5rem" : "5.5rem")};
    gap: 1rem;
  }
`;

export const ProfileRatingWrapper = styled.div`
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1.5rem;
  font-weight: 500;
  color: ${(props) => props.theme.main};
  @media screen and (max-width: 950px) {
    gap: 1rem;
  }
  @media screen and (max-width: 650px) {
    width: 100%;
    padding: 0 0.4rem 0 0.2rem;
    justify-content: space-between;
    font-size: 14px;
  }
`;

export const ProfileBtnWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  @media screen and (max-width: 650px) {
    width: 98%;
    justify-content: flex-end;
  }
  @media screen and (max-width: 450px) {
    justify-content: center;
  }
`;

export const DotsBtnwrap = styled.div`
  display: grid;
  place-items: center;
  font-size: 1.2rem;
  color: ${(props) => props.theme.dark};
  cursor: pointer;
  @media screen and (max-width: 450px) {
    font-size: ${({ forPost, forUserAllPost }) =>
      forPost ? "1.2rem" : forUserAllPost ? "1.2rem" : "2.5rem"};
  }
`;

export const DotsBtn = styled(TbDotsVertical)`
  cursor: pointer;
  /* margin-left: ${({ forpost }) => (forpost ? "0" : "-0.5rem")}; */
  color: ${(props) => props.theme.main};
  &:hover {
    color: ${(props) => props.theme.dark};
  }
`;

// <---------------------- profile header swiping images ---------------------->

export const ProfileImagesWrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 40%;
`;
