import { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const usePasswordToggle = () => {
  const [visible, setVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setVisible((prevState) => !prevState);
  };

  const ToggleIcon = visible ? (
    <FaEye color="white" />
  ) : (
    <FaEyeSlash color="white" />
  );

  const PasswordInputType = visible ? "text" : "password";

  return { PasswordInputType, ToggleIcon, togglePasswordVisibility };
};

export default usePasswordToggle;
