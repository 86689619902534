import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { PostVertiMediaLink, PostVertiWrap } from "./PostStyle";
import { Image100, Video100 } from "../Common/CommonStyle";
import { UserNameDpWrapper, UserProfileThumb } from "../Modals/ModalStyles";
import { setBackNavigate } from "../../ReduxToolKit/Slices/utilsSlice";
import defaultImg from "../../Assets/Images/default_profile_pic.png";

const PostVertiOverview = ({
  mediaType,
  postType,
  postId,
  sourceUrl,
  authorId,
  firstName,
  lastName,
  userName,
  profileImg,
}) => {
  const dispatch = useDispatch();
  const backNavigate = useSelector((state) => state.utils.backNavigate);

  return (
    <PostVertiWrap>
      <PostVertiMediaLink
        to={postType === "POST" ? `/post/${postId}` : `/share/${postId}`}
      >
        {mediaType === "image" ? (
          <Image100 src={sourceUrl} alt="" br={"15px"} />
        ) : (
          <Video100 src={sourceUrl} alt="" loop autoPlay muted br={"15px"} />
        )}
      </PostVertiMediaLink>
      <UserNameDpWrapper
        to={`/${firstName}_${lastName}/${authorId}`}
        onClick={() =>
          dispatch(setBackNavigate({ backNavigate: backNavigate + 1 }))
        }
      >
        <UserProfileThumb
          width={"1.8rem"}
          height={"1.8rem"}
          bgSize={"1.8rem"}
          bgPosition={profileImg === null}
          style={{
            backgroundImage: `url(${
              profileImg === null ? defaultImg : profileImg
            })`,
          }}
        />
        <p>{userName}</p>
      </UserNameDpWrapper>
    </PostVertiWrap>
  );
};

export default PostVertiOverview;
