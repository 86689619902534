import React from "react";

function VolumeIcon({ fill, width, height }) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width || "24px"}
      height={height || "24px"}
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill={fill}
        stroke="none"
      >
        <path
          d="M3888 4439 c-127 -19 -217 -139 -206 -271 6 -75 27 -112 123 -216
358 -393 545 -871 545 -1392 0 -480 -174 -960 -474 -1308 -39 -45 -94 -110
-122 -142 -54 -64 -74 -112 -74 -180 0 -65 19 -117 62 -165 52 -59 111 -86
187 -86 117 -1 191 56 376 286 231 287 418 680 499 1046 75 338 75 760 0 1098
-57 261 -182 570 -319 791 -128 205 -350 472 -426 511 -53 26 -114 36 -171 28z"
        />
        <path
          d="M2219 4336 c-20 -7 -49 -22 -65 -34 -16 -11 -243 -235 -504 -496
l-475 -476 -345 0 c-204 0 -360 -4 -382 -10 -65 -18 -130 -70 -160 -129 l-28
-53 0 -578 0 -578 28 -53 c30 -59 95 -111 160 -129 22 -6 178 -10 382 -10
l345 0 480 -481 c264 -264 499 -492 522 -506 123 -72 293 -19 358 112 20 39
20 70 20 1645 0 1575 0 1606 -20 1645 -57 115 -197 174 -316 131z"
        />
        <path
          d="M3105 3697 c-95 -45 -149 -130 -149 -232 0 -75 20 -119 86 -193 214
-240 312 -525 279 -815 -26 -231 -105 -408 -266 -592 -78 -89 -97 -130 -99
-205 -2 -152 124 -271 272 -257 82 7 130 35 207 120 195 215 330 487 381 767
25 132 25 409 0 540 -53 288 -206 589 -399 788 -68 70 -116 94 -197 99 -53 3
-74 0 -115 -20z"
        />
      </g>
    </svg>
  );
}

export default VolumeIcon;
