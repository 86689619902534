import {
  ExploreIcon,
  HomeIcon,
  SaveIcon,
  SettingsIcon,
  CreatePostOutlineIcon,
  CommunityOutlinIcon,
  LinkedInIcon,
  InstaIcon,
  FBIcon,
} from "../../Assets/SVG";

import {
  TAC_1,
  TAC_2,
  TAC_3,
  TAC_4,
  TAC_5,
  TAC_6,
  TAC_7,
  TAC_8,
  TAC_9,
  TAC_10,
} from "../../Documents/TACContents";
import {
  PP_1,
  PP_2,
  PP_3,
  PP_4,
  PP_5,
  PP_6,
  PP_7,
  PP_8,
  PP_9,
  PP_10,
} from "../../Documents/PPContents";
import {
  CG_1,
  CG_2,
  CG_3,
  CG_4,
  CG_5,
  CG_6,
  CG_7,
  CG_8,
  CG_9,
  CG_10,
  CG_11,
  CG_12,
  CG_13,
} from "../../Documents/CGContents";

export const Ethnic = [
  "Tamil",
  "Sinhala",
  "Sindhi",
  "Sudha",
  "Bengal",
  "Makkale",
  "fire",
  "mokane",
];

export const Language = [
  "Tamil",
  "Sinhala",
  "English",
  "Telugu",
  "Malaiyalam",
  "Chineese",
  "Hindi",
  "Urudu",
];

export const Gender = [
  {
    id: 1,
    name: "gender",
    label: "Male",
    val: "male",
  },
  {
    id: 2,
    name: "gender",
    label: "Female",
    val: "female",
  },
  {
    id: 3,
    name: "gender",
    label: "Other",
    val: "other",
  },
  {
    id: 4,
    name: "gender",
    label: "Prefer not to say",
    val: "prefer_not_to_say",
  },
];

export const PersonalInfo = [
  {
    id: 1,
    firstName: "John",
    lastName: "Doe",
    location: "Colombo, Sri Lanka",
    gender: "male",
    dob: "1999.12.20",
    ethnic: "Srilankan, Tamil",
    languageKnown: null,
    longBio:
      " Lorem ipsum dolor sit amet consectetur adipisicing elit. At laudantium illo dolore harum numquam tempore autem modi minima in. Eaque esse ea earum quaerat ducimus.",
    contact: "sample@gmail.com",
  },
];

export const forEmptySubCat = [
  {
    id: 400,
    value: "Please select the main category",
  },
];

export const forEmptySkills = [
  {
    id: 400,
    value: "Please select the sub category",
  },
];

export const forEmptyLevels = [
  {
    id: 400,
    value: "Please select the skill",
  },
];

export const forEmptyLangLevels = [
  {
    id: 400,
    value: "Please select the language",
  },
];

export const forEmptySecondaryLang = [
  {
    id: 400,
    value: "Please select the primary language",
  },
];

export const Levels = [
  { id: 0, value: "advanced" },
  { id: 1, value: "intermediate" },
  { id: 2, value: "beginner" },
];

export const BalanceSocialMedias = [
  {
    id: 0,
    value: "Portfolio",
    name: "portfolio",
  },
  {
    id: 1,
    value: "Twitter",
    name: "twitter",
  },
  {
    id: 2,
    value: "YouTube",
    name: "youtube",
  },
  {
    id: 3,
    value: "GitHub",
    name: "github",
  },
  {
    id: 4,
    value: "Snapchat",
    name: "snapchat",
  },
];

export const SidebarItemList = [
  {
    name: "Home",
    link: "/feed",
    isLink: true,
    icon: <HomeIcon />,
  },
  {
    name: "Explore",
    link: "/explore",
    isLink: true,
    icon: <ExploreIcon />,
  },
  // {
  //   name: "Share",
  //   link: "/share",
  //   isLink: true,
  //   icon: <LinkOutlineIcon />,
  // },
  {
    name: "Upload",
    link: "/create-post",
    isLink: false,
    icon: <CreatePostOutlineIcon width={"22px"} />,
  },
  {
    name: "Saved Collection",
    link: "/saved/collections",
    isLink: true,
    icon: <SaveIcon width={"22px"} />,
  },
  {
    name: "Community",
    link: "/collabration",
    isLink: true,
    icon: <CommunityOutlinIcon />,
  },
  {
    name: "Settings",
    link: "/settings",
    isLink: true,
    icon: <SettingsIcon width={"24px"} />,
  },
];

export const BottombarItemList = [
  {
    name: "Home",
    link: "/feed",
    icon: <HomeIcon />,
  },
  {
    name: "Explore",
    link: "/explore",
    icon: <ExploreIcon />,
  },
  {
    name: "Create Post",
    link: "/create-post",
    icon: <CreatePostOutlineIcon width={"22px"} />,
  },
  {
    name: "Community",
    link: "/collabration",
    icon: <CommunityOutlinIcon />,
  },
];

export const userListSample = [
  {
    _id: 0,
    name: "Apple",
    userName: "fox studio",
    profileImage: null,
  },
  {
    _id: 1,
    name: "Apple",
    userName: "fox studio",
    profileImage: null,
  },
  {
    _id: 2,
    name: "basket",
    userName: "fox studio",
    profileImage: null,
  },
  {
    _id: 3,
    name: "car",
    userName: "dodge viper",
    profileImage: null,
  },
  {
    _id: 4,
    name: "mercedez",
    userName: "benz studio",
    profileImage: null,
  },
  {
    _id: 5,
    name: "care for art",
    userName: "picture studio",
    profileImage: null,
  },
  {
    _id: 6,
    name: "may be",
    userName: "john wixck",
    profileImage: null,
  },
  {
    _id: 7,
    name: "x twitter",
    userName: "elon musk",
    profileImage: null,
  },
];

export const RatioOptions = [
  {
    text: "16 : 9",
    num: 169,
    width: "1.7rem",
    height: "1rem",
  },
  {
    text: "4 : 3",
    num: 43,
    width: "1.2rem",
    height: "1rem",
  },
  {
    text: "3 : 2",
    num: 32,
    width: "1.5rem",
    height: "1rem",
  },
  {
    text: "1 : 1",
    num: 11,
    width: "1.1rem",
    height: "1.1rem",
  },
  {
    text: "3 : 4",
    num: 34,
    width: "1rem",
    height: "1.4rem",
  },
  {
    text: "2 : 3",
    num: 23,
    width: "1rem",
    height: "1.5rem",
  },
];

export const sampleMedia = [
  {
    url: "https://te-files-media.s3.eu-west-2.amazonaws.com/user/1701120759663.mp4",
    mediaType: "video",
  },
  {
    url: "https://te-files-media.s3.eu-west-2.amazonaws.com/3232443580525295850-1701156165449.mp4",
    mediaType: "video",
  },
  {
    url: "https://te-files-media.s3.eu-west-2.amazonaws.com/3209092452903441884-1701156298312.mp4",
    mediaType: "video",
  },
];

export const ExploreTabsItems = [
  {
    id: undefined,
    name: "All",
  },
  {
    id: undefined,
    name: "Feed",
  },
  {
    id: undefined,
    name: "Shared",
  },
  {
    id: undefined,
    name: "Talents",
  },
  {
    id: "648fe2328c03a7e65336580b",
    name: "Sports & Fitness",
  },
  {
    id: "648fe2458c03a7e65336580f",
    name: "Songs & Music",
  },
  {
    id: "648fe25c8c03a7e653365813",
    name: "Dance & Styles",
  },
  {
    id: "648fe26c8c03a7e653365817",
    name: "Arts & Crafts",
  },
  {
    id: "648fe2838c03a7e65336581b",
    name: "Graphics & Designs",
  },
  {
    id: "648fe2958c03a7e65336581f",
    name: "Programming & Tech",
  },
  {
    id: "648fe2e48c03a7e653365828",
    name: "Video & Animation",
  },
  {
    id: "648fe3028c03a7e65336582c",
    name: "UX, Writing & Translation",
  },
  {
    id: "6490614d8c03a7e653365887",
    name: "Photography",
  },
  {
    id: "649841368c03a7e653365d69",
    name: "Film & Entertainment",
  },
];

// data for footer - terms and conditions - privacyh policy

export const TACQandA = [
  {
    id: 0,
    question: "1. Who You are Contracting With",
    answer: <TAC_1 />,
  },
  {
    id: 1,
    question: "2. What Services are Covered by These Terms",
    answer: <TAC_2 />,
  },
  {
    id: 2,
    question:
      "3. Additional Terms and Policies - Depending on the Features You Use",
    answer: <TAC_3 />,
  },
  {
    id: 3,
    question: "4. Using the Platform",
    answer: <TAC_4 />,
  },
  {
    id: 4,
    question: "5. What We Promise to You",
    answer: <TAC_5 />,
  },
  {
    id: 5,
    question: "6. Suspending or Ending Our Relationship",
    answer: <TAC_6 />,
  },
  {
    id: 6,
    question: "7. Changes to These Terms or the Platform",
    answer: <TAC_7 />,
  },
  {
    id: 7,
    question: "8. Resolving Disputes",
    answer: <TAC_8 />,
  },
  {
    id: 8,
    question: "9. Other",
    answer: <TAC_9 />,
  },
  {
    id: 9,
    question: "10. Contacting Talents Explore",
    answer: <TAC_10 />,
  },
];

export const PPQandA = [
  {
    id: 0,
    question: "1. What Information We Collect",
    answer: <PP_1 />,
  },
  {
    id: 1,
    question: "2. How We Use Your Information",
    answer: <PP_2 />,
  },
  {
    id: 2,
    question: "3. How We Share Your Information",
    answer: <PP_3 />,
  },
  {
    id: 3,
    question: "4. Our Legal Bases and How We Process Your Information",
    answer: <PP_4 />,
  },
  {
    id: 4,
    question: "5. Your Rights and Choices",
    answer: <PP_5 />,
  },
  {
    id: 5,
    question: "6. Data Security and Retention",
    answer: <PP_6 />,
  },
  {
    id: 6,
    question: "7. Our Global Operations and Data Transfers",
    answer: <PP_7 />,
  },
  {
    id: 7,
    question: "8. Younger Users",
    answer: <PP_8 />,
  },
  {
    id: 8,
    question: "9. Privacy Policy Updates",
    answer: <PP_9 />,
  },
  {
    id: 9,
    question: "10. Contact Us",
    answer: <PP_10 />,
  },
];

export const CGQandA = [
  { id: 0, question: "1. Minor Safety", answer: <CG_1 /> },
  { id: 1, question: "2. Dangerous Acts and Challenges", answer: <CG_2 /> },
  {
    id: 2,
    question: "3. Suicide, Self-harm, and Disordered Eating",
    answer: <CG_3 />,
  },
  {
    id: 3,
    question: "4. Adult Nudity and Sexual Activities",
    answer: <CG_4 />,
  },
  {
    id: 4,
    question: "5. Bullying and Harassment",
    answer: <CG_5 />,
  },
  {
    id: 5,
    question: "6. Hateful Behaviour",
    answer: <CG_6 />,
  },
  {
    id: 6,
    question: "7. Violent Extremism",
    answer: <CG_7 />,
  },
  {
    id: 7,
    question: "8. Integrity and Authenticity",
    answer: <CG_8 />,
  },
  {
    id: 8,
    question: "9. Illegal Activities and Regulated Goods",
    answer: <CG_9 />,
  },
  {
    id: 9,
    question: "10. Violent and Graphic Content",
    answer: <CG_10 />,
  },
  {
    id: 10,
    question: "11. Copyright and Trademark Infringement",
    answer: <CG_11 />,
  },
  {
    id: 11,
    question: "12. Platform Security",
    answer: <CG_12 />,
  },
  {
    id: 12,
    question: "13. Ineligible for the For You Feed",
    answer: <CG_13 />,
  },
];

export const SocialMediaData = [
  {
    name: "linkedin",
    icon: <LinkedInIcon width={"30px"} height={"30px"} />,
  },
  {
    name: "instagram",
    icon: <InstaIcon width={"26px"} height={"26px"} />,
  },
  {
    name: "facebook",
    icon: <FBIcon width={"30px"} height={"30px"} />,
  },
  {
    name: "github",
    icon: <LinkedInIcon width={"30px"} height={"30px"} />,
  },
  {
    name: "linkedin",
    icon: <LinkedInIcon width={"30px"} height={"30px"} />,
  },
];
