import React, { useState } from "react";
import { StarRating } from "../../../Components";
import {
  PrimaryBtn,
  SecondaryBtn,
} from "../../../Components/Common/CommonStyle";
import {
  ProfileActionContainer,
  ProfileBtnWrapper,
  ProfileRatingWrapper,
} from "../ProfileStyle";
import { useDispatch, useSelector } from "react-redux";
import {
  handleFollow,
  handleRateProfile,
  handleUnfollow,
} from "../../../Functions/getConnectHandler";
import { useNavigate } from "react-router-dom";

const ProfileActions = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  let userId = user?._id;
  const currentUserId = localStorage.getItem("currentUserId");
  const [rateTheProfile, setRateTheProfile] = useState(user?.yourRating);
  const navigate = useNavigate();

  const handleClick = async (isFollowing) => {
    if (isFollowing === true) {
      handleUnfollow({ currentUserId, userId, dispatch });
    } else {
      handleFollow({ currentUserId, userId, dispatch });
    }
  };

  const handleEditCopyClick = (id) => {
    if (1 === id) {
      navigate(
        `/edit/profile/${user?.personalInfo?.firstName}_${user?.personalInfo?.lastName}/${userId}`
      );
    } else {
    }
  };

  const handleRateTheProfile = (ratingValue) => {
    setRateTheProfile(ratingValue);
    handleRateProfile(currentUserId, dispatch, userId, ratingValue);
  };

  return (
    <ProfileActionContainer isTalent={user?.isTalent}>
      {user?.isTalent && (
        <ProfileRatingWrapper>
          {currentUserId === userId ? "Rate your profile" : "Rate this profile"}
          <StarRating
            gaps={"0.35rem"}
            width={"20px"}
            onClick={handleRateTheProfile}
            values={rateTheProfile ? rateTheProfile : user?.yourRating}
            toThe={"profile"}
          />
        </ProfileRatingWrapper>
      )}
      <ProfileBtnWrapper>
        {userId === currentUserId ? (
          <>
            <PrimaryBtn
              onClick={() => handleEditCopyClick(1)}
              isWidthFull={true}
            >
              Edit Profile
            </PrimaryBtn>
            <SecondaryBtn onClick={() => handleEditCopyClick(2)}>
              Copy Link
            </SecondaryBtn>
          </>
        ) : (
          <>
            <PrimaryBtn
              onClick={() => handleClick(user?.isFollowing)}
              isWidthFull={true}
            >
              {user?.isTalent ? (
                <>{user?.isFollowing ? "Following" : "Follow"}</>
              ) : (
                "Message"
              )}
            </PrimaryBtn>
            {user?.isTalent && <SecondaryBtn>Message</SecondaryBtn>}
          </>
        )}
      </ProfileBtnWrapper>
    </ProfileActionContainer>
  );
};

export default ProfileActions;
