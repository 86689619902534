import React from "react";

function SettingsIcon({ fill, width, height }) {
  return (
    <svg
      version="1.0"
      width={width || "24px"}
      height={height || "24px"}
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill={fill || "var(--dark-primary)"}
        stroke="none"
      >
        <path
          d="M2260 4944 c-189 -51 -339 -244 -340 -439 0 -60 -29 -127 -63 -150
-72 -47 -131 -38 -218 35 -166 138 -357 165 -537 74 -55 -27 -98 -64 -244
-211 -151 -152 -181 -188 -208 -245 -84 -179 -55 -366 79 -523 60 -70 78 -120
61 -171 -26 -78 -83 -114 -180 -114 -192 0 -385 -154 -434 -345 -19 -70 -22
-500 -5 -574 18 -81 61 -154 128 -222 91 -91 197 -138 311 -139 98 0 154 -36
180 -114 17 -51 -1 -101 -62 -171 -134 -156 -162 -346 -77 -527 25 -53 60 -93
212 -244 152 -151 193 -186 247 -212 176 -85 371 -56 523 77 71 63 113 77 172
61 78 -22 115 -82 115 -187 0 -185 156 -378 345 -427 70 -19 500 -22 574 -5
81 18 154 61 222 128 92 93 138 196 139 316 1 60 29 127 63 150 75 49 137 38
223 -36 152 -134 344 -162 524 -78 55 26 92 58 247 212 151 152 187 193 212
245 84 179 55 375 -77 526 -61 69 -79 120 -62 172 26 78 82 114 180 114 197 2
394 163 439 361 14 57 14 501 0 558 -18 81 -61 154 -128 222 -91 91 -197 138
-311 139 -98 0 -154 36 -180 114 -17 51 1 101 62 171 134 156 162 346 77 527
-25 52 -61 93 -212 245 -152 151 -193 187 -245 212 -177 83 -377 54 -520 -74
-92 -83 -169 -94 -238 -33 -37 32 -54 79 -54 148 -2 197 -163 394 -361 439
-71 17 -513 13 -579 -5z m546 -330 c54 -40 66 -67 74 -161 19 -223 151 -384
355 -438 153 -39 303 -3 432 104 32 27 68 53 80 60 30 15 88 14 121 -3 32 -17
295 -282 311 -313 14 -28 14 -88 0 -116 -7 -12 -33 -48 -60 -80 -143 -172
-159 -390 -41 -573 85 -130 204 -198 375 -213 94 -9 121 -21 161 -75 20 -26
21 -41 21 -246 0 -205 -1 -220 -21 -246 -40 -54 -67 -66 -161 -74 -223 -19
-384 -151 -438 -355 -39 -153 -3 -303 104 -432 27 -32 53 -68 60 -80 14 -28
14 -88 0 -116 -16 -31 -279 -296 -311 -313 -33 -17 -91 -18 -121 -2 -12 6 -47
33 -79 59 -128 107 -281 143 -433 104 -203 -53 -336 -217 -355 -438 -8 -94
-20 -121 -74 -161 -26 -20 -41 -21 -246 -21 -205 0 -220 1 -246 21 -54 39 -66
67 -75 161 -9 105 -17 133 -60 213 -76 143 -241 240 -409 240 -122 0 -216 -35
-316 -118 -32 -27 -69 -54 -81 -61 -30 -15 -88 -14 -121 3 -32 17 -295 282
-311 313 -14 28 -14 89 1 116 6 12 33 47 59 79 143 172 159 392 41 574 -85
130 -204 198 -375 213 -94 9 -121 21 -161 75 -20 26 -21 41 -21 246 0 205 1
220 21 246 40 54 67 66 161 74 187 16 324 105 402 262 39 78 55 157 48 248 -7
96 -36 174 -95 251 -89 116 -92 121 -92 161 0 22 5 49 11 61 16 31 279 296
311 313 15 8 45 14 66 14 40 0 45 -3 161 -92 189 -144 453 -127 622 42 86 85
139 207 139 316 0 61 12 99 43 133 44 47 67 51 289 48 191 -2 209 -4 234 -23z"
        />
        <path
          d="M2363 3666 c-466 -89 -827 -452 -908 -915 -91 -521 209 -1051 700
-1236 176 -67 389 -91 566 -66 240 35 479 158 647 335 205 216 312 482 312
776 0 310 -114 579 -340 800 -147 145 -310 236 -517 291 -101 27 -353 35 -460
15z m387 -333 c241 -58 453 -241 546 -471 44 -110 58 -181 58 -302 0 -132 -18
-215 -70 -328 -81 -178 -218 -315 -397 -397 -110 -50 -195 -68 -327 -68 -122
-1 -191 13 -302 57 -231 94 -410 302 -473 551 -25 96 -25 276 -1 370 111 432
538 692 966 588z"
        />
      </g>
    </svg>
  );
}

export default SettingsIcon;
