import React from "react";

function CommunityOutlinIcon({ fill, width, height }) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width || "24px"}
      height={height || "24px"}
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill={fill || "var(--dark-primary)"}
        stroke="none"
      >
        <path
          d="M2377 4530 c-201 -34 -410 -145 -560 -297 -93 -95 -141 -161 -195
-268 -207 -414 -121 -909 216 -1232 l74 -72 -53 -27 c-247 -124 -488 -372
-621 -639 -115 -230 -148 -416 -148 -831 0 -193 3 -230 20 -286 56 -182 234
-336 425 -368 51 -8 344 -11 1070 -8 l1000 3 70 29 c199 82 327 236 346 413
13 129 10 423 -7 585 -23 231 -90 416 -220 614 -132 200 -342 392 -541 493
l-42 21 88 87 c96 96 166 196 212 303 60 142 99 339 99 506 0 83 -45 273 -84
360 -76 167 -252 368 -412 472 -94 61 -237 115 -361 137 -91 16 -294 18 -376
5z m331 -335 c148 -30 316 -131 407 -243 25 -31 66 -97 90 -147 97 -199 99
-414 6 -610 -77 -162 -175 -261 -336 -340 -202 -100 -416 -99 -620 0 -160 78
-259 178 -336 340 -93 196 -91 411 6 610 78 160 178 259 340 336 138 66 290
84 443 54z m117 -1783 c375 -95 682 -385 795 -749 37 -119 50 -236 50 -446 0
-248 -12 -286 -105 -328 -38 -18 -90 -19 -1008 -19 -930 0 -969 1 -1005 19
-42 22 -63 43 -86 88 -23 45 -23 423 -1 544 87 467 433 816 899 905 104 20
357 12 461 -14z"
        />
        <path
          d="M1162 4169 c-95 -14 -239 -64 -311 -106 -173 -103 -317 -267 -388
-443 -41 -104 -56 -192 -50 -315 12 -249 98 -448 262 -604 l73 -69 -51 -27
c-208 -108 -408 -307 -515 -513 -103 -198 -127 -323 -127 -667 l0 -240 29 -60
c57 -116 173 -213 294 -247 57 -15 105 -18 349 -18 156 0 283 2 283 5 0 2 -5
27 -11 56 -6 28 -14 92 -18 143 l-6 91 -260 5 c-252 5 -261 6 -293 28 -61 44
-64 60 -59 301 3 184 7 227 26 289 77 260 252 462 504 583 127 60 218 81 392
86 l149 6 70 60 c69 59 210 157 227 157 21 0 4 29 -48 84 -56 58 -58 58 -88
46 -133 -56 -169 -64 -290 -64 -97 -1 -133 3 -190 21 -432 137 -566 657 -250
968 127 126 272 180 460 173 61 -3 117 -8 126 -12 13 -6 23 6 47 57 16 36 46
90 66 121 20 32 34 61 31 66 -20 32 -310 58 -433 39z"
        />
        <path
          d="M3705 4173 c-62 -7 -175 -35 -180 -43 -3 -5 11 -34 30 -65 20 -31 49
-85 65 -120 l28 -63 44 10 c24 5 93 8 153 6 87 -3 126 -10 185 -31 307 -110
469 -434 366 -734 -66 -194 -240 -345 -448 -389 -132 -28 -264 -11 -423 56
-30 12 -32 12 -88 -46 -31 -33 -57 -63 -56 -69 0 -5 34 -31 77 -57 42 -25 111
-75 152 -111 l75 -64 150 -6 c174 -5 259 -24 391 -86 205 -96 360 -251 453
-453 64 -138 73 -187 78 -423 5 -240 0 -261 -73 -302 -38 -22 -49 -23 -291
-23 l-253 0 0 -62 c-1 -35 -7 -99 -15 -143 -8 -44 -15 -83 -15 -87 0 -5 127
-8 283 -8 244 0 292 3 349 18 124 35 234 128 290 247 29 63 30 71 35 248 9
311 -26 517 -121 704 -106 210 -310 416 -522 527 l-52 27 87 87 c47 48 101
113 120 144 75 129 118 277 127 442 12 214 -54 389 -215 575 -59 69 -185 168
-261 205 -141 71 -366 109 -525 89z"
        />
      </g>
    </svg>
  );
}

export default CommunityOutlinIcon;
