import React from "react";
import {
  TalViewAllBtn,
  TalViewAllCard,
  TopHeading,
  TopTalContainer,
  TopTalWrap,
} from "./ExploreStyles";
import { useEffect } from "react";
import { useState } from "react";
import { getTalentSearchResults } from "../../Functions/getFetchFunctions";
import { useDispatch } from "react-redux";
import { TalentCard } from "../../Components";
import { BiPlus } from "react-icons/bi";

const TopTalents = ({ setSearchLoading }) => {
  const currentUserId = localStorage.getItem("currentUserId");
  const dispatch = useDispatch();
  const [topTalSuggest, setTopTalSuggest] = useState([]);
  let searchType = "TALENT";
  let pageNo = 1;
  let pageSize = 12;
  let publicRating = 4.0;

  useEffect(() => {
    getTalentSearchResults(
      searchType,
      currentUserId,
      pageNo,
      pageSize,
      dispatch,
      setSearchLoading,
      setTopTalSuggest,
      undefined,
      publicRating
    ).then(() => {
      setSearchLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TopTalWrap>
      <TopHeading>Top Talents</TopHeading>
      <TopTalContainer>
        {topTalSuggest.length > 0 &&
          topTalSuggest.map((item, index) => (
            <TalentCard
              key={index}
              accountId={item._id}
              userName={item.userName}
              profileImage={item.profileImage}
              avgRating={item.avgRating}
            />
          ))}
        <TalViewAllCard>
          <TalViewAllBtn>
            <BiPlus />
          </TalViewAllBtn>
          <p>View All</p>
        </TalViewAllCard>
      </TopTalContainer>
    </TopTalWrap>
  );
};

export default TopTalents;
