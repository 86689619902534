import React from "react";
import { QuillIcon } from "../../../../Assets/SVG";
import {
  DetailLabel,
  BioLabelWrapper,
  DetailSectionContainer,
  SingleDetailElem,
  UserDetailHeading,
} from "../OverviewStyle";
import { useDateConvertor } from "../../../../Hooks/useDateSelector";
import { addLineBreaks } from "../../../../Hooks/useCountFormat";

const BioDetails = ({ user }) => {
  const formatDate = useDateConvertor(user?.personalInfo?.dob);

  return (
    <DetailSectionContainer>
      <UserDetailHeading>
        <span>
          <QuillIcon width={"21px"} /> Bio
        </span>
      </UserDetailHeading>
      <BioLabelWrapper>
        <DetailLabel>Name</DetailLabel>
        <SingleDetailElem>
          {user?.personalInfo?.firstName}&nbsp;{user?.personalInfo?.lastName}
        </SingleDetailElem>
      </BioLabelWrapper>

      <BioLabelWrapper style={{ alignItems: "start" }}>
        <DetailLabel>Location</DetailLabel>
        <SingleDetailElem>{user?.location}</SingleDetailElem>
      </BioLabelWrapper>

      <BioLabelWrapper>
        <DetailLabel>Language</DetailLabel>
        <SingleDetailElem>{user?.personalInfo?.language}</SingleDetailElem>
      </BioLabelWrapper>

      {user?.personalInfo?.dob && (
        <BioLabelWrapper>
          <DetailLabel>Age</DetailLabel>
          <SingleDetailElem>Born on {formatDate}</SingleDetailElem>
        </BioLabelWrapper>
      )}

      {user?.isTalent && (
        <>
          <BioLabelWrapper>
            <DetailLabel>Description</DetailLabel>
          </BioLabelWrapper>
          <SingleDetailElem style={{ marginTop: "5px" }}>
            {addLineBreaks(user?.morePersonalInfo?.bio)}
          </SingleDetailElem>
        </>
      )}
    </DetailSectionContainer>
  );
};

export default BioDetails;
