import React, { useState } from "react";
import {
  OTPForm,
  OTPFormWrapper,
  OTPInput,
  OTPInputWrapper,
  ResendOTP,
  OTPAlert,
  OTPError,
} from "./AuthStyle";
import { PrimaryBtn } from "../../Components/Common/CommonStyle";
import useTimer from "../../Hooks/useTimer";
import { useNavigate } from "react-router-dom";
import { ModalBg } from "../../Components/Modals/ModalStyles";
import { useDispatch } from "react-redux";
import { otpAction } from "../../ReduxToolKit/APIs/AuthAPI";
import { setLogin } from "../../ReduxToolKit/Slices/authSlice";

const OTPVerification = ({ isOpen }) => {
  const dispatch = useDispatch();
  const otpUserId = localStorage.getItem("currentUserId");
  const [otpInput, setOTPInput] = useState(["", "", "", "", "", ""]);
  const [error, setError] = useState(null);
  const [
    isTimerRunning,
    countdown,
    formatTime,
    setIsTimerRunning,
    setCountdown,
  ] = useTimer();
  const navigate = useNavigate();

  const handleResend = () => {
    localStorage.setItem("countdown", "00:00");
    setIsTimerRunning(true);
    setCountdown(10 * 60);
    setError(null);
  };

  const handleChange = (index, event) => {
    const value = event.target.value;
    if (isNaN(value)) return;
    const newOTP = [...otpInput];
    newOTP[index] = value;
    setOTPInput(newOTP);
    if (index < 5 && value) {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === "Backspace" && !otpInput[index]) {
      if (index > 0) {
        document.getElementById(`otp-input-${index - 1}`).focus();
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isOtpValid = otpInput.every((value) => value !== "");
    if (!isOtpValid) {
      setError("OTP Verification Code is Required");
    } else {
      setError(null);
      try {
        const OTPData = {
          userId: otpUserId,
          emailVerificationCode: parseInt(otpInput.join("")),
          deviceId: "wepApp",
          appVersion: "2.0",
        };
        // api request and response for verification
        dispatch(otpAction(OTPData)).then((actionResult) => {
          const responseData = actionResult.payload;
          if (parseInt(responseData.status) === 7400) {
            setError(null);
            if (responseData.response) {
              dispatch(
                setLogin({
                  currentUserId: responseData.response.user,
                  token: responseData.response.accessToken,
                  refreshToken: responseData.response.refreshToken,
                })
              );
              navigate("/complete-profile/:id");
            }
          } else {
            setError("Invalid OTP Verification Code");
          }
        });
      } catch {}
    }
  };

  return (
    <ModalBg isOtp={isOpen}>
      <OTPFormWrapper>
        <OTPForm onSubmit={handleSubmit}>
          <h1>Verify Your Account</h1>
          <OTPAlert>
            Check your inbox we have send an OTP verification code to your
            email. Please Enter the code to continue...
          </OTPAlert>
          <OTPInputWrapper>
            {otpInput.map((value, index) => (
              <OTPInput
                key={index}
                id={`otp-input-${index}`}
                type="text"
                maxLength={1}
                value={value}
                onChange={(e) => handleChange(index, e)}
                onKeyDown={(e) => handleKeyDown(index, e)}
                error={error}
              />
            ))}
          </OTPInputWrapper>
          {error && <OTPError>{error}</OTPError>}
          {isTimerRunning ? (
            <h4 style={{ color: "var(--gray)" }}>
              Verification code expires in
              <span>{formatTime(countdown)}</span>
            </h4>
          ) : (
            <>{!error && <h4>Your OTP has expired. Try again!</h4>}</>
          )}
          <PrimaryBtn width={"100%"}>Submit</PrimaryBtn>
        </OTPForm>
        <footer>
          <h4>Didn't receive the code?</h4>
          <ResendOTP onClick={handleResend}>Resend</ResendOTP>
        </footer>
        <p>
          If you continue to have issues receiving the code, please contact our
          customer support.
        </p>
      </OTPFormWrapper>
    </ModalBg>
  );
};

export default OTPVerification;
