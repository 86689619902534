import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  Explore,
  Feed,
  Home,
  Message,
  Notification,
  Profile,
  Saved,
  Settings,
  MoreDetailedPost,
  SavedFolder,
  TermsConditions,
  CommunityGuide,
  BrandedPolicy,
  PrivacyPolicy,
  CookiesPolicy,
  Intellectual,
  EditProfile,
  Upload,
} from "./Pages";
import { Auth, ForgotPassword, PersonalInfo, TalentRegisterForm } from "./Auth";
import "./App.css";
import {
  CompleteProfileRoutes,
  LoggedinRoutes,
  NotLoggedinRoutes,
  PublicRoutes,
  UpdateAccountRoutes,
} from "./Layouts/Routes";
import axios from "axios";
import ErrorRoutes from "./Layouts/ErrorRoutes";
import ThemeProvider from "./Utils/Theme/ThemeProvider";
import { useDispatch, useSelector } from "react-redux";
import {
  getCheckIsAbout,
  getCheckIsTalent,
  getCurrentUserPersonalDetails,
} from "./Functions/getFetchFunctions";

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;
axios.defaults.headers.post["Accept"] = "application/JSON";

const App = () => {
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const currentUserId = localStorage.getItem("currentUserId");
  // const currentUserId = localStorage.getItem("TalentsExploreCurrentUserId");
  const auth = Boolean(token && currentUserId);

  const currentUser = useSelector((state) => state.auth.currentUser);
  const isAbout = Boolean(useSelector((state) => state.utils.isAbout));
  const isTalent = Boolean(useSelector((state) => state.utils.isTalent));

  useEffect(() => {
    if (currentUserId && currentUser === null) {
      getCurrentUserPersonalDetails(currentUserId, dispatch);
      console.log("getting current user details - app.js");
    } else {
      console.log("reject - app.js");
    }
  }, [currentUser, currentUserId, dispatch, token]);

  useEffect(() => {
    if (!isAbout) {
      console.log("current user - app.js", isAbout);
      getCheckIsAbout(currentUser, dispatch);
    }
    console.log(isAbout, "is about check - app.js");
    getCheckIsTalent(currentUser, dispatch);
  }, [currentUser, dispatch, isAbout, token]);

  return (
    <ThemeProvider>
      <Router>
        <Routes>
          {/* <Route path="complete-profile/:userid" element={<PersonalInfo />} /> */}
          <Route exact path="/" element={<NotLoggedinRoutes auth={auth} />}>
            <Route path="/" element={<Home />} />
            <Route path="login" element={<Auth />} />
            <Route path="joinus" element={<Auth />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
          </Route>

          <Route path="/" element={<PublicRoutes auth={auth} />}>
            {/* <Route path="profile/:userName/:userId" element={<Profile />} /> */}
            <Route path="terms-and-conditions" element={<TermsConditions />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="branded-policy" element={<BrandedPolicy />} />
            <Route path="cookies-policy" element={<CookiesPolicy />} />
            <Route path="community-guidelines" element={<CommunityGuide />} />
            <Route
              path="intellectual-property-policy"
              element={<Intellectual />}
            />
          </Route>

          <Route
            path="/"
            element={<CompleteProfileRoutes auth={auth} isAbout={isAbout} />}
          >
            <Route path="complete-profile/:userid" element={<PersonalInfo />} />
          </Route>

          <Route
            path="/"
            element={
              <UpdateAccountRoutes
                auth={auth}
                isAbout={isAbout}
                isTalent={isTalent}
                currentUserId={currentUserId}
              />
            }
          >
            <Route
              path="upgrade-account-as-talent/:userName"
              element={<TalentRegisterForm />}
            />
          </Route>

          <Route
            exact
            path="/"
            element={
              <LoggedinRoutes
                auth={auth}
                isAbout={isAbout}
                currentUserId={currentUserId}
              />
            }
          >
            <Route path="profile/:userName/:userId" element={<Profile />} />
            <Route
              path="edit/profile/:userName/:userId"
              element={<EditProfile />}
            />
            <Route path="post/:postId" element={<MoreDetailedPost />} />
            <Route path="share/:postId" element={<MoreDetailedPost />} />
            <Route path="feed" element={<Feed />} />
            <Route path="saved/collections" element={<Saved />} />
            <Route
              path="saved/collections/:folderName/:folderId"
              element={<SavedFolder />}
            />
            <Route path="explore" element={<Explore />} />
            <Route path="settings" element={<Settings />} />
            <Route path="messages" element={<Message />} />
            <Route path="notifications" element={<Notification />} />
            <Route path="create-post" element={<Upload />} />
            <Route path="share" element={<Upload />} />
          </Route>
          <Route path="*" element={<ErrorRoutes />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;
