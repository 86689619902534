import React, { useRef } from "react";
import {
  DetailSectionContainer,
  MyPhotoContent,
  MyPhotoImg,
  MyPhotoWrapper,
  MyPhotosContainer,
  UserDetailHeading,
} from "../OverviewStyle";
import { CameraIcon } from "../../../../Assets/SVG";
import { PiMagnifyingGlassBold } from "react-icons/pi";
import { useIndividualModalOpen } from "../../../../Hooks/useEventHandler";
import { PhotoViewModal } from "../../../../Components";
import { EmptyModalDataWrap } from "../../../../Components/Modals/ModalStyles";

const MyPhotos = ({ values, hasPhotos }) => {
  const myPhotoRefs = useRef([]);
  myPhotoRefs.current = Array.from(
    { length: 3 },
    (_, index) => myPhotoRefs.current[index] || React.createRef()
  );
  const {
    isIndividualModalOpen,
    setIsIndividualModalOpen,
    handleIndividualModalOpen,
  } = useIndividualModalOpen();

  return (
    <DetailSectionContainer>
      <UserDetailHeading>
        <span>
          <CameraIcon width={"21px"} /> My Photos
        </span>
      </UserDetailHeading>
      <MyPhotosContainer>
        {hasPhotos ? (
          values.map((item, index) => (
            <React.Fragment key={index}>
              {item !== "" && item !== null && (
                <>
                  <MyPhotoWrapper
                    onClick={() => handleIndividualModalOpen(index)}
                  >
                    <MyPhotoImg src={item} />
                    <MyPhotoContent>
                      <PiMagnifyingGlassBold />
                    </MyPhotoContent>
                  </MyPhotoWrapper>
                  {isIndividualModalOpen[index] && (
                    <PhotoViewModal
                      src={item}
                      setPhotoOpen={setIsIndividualModalOpen}
                    />
                  )}
                </>
              )}
            </React.Fragment>
          ))
        ) : (
          <EmptyModalDataWrap height={"10rem"}>
            <CameraIcon width={"2.6rem"} height={"2.6rem"} />
            No Photos added yet
          </EmptyModalDataWrap>
        )}
      </MyPhotosContainer>
    </DetailSectionContainer>
  );
};

export default MyPhotos;
