import React from "react";
import {
  FormContainerWrapper,
  FormFooter,
  FormFooterButtons,
  FormHeader,
  FormIndicatorWrapper,
  FormSectionWrapper,
  MultiFormContainer,
  MultiFormPage,
  PrimaryBtn,
  SecondaryBtn,
  StepWrapper,
} from "./CommonStyle";
import FormStatusIndicator from "./FormStatusIndicator";
import Footer from "../Footer";

const MultiStepForm = ({
  page,
  setPage,
  formData,
  formStatus,
  handleCancel,
  handleSkip,
  handleNext,
  handleSubmit,
  isComplete,
}) => {
  const currentPageData = formData[page];
  return (
    <MultiFormPage>
      <MultiFormContainer>
        <FormIndicatorWrapper>
          <FormStatusIndicator statusData={formStatus} page={page} />
        </FormIndicatorWrapper>

        <FormContainerWrapper>
          <FormSectionWrapper>
            <FormHeader>
              <div>
                <h1>{currentPageData.title}</h1>
                <StepWrapper>
                  Step
                  <span>
                    0{page + 1}/0{formData.length}
                  </span>
                </StepWrapper>
              </div>
              <p>{currentPageData.desc}</p>
            </FormHeader>

            <>{currentPageData.form}</>
          </FormSectionWrapper>

          <FormFooter>
            <FormFooterButtons>
              {page === 0 ? (
                <SecondaryBtn onClick={handleCancel} isWidthFull={true}>
                  Cancel
                </SecondaryBtn>
              ) : (
                <SecondaryBtn
                  onClick={() => {
                    setPage((currentPage) => currentPage - 1);
                  }}
                  isWidthFull={true}
                >
                  Back
                </SecondaryBtn>
              )}
              {page === 3 ? (
                <SecondaryBtn onClick={() => handleSkip()} isWidthFull={true}>
                  Skip
                </SecondaryBtn>
              ) : null}

              {page < formData.length - 1 ? (
                <PrimaryBtn onClick={() => handleNext()} isWidthFull={true}>
                  Next
                </PrimaryBtn>
              ) : null}

              {page === formData.length - 1 ? (
                <PrimaryBtn
                  onClick={() => handleSubmit()}
                  disabled={!isComplete}
                  isWidthFull={true}
                >
                  Complete
                </PrimaryBtn>
              ) : null}
            </FormFooterButtons>
            <Footer />
          </FormFooter>
        </FormContainerWrapper>
      </MultiFormContainer>
    </MultiFormPage>
  );
};

export default MultiStepForm;
