import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { BsDot } from "react-icons/bs";
import { setBackNavigate } from "../../ReduxToolKit/Slices/utilsSlice";
import defaultImg from "../../Assets/Images/default_profile_pic.png";
import { StarIcon } from "../../Assets/SVG";
import { PostOverviewTitleLink, PostVertiWrap } from "./PostStyle";
import {
  PinnedPostCard,
  PinnedPostContentWrap,
} from "../../Pages/Profile/Overview/OverviewStyle";
import { Image100, Video100 } from "../Common/CommonStyle";
import ActionMenu from "./ActionMenu";
import { UserNameDpWrapper, UserProfileThumb } from "../Modals/ModalStyles";

const PostOverview = ({
  mediaType,
  postType,
  postId,
  sourceUrl,
  authorId,
  firstName,
  lastName,
  userName,
  profileImg,
  postTitle,
  postAvgRating,
  ratersCount,
}) => {
  const dispatch = useDispatch();
  const backNavigate = useSelector((state) => state.utils.backNavigate);
  return (
    <PostVertiWrap>
      <PinnedPostCard>
        {mediaType === "image" ? (
          <Image100 src={sourceUrl} br={"15px"} alt="" />
        ) : (
          <Video100
            src={sourceUrl}
            alt=""
            loop={false}
            // autoPlay={false}
            // autoPlay
            controls={false}
            onPause={true}
            muted
            br={"15px"}
            playsInline
            webkit-playsinline
            controlsList="nofullscreen"
            preload="yes"
          />
        )}
        <PinnedPostContentWrap
          to={`/post/${postId}`}
          width={"20rem"}
          height={"13rem"}
          onClick={() =>
            dispatch(setBackNavigate({ backNavigate: backNavigate + 1 }))
          }
        >
          {/* <PinnedPostContent>
            <PinnedPostTitle>{postTitle}</PinnedPostTitle>
            <StarDisplay
              peopleCount={ratersCount}
              rating={postAvgRating ? postAvgRating : null}
            />
          </PinnedPostContent> */}
        </PinnedPostContentWrap>
        <ActionMenu
          postId={postId}
          authorId={authorId}
          // isBestWork={item.isBestWork}
          forPinned={true}
          // onCopyLink={handleCopyLink}
          // onPin={handleBestWorkClick}
          top={"1.1rem"}
          right={"3rem"}
        />
      </PinnedPostCard>
      <PostOverviewTitleLink to={`/post/${postId}`}>
        {postTitle}
      </PostOverviewTitleLink>
      <UserNameDpWrapper
        to={`/${firstName}_${lastName}/${authorId}`}
        onClick={() =>
          dispatch(setBackNavigate({ backNavigate: backNavigate + 1 }))
        }
      >
        <UserProfileThumb
          isExplore={true}
          width={"1.5rem"}
          height={"1.5rem"}
          bgSize={"1.5rem"}
          bgPosition={profileImg === null}
          style={{
            backgroundImage: `url(${
              profileImg === null ? defaultImg : profileImg
            })`,
          }}
        />
        <p>
          {userName}
          <BsDot />
        </p>
        <StarIcon width={"11px"} />
        {postAvgRating === null
          ? "N/A"
          : (Math.round(postAvgRating * 10) / 10).toFixed(1)}
      </UserNameDpWrapper>
    </PostVertiWrap>
  );
};

export default PostOverview;
