import styled from "styled-components";

// Skills section

export const SkillsContainer = styled.div`
  max-width: 40rem;
`;

export const SkillsCardContainer = styled.section`
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-template-rows: repeat(auto-fill, 16rem);
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;

  @media screen and (max-width: 1150px) {
    grid-template-columns: repeat(auto-fit, minmax(1fr));
    grid-row-gap: 1rem;
  }
`;

export const SkillCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  background: ${(props) => props.theme.mediumBg};
  margin-right: 15px;
  padding: 5px 0;
  @media screen and (max-width: 1400px) {
    width: 100%;
  }
`;

export const SkillCardHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 0.5rem;
  padding: 0.2rem 0 0.5rem 0.5rem;
  border-bottom: 1px solid ${(props) => props.theme.mediumMain};
  color: ${(props) => props.theme.dark};
  font-weight: 500;
  font-size: 14px;
`;

export const SkillCardIconBg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  background: ${(props) => props.theme.altBg};
  border-radius: 50%;
`;

export const SkillCardIcon = styled.img`
  width: 20px;
`;

export const SkillCardContentWrapper = styled.section`
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
`;

export const SkillCardContent = styled.div`
  display: flex;
`;

export const SkillCardLabel = styled.h3`
  font-size: 12px;
  font-weight: 400;
  color: ${(props) => props.theme.dark};
  text-align: justify;
  letter-spacing: 0.05px;
  min-width: 8rem;
`;

export const SkillCardLevel = styled.p`
  font-size: 12px;
  font-weight: 400;
  color: ${(props) => props.theme.main};
`;

export const SkillDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px 0;
`;

export const SkillDescription = styled.p`
  font-size: 12px;
  font-weight: 400;
  color: ${(props) => props.theme.main};
  margin-top: 0.3rem;
  /* text-align: justify; */
  /* word-spacing: 0.05px; */
  /* letter-spacing: 0.1px; */
`;
