import React from "react";
import { IoIosArrowBack } from "react-icons/io";
import { Link } from "react-router-dom";

const ErrorRoutes = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "10px",
        flexDirection: "column",
        color: "white",
        background: "#1c1c24",
        height: "100vh",
      }}
    >
      <p>404 | Page not found</p>
      <p>Design development in progress</p>
      <Link
        to="/feed"
        style={{
          textDecoration: "none",
          color: "#c92437",
          display: "flex",
          alignItems: "center",
        }}
      >
        <IoIosArrowBack />
        &nbsp;Go Back
      </Link>
    </div>
  );
};

export default ErrorRoutes;
