export const signupFormValidation = (values) => {
  let errors = [];

  if (!values.userName) {
    errors.userName = "Username is required";
  } else if (!/^[A-Za-z0-9_]{4,16}$/i.test(values.userName)) {
    errors.userName =
      "Username should be 4-16 characters and shouldn't include any special character!";
  }

  if (!values.email) {
    errors.email = "Email is required";
  } else if (
    !/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z0-9]{2,80}$/i.test(values.email)
  ) {
    errors.email = "Email address is invalid";
  }

  if (!values.password) {
    errors.password = "Password is required";
  } else if (
    !/^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/i.test(
      values.password
    )
  ) {
    errors.password =
      "Password must be between 8-20 characters and contain at least one letter, one number, and one special character!";
  }
  return errors;
};

export const loginFormValidation = (values) => {
  let errors = [];

  if (!values.email) {
    errors.email = "Email is required";
  }

  if (!values.password) {
    errors.password = "Password is required";
  }
  return errors;
};
