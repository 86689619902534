import React from "react";
import {
  Parah,
  PointsIcon,
  PointsWrapper,
  ParahScrollLink,
} from "../ContentsStyles";

const PP_7 = () => {
  return (
    <>
      <Parah>To Support our global operations:</Parah>
      <PointsWrapper>
        <PointsIcon />
        <div>
          We store the information described in{" "}
          <ParahScrollLink
            to="whatInformationWeCollect"
            smooth={true}
            duration={500}
          >
            What Information We Collect
          </ParahScrollLink>{" "}
          in secure servers located around the world.
        </div>
      </PointsWrapper>
      <PointsWrapper>
        <PointsIcon />
        <div>
          Certain entities in our Corporate Group, located outside your country
          of residence, are given limited remote access to this information so
          they can provide certain functions, as described in the Our Corporate
          Group section in{" "}
          <ParahScrollLink
            to="howWeShareYourInformation"
            smooth={true}
            duration={500}
          >
            How We Share Your Information.
          </ParahScrollLink>{" "}
          This access is limited, secure and only granted where necessary under
          strict security controls and authorisation approval protocols.
        </div>
      </PointsWrapper>
      <PointsWrapper>
        <PointsIcon />
        <div>
          We share{" "}
          <ParahScrollLink
            to="whatInformationWeCollect"
            smooth={true}
            duration={500}
          >
            What Information We Collect
          </ParahScrollLink>{" "}
          with service providers, partners and other third party entities, as
          described in How We Share Your Information. These third parties can be
          located outside your country of residence.
        </div>
      </PointsWrapper>
      <Parah>
        These entities commit to processing information in compliance with
        applicable privacy laws and to implementing appropriate security
        measures to protect your information. When we transfer your information
        outside of the EEA, the UK, or Switzerland, we ensure it benefits from
        an adequate level of data protection by relying on:
      </Parah>
      <PointsWrapper>
        <PointsIcon />
        <div>
          Adequacy decisions. These are decisions from the{" "}
          <a
            href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/adequacy-decisions_en"
            style={{
              color: " #ff2146f0",
              cursor: " pointer",
            }}
          >
            European Commission
          </a>{" "}
          under Article 45 GDPR (or equivalent decisions under other laws) where
          they recognise that a country offers an adequate level of data
          protection. We transfer your information as described in{" "}
          <ParahScrollLink
            to="whatInformationWeCollect"
            smooth={true}
            duration={500}
          >
            What Information We Collect
          </ParahScrollLink>{" "}
          to some countries with adequacy decisions or
        </div>
      </PointsWrapper>
      <PointsWrapper>
        <PointsIcon />
        <div>
          Standard contractual clauses. The European Commission has approved
          contractual clauses under Article 46 of the GDPR that allows companies
          in the EEA to transfer data outside the EEA. These (and their approved
          equivalent for the UK and Switzerland) are called standard contractual
          clauses. We rely on standard contractual clauses to transfer
          information as described in{" "}
          <ParahScrollLink
            to="whatInformationWeCollect"
            smooth={true}
            duration={500}
          >
            What Information We Collect
          </ParahScrollLink>{" "}
          to certain entities in our Corporate Group and third parties in
          countries without an adequacy decision.
        </div>
      </PointsWrapper>
      <Parah>
        For a copy of these adequacy decisions or standard contractual clauses,
        please contact us via the website or app.
      </Parah>
    </>
  );
};

export default PP_7;
