import React from "react";

function ErrorIcon({ fill, width, height }) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width || "24px"}
      height={height || "24px"}
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill={fill || "var(--dark-primary)"}
        stroke="none"
      >
        <path
          d="M2360 4954 c-247 -29 -452 -75 -626 -140 -332 -124 -620 -309 -869
-559 -368 -367 -600 -827 -682 -1345 -25 -162 -25 -538 1 -700 81 -520 313
-978 681 -1345 367 -368 825 -600 1345 -681 162 -26 538 -26 700 0 520 81 977
313 1345 681 310 309 523 682 630 1099 57 222 69 332 69 596 0 264 -12 374
-69 596 -194 759 -757 1382 -1499 1658 -124 47 -329 99 -471 121 -104 16 -472
29 -555 19z m353 -992 c106 -50 176 -136 203 -252 12 -49 8 -122 -42 -725 -32
-393 -61 -692 -70 -724 -28 -98 -139 -181 -244 -181 -105 0 -216 83 -244 181
-9 32 -38 329 -70 719 -59 718 -59 725 -11 820 45 89 141 164 242 190 66 16
168 4 236 -28z m-21 -2234 c199 -92 247 -355 93 -512 -121 -123 -326 -123
-449 0 -123 123 -123 326 0 447 97 96 233 121 356 65z"
        />
      </g>
    </svg>
  );
}

export default ErrorIcon;
