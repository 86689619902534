import React, { useEffect, useRef, useState } from "react";
import {
  AddSocialMedia,
  DropDown,
  ErrorModal,
  FormInput,
  TextArea,
} from "../../Components";
import {
  MyPhotoButton,
  MyPhotoContent,
  MyPhotoImg,
  MyPhotoWrapper,
  MyPhotoInputWrapper,
  FormPageDataWrapper,
} from "../../Components/Common/CommonStyle";
import { BiPlus } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
import { useSelector } from "react-redux";
import { convertEthnicitiesToArray } from "../../Hooks/useArrayConvertor";
import useImageInputType from "../../Hooks/useImageInputType";
import {
  AddAnother,
  AddAnotherWrapper,
  SocialMediaInputContainer,
} from "./TalentsInfoStyle";
import {
  FormInputWrapper,
  StyledLabel,
} from "../../Components/Inputs/InputStyle";

const MoreInfo = ({
  values,
  setValues,
  defaultSocialMedia,
  errors,
  mediaError,
  handleInputChange,
  handleDropdownChange,
  handleSelectMedia,
  handleMyPhotoDelete,
  handleOtherURLInput,
  handleDefaultURLInput,
}) => {
  const myPhotoRefs = useRef([]);
  myPhotoRefs.current = Array.from(
    { length: 3 },
    (_, index) => myPhotoRefs.current[index] || React.createRef()
  );
  const ethnicities = useSelector((state) => state.content.ethnicities);
  const [ethnicValues, setEthnicValues] = useState([]);
  const [addSocialOpen, setAddSocialOpen] = useState(false);
  const socialMediaLength = values.socialMediaLinks.length - 1;
  useEffect(() => {
    const convertedValues = convertEthnicitiesToArray(ethnicities);
    setEthnicValues(convertedValues);

    console.log("my photos length", values.myPhotos);
  }, [ethnicities, values.myPhotos]);

  let isPhotosArray = Array.isArray(values.myPhotos);
  const labelWidth = "17rem";

  const { handleImageInput, image, setImage, error, setError } =
    useImageInputType({ isPhotosArray });

  useEffect(() => {
    if (image && image.data) {
      setValues((prevValues) => ({
        ...prevValues,
        myPhotos: [...values.myPhotos, image],
      }));
    }

    setImage("");
  }, [image, setImage, setValues, values.myPhotos]);

  return (
    <FormPageDataWrapper width="57%">
      <DropDown
        label="Ethnic"
        name="ethnic"
        idName="ethnicId"
        defaultOption="Select your ethnicity"
        options={ethnicValues}
        value={values.ethnic}
        onChange={handleDropdownChange}
        isSingle={true}
        isHorz={true}
        labelWidth={labelWidth}
        isItemStart={true}
      />

      <TextArea
        name="description"
        label="Description"
        placeholder="Tell us about you and your profession..."
        maxLength={800}
        value={values.description}
        onChange={handleInputChange}
        height="14rem"
        labelWidth={labelWidth}
        invert={true}
        isHorz={true}
        validation={errors.description && errors.description}
      />

      <FormInputWrapper isHorz={true} isItemStart={true}>
        <StyledLabel labelWidth={labelWidth}>My Photos</StyledLabel>
        <MyPhotoInputWrapper>
          {values.myPhotos?.map((image, index) => (
            <React.Fragment key={index}>
              <MyPhotoWrapper>
                <MyPhotoImg src={image.preview} alt={`Photo ${index + 1}`} />
                <MyPhotoContent onClick={() => handleMyPhotoDelete(index)}>
                  <MdDelete />
                </MyPhotoContent>
              </MyPhotoWrapper>
            </React.Fragment>
          ))}
          {Array.from({
            length: 3 - values.myPhotos.length,
          }).map((item, index) => (
            <React.Fragment key={index}>
              <input
                type="file"
                ref={myPhotoRefs.current[index]}
                hidden
                onChange={(e) => handleImageInput(e, index)}
                accept="image/jpeg,image/png,image/webp"
              />
              <MyPhotoButton
                onClick={() => myPhotoRefs.current[index].current.click()}
              >
                <BiPlus />
                <p>Add Photo</p>
              </MyPhotoButton>
            </React.Fragment>
          ))}
        </MyPhotoInputWrapper>
      </FormInputWrapper>

      <FormInputWrapper isHorz={true} isItemStart={true}>
        <StyledLabel labelWidth={labelWidth}>Social Media</StyledLabel>
        <SocialMediaInputContainer>
          {defaultSocialMedia.map((item, index) => (
            <FormInput
              key={index}
              name={item.name}
              type="text"
              icon={item.icon}
              placeholder={item.placeHolder}
              vlaue={item.value}
              onChange={(e) =>
                handleDefaultURLInput(e, socialMediaLength, item.mediaType)
              }
              validation={item.error && item.error}
            />
          ))}

          <AddAnotherWrapper>
            <AddAnother onClick={() => setAddSocialOpen(true)}>
              Add Other Links
            </AddAnother>
          </AddAnotherWrapper>
          {addSocialOpen && (
            <AddSocialMedia
              setIsOpen={setAddSocialOpen}
              media={values.socialMediaLinks}
              length={socialMediaLength}
              handleSelectMedia={handleSelectMedia}
              handleOtherURLInput={handleOtherURLInput}
              error={mediaError}
            />
          )}
        </SocialMediaInputContainer>
      </FormInputWrapper>

      {error && (
        <ErrorModal
          error={error}
          setError={setError}
          image={image}
          setImage={setImage}
          handleSelectMedia={handleSelectMedia}
        />
      )}
    </FormPageDataWrapper>
  );
};

export default MoreInfo;
