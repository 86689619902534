import React, { useEffect } from "react";
import {
  IconWrapper,
  MediaFilesInfo,
  MediaInput,
  MediaInputContainer,
  MediaPrev,
  MediaPrevArrayWrapper,
  PrevActionWrapper,
  PrevArrayImg,
  PreviewContainer,
  PreviewContent,
  WhiteDot,
} from "./UploadStyle";
import { useRef } from "react";
import { useMediaUploadType } from "../../Hooks/useImageInputType";
import { useState } from "react";
import { BiPlus } from "react-icons/bi";
import { AddBtn } from "../../Components/Common/CommonStyle";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { useModalOpen } from "../../Hooks/useEventHandler";
import { CropProfileCoverPhoto } from "../../Components";
import { ImageFolderIcon, VideoFolderIcon } from "../../Assets/SVG";
import { useTheme } from "styled-components";

const MediaUploadPrev = ({ values, setValues }) => {
  const mediaRef = useRef(null);
  const theme = useTheme();
  const { handleMediaInput, media, setMedia } = useMediaUploadType();
  const [activeIndex, setActiveIndex] = useState(0);
  const [mediaHeight, setMediaHeight] = useState(null);
  const { isOpen, setIsOpen } = useModalOpen();
  let isValues = values.mediaValues.length !== 0;

  const [isRatio, setIsRatio] = useState({
    is16to9: false,
    is4to3: false,
    is3to2: false,
    is1to1: false,
    is3to4: false,
    is2to3: false,
  });

  const handleRatioChange = (ratio) => {
    switch (true) {
      case ratio === 169:
        setIsRatio({
          ...isRatio,
          is16to9: true,
          is4to3: false,
          is3to2: false,
          is1to1: false,
          is3to4: false,
          is2to3: false,
        });
        break;
      case ratio === 43:
        setIsRatio({
          ...isRatio,
          is16to9: false,
          is4to3: true,
          is3to2: false,
          is1to1: false,
          is3to4: false,
          is2to3: false,
        });
        break;
      case ratio === 32:
        setIsRatio({
          ...isRatio,
          is16to9: false,
          is4to3: false,
          is3to2: true,
          is1to1: false,
          is3to4: false,
          is2to3: false,
        });
        break;
      case ratio === 11:
        setIsRatio({
          ...isRatio,
          is16to9: false,
          is4to3: false,
          is3to2: false,
          is1to1: true,
          is3to4: false,
          is2to3: false,
        });
        break;
      case ratio === 34:
        setIsRatio({
          ...isRatio,
          is16to9: false,
          is4to3: false,
          is3to2: false,
          is1to1: false,
          is3to4: true,
          is2to3: false,
        });
        break;
      case ratio === 23:
        setIsRatio({
          ...isRatio,
          is16to9: false,
          is4to3: false,
          is3to2: false,
          is1to1: false,
          is3to4: false,
          is2to3: true,
        });
        break;
      default:
        setIsRatio({
          ...isRatio,
          is16to9: false,
          is4to3: true,
          is3to2: false,
          is1to1: false,
          is3to4: false,
          is2to3: false,
        });
        break;
    }
  };
  // useEffect(() => {
  //   if (media && media.data) {
  //     setValues((prevValues) => ({
  //       ...prevValues,
  //       mediaValues: [...values.mediaValues, media],
  //     }));
  //   }
  //   setActiveIndex(values.mediaValues.length - 1);
  //   setMedia("");
  // }, [media]);

  useEffect(() => {}, []);

  const handleInputChange = (e) => {
    handleMediaInput(e);
    setIsOpen(true);
  };

  const handleCropComplete = (img) => {
    if (img) {
      setValues((prevValues) => ({
        ...prevValues,
        mediaValues: [...values.mediaValues, img],
      }));
    }
    setActiveIndex(values.mediaValues.length);
    setMedia("");
  };

  useEffect(() => {
    const calculateMediaHeight = () => {
      if (values.mediaValues.length > 0) {
        const image = new Image();
        image.src = values.mediaValues[0].croppedPrev;
        image.onload = () => {
          const aspectRatio = image.width / image.height;
          const newHeight = 28 / aspectRatio; // Assuming width is always 28rem
          setMediaHeight(`${newHeight}rem`);
          console.log(newHeight, "inline useeffect heigth");
        };
      }
    };

    calculateMediaHeight();
  }, [values.mediaValues]);

  const handleNextClick = () => {
    setActiveIndex(activeIndex + 1);
  };

  const handlePrevClick = () => {
    setActiveIndex(activeIndex - 1);
  };

  const handleMediaDelete = (itemIndex) => {
    setValues((prevValues) => {
      const newPhotos = prevValues.mediaValues.filter(
        (_, index) => index !== itemIndex
      );
      return {
        ...prevValues,
        mediaValues: newPhotos,
      };
    });
    if (itemIndex === 0) {
      setActiveIndex(0);
    } else {
      setActiveIndex(activeIndex - 1);
    }
  };

  return (
    <>
      <MediaInputContainer>
        <input
          ref={mediaRef}
          type="file"
          onChange={handleInputChange}
          accept="image/jpeg, image/png, image/webp, video/mp4, video/quicktime, video/*"
          hidden
        />
        {isValues ? (
          <>
            <MediaPrev height={mediaHeight && mediaHeight}>
              {values.mediaValues.map((media, index) => (
                <React.Fragment key={index}>
                  {activeIndex === index && (
                    <>
                      <PrevActionWrapper>
                        <IconWrapper padN={true}>
                          <MdDelete onClick={() => handleMediaDelete(index)} />
                        </IconWrapper>
                      </PrevActionWrapper>
                      <PreviewContent
                        src={media.croppedPrev}
                        height={mediaHeight && mediaHeight}
                      />
                    </>
                  )}
                </React.Fragment>
              ))}
              <PreviewContainer isSingle={!activeIndex > 0}>
                {activeIndex > 0 && (
                  <IconWrapper padR={true} onClick={handlePrevClick}>
                    <IoIosArrowBack />
                  </IconWrapper>
                )}
                {activeIndex < values.mediaValues.length - 1 && (
                  <IconWrapper onClick={handleNextClick}>
                    <IoIosArrowForward />
                  </IconWrapper>
                )}
              </PreviewContainer>
            </MediaPrev>
          </>
        ) : (
          <MediaInput onClick={() => mediaRef.current.click()}>
            <div
              style={{
                position: "relative",
                marginTop: "20px",
                zIndex: "1",
              }}
            >
              <VideoFolderIcon width={"3.5rem"} height={"3.5rem"} />
              <ImageFolderIcon
                width={"5.5rem"}
                height={"5.5rem"}
                fill={theme.primary}
              />
              <WhiteDot />
            </div>
            Click here to upload a file
          </MediaInput>
        )}

        <MediaFilesInfo isBorder={isValues} style={{ marginTop: "0rem" }}>
          {isValues ? (
            <>
              Added Media Files 0{values.mediaValues.length}
              <AddBtn onClick={() => mediaRef.current.click()}>
                <BiPlus />
                <p>Add more</p>
              </AddBtn>
            </>
          ) : (
            "No Media Added Yet"
          )}
        </MediaFilesInfo>

        {isValues && (
          <MediaPrevArrayWrapper>
            {values.mediaValues.map((media, index) => (
              <PrevArrayImg
                src={media.croppedPrev}
                key={index}
                onClick={() => setActiveIndex(index)}
              />
            ))}
          </MediaPrevArrayWrapper>
        )}
      </MediaInputContainer>
      {media && isOpen && (
        <CropProfileCoverPhoto
          image={media}
          setImage={setMedia}
          handleCropComplete={handleCropComplete}
          setIsOpen={setIsOpen}
          isPost={true}
          handleRatioChange={handleRatioChange}
          setIsRatio={setIsRatio}
          isRatio={isRatio}
          values={values}
          isValues={isValues}
        />
      )}
    </>
  );
};

export default MediaUploadPrev;
