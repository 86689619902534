import styled from "styled-components";
import { IoIosArrowDown } from "react-icons/io";
import { MdClose } from "react-icons/md";
import { MdOutlineDone } from "react-icons/md";
import { Link } from "react-router-dom";

// Input Styles -------------------------------------------------------------------

const InputStyles = `
  border: none;
  border-radius: 8px;

  &:focus {
    border-bottom: 2px solid var(--primary);
    outline: none;
  }
`;

export const AuthInput = styled.input`
  ${InputStyles}
  height: 2.3rem;
  width: 16rem;
  padding: 0.5rem;
  /* height: 2.5rem; */

  margin-top: 0.7rem;
  font-weight: 500;
  color: var(--white);
  background: var(--input-bg);
  ${({ error }) =>
    error &&
    `
    border-bottom: 2px solid var(--yellow);
  `}
  &::placeholder {
    color: var(--placeholder-color);
    opacity: 1;
    font-size: 12px;
    font-weight: 400;
  }
`;

export const PrsnlFormInput = styled.input`
  ${InputStyles};
  background: none;
  border: 1px solid var(--input-outline);
  padding: 0.5rem 1rem;
  height: 2.5rem;
  width: 18rem;
  color: ${(props) => props.theme.dark};
  ${({ error }) =>
    error &&
    `
    border-bottom: 2px solid var(--red);
  `}
  &::placeholder {
    color: ${(props) => props.theme.main};
    opacity: 1;
    font-size: 12px;
    font-weight: 400;
  }
`;

export const FormInput = styled.input`
  ${InputStyles};
  position: relative;
  background: none;
  border: 1px solid ${(props) => props.theme.mediumMain};
  padding: 0.5rem 1rem;
  height: 2.5rem;
  color: ${(props) => props.theme.dark};
  width: ${({ width, isPost }) => (isPost ? "100%" : width || "18rem")};
  margin-top: ${(props) => props.marginTop || ""};
  &::placeholder {
    color: ${(props) => props.theme.placeholder};
    opacity: 1;
  }
  ${({ error }) =>
    error &&
    `
    border-bottom: 2px solid var(--red);
  `}
  width: 100%;
  max-width: 25rem;
  @media screen and (max-width: 1450px) {
    width: ${({ isPost }) => (isPost ? "100%" : "20rem")};
    width: 100%;
  }
  @media screen and (max-width: 500px) {
    width: 100%;
  }
`;

// optimized styles for input sections

export const FormInputWrapper = styled.div`
  display: flex;
  flex-direction: ${({ isHorz }) => (isHorz ? "row" : "column")};
  align-items: ${({ isItemStart }) => (isItemStart ? "flex-start" : "center")};
  gap: 5px;
  width: ${({ isLevel }) => (isLevel ? "16rem" : "100%")};
  justify-content: space-between;
  @media screen and (max-width: 1050px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
`;

export const InputValidationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 100%;
`;

export const InputValidation = styled.p`
  color: ${(props) => props.theme.red};
  display: flex;
  align-items: flex-start;
  text-align: start;
  font-size: 12px;
  @media screen and (max-width: 1450px) {
    font-size: 11px;
  }
`;

export const InputIconWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  width: 100%;
  fill: ${({ isValue, theme }) => (isValue ? theme.dark : theme.offSIcon)};
  span {
    display: grid;
    place-items: center;
  }
`;

export const StyledLabel = styled.label`
  display: flex;
  align-items: start;
  justify-content: start;
  width: ${({ labelWidth }) => (labelWidth ? labelWidth : "6rem")};
  font-size: 13px;
  color: ${(props) => props.theme.dark};
  @media screen and (max-width: 1450px) {
    max-width: 10rem;
  }
  @media screen and (max-width: 1450px) {
    max-width: 10rem;
  }
`;

export const StyledFormInput = styled.input`
  ${InputStyles};
  padding: 0.5rem 1rem;
  height: 2.5rem;
  background: none;
  border: 1px solid ${(props) => props.theme.mediumMain};
  width: 100%;
  /* max-width: 27rem; */
  font-size: 16px;
  color: ${(props) => props.theme.dark};
  &::placeholder {
    color: ${(props) => props.theme.placeholder};
    opacity: 1;
    font-size: 13px;
  }
  ${({ error }) =>
    error &&
    `
    border-bottom: 2px solid var(--red);
  `}
`;

export const CreatePostInput = styled.input`
  ${InputStyles}
  border-radius: 0;
  width: 36rem;
  background: none;
  font-size: 16px;
  padding: 0.5rem 1rem;
  height: 2.5rem;
  border-bottom: 1px solid ${(props) => props.theme.mediumMain};
  color: ${(props) => props.theme.dark};
  &::placeholder {
    color: ${(props) => props.theme.placeholder};
    opacity: 1;
  }
  @media screen and (max-width: 1450px) {
    width: 100%;
  }
  @media screen and (max-width: 900px) {
    margin-top: 1.5rem;
  }
`;

// Dropdown Styles ----------------------------------------------------------------

export const DropdownInputWrapper = styled.div``;

export const DropdownContainer = styled.div`
  position: relative;
  width: 100%;
  font-size: 13px;
  @media screen and (max-width: 1450px) {
  }
  @media screen and (max-width: 650px) {
  }
  @media screen and (max-width: 500px) {
  }
`;

export const DropdownHeader = styled.div`
  ${InputStyles}

  width: 100%;
  height: 2.5rem;
  color: var(--dropdown-gray);
  background-color: transparent;
  padding: 0.5rem 1rem;
  border: 1px solid ${(props) => props.theme.mediumMain};
  outline: none;
  cursor: pointer;
  &::first-letter {
    text-transform: uppercase;
  }
  div {
    color: var(--dropdown-gray);
    /* font-size: 13px;
    font-weight: 400; */
  }
  span {
    color: ${(props) => props.theme.dark};
  }

  &:focus-within {
    border-bottom: 2px solid var(--primary);
  }
  ${({ error }) =>
    error &&
    `
    border-bottom: 2px solid var(--red);
  `}

  @media screen and (max-width: 1450px) {
    width: ${({ isLevel, isDate, isPost }) =>
      isLevel ? "11rem" : isDate ? "auto" : isPost ? "100%" : "100%"};
    width: 100%;
  }
  @media screen and (max-width: 500px) {
    /* min-width: 22rem; */
    width: 100%;
  }
`;

export const DropdownList = styled.ul`
  position: ${({ isScrBottom }) => (isScrBottom ? "relative" : "absolute")};
  /* top: 85%; */
  left: 0;
  width: ${(props) => props.width || "100%"};
  max-height: ${({ isOptionShort }) => (isOptionShort ? "15rem" : "20rem")};
  overflow-y: auto;
  border: 1px solid ${(props) => props.theme.mediumMain};
  border-top: none;
  border-radius: 0 0 8px 8px;
  box-shadow: 4px 8px 5px rgba(0, 0, 0, 0.1);
  background: ${(props) => props.theme.mainBg};
  color: ${(props) => props.theme.main};
  z-index: 1;
  margin-top: -5px;
`;

export const DropdownListItem = styled.li`
  padding: 12px;
  cursor: pointer;
  color: ${(props) => props.theme.main};
  &::first-letter {
    text-transform: uppercase;
  }
  &:hover {
    background: ${(props) => props.theme.mediumBg};
    color: ${({ validId, theme }) => (validId ? theme.dark : theme.main)};
  }
`;

export const ArrowWrapper = styled.div`
  position: absolute;
  top: 1.5rem;
  right: 1rem;
  transform: translateY(-50%);
  pointer-events: none;
`;

export const ArrowIcon = styled(IoIosArrowDown)`
  font-size: 16px;
  color: ${(props) => props.theme.main};
`;

// Multi Select Component styles

export const MultiSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const MultiSelectInputWrapper = styled.div`
  position: relative;
`;

export const MultiSelectSelectedWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: ${(props) => props.marginBottom || ""};
  gap: 0.5rem;
`;

export const MultiSelectSelected = styled.div`
  display: flex;
  width: max-content;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  background: ${(props) => props.theme.mediumBg};
  border-radius: 20px;
  font-size: 14px;
  color: ${(props) => props.theme.dark};
  white-space: nowrap;
  gap: 0.5rem;
`;

export const MultiSelectCloseButton = styled(MdClose)`
  cursor: pointer;
`;

// Text Area styles -------------------------------------------------------------

export const StyledTextareaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  color: ${(props) => props.theme.dark};
  width: 100%;
`;

export const RemainChar = styled.span`
  font-size: 12px;
  margin-top: 2px;
  width: 100%;
  display: flex;
  justify-content: ${({ invert }) => (invert ? "flex-end" : "flex-start")};
  color: ${(props) => props.theme.dark};
`;

export const StyledTextarea = styled.textarea`
  ${InputStyles};
  width: ${(props) => props.width || "20rem"};
  height: ${(props) => props.height || "5rem"};
  width: 100%;
  /* max-width: 24rem; */
  padding: 10px;
  background: none;
  border: 1px solid ${(props) => props.theme.mediumMain};
  resize: none;
  font-size: 13px;
  color: ${(props) => props.theme.dark};
  &::placeholder {
    color: ${(props) => props.theme.placeholder};
    opacity: 1;
    font-size: 13px;
  }
  @media screen and (max-width: 1450px) {
    width: ${({ isPost }) => (isPost ? "100%" : "25.5rem")};
    width: 100%;
  }
  @media screen and (max-width: 650px) {
    width: 100%;
  }
`;

// Radio Input Styles

export const RadioInputContainer = styled.label`
  display: flex;
  align-items: center;
  margin-right: 1rem;
  max-width: max-content;
`;

export const RadioTypeInput = styled.input`
  opacity: 0;
  position: absolute;
  cursor: pointer;
`;

export const RadioLabel = styled.div`
  margin-left: 10px;
  color: ${(props) => props.theme.dark};
`;

export const RadioCircle = styled.div`
  height: 2rem;
  width: 2rem;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.mediumMain};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 150ms ease-in-out;
  background: ${(props) => props.theme.mainBg};
  background: none;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
`;

export const TickIcon = styled(MdOutlineDone)`
  color: ${(props) => props.theme.dark};
`;

// Editing Tools and Slider Input styles

export const SliderInput = styled.input`
  /* &:-webkit-appearance { */
  -webkit-appearance: none;
  /* } */
  width: 55%;
  height: 5px;
  background: ${(props) => props.theme.lightGrayBg};
  cursor: pointer;
  &:-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    outline: none;
    border-radius: 50%;
    background: #c40000;
    box-shadow: 0 2px 2px var(--shadow-1), 0 2px 2px 0 var(--shadow-2),
      inset 0 0 0 1px var(--shadow-inset);
    border: 1px solid var(--bg-third);
  }
`;

// people tag styles

export const PeopleTagWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 36rem;
  position: ${({ isRelative }) => isRelative && "relative"};
`;

export const UserNameDpWrapper = styled.div`
  display: flex;
  gap: 1rem;
  /* justify-content: ; */
`;

export const UserNameWrapper = styled.div`
  display: flex;
  gap: 5px;
  color: ${(props) => props.theme.main};
  p {
    font-size: 13px;
  }
`;

export const SelectedTagsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 10px;
`;

export const TagWrapper = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.mediumBg};
  padding: 2px 8px;
  border-radius: 100px;
  gap: 5px;
  color: ${(props) => props.theme.main};
  &:hover {
    color: ${(props) => props.theme.dark};
  }
`;

export const TaggedNameWrapper = styled(Link)`
  display: flex;
  justify-content: flex-start;

  text-decoration: none;
  font-size: 13px;
  color: ${(props) => props.theme.main};
  &:hover {
    color: ${(props) => props.theme.dark};
  }
`;

// comment section style for post ------------------------------------------

export const CommentWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  width: 100%;
`;

export const CommentEmoji = styled.div`
  min-width: 1.5rem;
  width: 1.5rem;
  height: 2rem;
  display: grid;
  place-items: center;
  border-radius: 6px;
  cursor: pointer;
  fill: ${(props) => props.theme.main};
  &:hover {
    fill: ${(props) => props.theme.dark};
  }
`;

export const CommentInputField = styled.input`
  ${InputStyles}
  width: ${({ isValue }) => (isValue ? "90%" : "100%")};
  font-size: 12px;
  height: ${({ isModal }) => (isModal ? "2.2rem" : "2rem")};
  padding: 7px 10px;
  border: none;
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.mediumMain};
  background: none;
  color: ${(props) => props.theme.dark};
  transition: 0.5s width ease-in-out;
  transition-duration: 200;
  &::placeholder {
    color: ${(props) => props.theme.placeholder};
    opacity: 1;
  }
`;

export const CommentSendButton = styled.button`
  border: none;
  color: var(--white);
  font-size: 12px;
  font-weight: 500;
  height: 2rem;
  width: 2.2rem;
  border-radius: 100px;
  display: grid;
  place-items: center;
  cursor: pointer;
  /* border: 1px solid ${(props) => props.theme.primary}; */
  background: ${(props) => props.theme.primary};
  padding-left: 2px;
  fill: ${(props) => props.theme.defaultLight};
  @media screen and (max-width: 1450px) {
    width: 2rem;
    min-width: 2rem;
  }
`;
