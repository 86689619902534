import React, { useEffect, useState } from "react";
import { MDCommentSection } from "./FeedStyle";
import {
  CmtDPLink,
  CmtDetailWrap,
  CmtUserDp,
  CmtUserName,
  CmtUserNameWrap,
  CmtloaderWrap,
  EmptyModalDataWrap,
  SingleCmtWrap,
} from "../../Components/Modals/ModalStyles";
import { BsDot } from "react-icons/bs";
import defaultImg from "../../Assets/Images/default_profile_pic.png";
import CommentInput from "../../Components/Inputs/CommentInput";
import { useDispatch } from "react-redux";
import { useHandleCreateShare } from "../../Hooks/useCreateShareHandler";
import { getFormattedDate } from "../../Hooks/useDateSelector";
import { getPostcomments } from "../../Functions/getFetchFunctions";
import { CommentIcon } from "../../Assets/SVG";
import { FadeLoader } from "react-spinners";

const MDComments = ({ isTM, postId, setCommentDataLength }) => {
  const [commentValues, setCommentValues] = useState("");
  const currentUserId = localStorage.getItem("currentUserId");
  const dispatch = useDispatch();
  const [commentData, setCommentData] = useState([]);
  const [commentLoading, setCommentLoading] = useState(true);
  const LoaderStyles = {
    margin: "0 0 0 1.2rem",
  };

  useEffect(() => {
    getPostcomments(
      currentUserId,
      postId,
      1,
      20,
      dispatch,
      setCommentData,
      setCommentLoading
    );
  }, [dispatch, postId, currentUserId, setCommentData, setCommentLoading]);

  useEffect(() => {
    setCommentDataLength(commentData.length);
  }, [commentData, setCommentDataLength]);

  const { handleCommentChange, handleCommentSubmit } = useHandleCreateShare({
    commentValues,
    setCommentValues,
    dispatch,
    currentUserId,
    commentData,
    setCommentData,
  });
  return (
    <>
      <MDCommentSection isTM={isTM}>
        <CommentInput
          id="comment"
          name="comment"
          isEmoji={true}
          placeHolder="Add a comment"
          postId={postId}
          value={commentValues}
          setValues={setCommentValues}
          isModal={true}
          onChange={handleCommentChange}
          onSubmit={handleCommentSubmit}
        />
        {!commentLoading ? (
          <>
            {commentData.length > 0 ? (
              <>
                {commentData?.map((item, index) => (
                  <SingleCmtWrap key={index}>
                    <CmtDPLink
                      to={`/${item.author.firstName}-${item.author.lastName}/${item.author._id}`}
                    >
                      <CmtUserDp
                        bgPosition={item.author.profileImage === null}
                        borderRadius={"100px"}
                        isTag={true}
                        style={{
                          backgroundImage: `url(${
                            item.author.profileImage === null
                              ? defaultImg
                              : item.author.profileImage
                          })`,
                        }}
                      />
                    </CmtDPLink>
                    <CmtDetailWrap>
                      <CmtUserNameWrap>
                        <CmtUserName
                          isMD={true}
                          to={`/${item.author.firstName}-${item.author.lastName}/${item.author._id}`}
                        >
                          @{item.author.userName}
                        </CmtUserName>
                        <span>
                          <BsDot style={{ fontSize: "1.2rem" }} />
                          {getFormattedDate(item.createdAt)}
                        </span>
                      </CmtUserNameWrap>
                      <p>{item.comment}</p>
                    </CmtDetailWrap>
                  </SingleCmtWrap>
                ))}
              </>
            ) : (
              <EmptyModalDataWrap>
                <CommentIcon width={"2.6rem"} height={"2.6rem"} />
                <span style={{ marginTop: "5px" }} />
                No Comments
                <p>Be the first one to add a comment</p>
              </EmptyModalDataWrap>
            )}
          </>
        ) : (
          <CmtloaderWrap style={{ height: "20vh" }}>
            <FadeLoader
              color="#747474"
              height={8.5}
              width={2}
              radius={1}
              margin={-9}
              speedMultiplier={1.5}
              loading={true}
              cssOverride={LoaderStyles}
            />
          </CmtloaderWrap>
        )}
      </MDCommentSection>
    </>
  );
};

export default MDComments;
