import React from "react";
import { DropDown, FormInput, PeopleTag, RadioInput } from "../../Components";
import {
  SelectedTagsWrapper,
  TagWrapper,
  TaggedNameWrapper,
} from "../../Components/Inputs/InputStyle";
import { RadioInputWrapper } from "../../Auth/PersonalInfo/PersonalInfoStyle";
import {
  AddedCreditTagsWrap,
  CPLabelWrapper,
  CreditFormDesc,
  InfoTooltip,
  ProductionDateWrap,
  UsedToolsBox,
  UsedToolsWrap,
} from "./UploadStyle";
import { BsQuestionCircleFill } from "react-icons/bs";
import { TerritoryBtn } from "../../Components/Common/CommonStyle";
import { AddAnotherWrapper } from "../../Auth/TalentRegister/TalentsInfoStyle";
import { MdClose, MdDelete } from "react-icons/md";
import { Levels } from "../../Utils/Others/Data";

const CreditForm = ({
  values,
  onCheckChange,
  handleCreditTempTag,
  handleTempCreditTagClose,
  handleInputChange,
  handleCreditAdd,
  handleCreditDelete,

  handleToolsAdd,
  handleToolsDelete,
  years,
  months,
  days,
  year,
  month,
  day,
  handleYearChange,
  handleMonthChange,
  handleDayChange,
  //
  handleDropdownChange,
}) => {
  return (
    <>
      <CreditFormDesc>
        We value your contribution. Use the checkboxes to specify your role
        'Credit Work' if you took part, or 'Full Ownership' if it's your sole
        creation.
      </CreditFormDesc>
      <RadioInputWrapper width={"100%"} isBorder={true}>
        <RadioInput
          isCheckBox={true}
          name={"credit"}
          label={"Credit Work"}
          onChange={onCheckChange}
          value={"credit"}
          checked={values.isCreditWork === true}
        />
        <RadioInput
          isCheckBox={true}
          name={"credit"}
          label={"Full Ownership"}
          onChange={onCheckChange}
          value={"nocredit"}
          checked={values.isCreditWork === false}
        />
      </RadioInputWrapper>
      <CPLabelWrapper>
        <InfoTooltip
          data-tooltip={
            "List individuals or contributors who participated in creating this post. Include their titles and tag their names if applicable. Proper credit recognition fosters a sense of community and collaboration."
          }
        >
          <BsQuestionCircleFill />
        </InfoTooltip>
        Credit info
      </CPLabelWrapper>
      {values.credit.length > 0 &&
        values.credit.map((item, index) => (
          <AddedCreditTagsWrap key={index}>
            <p>
              {item.creditTitle}
              <span>
                <MdDelete onClick={() => handleCreditDelete(index)} />
              </span>
            </p>
            <SelectedTagsWrapper>
              {item.peopleTag.map((tag, index) => (
                <TaggedNameWrapper
                  to={`/${tag.firstName}-${tag.lastName}/${tag._id}`}
                  target="_blank"
                >
                  @{tag.firstName}
                  {tag.lastName}
                </TaggedNameWrapper>
              ))}
            </SelectedTagsWrapper>
          </AddedCreditTagsWrap>
        ))}
      <FormInput
        name="creditTempTitle"
        placeholder="Credit title ex - Singer"
        onChange={handleInputChange}
        value={values.creditTempTitle}
      />
      {values.creditTempTags.length > 0 && (
        <SelectedTagsWrapper>
          {values.creditTempTags.map((item, index) => (
            <TagWrapper key={index}>
              <TaggedNameWrapper
                to={`/${item.firstName}-${item.lastName}/${item._id}`}
                target="_blank"
              >
                @{item.firstName}
                {item.lastName}
              </TaggedNameWrapper>
              <MdClose onClick={() => handleTempCreditTagClose(item._id)} />
            </TagWrapper>
          ))}
        </SelectedTagsWrapper>
      )}
      <PeopleTag
        placeholder="Tag people for this credit"
        isRelative={true}
        options={values.creditTempTags}
        handleTag={handleCreditTempTag}
      />
      <AddAnotherWrapper>
        <TerritoryBtn onClick={handleCreditAdd} width={"auto"}>
          Add
        </TerritoryBtn>
      </AddAnotherWrapper>
      <CPLabelWrapper>
        <InfoTooltip
          data-tooltip={
            "Share details about the production name and date of this post. This information provides context and helps others discover content by production specifics."
          }
        >
          <BsQuestionCircleFill />
        </InfoTooltip>
        Production info
      </CPLabelWrapper>
      <FormInput
        name="productionName"
        placeholder="Production Name"
        onChange={handleInputChange}
        value={values.productionName}
      />
      <ProductionDateWrap>
        <DropDown
          defaultOption="Year"
          options={years.map(({ value, label }) => ({
            value,
            label,
          }))}
          onChange={(option) => handleYearChange(option.selectedOption)}
          value={year}
        />
        <DropDown
          defaultOption="Month"
          options={months.map(({ label, value }) => ({
            value: label,
            label: value,
          }))}
          onChange={(option) => handleMonthChange(option.selectedOption)}
          value={month}
        />
        <DropDown
          defaultOption="Day"
          options={days.map(({ value, label }) => ({
            value,
            label,
          }))}
          onChange={(option) =>
            handleDayChange(option.selectedOption, "productionDate")
          }
          value={day}
        />
      </ProductionDateWrap>

      <CPLabelWrapper>
        <InfoTooltip
          data-tooltip={
            "Specify the tools and technologies you used to create this post. This helps others understand the tools involved and can lead to better connections with those who share your interests."
          }
        >
          <BsQuestionCircleFill />
        </InfoTooltip>
        Tools info
      </CPLabelWrapper>
      {values.toolsUsed.length > 0 && (
        <UsedToolsWrap>
          {values.toolsUsed.map((item, index) => (
            <UsedToolsBox key={index}>
              <p>
                {item.toolName}
                &nbsp;&nbsp;-&nbsp;&nbsp;
                {item.level}
              </p>
              <span>
                <MdDelete onClick={() => handleToolsDelete(index)} />
              </span>
            </UsedToolsBox>
          ))}
        </UsedToolsWrap>
      )}
      <FormInput
        placeholder="Name of the tool"
        name="toolTempTitle"
        onChange={handleInputChange}
        value={values.toolTempTitle}
      />
      <DropDown
        name="toolTempLevel"
        defaultOption="Level"
        options={Levels}
        onChange={handleDropdownChange}
        value={values.toolTempLevel}
      />
      <AddAnotherWrapper>
        <TerritoryBtn onClick={handleToolsAdd} width={"auto"}>
          Add
        </TerritoryBtn>
      </AddAnotherWrapper>
    </>
  );
};

export default CreditForm;
