import React from "react";

function TheaterIcon({ fill, width, height }) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width || "24px"}
      height={height || "24px"}
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill={fill || "var(--dark-primary)"}
        stroke="none"
      >
        <path
          d="M681 4144 c-169 -45 -301 -180 -346 -351 -23 -87 -22 -2380 0 -2468
45 -172 179 -305 352 -350 86 -23 3660 -23 3746 0 173 45 307 178 352 350 13
52 15 211 15 1235 0 1024 -2 1183 -15 1235 -45 172 -179 305 -352 350 -85 22
-3669 21 -3752 -1z m3725 -330 c15 -11 37 -33 48 -48 21 -27 21 -31 21 -1206
0 -1175 0 -1179 -21 -1206 -11 -15 -33 -37 -48 -48 l-27 -21 -1819 0 -1819 0
-27 21 c-15 11 -37 33 -48 48 -21 27 -21 34 -24 1179 -1 634 0 1166 3 1184 7
40 49 91 90 109 25 11 350 13 1837 11 l1807 -2 27 -21z"
        />
      </g>
    </svg>
  );
}

export default TheaterIcon;
