import React, { useEffect, useRef, useState } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import {
  MediaControlContainer,
  MediaIconWrap,
  MediaPrevContainer,
  MediaPrevOverlay,
  PlayBtnWrapper,
  PlayBtn,
  MediaRangeInput,
  MediaImagePreview,
  MediaVideoPreview,
  TopCornerDetails,
} from "./PostStyle";
import {
  FullScreenIcon,
  MinimizeScreenIcon,
  NoSoundIcon,
  PauseIcon,
  PlayIcon,
  ReplayIcon,
  TheaterIcon,
  TheaterOffIcon,
  VolumeIcon,
} from "../../Assets/SVG";

const MediaContainer = ({
  postId,
  isSampleNav,
  source,
  isImage,
  isMultiple,
  mediaLength,
  postIndex,
  activeIndex,
  onPrevClick,
  onNextClick,
  isCommonMute,
  setIsCommonMute,
  isTheaterMode,
  onTheater,
  isFullScreen,
  onFullScreen,
  onMinimizeScreen,
  isStopScrollPlay,
  setIsStopScrollPlay,
  onStopPlay,
  isShareLinkThumbnail,
  shareLinkThumbnailSrc,
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isEnded, setIsEnded] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isSmallDevice, setIsSmallDevice] = useState(false);
  const videoRef = useRef(null);

  // video pause play and replay --------------------------
  const togglePlay = () => {
    if (isEnded) {
      videoRef.current.currentTime = 0;
      videoRef.current.play();
      setIsPlaying(true);
      setIsEnded(false);
    } else {
      if (videoRef.current.paused) {
        videoRef.current.play();
        setIsPlaying(true);
        setIsEnded(false);
      } else {
        videoRef.current.pause();
        setIsPlaying(false);
        setIsEnded(false);
      }
    }
  };

  const handleVideoEnd = () => {
    setIsPlaying(false);
    setIsEnded(true);
    videoRef.current.currentTime = videoRef.current.duration;
  };

  // video volume, next btn and prev btn, full screen-------------
  const handlePrevClick = () => {
    if (isMultiple) {
      onPrevClick && onPrevClick(postIndex);
    } else {
      onPrevClick && onPrevClick();
    }
    setIsEnded(false);
    if (activeIndex === 1) {
      setIsPlaying(false);
    } else {
      setIsPlaying(true);
    }
    setDuration(0);
    setCurrentTime(0);
  };

  const handleNextClick = async () => {
    if (isMultiple) {
      onPrevClick && onNextClick(postIndex, isSampleNav);
    } else {
      onPrevClick && onNextClick();
    }
    setIsPlaying(true);
    setIsEnded(false);
    setDuration(0);
    setCurrentTime(0);
  };

  const toggleVolume = () => {
    if (isMultiple) {
      setIsCommonMute(!isCommonMute);
    } else {
      setIsMuted(!isMuted);
    }
  };

  const handleTheater = () => {
    onTheater && onTheater();
    onMinimizeScreen && onMinimizeScreen();
  };

  const handlefullScreen = () => {
    if (isMultiple) {
      setIsStopScrollPlay(true);
      onFullScreen && onFullScreen(postId);
    } else {
      onFullScreen && onFullScreen();
    }
    const elem = document.documentElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE11 */
      elem.msRequestFullscreen();
    }
  };

  const handleMinScreen = () => {
    if (isMultiple) {
      setIsStopScrollPlay(false);
      onMinimizeScreen && onMinimizeScreen(postId);
    } else {
      onMinimizeScreen && onMinimizeScreen();
    }

    if (document.fullscreenElement) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        /* Safari */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        /* IE11 */
        document.msExitFullscreen();
      }
    }
  };

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth < 1050) {
        setIsSmallDevice(true);
      } else {
        setIsSmallDevice(false);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // video timer display ----------------------------------
  const onTimeUpdate = () => {
    if (videoRef.current) {
      setCurrentTime(videoRef.current.currentTime);
    }
  };

  const onLoadedMetadata = () => {
    if (videoRef.current) {
      setDuration(videoRef.current.duration);
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    const formattedTime = `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    return formattedTime;
  };

  const getReverseCountdown = () => {
    const reverseTime = duration - currentTime;
    if (isEnded) {
      return formatTime(Math.floor(0.0));
    } else {
      return formatTime(Math.floor(reverseTime));
    }
  };

  // video progress input ---------------------------------
  const onSeek = (e) => {
    const video = videoRef.current;
    if (isEnded) {
      setIsEnded(false);
      setIsPlaying(false);
    }
    if (video) {
      const time = parseFloat(e.target.value);
      video.currentTime = time;
      setCurrentTime(time);
    }
  };

  useEffect(() => {
    const currentVideo = videoRef.current;

    if (currentVideo) {
      currentVideo.addEventListener("timeupdate", onTimeUpdate);
      currentVideo.addEventListener("loadedmetadata", onLoadedMetadata);
      if (activeIndex && videoRef.current.paused) {
        currentVideo.play();
        setIsPlaying(true);
        setIsEnded(false);
      }
      currentVideo.addEventListener("ended", handleVideoEnd);
      return () => {
        currentVideo.removeEventListener("timeupdate", onTimeUpdate);
        currentVideo.removeEventListener("loadedmetadata", onLoadedMetadata);
        currentVideo.removeEventListener("ended", handleVideoEnd);
      };
    }
  }, [activeIndex]);

  // handle auto play on scroll ---------------------------
  const handleScrollPlay = () => {
    const video = videoRef.current;
    if (!video) return;

    const rect = video.getBoundingClientRect();
    const isInMiddle =
      rect.top < window.innerHeight / 3 && rect.bottom > window.innerHeight / 3;

    if (isInMiddle && isEnded) {
      video.pause();
      setIsPlaying(false);
      // setIsEnded(false);
    } else if (!isInMiddle && isEnded) {
      video.pause();
      setIsPlaying(false);
      setIsEnded(false);
      video.currentTime = 0;
    } else if (isInMiddle && !isPlaying) {
      video.play();
      setIsPlaying(true);
      setIsEnded(false);
    } else if (!isInMiddle && isPlaying) {
      video.pause();
      setIsPlaying(false);
      setIsEnded(false);
      video.currentTime = 0;
    }
  };

  useEffect(() => {
    console.log("triggering bro", isEnded);
    if (isMultiple && isStopScrollPlay === false) {
      window.addEventListener("scroll", handleScrollPlay);
      return () => {
        window.removeEventListener("scroll", handleScrollPlay);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlaying, isEnded, isStopScrollPlay, isMultiple]);

  return (
    <MediaPrevContainer
      isImage={isImage}
      isMultiple={isMultiple}
      isTM={isTheaterMode}
      isFScr={isFullScreen}
    >
      {isImage ? (
        <>
          {isShareLinkThumbnail ? (
            <MediaImagePreview
              src={shareLinkThumbnailSrc}
              isFScr={isFullScreen}
            />
          ) : (
            <MediaImagePreview src={source} isFScr={isFullScreen} />
          )}
        </>
      ) : (
        <MediaVideoPreview
          src={source}
          ref={videoRef}
          controls={false}
          autoPlay={false}
          muted={isMultiple ? isCommonMute : isMuted}
          loop={false}
          playsInline
          webkit-playsinline
          controlsList="nodownload nofullscreen"
          preload="metadata"
          isFScr={isFullScreen}
        />
      )}
      {mediaLength > 1 && (
        <TopCornerDetails isLeft={true}>
          {activeIndex + 1}/{mediaLength}
        </TopCornerDetails>
      )}
      {!isImage && (
        <TopCornerDetails isRight={true}>
          {getReverseCountdown()}
        </TopCornerDetails>
      )}

      {!isImage && (
        <PlayBtnWrapper
          isPlaying={isPlaying}
          onClick={togglePlay}
          isFScr={isFullScreen}
        >
          <PlayBtn isPlaying={isPlaying || isEnded} padN={isPlaying || isEnded}>
            {isEnded ? (
              <ReplayIcon width={"22px"} height={"22px"} />
            ) : isPlaying ? (
              <PauseIcon width={"20px"} height={"20px"} />
            ) : (
              <PlayIcon width={"20px"} height={"20px"} />
            )}
          </PlayBtn>
          {isEnded && <p>Wanna Replay</p>}
        </PlayBtnWrapper>
      )}

      <MediaPrevOverlay
        isImage={isImage}
        isPlaying={isPlaying}
        isFScr={isFullScreen}
      >
        {!isImage && (
          <MediaControlContainer isPlaying={isPlaying}>
            <MediaIconWrap
              isNoBg={true}
              onClick={togglePlay}
              padN={isPlaying || isEnded}
            >
              {isEnded ? (
                <ReplayIcon width={"14px"} height={"14px"} />
              ) : isPlaying ? (
                <PauseIcon width={"13px"} height={"13px"} />
              ) : (
                <PlayIcon width={"13px"} height={"13px"} />
              )}
            </MediaIconWrap>
            <MediaRangeInput
              type="range"
              min={0}
              max={duration}
              value={currentTime}
              step={0.1}
              onChange={onSeek}
            />

            <MediaIconWrap
              isNoBg={true}
              padN={true}
              onClick={toggleVolume}
              padT={isMuted || isCommonMute}
            >
              {isMuted || isCommonMute ? (
                <NoSoundIcon width={"17px"} height={"17px"} />
              ) : (
                <VolumeIcon width={"17px"} height={"17px"} />
              )}
            </MediaIconWrap>
            {isFullScreen ? (
              <MediaIconWrap
                isNoBg={true}
                style={{ padding: "5px 0 0 0" }}
                onClick={handleMinScreen}
              >
                <MinimizeScreenIcon width={"17px"} height={"17px"} />
              </MediaIconWrap>
            ) : (
              <MediaIconWrap
                isNoBg={true}
                style={{ padding: "5px 0 0 0" }}
                onClick={handlefullScreen}
              >
                <FullScreenIcon width={"17px"} height={"17px"} />
              </MediaIconWrap>
            )}
          </MediaControlContainer>
        )}
        {!isMultiple && !isSmallDevice && (
          <MediaIconWrap
            onClick={handleTheater}
            style={{
              padding: "1px 0 0 0",
            }}
          >
            {isTheaterMode ? (
              <TheaterOffIcon width={"17px"} height={"17px"} />
            ) : (
              <TheaterIcon width={"17px"} height={"17px"} />
            )}
          </MediaIconWrap>
        )}
        {isImage && (
          <>
            {isFullScreen ? (
              <MediaIconWrap
                style={{ padding: "2px 0 0 0" }}
                onClick={handleMinScreen}
              >
                <MinimizeScreenIcon width={"17px"} height={"17px"} />
              </MediaIconWrap>
            ) : (
              <MediaIconWrap
                style={{ padding: "2px 0 0 0" }}
                onClick={handlefullScreen}
              >
                <FullScreenIcon width={"17px"} height={"17px"} />
              </MediaIconWrap>
            )}
          </>
        )}
        {activeIndex > 0 && (
          <MediaIconWrap padR={true} onClick={handlePrevClick}>
            <IoIosArrowBack />
          </MediaIconWrap>
        )}
        {activeIndex < mediaLength - 1 && (
          <MediaIconWrap onClick={handleNextClick}>
            <IoIosArrowForward />
          </MediaIconWrap>
        )}
      </MediaPrevOverlay>
    </MediaPrevContainer>
  );
};

export default MediaContainer;
