import React from "react";

function CameraIcon({ fill, width, height }) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width || "24px"}
      height={height || "24px"}
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill={fill || "var(--dark-primary)"}
        stroke="none"
      >
        <path
          d="M1955 4630 c-159 -33 -312 -142 -393 -279 -15 -25 -50 -113 -78 -196
l-51 -150 -404 -5 -404 -5 -67 -26 c-185 -72 -307 -197 -370 -378 l-23 -66 0
-1290 0 -1290 26 -67 c78 -200 219 -328 415 -378 76 -20 118 -20 1954 -20
1836 0 1878 0 1954 20 111 28 190 73 271 155 82 81 127 160 155 271 19 75 20
115 20 1314 0 1199 -1 1239 -20 1314 -51 200 -183 342 -390 419 -51 20 -82 21
-459 27 l-404 5 -51 150 c-58 171 -84 221 -160 302 -61 65 -134 113 -234 152
l-67 26 -590 2 c-324 1 -608 -2 -630 -7z m1181 -325 c61 -18 118 -64 146 -118
12 -23 49 -127 84 -231 35 -104 71 -200 79 -213 9 -14 35 -33 58 -44 39 -17
72 -19 478 -19 499 0 500 0 579 -80 86 -85 80 15 80 -1360 0 -1375 6 -1275
-80 -1360 -86 -87 95 -80 -2000 -80 -2095 0 -1914 -7 -2000 80 -86 85 -80 -15
-80 1360 0 1375 -6 1275 80 1360 79 80 80 80 579 80 403 0 439 2 477 19 55 25
72 56 139 261 69 209 92 258 142 299 71 59 77 60 659 60 439 1 539 -2 580 -14z"
        />
        <path
          d="M2425 3514 c-169 -26 -296 -67 -431 -138 -311 -166 -534 -463 -610
-816 -26 -118 -26 -362 0 -480 104 -482 475 -845 961 -941 91 -18 339 -18 430
0 488 96 849 450 962 943 23 101 23 373 0 476 -110 483 -461 834 -939 938 -71
15 -312 27 -373 18z m350 -342 c325 -89 554 -320 641 -648 27 -103 26 -312 -3
-419 -87 -320 -318 -551 -638 -638 -107 -29 -316 -30 -419 -3 -386 103 -648
416 -673 805 -16 248 77 490 257 671 132 131 282 211 465 246 94 18 278 11
370 -14z"
        />
        <path
          d="M4095 3346 c-101 -44 -125 -178 -46 -257 112 -113 296 -12 267 146
-18 94 -131 151 -221 111z"
        />
      </g>
    </svg>
  );
}

export default CameraIcon;
