import React from "react";

function HomeIcon({ fill, width, height }) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width || "24px"}
      height={height || "24px"}
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill={fill || "var(--dark-primary)"}
        stroke="none"
      >
        <path
          d="M2368 5065 c-115 -19 -253 -66 -358 -121 -93 -50 -186 -119 -720
-534 -195 -151 -465 -361 -600 -465 -338 -260 -425 -353 -530 -563 -49 -98
-73 -164 -97 -274 -17 -77 -18 -150 -18 -1083 l0 -1000 27 -98 c130 -470 498
-802 968 -873 141 -21 2899 -21 3040 0 251 38 473 150 655 331 135 133 227
280 285 455 59 174 60 196 60 1195 -1 528 -5 941 -10 985 -23 174 -93 357
-197 512 -83 125 -184 213 -663 584 -256 198 -581 450 -723 560 -341 264 -452
326 -666 375 -115 26 -337 33 -453 14z m354 -339 c84 -16 193 -55 258 -93 53
-31 77 -49 855 -651 330 -256 622 -486 648 -511 74 -70 133 -152 177 -243 80
-167 75 -93 75 -1198 l0 -985 -33 -95 c-43 -124 -98 -215 -186 -308 -120 -127
-274 -213 -441 -247 -72 -14 -231 -15 -1550 -13 -1647 4 -1499 -3 -1671 78
-104 48 -172 99 -259 192 -87 94 -136 176 -178 298 l-32 95 0 995 0 995 33 94
c41 119 98 215 174 296 33 36 255 216 501 407 1084 842 1046 814 1173 859 138
48 315 62 456 35z"
        />
      </g>
    </svg>
  );
}

export default HomeIcon;
