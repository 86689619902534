import React, { useEffect, useState } from "react";
import {
  FormPageDataWrapper,
  LevelContainer,
  DropdownFlexWrap,
  MultiArrayDelete,
  TerritoryBtn,
} from "../../Components/Common/CommonStyle";
import { DropDown, TextArea } from "../../Components";
import { useSelector } from "react-redux";
import {
  mainCategoriesArrayConvert,
  skillsArrayConvert,
  subCategoriesArrayConvert,
} from "../../Hooks/useArrayConvertor";
import {
  Levels,
  forEmptyLevels,
  forEmptySkills,
  forEmptySubCat,
} from "../../Utils/Others/Data";
import { MdClose } from "react-icons/md";
import {
  AddAnother,
  AddAnotherWrapper,
  InfoSectionWrapper,
} from "./TalentsInfoStyle";
import AddedInfoSection from "./AddedInfoSection";
import {
  FormInputWrapper,
  StyledLabel,
} from "../../Components/Inputs/InputStyle";

const SkillsInfo = ({
  values,
  errors,
  handleSkillSetChange,
  handleInputChange,
  handleAddAnotherSkillLevel,
  handleSkillsHaveDelete,
  handleAddNewSkill,
  addedSkillValues,
  handleAddedSkillDelete,
}) => {
  const mainCategories = useSelector((state) => state.content.mainCategories);
  const subCategories = useSelector((state) => state.content.subCategories);
  const skills = useSelector((state) => state.content.skills);
  const [mainCatValues, setMainCatValues] = useState([]);
  const [subCatValues, setSubCatValues] = useState([]);
  const [skillValues, setSkillValue] = useState([]);

  useEffect(() => {
    console.log(addedSkillValues);
  }, [addedSkillValues]);

  useEffect(() => {
    const convertedValues = mainCategoriesArrayConvert(mainCategories);
    setMainCatValues(convertedValues);
  }, [mainCategories]);

  useEffect(() => {
    const convertedValues = subCategoriesArrayConvert(subCategories);
    setSubCatValues(convertedValues);
  }, [subCategories]);

  useEffect(() => {
    const convertedValues = skillsArrayConvert(skills);
    setSkillValue(convertedValues);
  }, [skills]);

  let skillsLevelIndex = values.skillsHave.length - 1;

  return (
    <InfoSectionWrapper>
      <FormPageDataWrapper width="57%">
        <DropDown
          label="Main Category"
          isFor="mainCat"
          options={mainCatValues}
          defaultOption="Select Main Category"
          value={values.mainCategory}
          onChange={handleSkillSetChange}
          validation={errors.mainCategory && errors.mainCategory}
          isHorz={true}
          isItemStart={true}
          isSingle={true}
          labelWidth="17rem"
        />

        <DropDown
          label="Sub Category"
          isFor="subCat"
          options={values.mainCategory ? subCatValues : forEmptySubCat}
          defaultOption="Select Sub Category"
          index={0}
          value={values.subCategory}
          onChange={handleSkillSetChange}
          validation={errors.subCategory && errors.subCategory}
          isHorz={true}
          isItemStart={true}
          isSingle={true}
          labelWidth="17rem"
        />

        <FormInputWrapper isHorz={true} isItemStart={true}>
          <StyledLabel labelWidth="17rem">Skill & Level</StyledLabel>
          <LevelContainer>
            {values.skillsHave.map((item, skillsHaveIndex) => (
              <DropdownFlexWrap key={skillsHaveIndex}>
                <DropDown
                  isFor="skillsHave"
                  defaultOption="Select Skill Name"
                  options={values.subCategory ? skillValues : forEmptySkills}
                  value={item.skill}
                  onChange={handleSkillSetChange}
                  index={0}
                  childIndex={skillsHaveIndex}
                  validation={errors.skill && errors.skill}
                  isSingle={true}
                />
                <DropDown
                  isFor="skillLevel"
                  defaultOption="Level"
                  options={
                    values.skillsHave[skillsHaveIndex].skill
                      ? Levels
                      : forEmptyLevels
                  }
                  value={item.level}
                  onChange={handleSkillSetChange}
                  index={0}
                  childIndex={skillsHaveIndex}
                  validation={errors.level && errors.level}
                  isLevel={true}
                  isSingle={true}
                />

                {skillsHaveIndex !== 0 && (
                  <MultiArrayDelete
                    onClick={() => handleSkillsHaveDelete(skillsHaveIndex)}
                  >
                    <MdClose />
                  </MultiArrayDelete>
                )}
              </DropdownFlexWrap>
            ))}
          </LevelContainer>
        </FormInputWrapper>

        {values.skillsHave[skillsLevelIndex].level &&
          values.skillsHave.length < 5 && (
            <AddAnotherWrapper>
              <AddAnother onClick={handleAddAnotherSkillLevel}>
                Add Another Skill
              </AddAnother>
            </AddAnotherWrapper>
          )}

        <TextArea
          name="skillDescription"
          label="Skill Description"
          placeholder="Describe your skill"
          maxLength={600}
          value={values.skillDescription}
          onChange={handleInputChange}
          height="15rem"
          labelWidth="17rem"
          invert={true}
          isHorz={true}
          validation={errors.skillDescription && errors.skillDescription}
        />

        <AddAnotherWrapper>
          <TerritoryBtn onClick={handleAddNewSkill} width={"auto"}>
            Add
          </TerritoryBtn>
        </AddAnotherWrapper>
      </FormPageDataWrapper>

      <AddedInfoSection
        addedInfoValues={addedSkillValues}
        addedType="addedSkill"
        handleItemDelete={handleAddedSkillDelete}
      />
    </InfoSectionWrapper>
  );
};

export default SkillsInfo;
