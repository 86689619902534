import React, { useEffect } from "react";

import DropDown from "./Inputs/DropDown";
import { BalanceSocialMedias } from "../Utils/Others/Data";
import {
  DropdownFlexWrap,
  MultiArrayDelete,
  TerritoryBtn,
} from "./Common/CommonStyle";
import { MdClose } from "react-icons/md";
import { AddAnotherWrapper } from "../Auth/TalentRegister/TalentsInfoStyle";
import { FormInput } from ".";

const AddSocialMedia = ({
  setIsOpen,
  media,
  length,
  handleSelectMedia,
  handleInputChange,
  mediaError,
}) => {
  useEffect(() => {
    console.log(length, media, "ssdjkdghjdgf");
  });
  return (
    <>
      <DropdownFlexWrap style={{ margin: "10px 0" }}>
        <DropDown
          options={BalanceSocialMedias}
          width="2rem"
          defaultOption="Media Type"
          value={media[length].mediaType}
          onChange={handleSelectMedia}
          mainArraySize={length}
          isLevel={true}
        />
        <FormInput
          name={media[length].mediaType}
          placeholder="Paste your other media links"
          width={"13.5rem"}
          value={media[length].url}
          onChange={handleInputChange}
        />
        <MultiArrayDelete onClick={() => setIsOpen(false)}>
          <MdClose />
        </MultiArrayDelete>
      </DropdownFlexWrap>

      <AddAnotherWrapper>
        <TerritoryBtn
          //   onClick={() => handleAddNewSkill(skillsLength)}
          width="auto"
        >
          Add
        </TerritoryBtn>
      </AddAnotherWrapper>
    </>
  );
};

export default AddSocialMedia;
