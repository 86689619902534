import React, { useEffect } from "react";
import { AlertIcon, AlertModalContainer, AlertWrapper } from "./ModalStyles";
import { HiCheckCircle } from "react-icons/hi";
import { IoIosWarning } from "react-icons/io";
import { MdError } from "react-icons/md";
import { useTheme } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { setAlertDetails } from "../../ReduxToolKit/Slices/utilsSlice";
import { useSpring, animated } from "react-spring";
import "../Common/Common.css";

const AlertModal = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const alertDetails = useSelector((state) => state.utils.alertDetails);
  const token = localStorage.getItem("token");
  const currentUserId = localStorage.getItem("currentUserId");
  const auth = Boolean(token && currentUserId);

  const alertType = [
    {
      type: "success",
      icon: <HiCheckCircle />,
      color: theme.green,
      bgColor: theme.green,
    },
    {
      type: "warning",
      icon: <IoIosWarning />,
      color: theme.yellow,
      bgColor: theme.yellow,
    },
    {
      type: "danger",
      icon: <MdError />,
      color: theme.red,
      bgColor: theme.red,
    },
  ];

  useEffect(() => {
    if (alertDetails.isOpen) {
      const timeout = setTimeout(() => {
        dispatch(
          setAlertDetails({
            alertDetails: {
              isOpen: false,
            },
          })
        );
      }, 3500);
      return () => clearTimeout(timeout);
    }
  }, [alertDetails.isOpen, dispatch]);

  const animation = useSpring({
    config: {
      duration: 190,
    },
    opacity: alertDetails.isOpen ? 1 : 0,
    transform: alertDetails.isOpen ? `translateY(0%)` : `translateY(-100%)`,
  });

  return (
    <>
      {alertType.map(
        (typeItem) =>
          typeItem.type === alertDetails.type && (
            <AlertWrapper key={typeItem.type}>
              <animated.div style={animation} className="alertStyle">
                <AlertModalContainer
                  isAuth={auth}
                  // style={{ borderLeft: `2px solid ${typeItem.bgColor}` }}
                >
                  <AlertIcon style={{ color: typeItem.color }}>
                    {typeItem.icon}
                  </AlertIcon>
                  <div>{alertDetails.message}</div>
                </AlertModalContainer>
              </animated.div>
            </AlertWrapper>
          )
      )}
    </>
  );
};

export default AlertModal;
