import styled from "styled-components";

// Getting Personal Information Section

export const PrsnlInfoSection = styled.main`
  min-height: 100vh;
  background: ${(props) => props.theme.mainBg};
  padding: 1rem 0 2rem 0;
  display: grid;
  place-items: center;
  section {
    display: flex;
    flex-direction: column;
    /* justify-content: start; */
    /* align-items: flex-start; */
    max-width: 1400px;
    border: 1px solid red;
  }
  @media screen and (max-width: 1450px) {
    padding: 0.5rem 0 1rem 0;
  }
`;

export const PrsnlHeadingWrapper = styled.div`
  color: ${(props) => props.theme.dark};
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 24px;
  font-weight: 600;
  @media screen and (max-width: 1450px) {
    padding: 0 10px;
    font-size: 21px;
  }
`;

export const PrsnlSubHeading = styled.p`
  color: ${(props) => props.theme.main};
  font-weight: 500;
  font-size: 13px;
  width: 80%;
  @media screen and (max-width: 650px) {
    font-size: 12px;
    width: 100%;
  }
`;

// Personal Cover Photo and Profile Pic Section ---------------------------------

export const PrsnlPhotosWrapper = styled.div`
  /* width: 68%; */
  max-width: 1400px;
  padding: 1rem 0 3.5rem 0;
  position: relative;
  @media screen and (max-width: 1300px) {
    /* width: 80%; */
  }
  @media screen and (max-width: 950px) {
    width: 100%;
  }
`;

export const CoverPhotoContainer = styled.div`
  background: ${(props) => props.theme.mediumBg};
  position: relative;
  height: 21rem;
  width: 100%;
  border-radius: 15px;
  transition: all ease-in-out;
  /* @media screen and (max-width: 1400px) {
    height: 45vh;
  }
  @media screen and (max-width: 1300px) {
    height: 42vh;
  }
  @media screen and (max-width: 950px) {
    border-radius: 0px;
  }
  */
  @media screen and (max-width: 750px) {
    height: 36vh;
  }
  @media screen and (max-width: 450px) {
    height: 23vh;
  }
`;

export const PrsnlCoverPhoto = styled.img`
  border-radius: 10px;
  width: 100%;
  height: 100%;
  background: ${(props) => props.theme.altBg};
  object-fit: cover;
  -o-object-fit: cover;
  @media screen and (max-width: 950px) {
    border-radius: 0px;
  }
`;

export const ProfilePhotoContainerWrapper = styled.div`
  position: absolute;
  display: grid;
  place-items: center;
  bottom: 1rem;
  left: 2rem;
  width: 7rem;
  background: ${(props) => props.theme.mediumMain};
  border-radius: 10px;
  @media screen and (max-width: 450px) {
    left: 1rem;
  }
`;

export const PrsnlProfileImg = styled.img`
  border-radius: 10px;
  height: 100%;
  width: 100%;
  object-fit: cover;
  -o-object-fit: cover;
`;

// Personal Info Form Section ----------------------------------------------
export const PrsnlForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  padding: 10px;
`;

export const PrsnlInputContainer = styled.div`
  display: flex;
  flex-direction: ${({ isRow }) => (isRow ? "row" : "column")};
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 1rem;
  gap: ${({ isRow }) => (isRow ? "2rem" : "10px")};
  border-bottom: 1px solid ${(props) => props.theme.mediumMain};
  padding: 1rem 0;
  width: 70%;
  label {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 3px;
    color: ${(props) => props.theme.dark};
    span {
      color: ${(props) => props.theme.main};
      font-size: 11px;
      margin-left: 0.4rem;
    }
  }
  @media screen and (max-width: 950px) {
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }
`;

export const PrslInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: ${({ isPost, width }) => isPost && width};
  width: 50%;
  @media screen and (max-width: 1450px) {
    width: 60%;
  }
  @media screen and (max-width: 950px) {
    width: 100%;
  }
`;

export const PrsnlIntruct = styled.p`
  width: 80%;
  color: var(--gray);
  margin-top: 2px;
  font-size: 12px;
  @media screen and (max-width: 950px) {
    width: 100%;
  }
`;

export const RadioInputWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: ${(props) => props.width};
  border-bottom: ${({ isBorder, theme }) =>
    isBorder && `1px solid ${theme.borderColor}`};
  padding-bottom: ${({ isBorder }) => isBorder && "1rem"};
  @media screen and (max-width: 700px) {
    gap: 0.5rem;
  }
`;

export const DateInputWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  justify-content: start;
  gap: 1rem;
  @media screen and (max-width: 500px) {
    gap: 0.5rem;
    flex-direction: column;
  }
`;

export const PrsnlButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem 0;
  @media screen and (max-width: 500px) {
    padding: 1rem 0rem;
  }
`;

// Error styles

export const FormError = styled.div`
  color: ${(props) => props.theme.red};
  display: flex;
  align-items: flex-start;
  font-size: 12px;
  width: 16rem;
  margin-top: 2px;
`;
