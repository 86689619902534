import React from "react";

function WarningIcon({ fill, width, height }) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width || "24px"}
      height={height || "24px"}
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill={fill || "var(--dark-primary)"}
        stroke="none"
      >
        <path
          d="M2376 4830 c-145 -39 -285 -129 -377 -243 -25 -32 -268 -441 -539
-910 -271 -469 -514 -888 -540 -932 -55 -94 -73 -126 -478 -826 -351 -607
-352 -608 -384 -709 -33 -108 -33 -302 0 -410 67 -212 199 -370 387 -460 170
-81 -14 -75 2115 -75 2129 0 1945 -6 2115 75 299 144 466 483 399 808 -25 123
-43 161 -204 437 -26 44 -75 130 -110 190 -35 61 -84 146 -110 190 -71 122
-134 231 -590 1020 -456 789 -519 898 -590 1020 -26 44 -74 127 -107 185 -223
386 -260 438 -366 517 -79 58 -182 106 -274 127 -97 22 -257 20 -347 -4z m348
-369 c35 -17 81 -50 102 -73 22 -24 95 -140 163 -258 68 -118 145 -251 171
-295 342 -584 1526 -2637 1543 -2674 84 -189 -3 -420 -193 -510 l-55 -26
-1895 0 -1895 0 -57 27 c-191 89 -276 318 -190 511 16 36 135 244 485 847 33
58 85 148 116 200 30 52 76 131 101 175 80 137 156 269 590 1020 547 947 552
955 599 998 80 72 155 98 269 93 68 -3 95 -10 146 -35z"
        />
        <path
          d="M2452 3571 c-92 -32 -165 -122 -180 -222 -5 -36 0 -131 15 -289 12
-129 38 -422 58 -650 19 -228 40 -433 46 -455 15 -52 73 -110 127 -124 75 -21
161 19 199 92 11 23 27 159 59 517 24 267 50 555 59 641 27 281 18 335 -69
425 -39 40 -64 56 -105 68 -71 21 -143 20 -209 -3z"
        />
        <path
          d="M2481 1517 c-50 -19 -111 -75 -132 -121 -25 -54 -25 -148 0 -201 65
-140 244 -178 362 -76 54 45 79 100 79 174 0 96 -42 167 -124 209 -48 25 -139
32 -185 15z"
        />
      </g>
    </svg>
  );
}

export default WarningIcon;
