import styled from "styled-components";

export const ExploreContainer = styled.main`
  display: flex;
  flex-direction: column;
  /* align-items: center;
  justify-content: center; */
  gap: 10px;
  width: 100%;
  padding: 6rem 4rem;
  overflow-x: hidden;
  @media screen and (max-width: 1500px) {
    padding: 6rem 1rem;
  }
`;

export const TopbarWrapper = styled.header`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  width: 95%;
  max-width: 78vw;
  padding-bottom: 1rem;
  overflow-x: scroll;
  @media screen and (max-width: 1250px) {
    max-width: none;
  }
`;

export const TopBarTabs = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  display: inline-block;
  white-space: nowrap;
  padding: 8px 10px;
  border-radius: 5px;
  font-size: 13px;
  background: ${({ theme, isActive }) =>
    isActive ? theme.mediumMain : theme.mediumBg};
  color: ${({ theme, isActive }) => (isActive ? theme.dark : theme.main)};
  cursor: pointer;
  &:hover {
    background: ${(props) => props.theme.mediumMain};
    color: ${(props) => props.theme.dark};
  }
`;

// explore search styles ------------------------------------------

export const ExploreSearchWrap = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5rem 0rem;
`;

export const ExploreSearchContainer = styled.div`
  display: grid;
  place-items: center;
  position: relative;
  width: 60%;
  max-width: 40rem;
  @media screen and (max-width: 1050px) {
    width: 80%;
  }
  @media screen and (max-width: 650px) {
    width: 100%;
  }
`;

export const ExploreSearchInput = styled.input`
  border: none;
  border-radius: 8px;
  position: relative;
  background: ${(props) => props.theme.mediumBg};
  border: 1px solid ${(props) => props.theme.mediumMain};
  padding: 0.5rem 1rem;
  height: 2.5rem;
  color: ${(props) => props.theme.dark};
  width: 100%;
  &:focus {
    border-bottom: 2px solid var(--primary);
    outline: none;
  }
`;

export const SearchBtn = styled.button`
  position: absolute;
  right: 1px;
  top: 1px;
  cursor: pointer;
  fill: ${(props) => props.theme.main};
  display: grid;
  place-content: center;
  height: 2.3rem;
  width: 2.6rem;
  border-radius: 0 10px 10px 0px;
  cursor: pointer;
  border: none;
  padding-right: 1px;
  padding-top: 1px;
  z-index: 9;
  background: ${(props) => props.theme.mediumBg};

  &:hover {
    background: ${(props) => props.theme.mediumBg};
    fill: ${(props) => props.theme.dark};
  }
`;

export const ExploreCloseBtn = styled.button`
  position: absolute;
  right: 2.6rem;
  top: 1px;
  cursor: pointer;
  color: ${(props) => props.theme.main};
  display: grid;
  place-content: center;
  height: 2.3rem;
  width: 2.5rem;
  cursor: pointer;
  border: none;
  background: none;
  padding-top: 1px;
  z-index: 9;
  font-size: 20px;
  background: ${(props) => props.theme.mediumBg};
  border-right: 2px solid ${(props) => props.theme.borderColor};
  &:hover {
    background: ${(props) => props.theme.mediumBg};
    color: ${(props) => props.theme.dark};
  }
`;

// top talents card styles --------------------------------------

export const TopTalWrap = styled.section`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  margin-bottom: 1rem;
`;

export const TopHeading = styled.h2`
  display: flex;
  /* width: 100%; */
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  font-size: 1.5rem;
  color: ${(props) => props.theme.dark};
`;

export const TopTalContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1.5rem;
  padding-bottom: 1rem;
  max-width: 79vw;
  overflow-x: scroll;
`;

export const TalViewAllCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 10px;
  min-width: 7rem;
  max-width: 7rem;
  height: 9rem;
  min-height: 9rem;
  padding: 10px;
  cursor: pointer;
  color: ${(props) => props.theme.main};
  border: 1px solid ${(props) => props.theme.borderColor};
  &:hover {
    background: ${(props) => props.theme.mediumBg};
    color: ${(props) => props.theme.dark};
  }
  p {
    color: ${(props) => props.theme.postStarYellow};
  }
`;

export const TalViewAllBtn = styled.div`
  display: grid;
  place-items: center;
  font-size: 2rem;
  width: 4rem;
  height: 4rem;
  min-width: 4rem;
  max-width: 4rem;
  min-height: 4rem;
  max-height: 4rem;
  border-radius: 100px;
  color: ${(props) => props.theme.postStarYellow};
  background: ${(props) => props.theme.topTalStarBg};
`;

/* &::before {
    content: "";
    position: absolute;
    border-radius: 12px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: ${({ theme }) =>
    theme.mode === "light"
      ? "var(--linear-background--light--user)"
      : "var(--linear-background-top-talent-overlay)"};
  } */

export const ExploreTabResultWrap = styled.section`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-column-gap: 1rem;
  grid-row-gap: 2rem;
`;

export const ExploreTalentResult = styled.section`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(7rem, 7rem));
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  @media screen and (max-width: 650px) {
    grid-template-columns: repeat(auto-fit, minmax(7rem, 1fr));
  }
`;
