import React from "react";
import { PrimaryBtn, SecondaryBtn } from "../Common/CommonStyle";
import {
  ModalBg,
  ConfirmationContianer,
  ConfirmationMessage,
  HeaderWrapper,
  CloseButtonWrapper,
} from "./ModalStyles";
import { IoMdClose } from "react-icons/io";

const ConfirmationModal = ({ message, setIsOpen, onConfirm }) => {
  const handleClick = () => {
    setIsOpen(false);
    onConfirm && onConfirm();
  };
  return (
    <ModalBg>
      <ConfirmationContianer>
        <HeaderWrapper>
          <CloseButtonWrapper onClick={() => setIsOpen(false)} isCorner={true}>
            <IoMdClose />
          </CloseButtonWrapper>
          <header>Delete {message}</header>
          <ConfirmationMessage>
            Are you sure you want to delete this {message} detail from your
            profile? Once deleted, it cannot be undone.
          </ConfirmationMessage>
        </HeaderWrapper>
        <footer>
          <PrimaryBtn onClick={handleClick} isWidthFull={true}>
            Confirm
          </PrimaryBtn>
          <SecondaryBtn onClick={() => setIsOpen(false)} isWidthFull={true}>
            Cancel
          </SecondaryBtn>
        </footer>
      </ConfirmationContianer>
    </ModalBg>
  );
};

export default ConfirmationModal;
