import React from "react";
import {
  CloseButtonWrapper,
  MobileClose,
  ModalBg,
  UplaodOptions,
  UploadModalContainer,
} from "./ModalStyles";
import { HiOutlineArrowSmLeft } from "react-icons/hi";
import { IoMdClose } from "react-icons/io";
import { CreatePostOutlineIcon, LinkOutlineIcon } from "../../Assets/SVG";
import { useNavigate } from "react-router-dom";

const UploadModal = ({ setIsOpen, isTalent }) => {
  const navigate = useNavigate();
  const handleClick = (no) => {
    if (no === 1) {
      if (isTalent) {
        navigate("/create-post");
      }
    } else {
      navigate("/share");
    }
    setIsOpen(false);
  };

  return (
    <ModalBg>
      <UploadModalContainer>
        <MobileClose onClick={() => setIsOpen(false)}>
          <span>
            <HiOutlineArrowSmLeft />
          </span>
        </MobileClose>
        <CloseButtonWrapper onClick={() => setIsOpen(false)}>
          <IoMdClose />
        </CloseButtonWrapper>
        <header>Upload</header>
        <section>
          <UplaodOptions onClick={() => handleClick(0)}>
            <LinkOutlineIcon />
            <p>Share</p>
          </UplaodOptions>
          <UplaodOptions onClick={() => handleClick(1)}>
            <CreatePostOutlineIcon width={"22px"} />
            <p>Create Post</p>
          </UplaodOptions>
        </section>
      </UploadModalContainer>
    </ModalBg>
  );
};

export default UploadModal;
