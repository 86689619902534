// import React, { useEffect, useState } from "react";
// import { getMediaUpload } from "../../Functions/getFileUpload";

// const Explore = () => {
//   const [data, setData] = useState(null);

//   const handleInputChange = (e) => {
//     const file = e.target.files[0];
//     setData(file);
//   };

//   useEffect(() => {
//     console.log("clip1", data);
//   }, [data]);

//   const handleFileUpload = async (e) => {
//     const profileImgKey = await getMediaUpload(data);
//     console.log(profileImgKey);
//   };

//   return (
//     <div style={{ marginTop: "10rem" }}>
//       {" "}
//       <input
//         // ref={mediaRef}
//         type="file"
//         onChange={handleInputChange}
//         accept="image/jpeg, image/png, image/webp, video/mp4, video/quicktime, video/*"
//         // hidden
//       />
//       <button onClick={handleFileUpload}>Upload</button>
//     </div>
//   );
// };

// export default Explore;

import React, { useEffect, useState } from "react";
import {
  ExploreCloseBtn,
  ExploreContainer,
  ExploreSearchContainer,
  ExploreSearchInput,
  ExploreSearchWrap,
  ExploreTabResultWrap,
  ExploreTalentResult,
  SearchBtn,
} from "./ExploreStyles";
import ExploreTopBar from "./ExploreTopBar";
import TopTalents from "./TopTalents";
import TopPosts from "./TopPosts";
import { SearchOutlineIcon } from "../../Assets/SVG";
import { PostOverview, TalentCard } from "../../Components";
import {
  getSearchResults,
  getTalentSearchResults,
} from "../../Functions/getFetchFunctions";
import { useDispatch } from "react-redux";
import { MdClose } from "react-icons/md";

const Explore = () => {
  const [searchResults, setSearchResults] = useState([]);
  const [accountResults, setAccountResults] = useState([]);
  const [searchLoading, setSearchLoading] = useState(true);
  const [searchResultType, setSearchResultType] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [values, setValues] = useState(null);
  const [isSearched, setIsSearched] = useState(false);
  const [mainCatId, setMainCatId] = useState(undefined);

  const currentUserId = localStorage.getItem("currentUserId");
  const dispatch = useDispatch();
  let pageNo = 1;
  let pageSize = 20;

  useEffect(() => {
    console.log(values, "textt");
  }, [values]);

  const handleSearchInput = (e) => {
    setValues(e.target.value);
    if (values === null || values === "") {
      setIsSearched(false);
    }
  };

  const handleSearchInputDelete = () => {
    setValues("");
  };

  const handleSearchClick = () => {
    let searchType = null;
    if (activeTab === 0) {
      searchType = "ALL";
    } else if (activeTab === 1) {
      searchType = "FEED";
    } else if (activeTab === 2) {
      searchType = "SHARE";
    } else if (activeTab === 3) {
      searchType = "TALENT";
    } else {
      searchType = "ALL";
    }

    if (values === null || values === "") {
      setIsSearched(false);
    } else {
      setIsSearched(true);
    }

    if (activeTab === 3) {
      getTalentSearchResults(
        searchType,
        currentUserId,
        pageNo,
        20,
        dispatch,
        setSearchLoading,
        setAccountResults,
        values
      );
    } else {
      getSearchResults(
        searchType,
        currentUserId,
        pageNo,
        pageSize,
        dispatch,
        setSearchLoading,
        setSearchResults,
        values,
        mainCatId
      );
    }
  };

  return (
    <ExploreContainer>
      <ExploreTopBar
        setSearchResults={setSearchResults}
        setAccountResults={setAccountResults}
        setSearchLoading={setSearchLoading}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        setMainCatId={setMainCatId}
        searchText={values}
        setIsSearched={setIsSearched}
        setSearchResultType={setSearchResultType}
      />
      <ExploreSearchWrap>
        <ExploreSearchContainer>
          <ExploreSearchInput
            type="text"
            placeholder="Search"
            value={values}
            onChange={handleSearchInput}
          />
          {values && (
            <ExploreCloseBtn onClick={handleSearchInputDelete}>
              <MdClose />
            </ExploreCloseBtn>
          )}

          <SearchBtn onClick={handleSearchClick}>
            <SearchOutlineIcon width={"17px"} />
          </SearchBtn>
        </ExploreSearchContainer>
      </ExploreSearchWrap>

      {searchLoading && <p>Loading</p>}
      <>
        {searchResults.length > 0 &&
          searchResultType !== "TALENT" &&
          isSearched && (
            <ExploreTabResultWrap>
              {searchResults.map((item, index) => (
                <PostOverview
                  key={index}
                  mediaType={item.media[0]?.mediaType}
                  postType={item.media[0]?.type}
                  postId={item._id}
                  sourceUrl={item.media[0]?.url}
                  authorId={item.author?._id}
                  firstName={item.author?.firstName}
                  lastName={item.author?.lastName}
                  userName={item.author?.userName}
                  profileImg={item.author?.profileImage}
                  postTitle={item.about?.title}
                  postAvgRating={item?.postRating}
                  ratersCount={item?.numberOfRating}
                />
              ))}
            </ExploreTabResultWrap>
          )}

        {searchResults.length === 0 &&
          activeTab > 0 &&
          searchResultType !== "TALENT" && <p>No Results Found</p>}

        {accountResults.length > 0 && searchResultType === "TALENT" && (
          <ExploreTalentResult>
            {accountResults.map((item, index) => (
              <TalentCard
                key={index}
                accountId={item._id}
                userName={item.userName}
                profileImage={item.profileImage}
                avgRating={item.avgRating}
              />
            ))}
          </ExploreTalentResult>
        )}

        {accountResults.length === 0 &&
          searchResultType === "TALENT" &&
          activeTab > 0 && <p>No Talents Found</p>}

        {activeTab === 0 && !isSearched && (
          <>
            <TopTalents setSearchLoading={setSearchLoading} />
            <TopPosts setSearchLoading={setSearchLoading} />
          </>
        )}
      </>
    </ExploreContainer>
  );
};

export default Explore;
