import { useState } from "react";
import {
  AwardsFormValidation,
  DefaultSocialMediaValidation,
  EducationFormValidation,
  LanguageFormValidation,
  MoreInfoFormValidation,
  SkillsFormValidation,
  // SocialMediaValidation,
} from "../Utils/Validations/ProfileValidation";
import { handleTalentRegister } from "../Functions/getAuthHandler";

// handle multi step form values and changes
export function useHandleMultiStepForm({
  page,
  setPage,
  values,
  setValues,
  errors,
  setErrors,
  dispatch,
  navigate,
  currentUserId,
  currentUser,
  setIsLoading,
}) {
  const [addedSkillValues, setAddedSkillValues] = useState([]);
  const [addedLangValues, setAddedLangValues] = useState([]);
  const [addedEduValues, setAddedEduValues] = useState([]);
  const [addedAwardValues, setAddedAwardValues] = useState([]);
  const [skipComplete, setSkipComplete] = useState(false);

  // checking errors and changing to next page while clicking next button

  const handleTalentFormNext = () => {
    switch (true) {
      case page === 0:
        const moreInfoFormErrors = MoreInfoFormValidation(values);
        setErrors({
          ...errors,
          moreInfoError: moreInfoFormErrors,
        });
        console.log("moreinfo values", values);
        if (Object.keys(moreInfoFormErrors).length === 0) {
          setPage((currentPage) => currentPage + 1);
        }
        break;
      case page === 1:
        if (addedSkillValues.length > 0) {
          setPage((currentPage) => currentPage + 1);
          setErrors({
            ...errors,
            skillError: "",
          });
        } else {
          const skillFormErrors = SkillsFormValidation(values);
          setErrors({
            ...errors,
            skillError: skillFormErrors,
          });
          if (
            addedSkillValues.length > 0 &&
            Object.keys(skillFormErrors).length === 0
          ) {
            setPage((currentPage) => currentPage + 1);
          }
        }
        break;
      case page === 2:
        if (addedLangValues.length > 0) {
          setPage((currentPage) => currentPage + 1);
          setErrors({
            ...errors,
            langError: "",
            eduError: "",
          });
        } else {
          const languageFormErrors = LanguageFormValidation(values);
          setErrors({
            ...errors,
            langError: languageFormErrors,
          });
          if (
            addedLangValues > 0 &&
            Object.keys(languageFormErrors).length === 0
          ) {
            setPage((currentPage) => currentPage + 1);
          }
        }
        break;
      case page === 3:
        if (addedAwardValues.length > 0) {
          setPage((currentPage) => currentPage + 1);
          setSkipComplete(true);
          setErrors({
            ...errors,
            awardError: "",
          });
        } else {
          const awardFormErrors = AwardsFormValidation(values);
          setErrors({
            ...errors,
            awardError: awardFormErrors,
          });
          if (
            addedAwardValues.length > 0 &&
            Object.keys(awardFormErrors).length === 0
          ) {
            setPage((currentPage) => currentPage + 1);
            setSkipComplete(true);
          }
        }
        break;
      case page === 4:
        setPage((currentPage) => currentPage + 1);
        break;
      default:
        break;
    }
  };

  const handleTalentFormSkip = () => {
    setPage((currentPage) => currentPage + 1);
    setSkipComplete(true);
    setErrors({
      ...errors,
      awardError: "",
    });
  };

  const handleTalentFormSubmit = () => {
    setIsLoading(true);
    handleTalentRegister(
      values,
      addedSkillValues,
      addedLangValues,
      addedEduValues,
      addedAwardValues,
      dispatch,
      navigate,
      currentUserId,
      currentUser,
      setIsLoading
    );
  };

  // Handling more info values ---------------------------------------------

  const handleMyPhotoDelete = (itemIndex) => {
    setValues((prevValues) => {
      const newPhotos = prevValues.myPhotos.filter(
        (_, index) => index !== itemIndex
      );
      return {
        ...prevValues,
        myPhotos: newPhotos,
      };
    });
  };

  const handleDefaultURLInput = (event, mediaLength, mediaType) => {
    const { name, value } = event.target;
    const mediaError = DefaultSocialMediaValidation(value, name);

    if (value === "") {
      setErrors({
        ...errors,
        mediaError: {
          ...errors.mediaError,
          [name]: "",
        },
      });
    } else {
      setErrors({
        ...errors,
        mediaError: {
          ...errors.mediaError,
          [name]: mediaError[name],
        },
      });
    }

    console.log("media error", mediaError, errors);

    if (Object.keys(mediaError).length === 0) {
      const updatedSocialMediaLinks = [...values.socialMediaLinks];
      updatedSocialMediaLinks[mediaLength] = {
        ...updatedSocialMediaLinks[mediaLength],
        default: true,
        mediaType: mediaType,
        url: value,
      };

      setValues({
        ...values,
        socialMediaLinks: updatedSocialMediaLinks,
      });
    }
  };

  const handleSelectMedia = (selectedOption, mediaLength) => {
    console.log("handle submit", selectedOption, mediaLength);
    setValues({
      ...values,
      socialMediaLinks: [
        {
          ...values.socialMediaLinks[mediaLength],
          default: false,
          mediaType: selectedOption ? selectedOption.value : "",
        },
      ],
    });
  };

  const handleOtherURLInput = (event, mediaLength) => {
    console.log(event.target.value, event.target.name);

    setValues({
      ...values,
      socialMediaLinks: [
        {
          ...values.socialMediaLinks[mediaLength],
          default: false,
          url: event.target.value,
        },
      ],
    });
  };

  // handling skill set create read update delete --------------------------

  const handleAddAnotherSkillLevel = () => {
    const newSkillsHave = [...values.skillsHave];
    newSkillsHave.push({
      skill: "",
      skillId: "",
      level: "",
    });
    console.log(newSkillsHave);
    setValues({
      ...values,
      skillsHave: newSkillsHave,
    });
  };

  const handleSkillsHaveDelete = (skillIndex) => {
    setValues((prevSkillValues) => {
      const newSkillValues = { ...prevSkillValues };
      newSkillValues.skillsHave.splice(skillIndex, 1);
      console.log("last", newSkillValues);
      return newSkillValues;
    });
  };

  // handling new skill set add and delete ----------------------------------

  const handleAddNewSkill = () => {
    const finalErrors = SkillsFormValidation(values);
    setErrors({
      ...errors,
      skillError: finalErrors,
    });

    let newSkillsHave = [];
    for (let i = 0; i < values.skillsHave.length; i++) {
      if (values.skillsHave[i].skillId !== "") {
        newSkillsHave.push({
          skill: values.skillsHave[i].skill,
          skillId: values.skillsHave[i].skillId,
          level: values.skillsHave[i].level,
        });
      }
    }

    if (Object.keys(finalErrors).length === 0) {
      const newSkillSet = {
        mainCategory: values.mainCategory,
        mainCategoryId: values.mainCategoryId,
        subCategory: values.subCategory,
        subCategoryId: values.subCategoryId,
        skillsHave: newSkillsHave,
        skillDescription: values.skillDescription,
      };
      setAddedSkillValues((prevAddedValues) => [
        ...prevAddedValues,
        newSkillSet,
      ]);

      setValues({
        ...values,
        mainCategory: "",
        mainCategoryId: "",
        subCategory: "",
        subCategoryId: "",
        skillsHave: [
          {
            skill: "",
            skillId: "",
            level: "",
          },
        ],
        skillDescription: "",
      });
    }
  };

  const handleAddedSkillDelete = (itemIndex) => {
    setAddedSkillValues((prevValues) => {
      const newValues = prevValues.filter((item, index) => index !== itemIndex);
      return newValues;
    });
  };

  // handling language change ----------------------------------------------

  const handleAddNewLanguage = () => {
    const finalErrors = LanguageFormValidation(values);
    setErrors({
      ...errors,
      langError: finalErrors,
    });
    console.log(finalErrors);
    if (Object.keys(finalErrors).length === 0) {
      const newLang = {
        language: values.language,
        languageId: values.languageId,
        level: values.level,
      };
      setAddedLangValues((prevAddedValues) => [...prevAddedValues, newLang]);
      setValues({
        ...values,
        language: "",
        languageId: "",
        level: "",
      });
    }
  };

  const handleAddedLangDelete = (itemIndex) => {
    setAddedLangValues((prevValues) => {
      const newValues = prevValues.filter((item, index) => index !== itemIndex);
      return newValues;
    });
  };

  // handling education change ----------------------------------------------

  const handleAddNewEducation = () => {
    const finalErrors = EducationFormValidation(values);
    setErrors({
      ...errors,
      eduError: finalErrors,
    });
    console.log(finalErrors);
    if (Object.keys(finalErrors).length === 0) {
      const newEdu = {
        course: values.course,
        institution: values.institution,
        from: values.from,
        to: values.to,
      };
      console.log(newEdu);
      setAddedEduValues((prevAddedValues) => [...prevAddedValues, newEdu]);
      setValues({
        ...values,
        course: "",
        institution: "",
        from: "",
        to: "",
      });
    }
  };

  const handleAddedEduDelete = (itemIndex) => {
    setAddedEduValues((prevValues) => {
      const newValues = prevValues.filter((item, index) => index !== itemIndex);
      return newValues;
    });
  };

  // handling awards values and functions ----------------------------------

  const handleAddNewAward = (values) => {
    const finalErrors = AwardsFormValidation(values);
    setErrors({
      ...errors,
      awardError: finalErrors,
    });
    if (Object.keys(finalErrors).length === 0) {
      const newAward = {
        awardName: values.awardName,
        givenBy: values.givenBy,
        when: values.when,
      };
      console.log(newAward);
      setAddedAwardValues((prevAddedValues) => [...prevAddedValues, newAward]);
      setValues({
        ...values,
        awardName: "",
        givenBy: "",
        when: "",
      });
    }
  };

  const handleAddedAwardDelete = (itemIndex) => {
    setAddedAwardValues((prevValues) => {
      const newValues = prevValues.filter((item, index) => index !== itemIndex);
      return newValues;
    });
  };

  return {
    handleTalentFormNext,
    handleTalentFormSkip,
    handleTalentFormSubmit,
    // more info functions
    handleMyPhotoDelete,
    handleDefaultURLInput,
    handleSelectMedia,
    handleOtherURLInput,
    // skills info functions
    handleAddAnotherSkillLevel,
    handleSkillsHaveDelete,
    handleAddNewSkill,
    handleAddedSkillDelete,
    addedSkillValues,
    // language info functions
    handleAddNewLanguage,
    handleAddedLangDelete,
    addedLangValues,
    // education info functions
    handleAddNewEducation,
    handleAddedEduDelete,
    addedEduValues,
    // awards info function
    handleAddNewAward,
    handleAddedAwardDelete,
    addedAwardValues,
    skipComplete,
  };
}
