import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import MoreInfo from "./MoreInfo";
import SkillsInfo from "./SkillsInfo";
import LanguageEducationInfo from "./LanguageEducationInfo";
import AwardsCertificateInfo from "./AwardsCertificateInfo";
import {
  getEthnicities,
  getLanguages,
  getMainCategories,
  getSkills,
  getSubCategories,
} from "../../Functions/getFetchFunctions";
import {
  AtomicIcon,
  AwardsIcon,
  CheckIcon,
  FBIcon,
  GraduationIcon,
  InstaIcon,
  LinkedInIcon,
  UserIcon,
} from "../../Assets/SVG";
import { Loader, MultiStepForm } from "../../Components";
import { useHandleMultiStepForm } from "../../Hooks/useMultiFormHandler";
import CompleteProfile from "./CompleteProfile";
import { useHandleValues } from "../../Hooks/useEventHandler";

const TalentRegisterForm = () => {
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUserId =
    useSelector((state) => state.auth.currentUserId) ||
    localStorage.getItem("currentUserId");
  const currentUser = useSelector((state) => state.auth.currentUser);
  const [isLoading, setIsLoading] = useState(false);
  const handleCancel = () => {
    navigate(`/profile/${currentUserId}`);
  };

  const [values, setValues] = useState({
    ethnic: "",
    ethnicId: "",
    description: "",
    myPhotos: [],
    socialMediaLinks: [
      {
        default: true,
        mediaType: "",
        url: "",
      },
    ],
    // skill Values
    mainCategory: "",
    mainCategoryId: "",
    subCategory: "",
    subCategoryId: "",
    skillsHave: [
      {
        skill: "",
        skillId: "",
        level: "",
      },
    ],
    skillDescription: "",
    // language values
    language: "",
    languageId: "",
    level: "",
    // education values
    course: "",
    institution: "",
    from: "",
    to: "",
    // awards values
    awardName: "",
    givenBy: "",
    when: "",
  });

  const [checked, setChecked] = useState(false);
  const [errors, setErrors] = useState({
    moreInfoError: [],
    mediaError: [],
    skillError: [],
    langError: [],
    eduError: [],
    awardError: [],
    completeError: [],
  });

  const {
    handleTalentFormNext,
    handleTalentFormSkip,
    // more info functions
    handleMyPhotoDelete,
    handleDefaultURLInput,
    handleSelectMedia,
    handleOtherURLInput,
    // skills info functions
    handleAddAnotherSkillLevel,
    handleSkillsHaveDelete,
    handleAddNewSkill,
    addedSkillValues,
    handleAddedSkillDelete,
    // language info functions
    handleAddNewLanguage,
    handleAddedLangDelete,
    addedLangValues,
    // education info functions
    handleAddNewEducation,
    handleAddedEduDelete,
    addedEduValues,
    // awards info function
    handleAddNewAward,
    handleAddedAwardDelete,
    addedAwardValues,
    skipComplete,
    // final submission
    handleTalentFormSubmit,
  } = useHandleMultiStepForm({
    page,
    setPage,
    values,
    setValues,
    errors,
    setErrors,
    dispatch,
    navigate,
    currentUserId,
    currentUser,
    setIsLoading,
  });

  useEffect(() => {
    getEthnicities(dispatch);
    getMainCategories(dispatch);
    getLanguages(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (values.mainCategoryId) {
      getSubCategories(values.mainCategoryId, dispatch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, values.mainCategoryId]);

  useEffect(() => {
    if (values.subCategoryId) {
      getSkills(values.subCategoryId, dispatch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, values.subCategoryId]);

  const { handleDropdownChange, handleSkillSetChange, handleInputChange } =
    useHandleValues({
      values,
      setValues,
      errors,
      setErrors,
    });

  const DefaultSocialMedia = [
    {
      name: "linkedin",
      mediaType: "linkedin",
      icon: <LinkedInIcon width={"36px"} height={"36px"} />,
      placeHolder: "Paste your LinkedIn profile link",
      value: values.socialMediaLinks[values.socialMediaLinks.length - 1].url,
      error: errors.mediaError.linkedin,
    },
    {
      name: "instagram",
      mediaType: "Instagram",
      icon: <InstaIcon width={"36px"} height={"36px"} />,
      placeHolder: "Paste your Instagram profile link",
      value: values.socialMediaLinks[values.socialMediaLinks.length - 1].url,
      error: errors.mediaError.instagram,
    },
    {
      name: "facebook",
      mediaType: "Facebook",
      icon: <FBIcon width={"36px"} height={"36px"} />,
      placeHolder: "Paste your Facebook profile link",
      value: values.socialMediaLinks[values.socialMediaLinks.length - 1].url,
      error: errors.mediaError.facebook,
    },
  ];

  const TalentFormData = [
    {
      title: "More Information",
      desc: "Provide essential information, including description about you and your profession, social media links, and showcase you with 3 photos.",
      form: (
        <MoreInfo
          values={values}
          setValues={setValues}
          defaultSocialMedia={DefaultSocialMedia}
          errors={errors.moreInfoError}
          mediaError={errors.mediaError}
          handleInputChange={handleInputChange}
          handleDropdownChange={handleDropdownChange}
          handleSelectMedia={handleSelectMedia}
          handleMyPhotoDelete={handleMyPhotoDelete}
          handleOtherURLInput={handleOtherURLInput}
          handleDefaultURLInput={handleDefaultURLInput}
        />
      ),
    },
    {
      title: "Skills Details",
      desc: "Highlight your expertise with main and sub-categories, and showcase your skill names and levels to demonstrate your proficiency.",
      form: (
        <SkillsInfo
          values={values}
          errors={errors.skillError}
          handleSkillSetChange={handleSkillSetChange}
          handleInputChange={handleInputChange}
          handleAddAnotherSkillLevel={handleAddAnotherSkillLevel}
          handleSkillsHaveDelete={handleSkillsHaveDelete}
          handleAddNewSkill={handleAddNewSkill}
          addedSkillValues={addedSkillValues}
          handleAddedSkillDelete={handleAddedSkillDelete}
        />
      ),
    },
    {
      title: "Languages & Education Details",
      desc: "Share your educational background, institutions attended, language proficiency, and level of expertise to showcase your academic achievements.",
      form: (
        <LanguageEducationInfo
          handleDropdownChange={handleDropdownChange}
          handleInputChange={handleInputChange}
          // language info props
          values={values}
          langError={errors.langError}
          handleAddNewLanguage={handleAddNewLanguage}
          handleAddedLangDelete={handleAddedLangDelete}
          addedLangValues={addedLangValues}
          // education info props
          eduValues={values}
          eduError={errors.eduError}
          handleAddNewEducation={handleAddNewEducation}
          handleAddedEduDelete={handleAddedEduDelete}
          addedEduValues={addedEduValues}
        />
      ),
    },
    {
      title: "Awards & Certificates Details",
      desc: "Display your accolades and certificates, showcasing recognition for your talents and skills from various sources.",
      form: (
        <AwardsCertificateInfo
          values={values}
          errors={errors.awardError}
          handleInputChange={handleInputChange}
          handleDropdownChange={handleDropdownChange}
          handleAddNewAward={handleAddNewAward}
          handleAddedAwardDelete={handleAddedAwardDelete}
          addedAwardValues={addedAwardValues}
        />
      ),
    },
    {
      title: "Complete Registration",
      desc: "",
      form: (
        <CompleteProfile
          checked={checked}
          setChecked={setChecked}
          errors={errors.completeError}
        />
      ),
    },
  ];

  const TalentsFormStatus = [
    {
      title: "More Info",
      subTitle: "About Talent",
      icon: <UserIcon />,
      complete: values.description !== "",
    },
    {
      title: "Skills",
      subTitle: "Abilities Showcase",
      icon: <AtomicIcon />,
      complete: addedSkillValues.length > 0,
    },
    {
      title: "Education",
      subTitle: "Academic Background",
      icon: <GraduationIcon />,
      complete: addedLangValues.length > 0,
    },
    {
      title: "Awards",
      subTitle: "Achievements Highlight",
      icon: <AwardsIcon />,
      complete: skipComplete,
    },
    {
      title: "Complete",
      subTitle: "Finalize & Submit",
      icon: <CheckIcon />,
      complete: "",
    },
  ];

  return (
    <>
      {isLoading ? (
        <Loader isFull={true} />
      ) : (
        <MultiStepForm
          page={page}
          setPage={setPage}
          formData={TalentFormData}
          formStatus={TalentsFormStatus}
          handleCancel={handleCancel}
          handleSkip={handleTalentFormSkip}
          handleNext={handleTalentFormNext}
          handleSubmit={handleTalentFormSubmit}
          isComplete={checked}
        />
      )}
    </>
  );
};

export default TalentRegisterForm;
