import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const registerAction = createAsyncThunk(
  "auth/register",
  async (userData) => {
    try {
      const response = await axios.post("/auth/register", userData);
      console.log(response, "axiosRegisterRes");
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const otpAction = createAsyncThunk(
  "auth/verifyEmailOtp",
  async (otpData) => {
    try {
      const response = await axios.post("/auth/verifyEmailOtp", otpData);
      console.log(response, "otpRes");
      return response.data;
    } catch {}
  }
);

export const loginAction = createAsyncThunk("auth/login", async (userData) => {
  try {
    const response = await axios.post("/auth/login", userData);
    console.log(response, "loginAction");
    return response.data;
  } catch {}
});

// export const logoutAction = createAsyncThunk("auth/logout", async());

export const personalInfoPostAction = createAsyncThunk(
  "/user/personalInfo/{userId}",
  async ({ userId, formData }) => {
    console.log(userId, formData, "testing");
    try {
      const response = await axios.put(
        `/user/personalInfo/${userId}`,
        formData
      );
      console.log(response, "personalInfoRes");
      return response.data;
    } catch {
      console.log(userId, formData, "redux error");
    }
  }
);

export const personalInfoViewAction = createAsyncThunk(
  "user/{userId}",
  async (userId) => {
    try {
      const response = await axios.get(`/user/${userId}`);
      return response.data;
    } catch {
      const response = {
        data: { status: 400 },
      };
      return response.data;
    }
  }
);

export const personalInfoEditAction = createAsyncThunk(
  "/user/editPersonalInfo/{userId}",
  async ({ currentUserId, formData }) => {
    try {
      const response = await axios.put(
        `/user/editPersonalInfo/${currentUserId}`,
        formData
      );
      return response.data;
    } catch {
      const response = {
        data: { status: 400 },
      };
      return response.data;
    }
  }
);

export const userInfoPublicViewAction = createAsyncThunk(
  "user/public/{userId}/{viewerId}",
  async ({ userId, currentUserId }) => {
    try {
      const response = await axios.get(
        `/user/public/${userId}/${currentUserId}`
      );
      return response.data;
    } catch {
      const response = {
        data: { status: 400 },
      };
      return response.data;
    }
  }
);

export const talentRegisterAction = createAsyncThunk(
  "user/professionalInfo/{userId}",
  async ({ currentUserId, formData }) => {
    console.log(currentUserId, formData, "testing");

    try {
      const response = await axios.put(
        `/user/professionalInfo/${currentUserId}`,
        formData
      );
      console.log(response, "Te register");
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

// talent details view actions

export const talentSkillsViewAction = createAsyncThunk(
  "user/skills/{userId}",
  async (userId) => {
    console.log(userId);
    try {
      const response = await axios.get(`/user/skills/${userId}`);
      console.log(response, "Talent Skills");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const talentAwardsViewAction = createAsyncThunk(
  "user/awardsOrCertificate/{userId}",
  async (userId) => {
    try {
      const response = await axios.get(`/user/awardsOrCertificate/${userId}`);
      console.log(response, "Talent Awards");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const talentLanguageViewAction = createAsyncThunk(
  "user/languages/{userId}",
  async (userId) => {
    try {
      const response = await axios.get(`/user/languages/${userId}`);
      console.log(response, "Talent language");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const talentEducationViewAction = createAsyncThunk(
  "user/educations/{userId}",
  async (userId) => {
    try {
      const response = await axios.get(`/user/educations/${userId}`);
      console.log(response, "Talent education");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

//  talent details edited and deleted actions

export const talentLanguageEditAction = createAsyncThunk(
  "user/languages/{userId}",
  async ({ currentUserId, formData }) => {
    console.log(currentUserId, formData);
    try {
      const response = await axios.put(
        `/user/languages/${currentUserId}`,
        formData
      );
      console.log(response, "Talent language edited update");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);
