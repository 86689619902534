import React, { useEffect, useState } from "react";
import {
  CreatePostInput,
  SelectedTagsWrapper,
  StyledTextarea,
  TagWrapper,
  TaggedNameWrapper,
} from "../../Components/Inputs/InputStyle";
import { CPLabelWrapper, CPRatingWrapper, InfoTooltip } from "./UploadStyle";
import { DropDown, FormInput, PeopleTag, StarRating } from "../../Components";
import { DropdownFlexWrap } from "../../Components/Common/CommonStyle";
import {
  Levels,
  forEmptyLevels,
  forEmptySkills,
  forEmptySubCat,
  forEmptySecondaryLang,
} from "../../Utils/Others/Data";
import { useSelector } from "react-redux";
import {
  convertLanguagesToArray,
  countryArrayConvert,
  mainCategoriesArrayConvert,
  skillsArrayConvert,
  stateArrayConvert,
  subCategoriesArrayConvert,
} from "../../Hooks/useArrayConvertor";
import { BsQuestionCircleFill } from "react-icons/bs";
import { MdClose } from "react-icons/md";

const AboutForm = ({
  values,
  setValues,
  errors,
  handleInputChange,
  handleDropdownChange,
  handleSkillSetChange,
  handleNormalPeopleTag,
  handleSelectedTagClose,
  handleRatingClick,
}) => {
  const mainCategories = useSelector((state) => state.content.mainCategories);
  const subCategories = useSelector((state) => state.content.subCategories);
  const skills = useSelector((state) => state.content.skills);
  const countries = useSelector((state) => state.content.countries);
  const states = useSelector((state) => state.content.states);
  const languages = useSelector((state) => state.content.languages);

  const [mainCatValues, setMainCatValues] = useState([]);
  const [subCatValues, setSubCatValues] = useState([]);
  const [skillValues, setSkillValue] = useState([]);
  const [countryValues, setCountryValues] = useState([]);
  const [stateValues, setStateValues] = useState([]);
  const [langValues, setLangValues] = useState([]);

  useEffect(() => {
    const convertedValues = mainCategoriesArrayConvert(mainCategories);
    console.log(convertedValues, "maincat");
    setMainCatValues(convertedValues);
  }, [mainCategories]);

  useEffect(() => {
    const convertedValues = subCategoriesArrayConvert(subCategories);
    setSubCatValues(convertedValues);
  }, [subCategories]);

  useEffect(() => {
    const convertedValues = skillsArrayConvert(skills);
    setSkillValue(convertedValues);
  }, [skills]);

  useEffect(() => {
    const convertedValues = countryArrayConvert(countries);
    setCountryValues(convertedValues);
  }, [countries]);

  useEffect(() => {
    const convertedValues = stateArrayConvert(states);
    setStateValues(convertedValues);
  }, [states]);

  useEffect(() => {
    const convertedValues = convertLanguagesToArray(languages);
    setLangValues(convertedValues);
    console.log("check lang", convertedValues);
  }, [languages]);

  return (
    <>
      <CreatePostInput
        name="title"
        placeholder="Add Title"
        onChange={handleInputChange}
        value={values.title}
      />
      <StyledTextarea
        name="description"
        width={"100%"}
        height={"20rem"}
        placeholder="Description"
        value={values.description}
        onChange={handleInputChange}
        isPost={true}
      />
      <CPRatingWrapper>
        Your rating to your work / post
        <StarRating values={values.ownRating} onClick={handleRatingClick} />
      </CPRatingWrapper>
      <CPLabelWrapper>
        <InfoTooltip
          data-tooltip={
            "Choose skills that best describe your post's content. They help others discover your content more easily and connect with people who share your interests."
          }
        >
          <BsQuestionCircleFill />
        </InfoTooltip>
        Skills info
      </CPLabelWrapper>

      <DropDown
        isFor="mainCat"
        options={mainCatValues}
        defaultOption="Select Main Category"
        value={values.mainCategory}
        onChange={handleSkillSetChange}
        validation={errors?.mainCategory}
        isSingle={true}
      />

      <DropDown
        isFor="subCat"
        options={values.mainCategory ? subCatValues : forEmptySubCat}
        defaultOption="Select Sub Category"
        index={0}
        value={values.subCategory}
        onChange={handleSkillSetChange}
        validation={errors?.subCategory}
      />

      <DropdownFlexWrap>
        <DropDown
          isFor="singleSkill"
          defaultOption="Select Skill Name"
          options={values.subCategory ? skillValues : forEmptySkills}
          value={values.skill}
          onChange={handleSkillSetChange}
          validation={errors?.skill}
        />
        <DropDown
          isFor="singleSkillLevel"
          defaultOption="Level"
          options={values.skill ? Levels : forEmptyLevels}
          value={values.level}
          onChange={handleSkillSetChange}
          validation={errors?.level}
          isLevel={true}
        />
      </DropdownFlexWrap>

      <CPLabelWrapper>
        <InfoTooltip
          data-tooltip={
            "If your post is language-specific or multilingual, specifying the languages here is a great way to connect with a wider audience."
          }
        >
          <BsQuestionCircleFill />
        </InfoTooltip>
        Language info
        <span>(If it's related to the post)</span>
      </CPLabelWrapper>

      <DropDown
        name="primaryLanguage"
        idName="primaryLanguageId"
        defaultOption="Select Primary Language"
        options={langValues}
        value={values.primaryLanguage}
        onChange={handleDropdownChange}
      />

      <DropDown
        name="secondaryLanguage"
        idName="secondaryLanguageId"
        defaultOption="Select Secondary Language"
        options={
          values.primaryLanguage
            ? langValues.filter((lang) => lang.id !== values.primaryLanguageId)
            : forEmptySecondaryLang
        }
        value={values.secondaryLanguage}
        onChange={handleDropdownChange}
      />

      <CPLabelWrapper>
        <InfoTooltip
          data-tooltip={
            "it helps your posts reach people nearby who share your interests. Think of it as a way to connect with a local audience and discover content from your area."
          }
        >
          <BsQuestionCircleFill />
        </InfoTooltip>
        Location info
      </CPLabelWrapper>

      <DropDown
        name="country"
        idName="countryId"
        defaultOption="Country"
        options={countryValues}
        value={values.country}
        onChange={handleDropdownChange}
      />

      <DropDown
        name="state"
        idName="stateId"
        defaultOption="State"
        options={stateValues}
        value={values.state}
        onChange={handleDropdownChange}
      />

      <CPLabelWrapper>Tags</CPLabelWrapper>
      {values.peopleTag.length > 0 && (
        <SelectedTagsWrapper>
          {values.peopleTag.map((item, index) => (
            <TagWrapper key={index}>
              <TaggedNameWrapper
                to={`/${item.firstName}-${item.lastName}/${item._id}`}
                target="_blank"
              >
                @{item.firstName}
                {item.lastName}
              </TaggedNameWrapper>
              <MdClose onClick={() => handleSelectedTagClose(item._id)} />
            </TagWrapper>
          ))}
        </SelectedTagsWrapper>
      )}
      <PeopleTag
        isScrBottom={true}
        values={values}
        setValues={setValues}
        handleTag={handleNormalPeopleTag}
        options={values.peopleTag}
      />
      <FormInput
        name="keywords"
        placeholder="Hashtags"
        onChange={handleInputChange}
        value={values.keywords}
      />
    </>
  );
};

export default AboutForm;
