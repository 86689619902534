import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getViewAllShared } from "../../Functions/getFetchFunctions";
import { Loader, Post } from "../../Components";
import styled from "styled-components";

const FeedWrapper = styled.main`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
`;

const ShareFeed = () => {
  const dispatch = useDispatch();
  const currentUserId = localStorage.getItem("currentUserId");
  const [isLoading, setIsLoading] = useState(true);

  const [sharesById, setSharesById] = useState([]);
  //   const sharesById = useSelector((state) => state.shares.sharesById);
  const shareIds = Object.keys(sharesById); // Get an array of share IDs
  const shares = shareIds.map((shareId) => sharesById[shareId]);

  const [pageNo, setPageNo] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
    // console.log(scrollTop, clientHeight, scrollHeight);
    if (scrollHeight - scrollTop === clientHeight && !loading && hasMore) {
      setLoading(true); // Set loading state to true before fetching more shares
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.title = "Shared Feed | Talents Explore";
    getViewAllShared(
      currentUserId,
      currentUserId,
      pageNo,
      10,
      dispatch,
      setSharesById
    )
      .then(() => {
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching share feed");
      });
  }, [currentUserId, dispatch, pageNo]);

  return (
    <FeedWrapper>
      {!isLoading && shares.length !== 0 ? (
        <>
          <Post data={shares} />
          {loading && <p>Loading...</p>}
        </>
      ) : (
        <Loader />
      )}
    </FeedWrapper>
  );
};

export default ShareFeed;
