import React, { useState, useEffect } from "react";
import {
  FormInputWrapper,
  InputValidationWrapper,
  RemainChar,
  StyledLabel,
  StyledTextarea,
} from "./InputStyle";

const TextArea = ({
  name,
  label,
  value,
  placeholder,
  maxLength,
  height,
  onChange,
  invert,
  validation,
  isHorz,
  labelWidth,
  isOptional,
}) => {
  const [remainingChars, setRemainingChars] = useState(maxLength);

  useEffect(() => {
    if (value) setRemainingChars(maxLength - value.length); //text.length
  }, [value, maxLength]);

  function handleChange(e) {
    const inputText = e.target.value;
    if (inputText.length <= maxLength) {
      setRemainingChars(maxLength - inputText.length);
      onChange && onChange(e);
    } else {
      setRemainingChars(0);
    }
  }

  return (
    <FormInputWrapper isHorz={isHorz} isItemStart={true}>
      {label && (
        <StyledLabel labelWidth={labelWidth}>
          {label}
          {isOptional && <span>{`(Optional)`}</span>}
        </StyledLabel>
      )}
      <InputValidationWrapper>
        <StyledTextarea
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={handleChange}
          maxLength={maxLength}
          height={height}
        />
        <RemainChar invert={invert}>
          {remainingChars} / {maxLength}
        </RemainChar>
        {validation && <p>{validation}</p>}
      </InputValidationWrapper>
    </FormInputWrapper>
  );
};

export default TextArea;
