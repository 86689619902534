import React from "react";
import {
  CloseButtonWrapper,
  ModalBg,
  PhotoImg,
  PhotoViewContainer,
} from "./ModalStyles";
import { IoMdClose } from "react-icons/io";

const PhotoViewModal = ({ src, setPhotoOpen }) => {
  return (
    <ModalBg>
      <PhotoViewContainer>
        <PhotoImg src={src} alt={"view photo"} />
        <CloseButtonWrapper onClick={setPhotoOpen} isCorner={true}>
          <IoMdClose />
        </CloseButtonWrapper>
      </PhotoViewContainer>
    </ModalBg>
  );
};

export default PhotoViewModal;
