import React from "react";
import { Navigate } from "react-router-dom";
import { AuthLayout, UpdateAccountLayout, UserLayout } from "./Layouts";

export function NotLoggedinRoutes({ auth }) {
  console.log(auth, "auth chcek ===================");
  return !auth ? <AuthLayout /> : <Navigate to="/feed" />;
}

export function LoggedinRoutes({ auth, isAbout, currentUserId }) {
  console.log(isAbout, "isAbout, ");
  return auth ? (
    isAbout ? (
      <UserLayout />
    ) : (
      <Navigate to={`/complete-profile/${currentUserId}`} />
    )
  ) : (
    <Navigate to="/login" />
  );
}

export function PublicRoutes() {
  return <AuthLayout />;
}

export function CompleteProfileRoutes({ auth, isAbout }) {
  return auth ? (
    isAbout ? (
      <Navigate to="/feed" />
    ) : (
      <AuthLayout />
    )
  ) : (
    <Navigate to="/login" />
  );
}

export function UpdateAccountRoutes({
  auth,
  isAbout,
  isTalent,
  currentUserId,
}) {
  return auth ? (
    isAbout ? (
      isTalent ? (
        <Navigate to="/feed" />
      ) : (
        <UpdateAccountLayout />
      )
    ) : (
      <Navigate to={`/complete-profile/${currentUserId}`} />
    )
  ) : (
    <Navigate to="/login" />
  );
}
