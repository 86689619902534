import {
  followAction,
  rateProfileAction,
  unfollowAction,
} from "../ReduxToolKit/APIs/ConnectAPI";
import { getUserPersonalDetails } from "./getFetchFunctions";

export async function handleFollow({
  currentUserId,
  selectedId,
  dispatch,
  userId,
}) {
  try {
    const data = {
      userId: currentUserId,
      followingId: Boolean(selectedId) ? selectedId : userId,
    };
    const actionResult = await dispatch(followAction(data));
    const responseData = actionResult.payload;
    if (parseInt(responseData.status) === 7400) {
      getUserPersonalDetails(userId, currentUserId, dispatch);
    }
  } catch {}
}

export async function handleUnfollow({
  currentUserId,
  selectedId,
  dispatch,
  userId,
}) {
  try {
    const data = {
      userId: currentUserId,
      followingId: Boolean(selectedId) ? selectedId : userId,
    };
    const actionResult = await dispatch(unfollowAction(data));
    const responseData = actionResult.payload;
    if (parseInt(responseData.status) === 7400) {
      getUserPersonalDetails(userId, currentUserId, dispatch);
    }
  } catch {}
}

export async function handleRateProfile(
  currentUserId,
  dispatch,
  userId,
  ratingValue
) {
  try {
    const data = {
      userId: currentUserId,
      ratingUserId: userId,
      rating: ratingValue,
    };
    const actionResult = await dispatch(rateProfileAction(data));
    const responseData = actionResult.payload;
    if (parseInt(responseData.status) === 7400) {
      getUserPersonalDetails(userId, currentUserId, dispatch);
      console.log(responseData, "rate resposneesfsd");
    }
  } catch {}
}
