import React from "react";

function PlayIcon({ fill, width, height }) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width || "24px"}
      height={height || "24px"}
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill={fill}
        stroke="none"
      >
        <path
          d="M1005 5114 c-426 -69 -732 -386 -774 -804 -15 -138 -15 -3380 -1
-3505 14 -122 35 -194 86 -300 173 -363 586 -568 979 -486 148 31 171 42 700
348 66 39 356 206 645 373 289 166 818 471 1175 678 358 206 670 389 695 405
66 45 166 144 215 212 248 347 219 827 -70 1134 -27 29 -75 73 -105 96 -30 23
-404 244 -830 490 -426 246 -923 533 -1105 638 -181 104 -456 263 -610 352
-535 310 -535 310 -670 347 -69 19 -267 32 -330 22z"
        />
      </g>
    </svg>
  );
}

export default PlayIcon;
