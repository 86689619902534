import React, { useState } from "react";
import styled, { useTheme } from "styled-components";
import { useSelector } from "react-redux";

import Overview from "../Overview";
import Skills from "../Skills/Skills";
import AllPosts from "../AllPosts";
import SharedLinks from "../SharedLinks/SharedLinks";

const ProfileDetails = styled.main`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 650px) {
    margin-top: -5px;
  }
`;

const TabWrapper = styled.nav`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${({ isTalent }) => (isTalent ? "1rem" : "2.5rem 1rem")};
  @media screen and (max-width: 1450px) {
    top: 3.6rem;
  }
  @media screen and (max-width: 650px) {
    margin: 1rem;
  }
`;

const Content = styled.section`
  margin: 1rem 0;
  width: 100%;
  min-height: ${({ isTalent }) => (isTalent ? "65vh" : "40vh")};
  @media screen and (max-width: 1450px) {
    min-height: 50vh;
    margin: 0;
  }
  @media screen and (max-width: 950px) {
    padding: 0 1.2rem;
  }
`;

const ProfileTabs = () => {
  const theme = useTheme();
  const user = useSelector((state) => state.auth.user);

  const tabs = [
    {
      id: 0,
      title: "Overview",
      content: <Overview />,
    },
    { id: 1, title: "Skills", isTalent: true, content: <Skills /> },
    { id: 2, title: "All Posts", isTalent: true, content: <AllPosts /> },
    { id: 3, title: "Shared", content: <SharedLinks /> },
  ];

  const [currentTab, SetCurrentTab] = useState(tabs[0]);

  const Title = styled.h3`
    width: 10rem;
    padding: 8px 0;
    display: grid;
    place-items: center;
    cursor: pointer;
    position: relative;
    font-size: 16px;
    color: ${({ theme, active }) => (active ? theme.darkBlue : theme.main)};
    border-bottom: 1px solid
      ${({ active, theme }) => (active ? theme.darkBlue : theme.borderColor)};
    @media screen and (max-width: 1450px) {
      width: 10rem;
    }
    @media screen and (max-width: 700px) {
      width: 9rem;
      font-size: 14px;
    }
    @media screen and (max-width: 400px) {
      max-width: 5.5rem;
      /* font-size: 13px; */
    }
  `;

  return (
    <ProfileDetails>
      <TabWrapper isTalent={user?.isTalent} theme={theme}>
        {tabs.map((tab, index) => {
          return (
            <React.Fragment key={index}>
              {tab.isTalent ? (
                tab.isTalent === user?.isTalent && (
                  <Title
                    onClick={() => {
                      if (currentTab.id !== tab.id) {
                        SetCurrentTab(tab);
                      }
                    }}
                    active={currentTab.id === tab.id}
                  >
                    {tab.title}
                  </Title>
                )
              ) : (
                <Title
                  onClick={() => {
                    if (currentTab.id !== tab.id) {
                      SetCurrentTab(tab);
                    }
                  }}
                  active={currentTab.id === tab.id}
                >
                  {tab.title}
                </Title>
              )}
            </React.Fragment>
          );
        })}
      </TabWrapper>
      <Content isTalent={user?.isTalent}>{currentTab.content}</Content>
    </ProfileDetails>
  );
};

export default ProfileTabs;
