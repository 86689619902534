import { Link } from "react-router-dom";
import styled from "styled-components";

// <------------------------ Top Post Container ------------------------>

export const TopPostSection = styled.section``;
export const TopPostsWrapper = styled.section``;
export const TopPostCard = styled.article``;
export const TopPostMedia = styled.img``;
export const TopPostContentWrapper = styled.section``;
export const TopPostContent = styled.span``;
export const TopPostTitle = styled(Link)``;

export const PinnedPostLoader = styled.div`
  display: grid;
  place-items: center;
  width: 100%;
  height: 15rem;
`;

export const PinnedPostSection = styled.section`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 15px;
  gap: 1rem;
`;

export const PinnedPostWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
`;

export const PinnedPostNoContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* width: max-content; */
  p {
    position: relative;
    font-size: 14px;
    color: ${(props) => props.theme.main};
  }
  span {
    position: absolute;
    bottom: 35px;
    right: 60px;
    fill: ${(props) => props.theme.defaultLight};
  }
`;

export const PinnedPostCard = styled.div`
  width: ${({ width }) => (width ? width : "22rem")};
  height: ${({ height }) => (height ? height : "15rem")};
  width: 100%;
  max-width: 22rem;
  height: 22vh;
  color: white;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 1;
  cursor: pointer;
  overflow: hidden;
  border-radius: 15px;
  border: 1px solid ${(props) => props.theme.borderColor};
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      0deg,
      rgba(3, 6, 12, 0) 5%,
      rgba(59, 59, 59, 0) 95%
    );
    z-index: 2;
  }

  @media screen and (max-width: 1450px) {
    height: 32vh;
  }
`;

export const PinnedPostMedia = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 15px;
  -o-object-fit: cover;
  object-fit: cover;
`;

export const PinnedPostContentWrap = styled(Link)`
  position: absolute;
  width: ${({ width }) => (width ? width : "22rem")};
  height: ${({ height }) => (height ? height : "15rem")};
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  text-decoration: none;
  padding: 12px;
  background: none;
`;

export const PinnedPostContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 3px;
  background: ${(props) => props.theme.glassBg};
  padding: 8px;
  border-radius: 8px;
  width: 100%;
  backdrop-filter: blur(10px);
  z-index: 3;
`;

export const PinnedPostTitle = styled.h2`
  text-decoration: none;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  width: 20rem;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  color: ${(props) => props.theme.dark};
  &::first-letter {
    text-transform: uppercase;
  }
`;

export const PlayButton = styled.button`
  width: 2.8rem;
  height: 2.8rem;
  border-radius: 5rem;
  border: none;
  background: var(--light-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 8px;
  margin-left: 0.6rem;
`;

export const PlayButtonIcon = styled.img`
  width: 12px;
  object-fit: cover;
`;

export const StarRatingsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 16rem;
`;

// Common elements in user details / overview ------------------------>

export const DetailSectionContainer = styled.section`
  display: flex;
  width: 28rem;
  flex-direction: column;
  margin-bottom: 15px;
  @media screen and (max-width: 1450px) {
    width: 26rem;
  }
  @media screen and (max-width: 450px) {
    width: 100%;
  }
`;

export const UserDetailHeading = styled.div`
  margin-bottom: 5px;
  border-bottom: 2px solid ${(props) => props.theme.primary};
  fill: ${(props) => props.theme.dark};
  color: ${(props) => props.theme.dark};
  width: ${({ isWidthFull }) => (isWidthFull ? "100%" : "28rem")};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 3rem 0.5rem 0;
  span {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.8rem;
  }
  @media screen and (max-width: 1450px) {
    max-width: ${({ isWidthFull }) => (isWidthFull ? "100%" : "26rem")};
  }
  @media screen and (max-width: 650px) {
    width: 100%;
    padding: 0.5rem;
  }
`;

export const DetailLabel = styled.h3`
  min-width: 5rem;
  max-width: max-content;
  font-size: 13px;
  font-weight: 400;
  color: ${(props) => props.theme.dark};
  &::first-letter {
    text-transform: uppercase;
  }
`;

export const SingleDetailElem = styled.p`
  font-size: 13px;
  font-weight: 400;
  color: ${(props) => props.theme.main};
  text-align: justify;
  letter-spacing: 0.05px;
  max-width: 25rem;
  &::first-letter {
    text-transform: uppercase;
  }
  @media screen and (max-width: 650px) {
    max-width: 100%;
  }
`;

// <------------------------ Bio section ------------------------>

export const BioLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  margin-top: 0.8rem;
`;

// --------------- Language and Education section ---------------->

export const LEACSubheading = styled.h3`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.8rem;
  min-width: 5rem;
  max-width: 90%;
  margin: 10px 0;
  font-size: 13px;
  font-weight: 400;
  color: ${(props) => props.theme.dark};
  @media screen and (max-width: 650px) {
    max-width: 100%;
  }
`;

export const LAEActions = styled.div`
  position: absolute;
  right: ${({ isLang, isSingle }) =>
    isLang ? (isSingle ? "-2.8rem" : "-4.8rem") : "-3rem"};
  top: 5%;
  display: flex;
  flex-direction: ${({ isLang }) => (isLang ? "row" : "column")};
  align-items: center;
  padding: 5px;
  gap: ${({ isLang }) => (isLang ? "0.5rem" : "0.8rem")};
  color: ${(props) => props.theme.dark};
  opacity: 0;
  transition: opacity 0.3s, right 0.3s;
  span {
    display: grid;
    place-items: center;
    padding: 0;
    font-size: 1.5rem;
    cursor: pointer;
  }
  @media screen and (max-width: 650px) {
    opacity: 1;
    right: ${({ isLang, isSingle }) =>
      isLang ? (isSingle ? "-2.8rem" : "0.5rem") : "0.5rem"};
  }
`;

export const LangSingleElement = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 0 20px;
  width: 90%;
  margin: 4px 0;
  height: 2.5rem;
  border-radius: 8px;
  background-color: ${(props) => props.theme.mediumBg};

  &:hover ${LAEActions} {
    opacity: 1;
    right: ${({ isSingle }) => (isSingle ? "-2.2rem" : "-4.5rem")};
  }
  @media screen and (max-width: 650px) {
    width: 100%;
    padding: ${({ isEdit }) => (isEdit ? "0 5rem 0 20px" : "0 20px")};
    &:hover ${LAEActions} {
      opacity: 1;
      right: ${({ isSingle }) => (isSingle ? "-2.2rem" : "0.5rem")};
    }
  }
`;

export const EduAwardSingleElement = styled.div`
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  padding: 10px 20px;
  width: 90%;
  border-radius: 8px;
  margin-bottom: 10px;
  background-color: ${(props) => props.theme.mediumBg};

  &:hover ${LAEActions} {
    opacity: 1;
    right: -2.5rem;
  }
  @media screen and (max-width: 650px) {
    width: 100%;
    &:hover ${LAEActions} {
      right: 0.5rem;
    }
  }
`;

// <------------------------ My photos section ------------------------>

export const MyPhotosContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin: 1rem 0;
  @media screen and (max-width: 400px) {
    width: 22.2rem;
    flex-wrap: wrap;
  }
`;

export const MyPhotoWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
`;

export const MyPhotoImg = styled.img`
  height: 7rem;
  width: 7rem;
  min-height: 7rem;
  min-width: 7rem;
  border-radius: 8px;
  background: ${(props) => props.theme.mediumBg};
  object-fit: cover;
  -o-object-fit: cover;
  @media screen and (max-width: 450px) {
    height: 6.5rem;
    width: 6.5rem;
    min-height: 6.5rem;
    min-width: 6.5rem;
  }
`;

export const MyPhotoContent = styled.section`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  position: 8px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.defaultLight};
  font-size: 1.5rem;
  cursor: pointer;
  background: linear-gradient(
    0deg,
    rgba(5, 11, 24, 0.726),
    rgba(92, 92, 92, 0.1)
  );

  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.25s;
  transition-duration: 0.5s;
  &:hover {
    opacity: 1;
  }
  @media screen and (max-width: 950px) {
    opacity: ${({ isEdit }) => isEdit && 1};
  }
`;

// <------------------------ All posts section ------------------------>

export const AllPostWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

// <------------------------ All posts section ------------------------>

export const SocialMediaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const SocialMediaLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: start;
  fill: ${(props) => props.theme.main};
  color: ${(props) => props.theme.main};
  gap: 10px;
  text-decoration: none;
  width: max-content;
  &:hover {
    fill: ${(props) => props.theme.dark};
    color: ${(props) => props.theme.dark};
  }
`;
