import React from "react";

function CreatePostOutlineIcon({ fill, width, height }) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width || "24px"}
      height={height || "24px"}
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill={fill || "var(--dark-primary)"}
        stroke="none"
      >
        <path
          d="M1570 4879 c-449 -39 -801 -178 -1031 -408 -218 -218 -347 -523 -401
-951 -19 -152 -19 -1988 0 -2140 76 -602 302 -970 728 -1183 138 -69 362 -131
609 -168 114 -17 1846 -25 2045 -9 814 64 1260 410 1415 1098 56 249 58 280
62 1079 4 734 4 742 -16 782 -47 92 -166 122 -248 62 -68 -50 -73 -73 -73
-376 0 -146 -2 -265 -5 -265 -3 0 -36 25 -73 56 -83 69 -205 130 -297 149
-182 38 -379 -1 -527 -103 -24 -17 -261 -218 -528 -447 -341 -293 -501 -425
-540 -443 -45 -21 -70 -26 -135 -26 -100 0 -160 26 -260 113 -212 184 -495
226 -742 111 -34 -15 -273 -170 -532 -344 -258 -173 -475 -317 -482 -318 -15
-4 -32 60 -56 211 -17 106 -18 196 -18 1091 0 1075 -1 1064 65 1317 102 395
327 616 726 717 223 56 261 59 944 65 576 5 638 8 667 23 95 52 117 175 46
259 -51 60 -37 59 -670 58 -318 -1 -621 -5 -673 -10z m2712 -2632 c29 -15 127
-91 217 -168 l164 -142 -6 -236 c-13 -526 -103 -816 -317 -1031 -164 -163
-367 -249 -715 -301 -115 -17 -1818 -25 -2009 -9 -442 37 -710 147 -892 368
-30 36 -54 71 -54 77 0 6 51 45 113 87 644 436 899 605 940 620 65 25 171 24
240 -2 34 -13 82 -45 128 -85 82 -73 199 -136 299 -162 87 -23 244 -22 335 0
141 36 190 72 728 533 276 237 513 437 527 444 90 48 213 51 302 7z"
        />
        <path
          d="M3970 4819 c-29 -12 -67 -45 -83 -74 -8 -13 -14 -109 -17 -275 l-5
-255 -255 -5 c-173 -3 -262 -9 -276 -17 -37 -21 -83 -81 -90 -118 -3 -19 -3
-51 0 -70 7 -37 53 -97 90 -118 14 -8 103 -14 276 -17 l255 -5 5 -257 c5 -240
6 -258 26 -285 76 -103 224 -97 285 11 23 40 24 49 27 289 l3 247 240 0 c267
0 283 3 336 63 56 64 56 150 0 214 -53 60 -69 63 -337 63 l-240 0 0 228 c0
125 -5 244 -10 264 -13 44 -52 93 -91 113 -32 17 -102 19 -139 4z"
        />
        {/* <path
          d="M1722 3960 c-209 -55 -378 -217 -442 -425 -26 -83 -31 -231 -11 -315
41 -175 174 -343 332 -418 109 -52 163 -64 279 -63 149 0 259 37 377 124 79
59 137 131 184 232 45 97 59 160 59 268 0 101 -19 178 -67 277 -45 94 -167
220 -260 268 -134 70 -306 90 -451 52z m254 -337 c66 -22 145 -101 167 -167
51 -150 -22 -310 -166 -363 -18 -6 -64 -12 -103 -12 -56 -1 -80 4 -122 25
-156 77 -203 275 -101 419 72 100 203 140 325 98z"
        /> */}
      </g>
    </svg>
  );
}

export default CreatePostOutlineIcon;
