import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { StarIcon } from "../Assets/SVG";
import defaultImg from "../Assets/Images/default_profile_pic.png";

const TalCardWrap = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  text-decoration: none;
  color: ${(props) => props.theme.main};
  border: 1px solid ${(props) => props.theme.borderColor};
  border-radius: 10px;
  min-width: 7rem;
  height: 9rem;
  min-height: 9rem;
  padding: 10px;
  cursor: pointer;
  &:hover {
    background: ${(props) => props.theme.mediumBg};
    color: ${(props) => props.theme.dark};
  }
`;

const TalCardImg = styled.img`
  object-fit: cover;
  -o-object-fit: cover;
  width: 4rem;
  height: 4rem;
  min-width: 4rem;
  max-width: 4rem;
  min-height: 4rem;
  max-height: 4rem;
  border-radius: 100px;
  background: ${(props) => props.theme.mediumBg};
`;

const TalCardRating = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  width: max-content;
  font-size: 12px;
  gap: 5px;
  color: ${(props) => props.theme.postStarYellow};
  fill: ${(props) => props.theme.postStarYellow};
  background: ${(props) => props.theme.postStarBg};
  background: ${(props) => props.theme.topTalStarBg};
  padding: 1px 7px;
  span {
    display: grid;
    place-items: center;
    margin-top: 2px;
  }
`;

const TalCardUserName = styled.p`
  text-decoration: none;
  font-size: 13px;
  width: max-content;
  &::first-letter {
    text-transform: uppercase;
  }
`;

const TalentCard = ({ accountId, userName, profileImage, avgRating }) => {
  return (
    <TalCardWrap to={`/profile/${userName}/${accountId}`}>
      <TalCardImg
        src={profileImage === null ? defaultImg : profileImage}
        alt=""
      />
      <TalCardUserName>{userName}</TalCardUserName>
      <TalCardRating>
        <StarIcon width={"11px"} />
        {avgRating === null
          ? "N/A"
          : (Math.round(avgRating * 10) / 10).toFixed(1)}
      </TalCardRating>
    </TalCardWrap>
  );
};

export default TalentCard;
