import React from "react";

function CheckIcon({ fill, width, height }) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width || "24px"}
      height={height || "24px"}
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill={fill}
        stroke="none"
      >
        <path
          d="M4218 4041 c-31 -10 -79 -35 -105 -54 -26 -20 -518 -506 -1093 -1081
l-1045 -1046 -490 489 c-269 269 -503 496 -520 504 -53 25 -133 40 -188 33
-189 -24 -327 -207 -287 -383 7 -32 21 -76 32 -98 13 -26 243 -263 646 -666
595 -595 630 -628 687 -650 81 -31 171 -30 245 1 50 21 176 144 1267 1233
1092 1091 1215 1217 1238 1269 35 78 41 141 21 213 -31 104 -92 176 -186 219
-77 35 -151 41 -222 17z"
        />
      </g>
    </svg>
  );
}

export default CheckIcon;
