import React from "react";

function TheaterOffIcon({ fill, width, height }) {
  return (
    <svg
      version="1.0"
      width={width || "24px"}
      height={height || "24px"}
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill={fill || "var(--dark-primary)"}
        stroke="none"
      >
        <path
          d="M683 4145 c-175 -47 -317 -196 -352 -367 -8 -37 -11 -445 -9 -1354
l3 -1301 30 -49 c19 -30 49 -60 79 -79 l49 -30 1941 -3 c1368 -1 1957 1 1994
9 174 36 321 178 367 356 23 87 22 2380 0 2468 -45 172 -179 305 -352 350 -84
22 -3669 21 -3750 0z m3723 -331 c15 -11 37 -33 48 -48 21 -27 21 -31 21
-1206 0 -1175 0 -1179 -21 -1206 -11 -15 -33 -37 -48 -48 -27 -21 -36 -21
-897 -24 l-869 -2 0 581 c0 428 -3 593 -12 623 -18 60 -100 139 -160 154 -34
9 -286 12 -937 12 l-891 0 0 529 c0 351 4 539 11 558 14 37 47 73 84 89 25 11
346 13 1837 11 l1807 -2 27 -21z m-2055 -1463 c18 -22 19 -46 19 -547 l0 -524
-814 2 c-805 3 -815 3 -842 24 -15 11 -37 33 -48 48 -20 27 -21 40 -24 527
l-3 499 847 -2 846 -3 19 -24z"
        />
      </g>
    </svg>
  );
}

export default TheaterOffIcon;
