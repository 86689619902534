import React, { useState } from "react";
import {
  DropdownList,
  DropdownListItem,
  PeopleTagWrapper,
  UserNameWrapper,
} from "./InputStyle";
import { useDispatch } from "react-redux";
import { getPeopleTags } from "../../Functions/getFetchFunctions";
import { UserNameDpWrapper, UserProfileThumb } from "../Modals/ModalStyles";
import defaultImg from "../../Assets/Images/default_profile_pic.png";
import FormInput from "./FormInput";

const PeopleTag = ({
  values,
  setValues,
  options,
  isScrBottom,
  isRelative,
  handleTag,
}) => {
  const currentUserId = localStorage.getItem("currentUserId");
  const dispatch = useDispatch();

  const [profileList, setProfileList] = useState([]);
  const [typingVal, setTypingVal] = useState("");

  const handleChange = async (e) => {
    let filter = e.target.value;
    setTypingVal(filter);
    getPeopleTags(currentUserId, filter, dispatch, setProfileList);
  };

  const handleClick = (item) => {
    handleTag(item);
    setTypingVal("");
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <FormInput
        name="peopleTag"
        placeholder="Tag People"
        onChange={handleChange}
        value={typingVal}
      />

      <PeopleTagWrapper isRelative={isRelative}>
        {typingVal && profileList.length > 0 && (
          <DropdownList isScrBottom={isScrBottom}>
            {profileList
              .filter(
                (option) =>
                  !options.some((selected) => selected._id === option._id)
              )
              .map((item, index) => (
                <DropdownListItem key={index} onClick={() => handleClick(item)}>
                  <UserNameDpWrapper>
                    <UserProfileThumb
                      bgPosition={item.personalInfo.profileImage === null}
                      isTag={true}
                      style={{
                        backgroundImage: `url(${
                          item.personalInfo.profileImage === null
                            ? defaultImg
                            : item.personalInfo.profileImage
                        })`,
                      }}
                    />
                    <UserNameWrapper>
                      <p>{item.personalInfo.firstName}</p>
                      <p>{item.personalInfo.lastName}</p>
                    </UserNameWrapper>
                  </UserNameDpWrapper>
                </DropdownListItem>
              ))}
          </DropdownList>
        )}
      </PeopleTagWrapper>
    </div>
  );
};

export default PeopleTag;
