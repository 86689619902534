import React from "react";
import styled from "styled-components";

const RadialBarWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 9rem;
  height: 8rem;
  position: relative;
  p {
    color: ${(props) => props.theme.main};
    font-size: 13px;
    margin-bottom: 5px;
  }
`;

const SVG = styled.svg`
  transform: rotate(-90deg);
  width: 10rem;
  height: 10rem;
`;

const ProgressBarPath = styled.circle`
  stroke: ${(props) => props.theme.mediumBg};
  stroke-width: 8;
  stroke-linecap: round;
  fill: transparent;
`;

const RadialPath = styled.circle`
  stroke: ${(props) => props.theme.yellow};
  stroke-width: 8;
  stroke-linecap: round;
  fill: transparent;
  stroke-dasharray: ${({ strokeDasharray }) =>
    strokeDasharray && strokeDasharray};
  stroke-dashoffset: ${({ strokeDashoffset }) =>
    strokeDashoffset && strokeDashoffset};
`;

const AvgRatingVal = styled.span`
  position: absolute;
  top: 30%;
  color: ${(props) => props.theme.dark};
  font-size: 1.2rem;
`;

const RadialBar = ({ avgRating, peopleCount }) => {
  const percentage = (avgRating / 5) * 100;
  const dashArray = Math.PI * 100; // Assuming the circle has a radius of 50
  const dashOffset = dashArray - (dashArray * percentage) / 100;

  return (
    <RadialBarWrap>
      <SVG x="0px" y="0px" viewBox="0 0 200 120">
        <ProgressBarPath cx="100" cy="60" r="50"></ProgressBarPath>
        <RadialPath
          cx="100"
          cy="60"
          r="50"
          strokeDasharray={dashArray}
          strokeDashoffset={dashOffset}
        ></RadialPath>
      </SVG>
      <AvgRatingVal>{avgRating === 0 ? "N/A" : avgRating}</AvgRatingVal>
      <p>{peopleCount}&nbsp;Ratings</p>
    </RadialBarWrap>
  );
};

export default RadialBar;
