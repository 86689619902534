import React from "react";
import { Parah } from "../ContentsStyles";
import useHandleEmailClick from "../../Hooks/useEmail";

const TAC_10 = () => {
  const handleEmailClick = useHandleEmailClick;

  return (
    <>
      <Parah id="contactUs">
        You can contact us here:{" "}
        <p
          onClick={handleEmailClick}
          style={{
            color: " #ff2146f0",
            cursor: " pointer",
          }}
        >
          Share your feedback
        </p>
      </Parah>
      <Parah>
        In some countries, the law requires us to provide additional regulatory
        information and/or contact points within the country, even though we may
        not be physically present there.
        {/* You can find this information here */}
      </Parah>
    </>
  );
};

export default TAC_10;
