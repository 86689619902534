import React from "react";

function PinIcon({ fill, width, height }) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width || "24px"}
      height={height || "24px"}
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill={fill || "var(--dark-primary)"}
        stroke="none"
      >
        <path
          d="M3484 5106 c-36 -16 -97 -84 -126 -140 -59 -115 -69 -264 -25 -378
l25 -66 -746 -640 -746 -641 -35 28 c-137 107 -333 153 -501 117 -154 -32
-342 -156 -359 -236 -17 -80 -22 -74 443 -539 240 -240 436 -440 436 -446 0
-5 -229 -239 -508 -520 -647 -650 -752 -765 -1234 -1362 -37 -45 -71 -98 -77
-118 -27 -100 75 -192 170 -152 33 13 294 221 545 434 254 215 465 413 947
892 l498 495 418 -418 419 -419 51 -5 c62 -6 91 11 154 88 94 114 140 243 140
390 0 144 -35 254 -119 373 l-34 47 632 737 c348 405 636 741 640 746 4 6 27
1 60 -13 65 -29 213 -39 286 -20 114 31 232 117 250 183 23 83 48 55 -726 830
-394 395 -729 727 -743 737 -35 26 -97 33 -135 16z"
        />
      </g>
    </svg>
  );
}

export default PinIcon;
