const theme = {
  light: {
    mode: "light",
    primary: "#ea3148",
    secondary: "#0b1e49",

    // background colors
    darkBg: "#051030",
    mainBg: "#fafafa",
    TransparentMainBg: "#fcfcfc1f",
    mediumBg: "#e6e6e6",
    altBg: "#051030",
    glassBg: "rgba(252, 252, 252, 0.88)",
    // hoverBg: "#24244b",
    hoverBg: "#2e2e4d",
    inputBg: "#292d50",
    modalBg: "rgba(13, 18, 29, 0.9)",
    lightGrayBg: "#aaaaaa",
    otpBg: "rgba(13, 18, 29, 0.911)",

    // neutral colors
    dark: "#0c0c0f",
    defaultDark: "#0c0c0f",
    main: "#747474",
    defaultMain: "#747474",
    mediumMain: "#c7c7c7",
    defaultMediumMain: "#c7c7c7",
    light: "#fafafa",
    defaultLight: "#fafafa",

    // other colors
    yellow: "#ffb100",
    red: "#eb2727",
    darkBlue: "#0b1e49",
    placeholder: "#636363",
    borderColor: "#dddddd",
    sidebarText: "#a7a7a7",
    darkGray: "#979797",
    green: "#5fdb4f",
    postStarYellow: "#eb2727",
    postStarBg: "rgba(235, 39, 39, 0.06)",
    topTalStarBg: "rgba(235, 39, 39, 0.06)",
    hashTags: "#172e66",
    // off & on color for social media icons
    offSIcon: "#b3b3b3",
  },
  dark: {
    mode: "dark",
    primary: "#c92437",
    secondary: "#0b1e49",

    // background colors
    mainBg: "#1c1c24",
    // mainBg: "#0f1115",
    mediumBg: "#282831",
    darkBg: "#f0f0f0",
    altBg: "#09080a",
    glassBg: "rgba(28, 28, 36, 0.8)",
    hoverBg: "#1c1c24",
    inputBg: "#292d50",
    modalBg: "rgba(0, 0, 0, 0.8)",
    lightGrayBg: "#636363",
    otpBg: "rgba(13, 18, 29, 0.808)",

    // neutral colors
    dark: "#fbfbfb",
    defaultDark: "#0c0c0f",
    main: "#8f8f8f",
    defaultMain: "#747474",
    mediumMain: "#444450",
    defaultMediumMain: "#c7c7c7",
    light: "#0c0c0f",
    defaultLight: "#fafafa",

    // other colors
    yellow: "#ffb100",
    red: "#eb2727",
    darkBlue: "#d4d4d4",
    placeholder: "#636363",
    borderColor: "#444450",
    sidebarText: "#8f8f8f",
    darkGray: "#444450",
    green: "#00ad57",
    postStarYellow: "#ffb100",
    postStarBg: "rgba(255, 187, 0, 0.1)",
    topTalStarBg: "rgba(255, 187, 0, 0.2)",
    hashTags: "#6e8dd6",
    // off color for social media icons
    offSIcon: "#444450",
  },
};

export default theme;

// 0f1115 - gray
