import React from "react";
import {
  ActionItems,
  ActionMenuContainer,
  PinnedPostActionBtn,
} from "./PostStyle";
import { PinIcon, WarningIcon } from "../../Assets/SVG";
import { DotsBtn, DotsBtnwrap } from "../../Pages/Profile/ProfileStyle";
import { useModalOpen } from "../../Hooks/useEventHandler";
import { AiOutlineLink } from "react-icons/ai";
import { MdDelete, MdModeEdit } from "react-icons/md";
import { BsThreeDots } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { setAlertDetails } from "../../ReduxToolKit/Slices/utilsSlice";
import { TbDotsVertical } from "react-icons/tb";

const ActionMenu = ({
  postId,
  authorId,
  isBestWork,
  forPost,
  forMDPost,
  forUserAllPost,
  forShare,
  forProfile,
  forPinned,
  onCopyLink,
  onDelete,
  onPin,
  top,
  right,
}) => {
  const { isOpen, setIsOpen, clickOutRef } = useModalOpen();
  const currentUserId = localStorage.getItem("currentUserId");
  let selectedPostId = postId;
  const dispatch = useDispatch();

  const handleActionMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleCopyLink = (id) => {
    if (forPost || forPinned || forMDPost || forUserAllPost) {
      let linkToCopy = process.env.REACT_APP_API_URL + "/post/" + id;
      onCopyLink && onCopyLink(linkToCopy);
    } else if (forShare) {
      let linkToCopy = process.env.REACT_APP_API_URL + "/share/" + id;
      onCopyLink && onCopyLink(linkToCopy);
    } else if (forProfile) {
      let linkToCopy = process.env.REACT_APP_API_URL + "/profile/" + id;
      onCopyLink && onCopyLink(linkToCopy);
    }

    dispatch(
      setAlertDetails({
        alertDetails: {
          isOpen: true,
          type: "success",
          message: "Link copied to clipboard",
        },
      })
    );
    setIsOpen(!isOpen);
  };

  const handlePinClick = (isBestWork) => {
    if (forPost) {
      let forThis = "forPost";
      onPin && onPin(isBestWork, authorId, postId, forThis);
    } else if (forUserAllPost) {
      let forThis = "forUserAllPost";
      onPin && onPin(isBestWork, authorId, postId, forThis);
    } else if (forPinned) {
      let forThis = "forPinned";
      onPin && onPin(isBestWork, authorId, postId, forThis);
    }
    setIsOpen(!isOpen);
  };

  const handleDelete = () => {
    if (forPost) {
      let forThis = "forFeedPost";
      onDelete && onDelete(authorId, postId, forThis);
    } else if (forUserAllPost) {
      let forThis = "forUserAllPost";
      onDelete && onDelete(authorId, postId, forThis);
    } else if (forPinned) {
      let forThis = "forPinned";
      onDelete && onDelete(authorId, postId, forThis);
    }
    setIsOpen(!isOpen);
  };

  return (
    <div
      ref={clickOutRef}
      style={{
        display: "grid",
        placeItems: "center",
      }}
    >
      {forPost && (
        <DotsBtnwrap
          onClick={handleActionMenu}
          forPost={forPost}
          forMDPost={forMDPost}
        >
          {/* <DotsBtn /> */}
          <TbDotsVertical />
        </DotsBtnwrap>
      )}
      {forPinned && (
        <PinnedPostActionBtn onClick={handleActionMenu}>
          <BsThreeDots />
        </PinnedPostActionBtn>
      )}
      {forUserAllPost && (
        <DotsBtnwrap onClick={handleActionMenu} forPost={true}>
          <DotsBtn />
        </DotsBtnwrap>
      )}
      {isOpen && postId === selectedPostId ? (
        <ActionMenuContainer top={top} right={right}>
          {authorId === currentUserId ? (
            <>
              <ActionItems onClick={() => handlePinClick(isBestWork)}>
                <PinIcon width={"16px"} />
                <span>{isBestWork ? "Remove Pin" : "Pin on Profile"}</span>
              </ActionItems>
              <ActionItems onClick={() => handleCopyLink(postId)}>
                <AiOutlineLink />
                <span>Copy Link</span>
              </ActionItems>
              <ActionItems>
                <MdModeEdit />
                <span>Edit Post</span>
              </ActionItems>
              <ActionItems isRed={true} onClick={() => handleDelete(postId)}>
                <MdDelete />
                <span>Delete Post</span>
              </ActionItems>
            </>
          ) : (
            <>
              <ActionItems onClick={() => handleCopyLink(postId)}>
                <AiOutlineLink />
                <span>Copy Link</span>
              </ActionItems>
              <ActionItems isRed={true}>
                <WarningIcon width={"16px"} />
                <span>Report</span>
              </ActionItems>
            </>
          )}
        </ActionMenuContainer>
      ) : null}
    </div>
  );
};

export default ActionMenu;
