import React from "react";
import { StarIcon } from "../../Assets/SVG";
import styled, { useTheme } from "styled-components";

const StarWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${({ gap }) => (gap ? gap : "0.4rem")};
`;

const StarRating = ({
  values,
  onClick,
  width,
  gaps,
  postId,
  postOwnerId,
  toThe,
}) => {
  const theme = useTheme();

  const handleStarRating = (ratingValue) => {
    if (values !== ratingValue) {
      if (toThe === "profile") {
        onClick && onClick(ratingValue);
      } else {
        onClick && onClick(ratingValue, postId, postOwnerId);
      }
    }
  };

  return (
    <StarWrapper gap={gaps}>
      {[...Array(5)].map((star, i) => {
        const ratingValue = i + 1;
        return (
          <label style={{ cursor: "pointer" }} key={i}>
            <input
              type="radio"
              name="rating"
              style={{ display: "none" }}
              // value={values ? values : ratingValue}
              value={values}
              onClick={() => handleStarRating(ratingValue)}
            />
            <StarIcon
              width={width ? width : "21px"}
              fill={ratingValue <= values ? theme.yellow : theme.mediumMain}
            />
          </label>
        );
      })}
    </StarWrapper>
  );
};

export default StarRating;
