import React from "react";

function SaveIcon({ fill, width, height }) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width || "24px"}
      height={height || "24px"}
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill={fill || "var(--dark-primary)"}
        stroke="none"
      >
        <path
          d="M1676 5040 c-525 -32 -821 -136 -1034 -365 -214 -228 -300 -502 -332
-1059 -13 -235 -13 -2523 0 -2716 30 -424 114 -608 338 -739 98 -57 181 -82
292 -88 118 -7 190 10 315 73 160 80 316 202 775 604 370 324 409 350 530 350
118 0 170 -34 525 -346 533 -467 670 -569 865 -648 69 -28 85 -31 195 -31 100
0 132 4 190 23 154 52 299 167 362 287 45 85 81 218 101 370 15 117 17 264 17
1490 0 1431 -2 1511 -46 1774 -49 295 -133 486 -285 650 -81 88 -171 154 -289
212 -154 75 -343 122 -610 150 -133 14 -1701 21 -1909 9z m1764 -350 c385 -27
579 -80 727 -199 189 -152 265 -381 293 -879 13 -237 13 -2513 0 -2706 -22
-317 -65 -412 -210 -467 -172 -65 -282 3 -940 581 -297 260 -428 357 -542 398
-65 24 -87 26 -208 26 -121 0 -143 -2 -208 -26 -115 -42 -246 -139 -570 -422
-435 -381 -575 -491 -703 -550 -66 -32 -138 -34 -209 -7 -147 56 -188 149
-210 482 -15 223 -7 2722 9 2864 28 248 68 404 134 529 18 34 64 93 103 133
146 147 354 214 749 242 172 12 1612 13 1785 1z"
        />
      </g>
    </svg>
  );
}

export default SaveIcon;
