import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTheme } from "styled-components";
import { IoIosArrowForward } from "react-icons/io";
import {
  EmptyFolderWrapper,
  SavedContainer,
  SavedFolderContainer,
  SavedTitle,
} from "./SavedStyles";
import { getSavedFolderPosts } from "../../Functions/getFetchFunctions";
import { PostVertiOverview } from "../../Components";
import EmptyFolder from "../../Assets/SVGImages/EmptyFolder";
import { SaveIcon } from "../../Assets/SVG";

const SavedFolder = () => {
  const { folderName, folderId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [folderItems, setFolderItems] = useState([]);
  const currentUserId = localStorage.getItem("currentUserId");
  const theme = useTheme();
  // const backNavigate = useSelector((state) => state.utils.backNavigate);

  useEffect(() => {
    getSavedFolderPosts(
      folderId, //collectionId
      currentUserId,
      1,
      10,
      dispatch,
      setFolderItems
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folderId]);

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <SavedContainer>
      <Helmet>
        <title>
          {folderName ? `${folderName} | Saved Collection` : "Saved Collection"}
        </title>
      </Helmet>
      <SavedTitle>
        <h2 onClick={handleBack}>Saved Collection</h2>
        <span>
          <IoIosArrowForward />
        </span>
        {folderName && folderName}
      </SavedTitle>
      <SavedFolderContainer>
        {folderItems.length > 0 ? (
          <>
            {folderItems.map((item, index) => (
              <PostVertiOverview
                key={index}
                mediaType={item.media[0].mediaType}
                postType={item.media[0].type}
                postId={item._id}
                sourceUrl={item.media[0].url}
                authorId={item.author._id}
                firstName={item.author.firstName}
                lastName={item.author.lastName}
                userName={item.author.userName}
                profileImg={item.author.profileImage}
              />
            ))}
          </>
        ) : (
          <EmptyFolderWrapper>
            <div style={{ position: "relative" }}>
              <span>
                <SaveIcon width={"18px"} height={"18px"} />
              </span>
              <EmptyFolder
                pmClr={theme.primaryColor}
                terClr={theme.mediumMain}
              />
            </div>
            No Posts Saved in this Folder
          </EmptyFolderWrapper>
        )}
      </SavedFolderContainer>
    </SavedContainer>
  );
};

export default SavedFolder;

/* <SavedItemWrap key={index}>
            {item.media[0].mediaType === "image" ? (
              <SavedItemLink
                to={`/post/${item._id}`}
                onClick={() =>
                  dispatch(setBackNavigate({ backNavigate: backNavigate + 1 }))
                }
              >
                <Image100 src={item.media[0].url} alt="" br={"15px"} />
              </SavedItemLink>
            ) : (
              <SavedItemLink to={`/post/${item._id}`}>
                <Video100
                  src={item.media[0].url}
                  alt=""
                  loop
                  autoPlay
                  muted
                  br={"15px"}
                />
              </SavedItemLink>
            )}
            <UserNameDpWrapper
              to={`/${item.author.firstName}_${item.author.lastName}/${item.author._id}`}
              onClick={() =>
                dispatch(setBackNavigate({ backNavigate: backNavigate + 1 }))
              }
            >
              <UserProfileThumb
                width={"1.8rem"}
                height={"1.8rem"}
                bgSize={"1.8rem"}
                bgPosition={item.author.profileImage === null}
                style={{
                  backgroundImage: `url(${
                    item.profileImage === null
                      ? defaultImg
                      : item.author.profileImage
                  })`,
                }}
              />
              <p>{item.author.userName}</p>
            </UserNameDpWrapper>
          </SavedItemWrap> */
