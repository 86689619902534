import React from "react";
import {
  RadioCircle,
  RadioInputContainer,
  RadioLabel,
  RadioTypeInput,
  TickIcon,
} from "./InputStyle";

const RadioInput = ({ label, name, checked, isCheckBox, onChange, value }) => {
  return (
    <RadioInputContainer>
      <RadioTypeInput
        type={isCheckBox ? "checkbox" : "radio"}
        name={name}
        onChange={onChange}
        value={value}
      />
      <RadioCircle>{checked && <TickIcon />}</RadioCircle>
      <RadioLabel>{label}</RadioLabel>
    </RadioInputContainer>
  );
};

export default RadioInput;
