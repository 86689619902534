import React from "react";
import {
  AddedEditDeleteBtnWrapper,
  AddedEducationWrapper,
  AddedInfoCard,
  AddedInfoTitle,
  AddedInfoViewer,
  AddedLanguageWrapper,
  AddedSkillHeader,
  EmptyAddedContainer,
} from "./TalentsInfoStyle";
import { MdDelete, MdModeEdit } from "react-icons/md";
import {
  DetailLabel,
  SingleDetailElem,
} from "../../Pages/Profile/Overview/OverviewStyle";
import {
  SkillCardContent,
  SkillCardContentWrapper,
  SkillCardLabel,
  SkillCardLevel,
  SkillDescription,
  SkillDetailsWrapper,
} from "../../Pages/Profile/Skills/SkillsStyle";
import {
  AtomicIcon,
  AwardsIcon,
  GraduationIcon,
  LanguageIcon,
} from "../../Assets/SVG";

const AddedInfoSection = ({
  addedInfoValues,
  addedType,
  handleItemDelete,
  addedInfoValuesTwo,
  handleAddedLangDelete,
  handleAddedEduDelete,
  handleAddedAwardDelete,
}) => {
  return (
    <AddedInfoViewer>
      {addedType === "addedSkill" && (
        <>
          <AddedInfoTitle>
            {addedType === "addedSkill" && "Added Skills"}
            <span>
              {addedInfoValues.length < 10 && "0"}
              {addedInfoValues.length}
            </span>
          </AddedInfoTitle>
          {addedInfoValues.length > 0 ? (
            addedInfoValues.map((item, index) => (
              <AddedInfoCard key={index}>
                <AddedSkillHeader>
                  <p>{item.mainCategory}</p>
                  <AddedEditDeleteBtnWrapper>
                    <MdModeEdit />
                    <MdDelete onClick={() => handleItemDelete(index)} />
                  </AddedEditDeleteBtnWrapper>
                </AddedSkillHeader>
                <SkillCardContentWrapper>
                  <DetailLabel>{item.subCategory}</DetailLabel>

                  <SkillDetailsWrapper>
                    {item.skillsHave.map((skill, index) => (
                      <SkillCardContent key={index}>
                        <SkillCardLabel>{skill.skill}</SkillCardLabel>
                        <SkillCardLevel>{skill.level}</SkillCardLevel>
                      </SkillCardContent>
                    ))}
                  </SkillDetailsWrapper>

                  <SkillDetailsWrapper>
                    <SkillCardLabel>Description</SkillCardLabel>
                    <SkillDescription>{item.skillDescription}</SkillDescription>
                  </SkillDetailsWrapper>
                </SkillCardContentWrapper>
              </AddedInfoCard>
            ))
          ) : (
            <EmptyAddedContainer>
              <AtomicIcon height={"42px"} width={"42px"} />
              No skills added yet
            </EmptyAddedContainer>
          )}
        </>
      )}

      {addedType === "addedLang" && (
        <>
          <AddedInfoTitle>
            {addedType === "addedLang" && "Added Languages"}
            <span>
              {addedInfoValues.length < 10 && "0"}
              {addedInfoValues.length}
            </span>
          </AddedInfoTitle>
          {addedInfoValues.length > 0 ? (
            addedInfoValues.map((item, index) => (
              <AddedInfoCard key={index}>
                <AddedLanguageWrapper>
                  <DetailLabel>{item.language}</DetailLabel>
                  <section>
                    <SingleDetailElem>{item.level}</SingleDetailElem>
                    <AddedEditDeleteBtnWrapper>
                      <MdModeEdit />
                      <MdDelete onClick={() => handleAddedLangDelete(index)} />
                    </AddedEditDeleteBtnWrapper>
                  </section>
                </AddedLanguageWrapper>
              </AddedInfoCard>
            ))
          ) : (
            <EmptyAddedContainer height={"12rem"}>
              <LanguageIcon height={"42px"} width={"42px"} />
              No languages added yet
            </EmptyAddedContainer>
          )}
        </>
      )}

      {addedType === "addedLang" && (
        <>
          <div style={{ marginTop: "1rem" }}></div>
          <AddedInfoTitle>
            Added Education Details
            <span>
              {addedInfoValuesTwo.length < 10 && "0"}
              {addedInfoValuesTwo.length}
            </span>
          </AddedInfoTitle>
          {addedInfoValuesTwo.length > 0 ? (
            addedInfoValuesTwo.map((item, index) => (
              <AddedInfoCard key={index}>
                <AddedEducationWrapper>
                  <header>
                    <DetailLabel>{item.course}</DetailLabel>
                    <AddedEditDeleteBtnWrapper>
                      <MdModeEdit />
                      <MdDelete onClick={() => handleAddedEduDelete(index)} />
                    </AddedEditDeleteBtnWrapper>
                  </header>

                  <SingleDetailElem>{item.institution}</SingleDetailElem>
                  <SingleDetailElem>
                    {item.from} - {item.to}
                  </SingleDetailElem>
                </AddedEducationWrapper>
              </AddedInfoCard>
            ))
          ) : (
            <EmptyAddedContainer height={"12rem"}>
              <GraduationIcon height={"42px"} width={"42px"} />
              No Education added yet
            </EmptyAddedContainer>
          )}
        </>
      )}

      {addedType === "addedAward" && (
        <>
          <AddedInfoTitle>
            Added Awards or Certificates Details
            <span>
              {addedInfoValues.length < 10 && "0"}
              {addedInfoValues.length}
            </span>
          </AddedInfoTitle>
          {addedInfoValues.length > 0 ? (
            addedInfoValues.map((item, index) => (
              <AddedInfoCard key={index}>
                <AddedEducationWrapper>
                  <header>
                    <DetailLabel>{item.awardName}</DetailLabel>
                    <AddedEditDeleteBtnWrapper>
                      <MdModeEdit />
                      <MdDelete onClick={() => handleAddedAwardDelete(index)} />
                    </AddedEditDeleteBtnWrapper>
                  </header>

                  <SingleDetailElem>{item.givenBy}</SingleDetailElem>
                  <SingleDetailElem>{item.when}</SingleDetailElem>
                </AddedEducationWrapper>
              </AddedInfoCard>
            ))
          ) : (
            <EmptyAddedContainer height={"12rem"}>
              <AwardsIcon height={"42px"} width={"42px"} />
              No Awards or Certificates added yet
            </EmptyAddedContainer>
          )}
        </>
      )}
    </AddedInfoViewer>
  );
};

export default AddedInfoSection;
