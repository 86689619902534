import React, { useEffect, useState } from "react";
import { AwardsIcon } from "../../../../Assets/SVG";
import {
  DetailSectionContainer,
  EduAwardSingleElement,
  SingleDetailElem,
  UserDetailHeading,
  DetailLabel,
  LAEActions,
} from "../OverviewStyle";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getTalentAward } from "../../../../Functions/getFetchFunctions";
import { AddBtn } from "../../../../Components/Common/CommonStyle";
import { BiPlus } from "react-icons/bi";
import { MdDelete, MdModeEdit } from "react-icons/md";
import { useModalOpen } from "../../../../Hooks/useEventHandler";
import { AddProInfoModal } from "../../../../Components";
import { EmptyModalDataWrap } from "../../../../Components/Modals/ModalStyles";

const AwardsCertificates = () => {
  const { userId } = useParams();
  const dispatch = useDispatch();
  const currentUserId = localStorage.getItem("currentUserId");
  let isEdit = currentUserId === userId;

  const { isOpen, setIsOpen } = useModalOpen();
  const [type, setType] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [awardArray, setAwardArray] = useState([]);

  useEffect(() => {
    getTalentAward(userId, dispatch, setAwardArray);
  }, [userId, dispatch]);

  const handleCrudModalOpen = (text, index) => {
    setIsOpen(true);
    setType(text);
    setSelectedIndex(index);
  };

  return (
    <DetailSectionContainer>
      <UserDetailHeading style={{ marginBottom: "1rem" }}>
        <span>
          <AwardsIcon /> Awards & Certificates
        </span>
        {isEdit && (
          <AddBtn
            onClick={() => handleCrudModalOpen("awards", awardArray.length)}
          >
            <BiPlus />
            <p>Add</p>
          </AddBtn>
        )}
      </UserDetailHeading>
      {awardArray.length > 0 ? (
        awardArray.map((item, index) => (
          <EduAwardSingleElement key={index}>
            <DetailLabel>{item.name}</DetailLabel>
            <SingleDetailElem>{item.givenBy}</SingleDetailElem>
            <SingleDetailElem>{item.year}</SingleDetailElem>
            {isEdit && (
              <LAEActions>
                <span onClick={() => handleCrudModalOpen("awardEdit", index)}>
                  <MdModeEdit />
                </span>
                <span onClick={() => handleCrudModalOpen("awardDelete", index)}>
                  <MdDelete />
                </span>
              </LAEActions>
            )}
          </EduAwardSingleElement>
        ))
      ) : (
        <EmptyModalDataWrap height="10rem">
          <AwardsIcon width="2.6rem" height="2.6rem" />
          No Awards or Cetificate Added
        </EmptyModalDataWrap>
      )}
      {(type === "awards" || type === "awardEdit") && isOpen && (
        <AddProInfoModal
          type={type}
          awardArray={awardArray}
          setAwardArray={setAwardArray}
          setIsOpen={setIsOpen}
          selectedIndex={selectedIndex}
        />
      )}
    </DetailSectionContainer>
  );
};

export default AwardsCertificates;
