import React from "react";

const Home = () => {
  return (
    <div
      style={{
        minHeight: "100vh",
        display: "grid",
        placeItems: "center",
        background: "#0b1e49",
        color: "white",
      }}
    >
      Home page design development in progress
    </div>
  );
};

export default Home;
