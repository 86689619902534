import { Link } from "react-router-dom";
import styled from "styled-components";

// <<--------------------- Register and Login Screen Section ------------------>>

export const AuthPage = styled.main`
  width: 100%;
  min-height: 100vh;
  /* height: fit-content; */
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10%;
  background: var(--d-blue);
  /* transition: all 0.3s ease-in; */
  @media screen and (max-width: 700px) {
    justify-content: center;
    padding: 0;
  }
`;

export const AuthForm = styled.form`
  padding: 2rem;
  display: flex;
  border-radius: 15px;
  /* margin-right: 4rem; */
  margin-top: 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  /* border: 1px solid ${(props) => props.theme.mediumMain}; */
  h1 {
    color: var(--white);
    width: 100%;
  }
  h4 {
    color: var(--white);
    width: 100%;
  }
`;

export const AuthPasswordWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-end;
  height: auto;
`;

export const AuthPasswordIconWrapper = styled.span`
  position: absolute;
  top: 0.8rem;
  right: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  width: 2.5rem;
  border-radius: 5px;
  /* border: 1px solid red; */
  background: var(--input-bg);
  z-index: 0;
  color: var(--placeholder-color);
`;

export const PasswordEyeIcon = styled.i`
  display: flex;
  cursor: pointer;
`;

export const AuthError = styled.div`
  color: var(--yellow);
  display: flex;
  align-items: flex-start;
  font-size: 12px;
  width: 16rem;
  margin-top: 2px;
`;

export const AuthButton = styled.button`
  width: 16rem;
  height: 2.2rem;
  margin: 1.5rem 0;
  border: none;
  border-radius: 5px;
  background: var(--primary);
  color: var(--white);
  font-weight: 600;
  cursor: pointer;
`;

export const AuthLink = styled(Link)`
  text-decoration: none;
  margin-left: 0.5rem;
  color: var(--l-blue);
  &:hover {
    color: var(--white);
  }
`;

export const HR = styled.hr`
  border: none;
  border-top: 1px solid var(--white);
  color: var(--white);
  overflow: visible;
  text-align: center;
  height: 8px;
  width: 16rem;
  margin: 1rem 0;
  min-width: 150px;

  &:after {
    background: var(--d-blue);
    content: "Or";
    font-size: 15px;
    padding: 0 14px;
    position: relative;
    top: -15px;
  }
`;

export const AuthFormFooter = styled.div`
  color: var(--gray);
  justify-content: space-between;
  align-items: center;
  display: flex;
  width: 100%;
`;

export const ForgotPwdWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 0.4rem;
`;

export const ForgotPwdBtn = styled.p`
  margin-right: 0.2rem;
  color: var(--l-blue);
  cursor: pointer;
  background: none;
  border: none;
  &:hover {
    color: var(--white);
  }
`;

// <<--------------------- OTP Verification Section ------------------>>

export const OTPFormWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  h1 {
    color: var(--white);
  }
  h4 {
    font-size: 14px;
    font-weight: 500;
    color: var(--gray);
  }
  span {
    color: var(--primary);
    margin-left: 0.5rem;
  }
  p {
    color: var(--gray);
    font-size: 11px;
    width: 18rem;
    text-align: justify;
    line-height: 1rem;
  }
  footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`;

export const OTPAlert = styled.div`
  padding: 0.6rem 0.8rem;
  background-color: #002472ac;
  border-radius: 5px;
  color: var(--white);
  font-size: 12px;
  width: 18.5rem;
  text-align: justify;
  line-height: 1rem;
  word-spacing: 1px;
`;

export const OTPForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  h4 {
    display: flex;
    width: 15rem;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 500;
    color: ${(props) => props.theme.yellow};
  }
`;

export const OTPInputWrapper = styled.div`
  width: 18.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
`;

export const OTPInput = styled.input`
  width: 2.7rem;
  height: 2.7rem;
  padding: 1rem;
  font-size: 14px;
  font-weight: 500;
  border: none;
  border-radius: 5px;
  color: var(--white);
  background: var(--input-bg);
  &:focus {
    border-bottom: 2px solid var(--primary);
    outline: none;
  }
  ${({ error }) =>
    error &&
    `
    border-bottom: 2px solid var(--yellow);
  `}
`;

export const ResendOTP = styled.button`
  background: none;
  border: none;
  text-decoration: none;
  color: var(--l-blue);
  margin-left: 2rem;
  font-size: 14px;
  cursor: ${({ cursor }) => (cursor ? "not-allowed" : "pointer")};
  cursor: pointer;
  &:hover {
    color: var(--white);
  }
`;

export const OTPError = styled.div`
  color: var(--yellow);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  width: 16rem;
`;
