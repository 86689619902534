import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FaTags } from "react-icons/fa6";
import { BsDot, BsTools } from "react-icons/bs";
import { MdLocationPin } from "react-icons/md";
import { HiOutlineUpload } from "react-icons/hi";
import {
  MDActionBtn,
  MDAuthProfilePic,
  MDAuthorContainer,
  MDAuthorRating,
  MDAuthorWrap,
  MDContainer,
  MDDetailHeader,
  MDDetailsWrap,
  MDFlexStart,
  MDFollowBtn,
  MDHeader,
  MDHeaderAction,
  MDHeaderBlur,
  MDHeaderImg,
  MDHeaderOverlay,
  MDHeaderVideo,
  MDMediaActionWrap,
  MDMediaPrevWrap,
  MDName,
  MDRatingWrap,
  MDRatingsd,
  MDSection,
  MDSkill,
  // MDTMRatingTitle,
  MDTabsContainer,
  MDTabsTitle,
  MDTabsWrapper,
  MDUploadIcon,
  MDUserName,
} from "./FeedStyle";
import ActionMenu from "../../Components/Post/ActionMenu";

import MediaContainer from "../../Components/Post/MediaContainer";
import {
  Footer,
  RadialBar,
  HorzBar,
  StarRating,
  SaveCollectionModal,
} from "../../Components";
import {
  PostDesc,
  PostHorzSubtitle,
  PostUserNameWrap,
} from "../../Components/Post/PostStyle";
import {
  SelectedTagsWrapper,
  TaggedNameWrapper,
} from "../../Components/Inputs/InputStyle";
import { EmptyModalDataWrap } from "../../Components/Modals/ModalStyles";
import { useCommonHandle } from "../../Hooks/useEventHandler";
import { handlePostRating } from "../../Functions/getPostShareHandler";
import {
  getPostMoreDetails,
  // getSearchResults,
  getUserPersonalDetails,
} from "../../Functions/getFetchFunctions";
import defaultImg from "../../Assets/Images/default_profile_pic.png";
import { SaveIcon } from "../../Assets/SVG";
import MDComments from "./MDComments";

import { setBackNavigate } from "../../ReduxToolKit/Slices/utilsSlice";
import { useHandleCreateShare } from "../../Hooks/useCreateShareHandler";
import { addLineBreaks } from "../../Hooks/useCountFormat";

import {
  AddedCreditTagsWrap,
  UsedToolsBox,
  UsedToolsWrap,
} from "../Upload/UploadStyle";

const MoreDetailedPost = () => {
  const { postId } = useParams();
  const dispatch = useDispatch();
  const backNavigate = useSelector((state) => state.utils.backNavigate);

  const userId = localStorage.getItem("currentUserId"); //tediuucet
  let currentUserId = userId;
  const [isLoading, setIsLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);
  const [postData, setPostData] = useState(null);
  const [authorData, setAuthorData] = useState(null);
  const isAuthor = true;
  const [rateThePost, setRatethePost] = useState(postData?.yourRating);
  const [commentDataLength, setCommentDataLength] = useState();
  const [isTheaterMode, setIsTheaterMode] = useState(true);
  const [isCommonMute, setIsCommonMute] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isNoUser, setIsNoUser] = useState(false);
  // for saave collection modal
  const [isSCOpen, setIsSCOpen] = useState(false);
  const [saveCollectionList, setSaveCollectionList] = useState([]);
  const [saveCollectionLoading, setSaveCollectionLoading] = useState(true);

  const { handleSaveColelctionOpenClose } = useHandleCreateShare({
    currentUserId,
    dispatch,
    isSCOpen,
    setSaveCollectionList,
    setSaveCollectionLoading,
    setIsSCOpen,
  });

  const { handleCopyLink } = useCommonHandle();

  useEffect(() => {
    getPostMoreDetails(postId, userId, dispatch, setPostData)
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        console.error("Error fetching post details:", postId);
      });
  }, [postId, dispatch, userId, setPostData]);

  useEffect(() => {
    if (postData?.author?._id) {
      let postAuthorId = postData.author._id;
      getUserPersonalDetails(
        postAuthorId,
        userId,
        dispatch,
        setIsNoUser,
        isAuthor,
        setAuthorData
      )
        .then(() => {
          setIsLoading(false);
        })
        .catch(() => {});
    }
  }, [postData, userId, dispatch, isAuthor]);

  useEffect(() => {
    document.title = postData
      ? `${postData?.about?.title} | Post `
      : "Post | More Details";
  }, [postData]);

  const handleRateThePost = (ratingValue, postId, postOwnerId) => {
    setRatethePost(ratingValue);
    let forThis = "forMoreDetailsPost";
    handlePostRating(
      userId,
      postId,
      postOwnerId,
      ratingValue,
      dispatch,
      1,
      1,
      forThis,
      setPostData
    );
  };

  // const handeSkillsetClick = () => {
  //   getSearchResults(
  //     searchType,
  //     currentUserId,
  //     pageNo,
  //     pageSize,
  //     dispatch,
  //     setSearchLoading,
  //     setSearchResults,
  //     values,
  //     mainCatId
  //   );
  // }

  const ratingCounts = [
    {
      no: 5,
      value: postData?.rating?.fiveStar,
      percentage: (postData?.rating?.fiveStar / postData?.numberOfRating) * 100,
    },
    {
      no: 4,
      value: postData?.rating?.fourStar,
      percentage: (postData?.rating?.fourStar / postData?.numberOfRating) * 100,
    },
    {
      no: 3,
      value: postData?.rating?.threeStar,
      percentage:
        (postData?.rating?.threeStar / postData?.numberOfRating) * 100,
    },
    {
      no: 2,
      value: postData?.rating?.twoStar,
      percentage: (postData?.rating?.twoStar / postData?.numberOfRating) * 100,
    },
    {
      no: 1,
      value: postData?.rating?.oneStar,
      percentage: (postData?.rating?.oneStar / postData?.numberOfRating) * 100,
    },
  ];

  const moreDetailsTabs = [
    {
      id: 0,
      title: "Comments",
      //  content: <PostFeed />,
    },
    {
      id: 1,
      title: "Tools Used",
      //  content: <ShareFeed />,
    },
    {
      id: 2,
      title: "Credits & Tags",
      //  content: <ShareFeed />,
    },
  ];
  const [currentTab, SetCurrentTab] = useState(moreDetailsTabs[0]);

  return (
    <MDContainer>
      {!isLoading && authorData !== null && (
        <>
          <MDHeader>
            {postData.media[activeIndex] && (
              <>
                {postData.media[activeIndex].mediaType === "image" ? (
                  <MDHeaderImg src={postData.media[activeIndex].url} alt="" />
                ) : (
                  <MDHeaderVideo
                    src={postData.media[activeIndex].url}
                    alt=""
                    muted
                    autoPlay={false}
                    loop={false}
                    playsInline
                    webkit-playsinline
                    controlsList="nodownload nofullscreen"
                    preload="metadata"
                    controls={false}
                  />
                )}
              </>
            )}
            <MDHeaderBlur />
            <MDHeaderOverlay />
          </MDHeader>
          <MDSection isTM={isTheaterMode}>
            <MDMediaPrevWrap isTM={isTheaterMode} isFScr={isFullScreen}>
              <MediaContainer
                source={postData.media[activeIndex]?.url}
                isImage={postData.media[activeIndex]?.mediaType === "image"}
                isMultiple={false}
                isTheaterMode={isTheaterMode}
                onTheater={() => setIsTheaterMode(!isTheaterMode)}
                mediaLength={postData.media?.length}
                activeIndex={activeIndex}
                onPrevClick={() => setActiveIndex(activeIndex - 1)}
                onNextClick={() => setActiveIndex(activeIndex + 1)}
                isCommonMute={isCommonMute}
                setIsCommonMute={setIsCommonMute}
                isFullScreen={isFullScreen}
                onFullScreen={() => setIsFullScreen(true)}
                onMinimizeScreen={() => setIsFullScreen(false)}
              />
              {!isFullScreen && (
                <MDMediaActionWrap>
                  <MDActionBtn
                    onClick={() =>
                      handleSaveColelctionOpenClose(postData._id, isSCOpen)
                    }
                  >
                    <SaveIcon width={"16px"} />
                    <p>Save {isTheaterMode && "to collection"}</p>
                  </MDActionBtn>
                </MDMediaActionWrap>
              )}
            </MDMediaPrevWrap>
            {/* MD heading, author details, and post details section -------------------------------------- */}
            <MDDetailsWrap isTM={isTheaterMode}>
              <MDDetailHeader>
                <h1>{postData.about.title} </h1>
                <MDHeaderAction>
                  <MDUploadIcon>
                    <HiOutlineUpload />
                  </MDUploadIcon>
                  <ActionMenu
                    forPost={true}
                    forMDPost={true}
                    postId={postData._id}
                    authorId={postData.author._id}
                    isBestWork={postData.isBestWork}
                    onCopyLink={handleCopyLink}
                    // onPin={handleBestWorkClick}
                  />
                </MDHeaderAction>
              </MDDetailHeader>
              {/* MD author details, section -------------------------------------------------------------- */}
              <MDAuthorContainer>
                <MDAuthorWrap>
                  <MDAuthProfilePic
                    bgPosition={postData.author.profileImage === null}
                    style={{
                      backgroundImage: `url(${
                        postData.author.profileImage === null
                          ? defaultImg
                          : postData.author.profileImage
                      })`,
                    }}
                  />
                  <PostUserNameWrap>
                    <MDName
                      to={`/${postData.author.firstName}-${postData.author.lastName}/${postData.author._id}`}
                      onClick={() =>
                        dispatch(
                          setBackNavigate({ backNavigate: backNavigate + 1 })
                        )
                      }
                    >
                      {postData.author.firstName}
                      &nbsp;
                      {postData.author.lastName}
                    </MDName>
                    <span>
                      <MDUserName
                        to={`/${postData.author.firstName}-${postData.author.lastName}/${postData.author._id}`}
                        onClick={() =>
                          dispatch(
                            setBackNavigate({
                              backNavigate: backNavigate + 1,
                            })
                          )
                        }
                      >
                        @{postData.author.userName}
                      </MDUserName>
                      {authorData._id !== userId &&
                        authorData.isTalent &&
                        !isNoUser && (
                          <>
                            <BsDot style={{ fontSize: "1.2rem" }} />
                            <MDFollowBtn>
                              {authorData.isFollowing ? "Unfollow" : "Follow"}
                            </MDFollowBtn>
                          </>
                        )}
                    </span>
                  </PostUserNameWrap>
                </MDAuthorWrap>
                <MDAuthorRating>
                  <StarRating
                    gaps={"0.35rem"}
                    width={"18px"}
                    onClick={handleRateThePost}
                    values={rateThePost ? rateThePost : postData.yourRating}
                    postId={postData._id}
                    postOwnerId={postData.author._id}
                  />
                </MDAuthorRating>
              </MDAuthorContainer>

              <MDRatingsd>
                <p>Rate this post</p>
                <StarRating
                  gaps={"0.35rem"}
                  width={"18px"}
                  onClick={handleRateThePost}
                  values={rateThePost ? rateThePost : postData.yourRating}
                  postId={postData._id}
                  postOwnerId={postData.author._id}
                />
              </MDRatingsd>
              {/* MD post details section ----------------------------------------------------------------- */}
              <PostHorzSubtitle isMB={true}>
                <span>
                  <MdLocationPin />
                </span>
                {postData.about.country}&nbsp;-&nbsp;{postData.about.state}
              </PostHorzSubtitle>
              <PostDesc>{addLineBreaks(postData.about.description)}</PostDesc>
              <MDFlexStart>
                <MDSkill>{postData.about.mainCategory}</MDSkill>
                <MDSkill>{postData.about.subCategory}</MDSkill>
                <MDSkill>{postData.about.skill}</MDSkill>
              </MDFlexStart>

              <MDRatingWrap isMT={isTheaterMode}>
                <HorzBar ratingCounts={ratingCounts} />
                <RadialBar
                  avgRating={
                    postData.postRating === null
                      ? 0
                      : (Math.round(postData.postRating * 10) / 10).toFixed(1)
                  }
                  peopleCount={postData.numberOfRating}
                />
              </MDRatingWrap>
            </MDDetailsWrap>
          </MDSection>

          <MDTabsWrapper>
            {moreDetailsTabs.map((tab, index) => (
              <MDTabsTitle
                key={index}
                isActive={currentTab.id === tab.id}
                onClick={() => {
                  if (currentTab.id !== tab.id) {
                    SetCurrentTab(tab);
                  }
                }}
              >
                {tab.title}
              </MDTabsTitle>
            ))}
          </MDTabsWrapper>
          {currentTab.id === 0 && (
            <MDTabsContainer isTM={isTheaterMode}>
              <MDComments
                isTM={isTheaterMode}
                postId={postData._id}
                setCommentDataLength={setCommentDataLength}
              />
            </MDTabsContainer>
          )}
          {currentTab.id === 1 && (
            <MDTabsContainer isTM={isTheaterMode}>
              {postData.toolsUsed.length > 0 ? (
                <UsedToolsWrap>
                  {postData.toolsUsed.map((item, index) => (
                    <UsedToolsBox key={index}>
                      <p>{item.toolName}</p>
                      {item.level && <p>&nbsp;&nbsp;-&nbsp;&nbsp;</p>}
                      {item.level && <p>{item.level}</p>}
                    </UsedToolsBox>
                  ))}
                </UsedToolsWrap>
              ) : (
                <EmptyModalDataWrap>
                  <BsTools
                    style={{ fontSize: "1.6rem", marginBottom: "5px" }}
                  />
                  No tools provided
                </EmptyModalDataWrap>
              )}
            </MDTabsContainer>
          )}

          {currentTab.id === 2 && (
            <MDTabsContainer isTM={isTheaterMode}>
              {postData.credit.length > 0 ? (
                <UsedToolsWrap>
                  {postData.credit.map((item, index) => (
                    <AddedCreditTagsWrap key={index} isHalf={true}>
                      <p>{item.creditTitle}</p>
                      <SelectedTagsWrapper>
                        {item.peopleTag.map((tag, index) => (
                          <TaggedNameWrapper
                            to={`/${tag.userName}/${tag.id}`}
                            target="_blank"
                            key={index}
                          >
                            @{tag.userName}
                          </TaggedNameWrapper>
                        ))}
                      </SelectedTagsWrapper>
                    </AddedCreditTagsWrap>
                  ))}
                </UsedToolsWrap>
              ) : (
                <EmptyModalDataWrap>
                  <FaTags style={{ fontSize: "1.6rem", marginBottom: "5px" }} />
                  No Credits or Tags provided
                </EmptyModalDataWrap>
              )}
            </MDTabsContainer>
          )}

          {isSCOpen && (
            <SaveCollectionModal
              isMulti={false}
              onClose={handleSaveColelctionOpenClose}
              postId={postData._id}
              saveCollectionList={saveCollectionList}
              saveCollectionLoading={saveCollectionLoading}
              setSaveCollectionList={setSaveCollectionList}
              setSaveCollectionLoading={setSaveCollectionLoading}
            />
          )}
          <Footer />
        </>
      )}
    </MDContainer>
  );
};

export default MoreDetailedPost;
