import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getViewAllPost } from "../../Functions/getFetchFunctions";
import { Loader, Post } from "../../Components";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { DarkLogo } from "../../Assets/Images";

const FeedWrapper = styled.main`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
`;

const PostFeed = () => {
  const dispatch = useDispatch();
  const currentUserId = localStorage.getItem("currentUserId");
  const [isLoading, setIsLoading] = useState(true);

  const postsById = useSelector((state) => state.posts.postsById);

  const postIds = Object.keys(postsById); // Get an array of post IDs
  // const [posts, setPosts] = useState(
  //   postIds.map((postId) => postsById[postId])
  // );

  const posts = postIds.map((postId) => postsById[postId]);

  const [pageNo, setPageNo] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
    console.log(scrollTop, clientHeight, scrollHeight);
    if (scrollHeight - scrollTop === clientHeight && !loading && hasMore) {
      setLoading(true); // Set loading state to true before fetching more posts
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getViewAllPost(currentUserId, currentUserId, pageNo, 10, dispatch)
      .then(() => {
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching feed");
      });
  }, [currentUserId, dispatch, pageNo]);

  return (
    <>
      <Helmet>
        <title>Home | Talents Explore</title>
        <meta property="og:image" content={`${DarkLogo}`} />
        <meta
          name="description"
          content="Discover inspiring works, connect with talented artists, and unleash your own creativity. Join our global community and be part of a platform that showcases and empowers talents worldwide."
        />
        {/* <meta
          property="og:url"
          name="url"
          content={`${process.env.REACT_APP_API_URL}/feed`}
        />
        <meta property="og:type" content="website" /> */}
      </Helmet>
      <FeedWrapper>
        {isLoading || postIds.length === 0 ? (
          <Loader />
        ) : (
          <>
            <Post data={posts} />
            {loading && <p>Loading...</p>}
          </>
        )}
      </FeedWrapper>
    </>
  );
};

export default PostFeed;
