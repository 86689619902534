import React from "react";
import { RadioInput } from "../../Components";
import { CompleteText, CompleteTextwrapper } from "./TalentsInfoStyle";

const CompleteProfile = ({ checked, setChecked }) => {
  const handleCheck = () => {
    setChecked((prevVal) => !prevVal);
  };

  return (
    <CompleteTextwrapper>
      <RadioInput
        isCheckBox={true}
        name={"complete"}
        onChange={handleCheck}
        value={checked}
        checked={checked}
      />
      <CompleteText>
        I agree to the terms and conditions, and I'm excited to share my talents
        with the world. Check this box to proceed.
      </CompleteText>
    </CompleteTextwrapper>
  );
};

export default CompleteProfile;
