import React from "react";
import {
  Answer,
  Heading,
  PageWrapper,
  Paragraph,
  ParahLink,
  PlusButton,
  QAWrapper,
  Question,
} from "./PoliciesStyles";
import {
  Parah,
  PointsIcon,
  PointsWrapper,
  SubTitle,
  ThirdTitle,
} from "../../Documents/ContentsStyles";
import useHandleEmailClick from "../../Hooks/useEmail";
import { Helmet } from "react-helmet";

const Intellectual = () => {
  const handleEmailClick = useHandleEmailClick;

  return (
    <>
      <Helmet>
        <title>Intellectual Property Policy| Talents Explore</title>
      </Helmet>

      <PageWrapper>
        <Heading>Intellectual Property Policy</Heading>
        <Paragraph>
          Talents Explore respects the intellectual property rights of others,
          and we expect you to do the same. Talents Explore’s{" "}
          <ParahLink to="/terms-and-conditions">Terms of Service</ParahLink> and{" "}
          <ParahLink to="/community-guidelines">Community Guidelines</ParahLink>{" "}
          do not allow posting, sharing, or sending any content that violates or
          infringes someone else’s copyrights, trademarks or other intellectual
          property rights.
        </Paragraph>
        <QAWrapper>
          <Question isActive={true}>
            1. Copyright <PlusButton />
          </Question>
          <Answer>
            <Parah>
              Copyright is a legal right that protects original works of
              authorship (e.g., music, videos, etc.). Generally, copyright
              protects an original expression of an idea (e.g., the specific way
              a video or music is expressed or created) but does not protect
              underlying ideas or facts.
            </Parah>
            <ThirdTitle>Copyright Infringement</ThirdTitle>
            <Parah>
              We do not allow any content that infringes copyright. The use of
              copyrighted content of others without proper authorization or
              legally valid reason may lead to a violation of Talents Explore's
              policies.
            </Parah>
            <Parah>
              At the same time, not all unauthorized uses of copyrighted content
              constitute an infringement. In many countries, exceptions to
              copyright infringement allow the use of copyrighted works under
              certain circumstances without authorization. These include the
              fair use doctrine in the United States and permitted acts of fair
              dealing in the European Union (and other equivalent exceptions
              under applicable local laws in other countries).
            </Parah>
            <ThirdTitle>
              Removal of Content; Suspension or Termination of Account
            </ThirdTitle>
            <Parah>
              Any user content that infringes another person’s copyright may be
              removed. The account may be suspended or terminated for multiple
              copyright violations in connection with the use of the Talents
              Explore site or app, or other violations of the{" "}
              <ParahLink to="/terms-and-conditions">Terms of Service</ParahLink>{" "}
              and{" "}
              <ParahLink to="/community-guidelines">
                Community Guidelines.
              </ParahLink>{" "}
              We reserve the right to refuse any account holder whose account
              was used for improper activities from opening a new account on
              Talents Explore’s site or app, or otherwise hosted by Talents
              Explore.
            </Parah>
            <SubTitle>Information for Rightsholders</SubTitle>
            <ThirdTitle>Copyright Infringement Notification</ThirdTitle>
            <Parah>
              Contacting the user directly may resolve your complaint more
              quickly and in a way that is more beneficial to you, the user, and
              our community. You may also file a Copyright Infringement Report
              and send email to{" "}
              <a
                href
                onClick={handleEmailClick}
                style={{
                  color: " #ff2146f0",
                  cursor: " pointer",
                }}
              >
                info@talentsexplore
              </a>{" "}
              to request the removal of the alleged infringing content from
              Talents Explore.
            </Parah>
            <Parah>
              All complaints should contain the information requested in our
              online Copyright Infringement Report form. Failure to include
              necessary information may limit our ability to investigate your
              claims and may result in your complaint being denied.
            </Parah>
            <Parah>
              We may provide the account holder with your contact information,
              including the email address and the name of the copyright owner,
              and/or details of the complaint.
            </Parah>
            <Parah>
              Before submitting a notification, please be aware that
              intentionally submitting a misleading or fraudulent report may
              lead to liability for damages under section 512(f) of the United
              States Digital Millennium Copyright Act (DMCA) or similar laws as
              may be applicable in other countries.
            </Parah>
            <Parah>
              If you submit a report or infringement notification to us, we may
              contact you if we have additional questions about your report or
              notification. Please note that Talents Explore is not in a
              position to adjudicate disputes between third parties, and may not
              be able to remove the content or suspend the account you reported.
              As an alternative, you may want to contact the person who posted
              the content or owns the account to try to resolve your issue
              directly.
            </Parah>
            <ThirdTitle>EU Copyright Directive</ThirdTitle>
            <Parah>
              Pursuant to Article 17 of the Copyright Directive (EU 2019/790),
              if you want to make an enquiry about granting an authorisation to
              Talents Explore to make your copyright-protected works available
              on Talents Explore, please contacts us. We will review your
              request and be in touch.
            </Parah>
            <Parah>
              If you want to request that your music or audio-visual works are
              made unavailable in the EU, we need you contact us. So that
              Talents Explore can consider your request, you will have to
              provide us with relevant and necessary information about you and
              your copyright works. Upon receiving this information and
              validating your request, Talents Explore will do its best to
              ensure that your copyright work is made unavailable on Talents
              Explore in the EU.
            </Parah>
            <Parah>
              Please note that in accordance with its legal obligations Talents
              Explore provides to users and rightsholders a copyright
              infringement dispute resolution mechanism, however rightsholders
              remain free to assert their rights in court.
            </Parah>
            <SubTitle>
              Information for Users: Copyright Infringement Counter-Notification
            </SubTitle>
            <ThirdTitle>
              If you are a user located outside of the European Union
            </ThirdTitle>
            <Parah>
              If you receive a copyright infringement notification that you
              believe to be in error or believe that you are authorised to use
              the content, you can reach out to the copyright owner directly to
              request a retraction.
            </Parah>
            <Parah>
              You may also provide us with a counter-notification via our
              Counter Notification Form. Failure to include necessary
              information may limit our ability to investigate your claims and
              may result in your counter-notification being denied.
            </Parah>
            <Parah>
              The counter-notification process will take time to complete,
              please be patient. During this time, the copyright claimant may
              file an action seeking a court order to keep the content down
              pursuant to the United States Digital Millennium Copyright Act
              (DMCA) or similar laws in other countries. Please note that, where
              appropriate and authorised by law, we will forward the entire
              counter-notification to the original reporter, including any
              contact information you provide, in accordance with our Terms of
              Services and Privacy Policy. The claimant may use this information
              to file a lawsuit against you.
            </Parah>
            <Parah>
              If we do not receive notice that the original reporter is seeking
              a court order to prevent further infringement of the material at
              issue, we may replace or cease disabling access to the material
              that was removed if the material does not infringe on third-party
              copyright. The decision to re-post any material is at Talents
              Explore’s sole discretion.
            </Parah>
            <ThirdTitle>
              If you are a user located in the European Union
            </ThirdTitle>
            <Parah>
              If you receive a copyright infringement notification and believe
              that you have the right to post the content in question, you can
              complete a Counter Notification Form.
            </Parah>
            <Parah>
              Under EU law, users are allowed to use copyright works without the
              authorization of the copyright holder for quotation, criticism,
              review and for the purpose of caricature, parody, or pastiche
              provided that such use is fair. EU countries may also provide for
              additional exceptions. See below some further information about
              the exceptions and limitations to copyright available in the EU:
            </Parah>
            <PointsWrapper>
              <PointsIcon />
              Quotation, criticism and review
            </PointsWrapper>
            <Parah>
              Quotation is the use of an extract from a copyright-protected work
              for purposes such as illustrating an assertion, defending an
              opinion or engaging in debate. Quotations may also be used for the
              purposes of criticising a copyrighted work (e.g. critical
              commentary on a movie) or reviewing one (e.g. reviewing a book or
              an album).
            </Parah>
            <PointsWrapper>
              <PointsIcon />
              Caricature, parody and pastiche
            </PointsWrapper>
            <Parah>
              Caricatures aim at exaggerating or distorting reality, usually for
              humorous purposes. A parody will evoke an existing copyrighted
              work while being noticeably different from it and should
              constitute an expression of humour or mockery. A pastiche will
              usually incorporate distinctive elements from other works or
              styles into a new work.
            </Parah>
            <Parah>
              In order for any use of copyrighted work to come within an
              exception or limitation it must be fair, this means that it
              should, where applicable: (i) be no longer than necessary; (ii) be
              accompanied by sufficient acknowledgement of the source material
              and (iii) not unreasonably harm the legitimate interests of the
              rightsholder.
            </Parah>
            <Parah>
              Videos that fall within an exception or limitation to copyright
              are still subject to our Community Guidelines. This means that
              such videos should not contain hate speech or involve hateful
              behaviour or be used to abuse, mock, humiliate, embarrass,
              intimidate, or hurt any person.
            </Parah>
            <Parah>
              Please note that in accordance with its legal obligations Talents
              Explore provides to users and rightsholders a copyright
              infringement dispute resolution mechanism, however users remain
              free to assert their rights in court.
            </Parah>
          </Answer>
        </QAWrapper>
        <QAWrapper>
          <Question isActive={true}>
            2. Trademark <PlusButton />{" "}
          </Question>
          <Answer>
            <Parah>
              A trademark is a word, symbol, slogan, design, or combination of
              any of the foregoing that identifies the source of a product or
              service and distinguishes it from other products or services.
            </Parah>
            <SubTitle>Trademark Infringement</SubTitle>
            <Parah>
              Trademark laws prohibit trademark infringement, which is generally
              the unauthorized use of a trademark or service mark in connection
              with goods or services in a way that is likely to cause confusion,
              deception or mistake about the source, origin, sponsorship, or
              affiliation of the associated goods and/or services.
            </Parah>
            <Parah>
              At the same time, the use of another’s trademark for purposes of
              accurately referencing, lawfully commenting, criticizing,
              parodying, or reviewing the trademark owner’s products or
              services, or for purposes of comparing them to other products or
              services, where the mark is not used to designate the user’s own
              goods or services or those of a third party, is generally not
              considered a violation of our policies. Likewise, it is generally
              permissible to make a fan page about a brand, even without the
              brand’s permission, provided that you do not claim to speak for or
              be associated with the brand or otherwise violate the brand’s
              intellectual property rights.
            </Parah>
            <SubTitle>
              Removal of Content; Suspension or Termination of Account
            </SubTitle>
            <Parah>
              Any content that violates another’s trademark rights may be taken
              down. Repeat violations of trademark rights in connection with the
              use of the Talents Explore site or app, or other violations of the{" "}
              <ParahLink to="/terms-and-conditions">Terms of Service</ParahLink>{" "}
              and{" "}
              <ParahLink to="/community-guidelines">
                Community Guidelines.
              </ParahLink>{" "}
              may result in the suspension or termination of the user account.
              We reserve the right to refuse any account holder whose account
              was used for improper activities from opening a new account on
              Talents Explore’s site or app, or otherwise hosted by Talents
              Explore.
            </Parah>
            <SubTitle>Trademark Complaint and Notification</SubTitle>
            <Parah>
              Contacting the user directly may resolve your complaint more
              quickly and in a way that is more beneficial to you, the user, and
              our community. You may also file a Trademark Infringement Report
              and send email to{" "}
              <a
                href
                onClick={handleEmailClick}
                style={{
                  color: " #ff2146f0",
                  cursor: " pointer",
                }}
              >
                info@talentsexplore.com
              </a>{" "}
            </Parah>
            <Parah>
              All complaints should contain the information requested in our
              online Trademark Infringement Report form. Failure to include
              necessary information may limit our ability to deal with your
              claims and may result in your complaint being denied. We may
              provide the account holder with your contact information,
              including the email address and the name of the trademark owner,
              and/or details of the complaint, in accordance with our Terms of
              Services and Privacy Policy.
            </Parah>
            <Parah>
              Before submitting a notification, please be aware that
              intentionally submitting a misleading or fraudulent report may
              lead to liability for damages under laws as may be applicable in
              some countries.
            </Parah>
            <SubTitle>Trademark Infringement Counter-Notification</SubTitle>
            <Parah>
              If you receive a trademark infringement notification that you
              believe to be in error or believe that you are authorized to use
              the content, you can reach out to the trademark owner directly to
              request a retraction.
            </Parah>
            <Parah>
              You may also provide us with a counter-notification via our online
              Counter Notification Form. All counter-notification should contain
              the information requested in our online Counter Notification Form.
              Failure to include necessary information may limit our ability to
              investigate your claims and may result in your
              counter-notification being denied.
            </Parah>
          </Answer>
        </QAWrapper>
        <QAWrapper>
          <Question isActive={true}>
            3. General Note
            <PlusButton />
          </Question>
          <Answer>
            <Parah>
              As a Talents Explore user, you are responsible for the content you
              post. If you have questions about copyright law or trademark law,
              such as questions about whether your content or your use of
              another person’s name or brand infringes or otherwise violates
              another person’s rights, you may want to contact an attorney. If
              you are unsure whether the material you plan to report to us is
              infringing or otherwise violating another person’s right, you may
              also want to first seek legal advice before reporting such content
              to us.
            </Parah>
          </Answer>
        </QAWrapper>
      </PageWrapper>
    </>
  );
};

export default Intellectual;
