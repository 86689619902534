import React, { useEffect, useState } from "react";
import { GraduationIcon } from "../../../../Assets/SVG";
import {
  DetailLabel,
  DetailSectionContainer,
  EduAwardSingleElement,
  LangSingleElement,
  SingleDetailElem,
  UserDetailHeading,
  LEACSubheading,
  LAEActions,
} from "../OverviewStyle";
import {
  getTalentEducation,
  getTalentLanguage,
} from "../../../../Functions/getFetchFunctions";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { AddBtn } from "../../../../Components/Common/CommonStyle";
import { BiPlus } from "react-icons/bi";
import { useModalOpen } from "../../../../Hooks/useEventHandler";
import { AddProInfoModal, ConfirmationModal } from "../../../../Components";
import { MdDelete, MdModeEdit } from "react-icons/md";
import { useProfileEdit } from "../../../../Hooks/useProfileEdit";
import { EmptyModalDataWrap } from "../../../../Components/Modals/ModalStyles";

const LanguageEducations = () => {
  const { userId } = useParams();
  const dispatch = useDispatch();
  const currentUserId = localStorage.getItem("currentUserId");
  let isEdit = currentUserId === userId;

  const { isOpen, setIsOpen } = useModalOpen();
  const [type, setType] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [langArray, setLangArray] = useState([]);
  const [eduArray, setEduArray] = useState([]);

  useEffect(() => {
    getTalentLanguage(userId, dispatch, setLangArray);
  }, [userId, dispatch]);

  useEffect(() => {
    getTalentEducation(userId, dispatch, setEduArray);
  }, [userId, dispatch]);

  const { handleLanguageDelete, handleEducationDelete } = useProfileEdit({
    dispatch,
    currentUserId,
    type,
    selectedIndex,
    langArray,
    setLangArray,
    eduArray,
    setEduArray,
  });

  const handleCrudModalOpen = (text, index) => {
    setIsOpen(true);
    setType(text);
    setSelectedIndex(index);
    console.log(text, index);
  };

  return (
    <DetailSectionContainer>
      <UserDetailHeading>
        <span>
          <GraduationIcon /> Languages & Education
        </span>
      </UserDetailHeading>
      <LEACSubheading>
        Languages
        {isEdit && (
          <AddBtn
            onClick={() => handleCrudModalOpen("languages", langArray.length)}
          >
            <BiPlus />
            <p>Add</p>
          </AddBtn>
        )}
      </LEACSubheading>

      {langArray.map((item, index) => (
        <LangSingleElement
          key={index}
          isSingle={langArray.length === 1}
          isEdit={isEdit}
        >
          <DetailLabel>{item.languageName}</DetailLabel>
          <SingleDetailElem>{item.level}</SingleDetailElem>
          {isEdit && (
            <LAEActions isLang={true} isSingle={!langArray.length > 1}>
              <span onClick={() => handleCrudModalOpen("langEdit", index)}>
                <MdModeEdit />
              </span>
              {langArray.length > 1 && (
                <span onClick={() => handleCrudModalOpen("langDelete", index)}>
                  <MdDelete />
                </span>
              )}
            </LAEActions>
          )}
        </LangSingleElement>
      ))}

      <LEACSubheading>
        Education
        {isEdit && (
          <AddBtn
            onClick={() => handleCrudModalOpen("educations", eduArray.length)}
          >
            <BiPlus />
            <p>Add</p>
          </AddBtn>
        )}
      </LEACSubheading>

      {eduArray.length > 0 ? (
        eduArray.map((item, index) => (
          <EduAwardSingleElement key={index}>
            <DetailLabel>{item.course}</DetailLabel>
            <SingleDetailElem>{item.institution}</SingleDetailElem>
            <SingleDetailElem>
              {item.from} &nbsp;- &nbsp;{item.to}
            </SingleDetailElem>
            {isEdit && (
              <LAEActions>
                <span onClick={() => handleCrudModalOpen("eduEdit", index)}>
                  <MdModeEdit />
                </span>
                <span onClick={() => handleCrudModalOpen("eduDelete", index)}>
                  <MdDelete />
                </span>
              </LAEActions>
            )}
          </EduAwardSingleElement>
        ))
      ) : (
        <EmptyModalDataWrap height={"10rem"}>
          <GraduationIcon width={"2.6rem"} height={"2.6rem"} />
          No Education added yet
        </EmptyModalDataWrap>
      )}

      {(type === "languages" || type === "langEdit") && isOpen && (
        <AddProInfoModal
          type={type}
          setIsOpen={setIsOpen}
          langArray={langArray}
          setLangArray={setLangArray}
          selectedIndex={selectedIndex}
        />
      )}

      {(type === "educations" || type === "eduEdit") && isOpen && (
        <AddProInfoModal
          type={type}
          setIsOpen={setIsOpen}
          eduArray={eduArray}
          setEduArray={setEduArray}
          selectedIndex={selectedIndex}
        />
      )}
      {isOpen && type === "langDelete" && (
        <ConfirmationModal
          setIsOpen={setIsOpen}
          onConfirm={handleLanguageDelete}
          message={"language"}
        />
      )}
      {isOpen && type === "eduDelete" && (
        <ConfirmationModal
          setIsOpen={setIsOpen}
          onConfirm={handleEducationDelete}
          message={"education"}
        />
      )}
    </DetailSectionContainer>
  );
};

export default LanguageEducations;
