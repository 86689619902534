import React from "react";
import {
  FormInputWrapper,
  InputIconWrapper,
  InputValidation,
  InputValidationWrapper,
  StyledFormInput,
  StyledLabel,
} from "./InputStyle";

const FormInput = ({
  isHorz,
  isItemStart,
  label,
  labelWidth,
  icon,
  name,
  type,
  placeholder,
  value,
  onChange,
  validation,
}) => {
  function handleChange(e) {
    onChange && onChange(e);
  }

  return (
    <FormInputWrapper isHorz={isHorz} isItemStart={isItemStart}>
      {label && <StyledLabel labelWidth={labelWidth}>{label}</StyledLabel>}
      <InputValidationWrapper>
        <InputIconWrapper>
          {icon && <span>{icon}</span>}
          <StyledFormInput
            type={type}
            name={name}
            placeholder={placeholder}
            value={value}
            onChange={handleChange}
            error={validation}
          />
        </InputIconWrapper>
        {validation && <InputValidation>{validation}</InputValidation>}
      </InputValidationWrapper>
    </FormInputWrapper>
  );
};

export default FormInput;
