import React, { useEffect } from "react";
import {
  EditDetailSection,
  EditPhotosWrapper,
  EditProfileContainer,
  EditProfileFooter,
  EditTitle,
} from "./EditProfileStyles";
import ProfileCoverPhoto from "../../Auth/PersonalInfo/ProfileCoverPhoto";
import ProfilePhoto from "../../Auth/PersonalInfo/ProfilePhoto";
import BioEdit from "./BioEdit";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useHandleValues } from "../../Hooks/useEventHandler";
import { PrimaryBtn, SecondaryBtn } from "../../Components/Common/CommonStyle";
import { handleProfileEdit } from "../../Functions/getAuthHandler";
import { useNavigate } from "react-router-dom";
import { Footer, Loader } from "../../Components";
import MyPhotosEdit from "./MyPhotosEdit";
import { Helmet } from "react-helmet";
import MoreDetailEdit from "./MoreDetailEdit";
import {
  getEthnicities,
  getLanguages,
  getProfessions,
} from "../../Functions/getFetchFunctions";
import { getFileUpload } from "../../Functions/getFileUpload";
import { useCoverProfilePhoto } from "../../Hooks/useImageInputType";

const EditProfile = () => {
  const user = useSelector((state) => state.auth.user);
  const currentUserId = localStorage.getItem("currentUserId");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [values, setValues] = useState({
    firstName: user?.personalInfo?.firstName || "",
    lastName: user?.personalInfo?.lastName || "",
    location: user?.location || "",
    knownLanguage: user?.personalInfo?.language || "",
    knownLanguageId: user?.personalInfo?.languageKnown || "",
    quotes: user?.personalInfo?.shortBio || "",
    profession: user?.personalInfo?.professional || "",
    professionId: user?.personalInfo?.professionalId || "",
    dob: user?.personalInfo?.dob || "2010-12-07T06:05:23.124Z",
    gender: user?.personalInfo?.gender || "",
    profilePhoto: user?.personalInfo?.profileImage,
    coverPhoto: user?.personalInfo?.coverImage,
    ethnic: user?.morePersonalInfo?.ethnic || null,
    ethnicId: user?.morePersonalInfo?.ethnicId || null,
    myPhotos: user?.morePersonalInfo?.featuredPhotos || [],
    bio: user?.morePersonalInfo?.bio || "",
    myNewPhotos: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    getLanguages(dispatch);
    getEthnicities(dispatch);
    getProfessions(dispatch);
  }, [dispatch]);

  const [errors, setErrors] = useState([]);
  const { handleInputChange, handleGenderOptionChange, handleDropdownChange } =
    useHandleValues({
      values,
      setValues,
      errors,
      setErrors,
    });
  const { handleCoverChange, handleProfileChange } = useCoverProfilePhoto(
    values,
    setValues
  );
  const hasPhotos = values.myPhotos.some((photo) => photo && photo);

  useEffect(() => {
    console.log(user, "user");
  }, [user]);

  const handleSubmit = async () => {
    console.log(values);
    setIsLoading(true);

    let profileImgKey = values.profilePhoto;
    let coverImgKey = values.coverPhoto;
    if (values.profilePhoto?.name) {
      profileImgKey = await getFileUpload(
        values.profilePhoto?.name && values.profilePhoto
      );
    }

    if (values.coverPhoto?.name) {
      coverImgKey = await getFileUpload(
        values.coverPhoto?.name && values.coverPhoto
      );
    }

    console.log(profileImgKey, coverImgKey, values);
    handleProfileEdit(
      currentUserId,
      values,
      profileImgKey,
      coverImgKey,
      dispatch,
      navigate,
      setIsLoading,
      user
    );
  };

  const handleCancel = () => {
    navigate(`/profile/${values.firstName}_${values.lastName}/${user?._id}`);
  };

  useEffect(() => {
    if (currentUserId !== user._id) {
      navigate("/feed");
    }
  }, [user, currentUserId, navigate]);

  return (
    <>
      <Helmet>
        <title>
          Edit Profile | {values.firstName} {values.lastName}
        </title>
      </Helmet>
      {isLoading ? (
        <Loader />
      ) : (
        <EditProfileContainer>
          <EditTitle>Edit Profile</EditTitle>

          <EditPhotosWrapper>
            <ProfileCoverPhoto
              isEdit={true}
              currentImg={values.coverPhoto}
              values={values}
              setValues={setValues}
              onFinalCoverPhotoChange={handleCoverChange}
            />
            <ProfilePhoto
              currentImg={values.profilePhoto}
              values={values}
              setValues={setValues}
              onFinalProfilePhotoChange={handleProfileChange}
            />
          </EditPhotosWrapper>
          <EditDetailSection>
            <BioEdit
              values={values}
              handleInputChange={handleInputChange}
              handleDropdownChange={handleDropdownChange}
              handleGenderChange={handleGenderOptionChange}
            />
            {user?.isTalent && (
              <>
                <MoreDetailEdit
                  values={values}
                  setValues={setValues}
                  handleInputChange={handleInputChange}
                  handleDropdownChange={handleDropdownChange}
                />
                <MyPhotosEdit
                  values={values}
                  setValues={setValues}
                  hasPhotos={hasPhotos}
                />
              </>
            )}
          </EditDetailSection>
          <EditProfileFooter>
            <PrimaryBtn onClick={handleSubmit} isWidthFull={true}>
              Save Changes
            </PrimaryBtn>
            <SecondaryBtn onClick={handleCancel} isWidthFull={true}>
              Cancel
            </SecondaryBtn>
          </EditProfileFooter>
          <Footer />
        </EditProfileContainer>
      )}
    </>
  );
};

export default EditProfile;
