import React from "react";

function NoSoundIcon({ fill, width, height }) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width || "24px"}
      height={height || "24px"}
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill={fill}
        stroke="none"
      >
        <path
          d="M540 4668 c-65 -35 -103 -93 -108 -167 -8 -110 -20 -95 468 -586
l447 -450 -18 -35 c-48 -95 -50 -127 -47 -890 l3 -715 24 -58 c44 -112 143
-207 258 -250 56 -21 74 -22 483 -25 l425 -3 515 -514 c354 -354 527 -519 552
-530 85 -35 184 -11 246 59 45 51 52 90 52 290 l0 180 263 -261 c286 -284 292
-289 397 -281 67 5 120 36 159 94 30 43 39 133 21 190 -10 32 -360 387 -1949
1977 -1065 1066 -1953 1950 -1975 1964 -56 40 -153 44 -216 11z"
        />
        <path
          d="M3540 4674 c-22 -9 -224 -203 -550 -529 l-515 -514 -63 -3 -62 -3
745 -745 745 -745 0 1185 c0 796 -4 1197 -11 1223 -13 49 -63 105 -114 128
-48 22 -127 24 -175 3z"
        />
      </g>
    </svg>
  );
}

export default NoSoundIcon;
