import { Link } from "react-router-dom";
import styled from "styled-components";

export const MDContainerWrapper = styled.main`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
`;

export const MDContainer = styled.section`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem;
  padding-top: 6.5rem;
  width: 100%;
  max-width: 1130px;
  margin-bottom: 3rem;
  @media screen and (max-width: 1450px) {
    padding-top: 5.5rem;
  }
`;

export const MDHeader = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: 35vh;
`;

export const MDHeaderImg = styled.img`
  width: 100%;
  height: 100%;
  border: none;
  object-fit: cover;
  -o-object-fit: cover;
  background: ${(props) => props.theme.altBg};
`;

export const MDHeaderVideo = styled.video`
  width: 100%;
  height: 100%;
  border: none;
  object-fit: cover;
  -o-object-fit: cover;
  background: ${(props) => props.theme.altBg};
`;

export const MDHeaderBlur = styled.div`
  width: 100%;
  height: 35vh;
  position: absolute;
  top: 0;
  backdrop-filter: blur(10px);
  content: "";
`;

export const MDHeaderOverlay = styled.div`
  width: 100%;
  height: 35vh;
  z-index: 1;
  content: "";
  position: absolute;
  top: 0;
  background: ${({ theme }) =>
    theme.mode === "light"
      ? theme.mainBg
      : "var(--linear-background--dark--md)"};
`;

// <---------------------- post content section ---------------------->

export const MDSection = styled.section`
  position: ${({ isFScr }) => (isFScr ? "fixed" : "relative")};
  display: flex;
  align-items: flex-start;
  flex-direction: ${({ isTM }) => isTM && "column"};
  gap: 3rem;
  padding: ${({ isTM }) => isTM && "0 10px"};
  border-radius: 20px;
  width: ${({ isTM }) => (isTM ? "90%" : "maxContent")};
  justify-content: ${({ isTM }) => (isTM ? "center" : "felxStart")};
  justify-content: flex-start;
  width: 100%;
  max-width: 67rem;
  @media screen and (max-width: 1450px) {
    max-width: none;
    width: 90%;
  }
  @media screen and (max-width: 1050px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
  @media screen and (max-width: 950px) {
    width: 100%;
    padding: 0 10px;
  }
`;

export const MDBackbutton = styled.button`
  position: absolute;
  left: -3rem;
  top: 2px;
  display: grid;
  place-items: center;
  padding: 7px;
  border-radius: 100px;
  background: ${(props) => props.theme.mediumMain};
  color: ${(props) => props.theme.dark};
  font-size: 1.3rem;
  border: none;
  cursor: pointer;
  &:hover {
    background: ${(props) => props.theme.mainBg};
  }
`;

export const MDMediaPrevWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: ${({ isTM, isFScr }) => (isFScr ? "100%" : isTM ? "100%" : "28rem")};

  align-items: ${({ isTM }) => isTM && "center"};
  position: ${({ isFScr }) => isFScr && "fixed"};
  z-index: ${({ isFScr }) => isFScr && 101};
  height: ${({ isFScr }) => isFScr && "100vh"};
  top: 0;
  left: 0;
  top: ${({ isFScr }) => isFScr && 0};
  left: ${({ isFScr }) => isFScr && 0};
  @media screen and (max-width: 1390px) {
    /* width: ${({ isTM, isFScr }) =>
      isFScr ? "100%" : isTM ? "100%" : "28rem"}; */
  }
  @media screen and (max-width: 1050px) {
    width: 100%;
  }
`;

export const MDMediaActionWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 6px;
  width: 100%;
`;

export const MDActionBtn = styled.span`
  color: ${(props) => props.theme.dark};
  fill: ${(props) => props.theme.dark};
  border-radius: 100px;
  background: ${(props) => props.theme.mediumBg};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  cursor: pointer;
  gap: 8px;
  p {
    font-size: 11px;
    font-weight: 400;
  }
  &:hover {
    background: ${(props) => props.theme.altBg};
    color: ${(props) => props.theme.defaultLight};
    fill: ${(props) => props.theme.defaultLight};
  }
`;

export const MDDetailsWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: ${({ isTM }) => (isTM ? "100%" : "32rem")};
  gap: 1rem;
  @media screen and (max-width: 1390px) {
    width: ${({ isTM }) => (isTM ? "100%" : "30rem")};
  }
  @media screen and (max-width: 1050px) {
    width: ${({ isTM }) => (isTM ? "100%" : "28rem")};
    width: 100%;
  }
`;

export const MDDetailHeader = styled.header`
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  color: ${(props) => props.theme.dark};
  h1 {
    font-size: 1.8rem;
    width: 90%;
    &::first-letter {
      text-transform: uppercase;
    }
  }
  @media screen and (max-width: 1050px) {
    h1 {
      font-size: 1.3rem;
    }
  }
`;

export const MDHeaderAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  color: ${(props) => props.theme.dark};
  fill: ${(props) => props.theme.dark};
  margin-top: 12px;
  @media screen and (max-width: 1050px) {
    margin-top: 10px;
  }
  @media screen and (max-width: 550px) {
    margin-top: 8px;
  }
`;

export const MDUploadIcon = styled.span`
  font-size: 1.2rem;
  display: grid;
  place-items: center;
  cursor: pointer;
`;

export const MDAuthorContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const MDFlexStart = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
`;

export const MDAuthorWrap = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
`;

export const MDAuthProfilePic = styled.div`
  width: 4rem;
  height: 4rem;
  border-radius: 5px;

  background-position: ${({ bgPosition }) => (bgPosition ? "center" : "top")};
  background-size: 4rem;
  background-repeat: no-repeat;
  background-color: ${(props) => props.theme.mediumMain};
`;

export const MDName = styled(Link)`
  color: ${(props) => props.theme.dark};
  font-size: 18px;
  text-decoration: none;
  font-weight: 500;
  text-transform: capitalize;
  cursor: pointer;
`;

export const MDUserName = styled(Link)`
  text-decoration: none;
  font-size: 12px;
  color: ${(props) => props.theme.main};
  cursor: pointer;
`;

export const MDFollowBtn = styled.p`
  color: ${(props) => props.theme.main};
  font-size: 12px;
  background: rgba(224, 0, 37, 0.18);
  padding: 2px 10px;
  border-radius: 100px;
  color: ${(props) => props.theme.postStarYellow};
  fill: ${(props) => props.theme.postStarYellow};
  background: ${(props) => props.theme.postStarBg};
  cursor: pointer;
`;

export const MDAuthorRating = styled.div`
  display: grid;
  place-items: center;
  @media screen and (max-width: 650px) {
    display: none;
  }
`;

export const MDRatingsd = styled.div`
  display: none;
  @media screen and (max-width: 650px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: -8px;
    p {
      color: ${(props) => props.theme.main};
      font-size: 13px;
    }
  }
`;

export const MDTMRatingTitle = styled.h2`
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 8px;
  border-bottom: 1px solid ${(props) => props.theme.borderColor};
  color: ${(props) => props.theme.dark};
  width: 19rem;
  margin: 5px 0;
`;

export const MDSkill = styled.p`
  color: ${(props) => props.theme.main};
  font-size: 12px;
  padding: 8px;
  border-radius: 5px;
  background: ${(props) => props.theme.mediumBg};
`;

export const MDRatingWrap = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1rem;
  width: 32rem;
  margin-top: ${({ isMT }) => isMT && "6px"};
  @media screen and (max-width: 1350px) {
    width: 30rem;
  }
  @media screen and (max-width: 1050px) {
    width: 28rem;
  }
  @media screen and (max-width: 550px) {
    width: 100%;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
`;

// comments section for more details ----------------------------

export const MDTabsWrapper = styled.section`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 95%;
  padding-left: 10px;
  z-index: 2;
  @media screen and (max-width: 1450px) {
    width: 90%;
  }
  @media screen and (max-width: 950px) {
    width: 100%;
  }
  @media screen and (max-width: 750px) {
    width: max-content;
    justify-content: center;
  }
  @media screen and (max-width: 550px) {
    padding: 0;
    width: 96%;
  }
`;

export const MDTabsTitle = styled.h2`
  display: block;
  padding: 10px 2rem;
  border-bottom: 1px solid
    ${({ isActive, theme }) => (isActive ? theme.dark : theme.borderColor)};
  color: ${({ isActive, theme }) => (isActive ? theme.dark : theme.main)};
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  @media screen and (max-width: 550px) {
    padding: 10px 1.2rem;
    font-size: 13px;
  }
`;

export const MDTabsContainer = styled.section`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  max-width: 67rem;
  gap: 1rem;
  z-index: 2;
  min-height: 20rem;
  @media screen and (max-width: 1450px) {
    width: 90%;
  }
  @media screen and (max-width: 950px) {
    width: 100%;
    padding: 0 10px;
  }
  @media screen and (max-width: 650px) {
    align-items: center;
  }
`;

export const MDCommentSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1.8rem;
  width: 90%;
  @media screen and (max-width: 950px) {
    margin-bottom: 2rem;
  }
  @media screen and (max-width: 550px) {
    width: 100%;
  }
`;
