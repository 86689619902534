import React from "react";

function VideoFolderIcon({ width, height, fill }) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width || "24px"}
      height={height || "24px"}
      style={{ position: "absolute", top: "-45px", right: "-25px" }}
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill={fill || "var(--dark-primary)"}
        stroke="none"
      >
        <path
          d="M965 4784 c-71 -37 -130 -114 -167 -219 -19 -53 -78 -305 -78 -331 0
-11 8 -8 28 11 50 47 120 75 186 74 71 0 111 -7 261 -44 61 -14 135 -32 165
-40 64 -15 505 -129 725 -187 195 -52 263 -69 415 -107 69 -17 148 -37 175
-45 28 -8 69 -17 93 -21 53 -9 53 -21 3 220 -42 206 -52 231 -116 286 -36 32
-87 48 -390 125 -165 41 -370 93 -455 115 -716 185 -779 198 -845 163z"
        />
        <path
          d="M846 4265 c-44 -15 -109 -75 -132 -120 -34 -65 -64 -158 -107 -335
-25 -102 -92 -369 -147 -595 -56 -225 -155 -628 -221 -895 -161 -653 -152
-606 -147 -724 3 -89 8 -109 36 -168 53 -110 150 -184 312 -238 30 -10 190
-53 355 -95 165 -42 431 -109 590 -150 160 -41 414 -105 565 -144 151 -38 381
-97 510 -130 225 -57 500 -127 1102 -280 153 -39 289 -71 302 -71 14 0 28 -5
31 -10 8 -13 122 -13 130 0 3 6 16 10 28 10 33 0 135 52 194 98 86 68 172 204
208 327 20 70 79 296 105 405 21 89 59 232 190 725 198 744 280 1064 280 1099
0 63 -39 160 -90 223 -54 67 -116 103 -220 128 -96 23 -188 47 -450 114 -129
33 -377 96 -550 140 -659 167 -827 210 -875 226 -48 15 -192 53 -730 190 -137
35 -284 73 -325 84 -314 83 -725 182 -793 191 -101 12 -103 12 -151 -5z m1959
-1380 c326 -83 530 -410 460 -740 -84 -395 -513 -607 -883 -435 -156 73 -269
202 -328 375 -36 107 -38 263 -4 370 69 217 218 363 435 426 77 23 239 24 320
4z"
        />
        <path
          d="M2516 2824 c-145 -35 -289 -147 -358 -280 -163 -310 -19 -682 310
-801 289 -105 622 58 724 355 30 89 32 258 4 342 -66 197 -218 340 -407 385
-79 18 -195 18 -273 -1z m253 -439 c89 -96 161 -179 161 -184 0 -4 -33 -16
-72 -25 -269 -62 -413 -92 -416 -86 -1 4 29 105 68 226 39 120 73 225 75 232
3 6 9 12 14 12 5 0 81 -79 170 -175z"
        />
      </g>
    </svg>
  );
}

export default VideoFolderIcon;
