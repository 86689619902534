import React from "react";

function NotificationIcon({ fill, width, height }) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width || "24px"}
      height={height || "24px"}
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill={fill || "var(--dark-primary)"}
        stroke="none"
      >
        <path
          d="M2360 5070 c-363 -44 -720 -207 -995 -453 -307 -276 -504 -625 -581
-1034 -12 -67 -18 -181 -24 -478 -7 -383 -7 -391 -34 -478 -28 -94 -80 -205
-128 -275 -16 -24 -81 -97 -143 -162 -148 -157 -202 -263 -224 -439 -37 -296
126 -600 396 -735 133 -67 151 -69 531 -74 329 -3 342 -4 342 -23 0 -32 58
-200 93 -269 18 -36 50 -92 72 -125 50 -77 223 -250 300 -300 373 -244 814
-246 1176 -6 232 154 406 396 467 652 l17 69 307 0 c351 0 415 8 542 67 153
72 266 183 341 338 55 111 77 203 77 320 1 107 -14 182 -52 275 -39 94 -77
147 -192 267 -112 117 -175 208 -218 316 -61 153 -62 161 -70 577 -4 212 -13
414 -19 450 -70 389 -230 706 -495 978 -122 126 -223 207 -364 293 -190 115
-399 194 -621 235 -102 18 -395 26 -501 14z m465 -374 c578 -113 1017 -552
1150 -1146 13 -59 18 -161 25 -470 9 -427 13 -460 71 -628 63 -180 165 -338
320 -497 110 -112 140 -172 140 -274 0 -39 -7 -95 -15 -124 -32 -110 -130
-210 -239 -242 -75 -22 -3357 -23 -3433 0 -154 46 -255 186 -256 356 -1 109
28 167 140 283 160 166 260 321 321 501 60 177 62 199 71 625 7 337 11 408 28
485 98 445 374 801 781 1005 134 67 341 128 486 144 79 8 332 -3 410 -18z
m425 -3772 c0 -31 -72 -170 -123 -237 -132 -174 -356 -287 -567 -287 -295 0
-582 209 -685 499 l-14 41 694 0 c624 0 695 -2 695 -16z"
        />
      </g>
    </svg>
  );
}

export default NotificationIcon;
