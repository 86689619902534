import React from "react";

function CommentIcon({ fill, width, height }) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width || "24px"}
      height={height || "24px"}
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill={fill || "var(--dark-primary)"}
        stroke="none"
      >
        <path
          d="M1366 4969 c-239 -15 -455 -63 -591 -131 -375 -189 -643 -540 -709
-928 -32 -192 -37 -328 -43 -1147 -6 -895 -3 -982 42 -1138 98 -336 345 -598
680 -718 103 -36 241 -57 385 -57 114 0 130 -2 154 -21 31 -24 34 -67 11 -143
-31 -101 -13 -239 42 -333 13 -23 50 -66 82 -95 111 -103 244 -143 380 -114
40 8 92 26 115 40 23 13 197 135 386 270 369 263 425 297 565 344 131 44 211
52 549 52 320 0 513 11 635 36 154 32 375 135 516 242 197 149 369 386 453
622 70 200 82 347 82 1060 0 1066 -30 1262 -237 1574 -152 229 -412 430 -657
510 -104 34 -294 64 -481 76 -190 12 -2170 11 -2359 -1z m2424 -359 c191 -17
299 -42 404 -94 242 -121 430 -343 496 -589 42 -158 45 -234 45 -1077 l0 -805
-23 -90 c-53 -201 -178 -395 -342 -527 -99 -81 -252 -160 -365 -188 -104 -27
-328 -40 -677 -40 -543 0 -647 -38 -1223 -450 -192 -138 -360 -253 -373 -257
-31 -7 -67 10 -88 41 -15 24 -15 30 5 104 25 93 26 157 5 237 -31 123 -115
225 -231 282 l-74 37 -182 7 c-203 7 -270 20 -384 75 -191 91 -331 264 -384
474 -20 78 -20 102 -16 1007 4 1020 5 1041 66 1222 67 198 210 374 407 497
157 98 316 132 684 144 339 11 2101 4 2250 -10z"
        />
      </g>
    </svg>
  );
}

export default CommentIcon;
