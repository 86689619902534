import { Link } from "react-router-dom";
import styled from "styled-components";

export const SavedContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: 90%;
  padding: 6rem 12rem 1rem 12rem;
  padding: 6rem 0 1rem 0;
  @media screen and (max-width: 1450px) {
    padding: 5rem 0 0 0;
  }
  @media screen and (max-width: 950px) {
    padding: 5rem 2rem 0 2rem;
  }
  @media screen and (max-width: 650px) {
    padding: 5rem 0px 1rem 0px;
    width: 98%;
  }
`;

export const SavedTitleWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const SavedTitle = styled.h1`
  font-size: 1.8rem;
  font-size: 400;
  color: ${(props) => props.theme.dark};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  text-transform: capitalize;
  span {
    color: ${(props) => props.theme.dark};
    font-size: 2rem;
    margin-bottom: -10px;
  }
  h2 {
    font-size: 1.8rem;
    font-size: 400;
    color: ${(props) => props.theme.dark};
    cursor: pointer;
  }
  @media screen and (max-width: 650px) {
    font-size: 1.5rem;
  }
  @media screen and (max-width: 400px) {
    font-size: 1.2rem;
  }
`;

export const SavedFolderContainer = styled.div`
  display: grid;
  grid-gap: 2em;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  margin-top: 1rem;
  @media screen and (max-width: 950px) {
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  }
  @media screen and (max-width: 550px) {
    grid-gap: 1em;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  }
`;

export const EmptyFolderWrapper = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  height: 60vh;
  color: ${(props) => props.theme.main};
  span {
    position: absolute;
    bottom: 10px;
    right: 10px;
    fill: white;
    display: grid;
    place-items: center;
    background: ${(props) => props.theme.primary};
    border-radius: 100px;
    height: 2.2rem;
    width: 2.2rem;
    padding-top: 2px;
  }
  p {
    font-size: 14px;
  }
`;

export const SavedFolderWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media screen and (max-width: 450px) {
    gap: 5px;
  }
`;

export const SavedFolder = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  background-color: ${(props) => props.theme.mediumBg};
  height: 14rem;
  width: 100%;
  @media screen and (max-width: 1400px) {
    height: 12rem;
  }
  @media screen and (max-width: 950px) {
    height: 12rem;
  }
  @media screen and (max-width: 450px) {
    height: 10rem;
    width: 100%;
  }
`;

export const SavedFolderImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 15px;
  object-fit: cover;
  -o-object-fit: cover;
`;

export const SavedFolderTilte = styled(Link)`
  color: ${(props) => props.theme.dark};
  font-size: 14px;
  font-weight: 500;
  width: max-content;
  text-decoration: none;
  &::first-letter {
    text-transform: uppercase;
  }
`;

export const SavedLoader = styled.div`
  display: grid;
  place-items: center;
  height: 70vh;
  width: 100%;
`;

// inside the saved collection folder styles

export const SavedItemWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  p {
    font-size: 12px;
    color: ${(props) => props.theme.dark};
  }
`;

export const SavedItemLink = styled(Link)`
  width: 14rem;
  height: auto;
  border-radius: 15px;
  cursor: pointer;
`;

export const SavedItemImg = styled.img`
  object-fit: cover;
  -o-object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 15px;
`;

export const SavedItemVideo = styled.video`
  object-fit: cover;
  -o-object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 15px;
`;
