import React, { useEffect, useState } from "react";
import { ExploreTabResultWrap, TopHeading, TopTalWrap } from "./ExploreStyles";
import { useDispatch } from "react-redux";
import { getSearchResults } from "../../Functions/getFetchFunctions";
import { PostOverview } from "../../Components";

const TopPosts = ({ setSearchLoading }) => {
  const currentUserId = localStorage.getItem("currentUserId");
  const dispatch = useDispatch();
  const [allPostSuggest, setAllPostSuggest] = useState([]);
  let pageNo = 1;
  let pageSize = 12;

  useEffect(() => {
    getSearchResults(
      "ALL",
      currentUserId,
      pageNo,
      pageSize,
      dispatch,
      setSearchLoading,
      setAllPostSuggest
    ).then(() => {
      setSearchLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TopTalWrap>
      <TopHeading>All Posts</TopHeading>
      <ExploreTabResultWrap>
        {allPostSuggest.map((item, index) => (
          <PostOverview
            key={index}
            mediaType={item.media[0].mediaType}
            postType={item.media[0].type}
            postId={item._id}
            sourceUrl={item.media[0].url}
            authorId={item.author._id}
            firstName={item.author.firstName}
            lastName={item.author.lastName}
            userName={item.author.userName}
            profileImg={item.author.profileImage}
            postTitle={item.about.title}
            postAvgRating={item.postRating}
            ratersCount={item.numberOfRating}
          />
        ))}
      </ExploreTabResultWrap>
    </TopTalWrap>
  );
};

export default TopPosts;
