import { Link } from "react-router-dom";
import styled from "styled-components";

export const PostWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 28.5rem;
  @media screen and (max-width: 1450px) {
    /* width: 26rem; */
    /* width: 40vw; */
  }
  @media screen and (max-width: 650px) {
    width: 95%;
  }
`;

export const PostHeader = styled.header`
  display: flex;
  gap: 10px;
  align-items: flex-start;
  justify-content: space-between;
  @media screen and (max-width: 500px) {
    background: ${(props) => props.theme.mediumBg};
    border-radius: 15px 15px 0 0;
    align-items: center;
  }
`;

export const PostUserDpWrap = styled(Link)`
  display: grid;
  place-items: center;
  padding: 4px;
  background: ${(props) => props.theme.mediumBg};
  border-radius: 15px;
  z-index: 2;
  text-decoration: none;
  cursor: pointer;
  @media screen and (max-width: 500px) {
    padding: 0;
    margin-left: 10px;
  }
`;

export const PostHeaderDetail = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  position: relative;
  padding: 6px 10px 8px 12px;
  background: ${(props) => props.theme.mediumBg};
  border-radius: 15px 15px 0 0;

  &::before {
    content: "";
    position: absolute;
    left: -29.9px;
    right: 0;
    bottom: 0px;
    border-bottom-right-radius: 20px;
    z-index: 1;
    background: ${(props) => props.theme.mainBg};
    width: 30px;
    height: 30px;
  }

  &::after {
    content: "";
    position: absolute;
    left: -18px;
    right: 0;
    bottom: 0px;
    z-index: 0;
    background: ${(props) => props.theme.mediumBg};
    width: 20px;
    height: 20px;
  }
  @media screen and (max-width: 500px) {
    width: 100%;
    padding: 10px 10px 10px 5px;
    &::before {
      background: none;
      width: 0px;
      height: 0px;
    }
  }
`;

export const PostUserNameWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  span {
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: ${(props) => props.theme.main};
    p {
      font-size: 12px;
      max-width: 13rem;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  @media screen and (max-width: 950px) {
    span {
      p {
        max-width: 9rem;
      }
    }
  }
  @media screen and (max-width: 390px) {
    span {
      p {
        max-width: 8rem;
      }
    }
  }
`;

export const PostUserName = styled(Link)`
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 13px;
  font-weight: 500;
  color: ${(props) => props.theme.dark};
  text-decoration: none;
  cursor: pointer;
  width: max-content;
`;

export const PostHeaderActionWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

export const PostAvgRating = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  color: ${(props) => props.theme.postStarYellow};
  fill: ${(props) => props.theme.postStarYellow};
  background: ${(props) => props.theme.postStarBg};
  border-radius: 5px;
  padding: 2px 8px;
  span {
    display: grid;
    place-items: center;
    margin-top: 2px;
  }
`;

// <---------------------- post media style ---------------------->

export const PostBody = styled.section`
  background: ${(props) => props.theme.mediumBg};
  padding: 5px 10px 10px 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 15px 0 15px 15px;
  @media screen and (max-width: 500px) {
    padding: 0 0 10px 0;
    border-radius: 0px 0px 15px 15px;
  }
`;

export const PostMediaWrap = styled.div`
  display: grid;
  place-items: center;
  position: ${({ isFScr }) => isFScr && "fixed"};
  z-index: ${({ isFScr }) => isFScr && 101};
  width: 100%;
  /* max-height: ${({ isFScr }) => (isFScr ? "100%" : "37rem")}; */
  top: 0;
  left: 0;
  /* border: 1px solid blue; */
`;

export const PostBodyContent = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media screen and (max-width: 500px) {
    padding: 0 10px;
  }
`;

export const PostMediaPrevContainer = styled.div`
  display: grid;
  place-items: center;
  position: relative;
  min-height: 20rem;
  border-radius: 20px;
  width: 100%;
  background: ${(props) => props.theme.mediumBg};
  z-index: 1;
`;

export const PostMediaPreview = styled.img`
  object-fit: cover;
  -o-object-fit: cover;
  width: 100%;
  height: 100%;
  min-height: 20rem;
  max-height: 42rem;
  border-radius: 15px;
  @media screen and (max-width: 1450px) {
    max-height: 34rem;
  }
`;

export const PostMediaVideoPreview = styled.video`
  object-fit: cover;
  -o-object-fit: cover;
  width: 100%;
  height: 100%;
  min-height: 20rem;
  max-height: 34rem;
  border-radius: 15px;
`;

export const PostMediaIconWrap = styled.div`
  height: 1.7rem;
  width: 1.7rem;
  font-size: 1.1rem;
  cursor: pointer;
  display: grid;
  place-items: center;
  z-index: 2;
  padding: ${({ padR, padN }) =>
    padR ? "0 2px 0 0" : padN ? "0" : "0 0 0 2px"};
  background: ${(props) => props.theme.mediumMain};
  border-radius: 100px;
  color: ${(props) => props.theme.dark};
  &:hover {
    background: ${(props) => props.theme.mediumBg};
  }
`;

// media component style -----------------------------------------------------

export const MediaPrevOverlay = styled.div`
  // 1st child of media container
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 12%;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  justify-content: ${({ isImage }) => (isImage ? "flex-end" : "space-between")};
  gap: 9px;
  padding: 11px;
  z-index: 3;
  border-radius: ${({ isFScr }) => (isFScr ? "0" : "15px")};
  fill: white;
  background: ${({ isImage, isPlaying }) =>
    isImage
      ? "none"
      : isPlaying
      ? "none"
      : "var(--linear-background-media-overlay)"};
  @media screen and (max-width: 650px) {
    padding: 10px;
  }
  @media screen and (max-width: 500px) {
    border-radius: 0;
  }
`;

export const MediaControlContainer = styled.div`
  // 2nd child of media container
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  font-size: 14px;
  color: white;
  width: 100%;
  border-radius: 100px;
  opacity: ${({ isPlaying }) => (isPlaying ? 0 : 1)};
  transition: opacity 0.5s, bottom 0.3s;
  @media screen and (max-width: 500px) {
    border-radius: 0;
    opacity: ${({ isPlaying, isFScr }) => (isPlaying && !isFScr ? 0 : 1)};
  }
`;

export const MediaPrevContainer = styled.div`
  // main parent
  height: ${({ isFScr }) => isFScr && "100%"};
  display: grid;
  place-items: center;

  min-height: 20rem;
  /* max-height: auto; */
  border-radius: ${({ isFScr }) => (isFScr ? "0" : "14px")};
  width: ${({ isMultiple, isTM, isFScr }) =>
    isMultiple ? "100%" : isTM ? "100%" : isFScr ? "100%" : "28rem"};
  /* width: 100%; */
  /* max-height: 60vh; */
  background: ${(props) => props.theme.mediumBg};
  overflow: hidden;
  &:hover ${MediaControlContainer} {
    opacity: 1;
  }
  &:hover ${MediaPrevOverlay} {
    background: ${({ isImage }) =>
      isImage ? "none" : "var(--linear-background-media-overlay)"};
    transition: background 0.5s ease-in-out;
  }
  background: black;
  //
  /* max-height: ${({ isFScr, isMultiple }) =>
    isMultiple ? (isFScr ? "100vh" : "37rem") : "37rem"}; */
  max-height: ${({ isFScr }) => (isFScr ? "100vh" : "37rem")};
  position: ${({ isFScr }) => (isFScr ? "fixed" : "relative")};
  top: ${({ isFScr }) => isFScr && 0};
  left: ${({ isFScr }) => isFScr && 0};
  z-index: ${({ isFScr }) => isFScr && 999};
  height: ${({ isFScr }) => isFScr && "100%"};

  @media screen and (max-width: 500px) {
    border-radius: 0;
  }
`;

export const MediaImagePreview = styled.img`
  /* object-fit: cover; */
  /* -o-object-fit: cover; */
  object-fit: contain;
  -o-object-fit: contain;
  background: black;
  width: 100%;
  height: 100%;
  /* width: 40vw; */
  /* min-height: 20rem; */
  /* max-height: ${({ isFScr }) => (isFScr ? "100%" : "42rem")}; */
  /* border-radius: ${({ isFScr }) => (isFScr ? "0" : "15px")}; */
  @media screen and (max-width: 1450px) {
    /* max-height: ${({ isFScr }) => (isFScr ? "100%" : "32rem")}; */
  }
  @media screen and (max-width: 500px) {
    border-radius: 0;
  }
`;

export const MediaVideoPreview = styled.video`
  object-fit: cover;
  -o-object-fit: cover;
  object-fit: contain;
  -o-object-fit: contain;
  width: 100%;
  height: 100%;
  min-height: 20rem;
  max-height: ${({ isFScr }) => (isFScr ? "100%" : "37rem")};
  border-radius: ${({ isFScr }) => (isFScr ? "0" : "15px")};
  background: ${(props) => props.theme.mediumBg};
  background: black;
  @media screen and (max-width: 1450px) {
    max-height: ${({ isFScr }) => (isFScr ? "100%" : "34rem")};
  }
  @media screen and (max-width: 500px) {
    border-radius: 0;
    object-fit: contain;
    -o-object-fit: contain;
  }
`;

export const PlayBtnWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: ${({ isFScr }) => (isFScr ? "0" : "15px")};
  z-index: 2;
  opacity: ${({ isPlaying }) => (isPlaying ? 0 : 1)};
  cursor: ${({ isPlaying }) => (isPlaying ? "default" : "pointer")};
  p {
    color: #fafafa;
    font-size: 12px;
    font-weight: 400;
  }
  transition: opacity 0.5s, bottom 0.3s;
  background: rgba(0, 0, 0, 0.5);
  /* box-shadow: 0px 0px 30px 20px rgba(0, 0, 0, 0.17); */
`;

export const PlayBtn = styled.button`
  display: grid;
  place-items: center;
  height: 3rem;
  width: 3rem;
  border: none;
  border-radius: 100px;
  background: ${(props) => props.theme.primary};
  fill: white;
  cursor: pointer;
  padding-left: ${({ padN }) => (padN ? "0" : "2px")};
`;

export const MediaIconWrap = styled.div`
  min-height: 1.7rem;
  min-width: 1.7rem;
  max-height: 1.7rem;
  max-width: 1.7rem;
  font-size: 1.1rem;
  cursor: pointer;
  display: grid;
  place-items: center;
  z-index: 2;
  padding: ${({ padR, padN }) =>
    padR ? "0 2px 0 0" : padN ? "0" : "0 0 0 2px"};
  background: ${({ theme, isNoBg }) => (isNoBg ? "none" : theme.mediumMain)};
  border-radius: 100px;
  padding-right: ${({ padT }) => padT && "1px"};
  color: ${(props) => props.theme.dark};
  fill: ${({ theme, isNoBg }) => (isNoBg ? theme.defaultLight : theme.dark)};
  &:hover {
    background: ${({ theme, isNoBg }) =>
      theme.mode === "light"
        ? isNoBg
          ? "#858585"
          : theme.mediumBg
        : isNoBg
        ? theme.mediumMain
        : theme.mediumBg};
  }
`;

export const MediaRangeInput = styled.input`
  width: 100%;
  min-width: 10rem;
  height: 4px;
  -webkit-appearance: none;
  background: rgba(250, 250, 250, 0.4);
  outline: none;
  border-radius: 20px;
  overflow: hidden;
  /* for firefox */
  &::-moz-range-thumb {
    cursor: pointer;
    width: 5px;
    height: 15px;
    background: ${(props) => props.theme.primary};
    border: 4px solid ${(props) => props.theme.primary};
    border: none;
    box-shadow: -705px 0 0 700px ${(props) => props.theme.primary};
  }
  &::-moz-range-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    background: none;
    border-radius: 10px;
  }
  /* for google chrome and others*/
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    cursor: pointer;
    width: 5px;
    height: 15px;
    background: green;
    border: 4px solid ${(props) => props.theme.primary};
    box-shadow: -705px 0 0 700px ${(props) => props.theme.primary};
  }
  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    background: none;
    border-radius: 10px;
  }
`;

export const TopCornerDetails = styled.p`
  color: #fafafa;
  font-size: 12px;
  font-weight: 400;
  min-width: 2.5rem;
  display: grid;
  place-items: center;
  position: absolute;
  top: 10px;
  right: ${({ isRight }) => isRight && "8px"};
  left: ${({ isLeft }) => isLeft && "2px"};
  background: rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 30px 20px rgba(0, 0, 0, 0.17);
`;

// <---------------------- post content style ---------------------->

export const PostActionWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 4px;
`;

export const PostActionIconsSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  span {
    fill: ${(props) => props.theme.dark};
    cursor: pointer;
    display: grid;
    place-items: center;
  }
`;

export const PostTitle = styled(Link)`
  font-size: 14px;
  color: ${(props) => props.theme.dark};
  font-weight: 500;
  margin: -4px 0 0 4px;
  cursor: pointer;
  text-decoration: none;
  width: max-content;
  &::first-letter {
    text-transform: uppercase;
  }
`;

export const PostWebsiteLink = styled.a`
  display: flex;
  justify-content: flex-start;
  align-items: start;
  gap: 8px;
  font-size: 12px;
  font-weight: 400;
  /* width: max-content; */
  max-width: 90%;
  color: ${(props) => props.theme.hashTags};
  margin: -3px 4px 0 4px;
  max-height: ${({ maxHeight }) => (maxHeight ? maxHeight : "1.1rem")};
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  cursor: pointer;
  span {
    font-size: 14px;
  }
`;

export const PostsubTitle = styled.h3`
  font-size: 12px;
  font-weight: 400;
  color: ${(props) => props.theme.main};
  margin: -4px 4px 0 4px;
  display: flex;
  justify-content: space-between;
  &::first-letter {
    text-transform: uppercase;
  }
`;

export const PostMoreDetails = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  font-size: 11px;
  color: ${(props) => props.theme.postStarYellow};
  cursor: pointer;
  text-decoration: none;
`;

export const PostHashtags = styled(Link)`
  font-size: 12px;
  text-decoration: none;
  margin-right: 5px;
  color: ${(props) => props.theme.hashTags};
`;

export const PostContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  max-height: ${({ maxHeight }) => (maxHeight ? maxHeight : "2rem")};
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
  gap: 0px;
  margin: 0 4px;
`;

export const PostDesc = styled.p`
  font-size: 12px;
  color: ${(props) => props.theme.main};
  &::first-letter {
    text-transform: uppercase;
  }
`;

// <---------------------- post action button styles ---------------------->

export const ActionMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 10px 0 10px 10px;
  background: ${(props) => props.theme.mediumBg};
  padding: 1rem;
  position: absolute;
  z-index: 10;
  right: ${({ right }) => (right ? right : "2rem")};
  top: ${({ top }) => (top ? top : "1.5rem")};
  box-shadow: 1px 7px 10px 4px rgba(0, 0, 0, 0.2);
  width: ${({ width }) => (width ? width : "9rem")};
  cursor: default;
`;

export const ActionItems = styled.p`
  color: ${({ isRed, theme }) => (isRed ? theme.red : theme.main)};
  fill: ${({ isRed, theme }) => (isRed ? theme.red : theme.main)};
  cursor: pointer;
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 1.1rem;
  span {
    font-size: 13px;
    display: grid;
    place-items: center;
  }
  &:hover {
    color: ${(props) => props.theme.dark};
    fill: ${(props) => props.theme.dark};
    span {
      color: ${(props) => props.theme.dark};
      fill: ${(props) => props.theme.dark};
    }
  }
`;

export const SeeMoreBtn = styled.button`
  font-size: 12px;
  text-align: justify;
  color: ${(props) => props.theme.main};
  width: 98%;
  background: ${(props) => props.theme.mediumBg};
  border: none;
  margin: ${({ margin }) => (margin ? "-8px 0 0 4px" : "-15px 0 0 4px")};
  cursor: pointer;
  z-index: 1;
`;

export const PinnedPostActionBtn = styled.button`
  display: grid;
  place-items: center;
  background: rgba(146, 146, 146, 0.4);
  padding: 2px 5px;

  padding: 5px;
  border-radius: 100px;
  border: none;
  color: var(--white);
  font-size: 1.2rem;
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
  z-index: 5;
  &:hover {
    background: rgba(146, 146, 146, 0.7);
  }
`;

// <---------------------- post overview styles ---------------------->

export const PostOverviewWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`;

export const PostOverviewCard = styled.div`
  width: 100%;
  height: auto;
  max-height: auto;
  color: white;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 1;
  cursor: pointer;
  margin-bottom: 10px;
  break-inside: avoid;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 20px;
    background: none;
  }
`;

export const PostOverviewMedia = styled.img`
  width: 100%;
  display: block;
  border-radius: 20px;
  -o-object-fit: cover;
  object-fit: cover;
  background: ${(props) => props.theme.mainBg};
  border: none;
`;

export const MasonryWrap = styled.div`
  width: 100%;
  columns: 4;
  column-gap: 15px;
`;

export const PostOverviewTitleLink = styled(Link)`
  font-size: 15px;
  color: ${(props) => props.theme.dark};
  text-decoration: none;
  width: max-content;
  &::first-letter {
    text-transform: uppercase;
  }
`;

export const PostOverviewUserIdLink = styled(Link)`
  font-size: 13px;
  color: ${(props) => props.theme.main};
  width: max-content;
  text-decoration: none;
  &::first-letter {
    text-transform: uppercase;
  }
`;

// post horizontal overview like youtube in profile -------------------------------

export const PostHorzContiner = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  width: 100%;
  gap: 1.1rem;
  padding: 10px;
  border-radius: 20px;
  text-decoration: none;
  background: ${(props) => props.theme.mediumBg};
  @media screen and (max-width: 650px) {
    gap: 12px;
  }
`;

export const PostHorzMediaCard = styled(Link)`
  position: relative;
  display: grid;
  place-items: center;
  width: 25rem;
  height: 16rem;
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
  @media screen and (max-width: 1450px) {
    width: 22rem;
    height: 14rem;
  }
  @media screen and (max-width: 650px) {
    height: 10rem;
    width: 8rem;
  }
`;

export const PostHorzPin = styled.span`
  position: absolute;
  display: ${({ isDisplay }) => (isDisplay ? "flex" : "hidden")};
  align-items: center;
  justify-content: center;
  gap: 10px;
  top: 10px;
  left: 10px;
  background: rgba(0, 0, 0, 0.15);
  box-shadow: ${({ isDisplay }) =>
    isDisplay ? "0px 0px 30px 20px rgba(0, 0, 0, 0.17)" : "none"};
  @media screen and (max-width: 650px) {
    top: 5px;
    left: 5px;
  }
`;

export const PostHorzMedia = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  -o-object-fit: cover;
`;

export const PostHorzDetailWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
`;

export const PostHorzTitle = styled(Link)`
  font-weight: 500;
  font-size: 1.2rem;
  width: max-content;
  color: ${(props) => props.theme.dark};
  text-align: left;
  font-size: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-decoration: none;
  cursor: pointer;
  &::first-letter {
    text-transform: uppercase;
  }
  @media screen and (max-width: 950px) {
    font-size: 18px;
    width: 14rem;
  }
  @media screen and (max-width: 400px) {
    font-size: 16px;
    width: 11rem;
  }
`;

export const ActionMenuWrap = styled.span`
  position: absolute;
  top: 1rem;
  right: 10px;
  z-index: 3;
  @media screen and (max-width: 650px) {
    top: 10px;
  }
`;

export const PostHorzDesc = styled.p`
  font-size: 14px;
  color: ${(props) => props.theme.main};
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  &::first-letter {
    text-transform: uppercase;
  }
  margin: 5px 0;
  @media screen and (max-width: 1450px) {
    font-size: 12px;
  }
`;

export const PostHorzSubtitle = styled.p`
  display: flex;
  align-items: center;
  font-size: 12px;
  color: ${(props) => props.theme.main};
  margin-bottom: ${({ isMB }) => (isMB ? "-10px" : "0")};
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: auto;
  p {
    display: inline-block;
    place-items: center;
    font-size: 12px;
    color: ${(props) => props.theme.main};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: auto;
  }
  span {
    display: grid;
    place-items: center;
    font-size: 13px;
    margin-right: 5px;
    margin-left: -2px;
  }
  &::first-letter {
    text-transform: uppercase;
  }
  @media screen and (max-width: 1450px) {
    span {
      font-size: 12px;
    }
    p {
      font-size: 11px;
    }
  }
  @media screen and (max-width: 550px) {
    max-width: 11rem;
    p {
      /* max-width: 9rem; */
    }
  }
`;

// <---------------------- post more details styles ---------------------->
export const MDContainer = styled.main`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem;
  padding-top: 8rem;
  width: 100%;
  @media screen and (max-width: 1450px) {
    padding-top: 6rem;
  }
`;

export const MDHeader = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: 35vh;
`;

export const MDHeaderImg = styled.img`
  width: 100%;
  height: 100%;
  border: none;
  object-fit: cover;
  -o-object-fit: cover;
`;

export const MDHeaderBlur = styled.div`
  width: 100%;
  height: 35vh;
  position: absolute;
  top: 0;
  backdrop-filter: blur(10px);
  content: "";
`;

export const MDHeaderOverlay = styled.div`
  width: 100%;
  height: 35vh;
  z-index: 1;
  content: "";
  position: absolute;
  top: 0;
  background: ${({ theme }) =>
    theme.mode === "light"
      ? "var(--linear-background--light--md)"
      : "var(--linear-background--dark--md)"};
`;

// <---------------------- post more details section ---------------------->

export const MDSection = styled.section`
  display: flex;
  align-items: flex-start;
  gap: 3rem;
  padding: 10px;
  border-radius: 20px;
  width: max-content;
  z-index: 2;
  @media screen and (max-width: 1050px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
`;

export const MDMediaPrevWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const MDMediaPrev = styled.div`
  width: 28rem;
  height: auto;
  max-height: 36rem;
  border-radius: 5px;
  @media screen and (max-width: 1450px) {
    width: 24rem;
  }
  @media screen and (max-width: 450px) {
    width: 22rem;
  }
`;

export const MDDetailsWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 32rem;
  gap: 1rem;
  @media screen and (max-width: 1350px) {
    width: 30rem;
  }
  @media screen and (max-width: 1050px) {
    width: 28rem;
  }
  @media screen and (max-width: 550px) {
    width: 24rem;
  }
  @media screen and (max-width: 450px) {
    width: 22rem;
    /* padding: 10px; */
  }
`;

export const MDDetailHeader = styled.header`
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  color: ${(props) => props.theme.dark};
  h1 {
    font-size: 1.8rem;
    width: 90%;
    &::first-letter {
      text-transform: uppercase;
    }
  }
  @media screen and (max-width: 1050px) {
    h1 {
      font-size: 1.3rem;
    }
  }
`;

export const MDHeaderAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  color: ${(props) => props.theme.dark};
  fill: ${(props) => props.theme.dark};
  margin-top: 12px;
  @media screen and (max-width: 1050px) {
    margin-top: 10px;
  }
  @media screen and (max-width: 550px) {
    margin-top: 8px;
  }
`;

export const MDUploadIcon = styled.span`
  font-size: 1.2rem;
  display: grid;
  place-items: center;
  cursor: pointer;
`;

export const MDAuthorContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const MDFlexStart = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
`;

export const MDAuthorWrap = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
`;

export const MDAuthProfilePic = styled.div`
  width: 4rem;
  height: 4rem;
  border-radius: 5px;

  background-position: ${({ bgPosition }) => (bgPosition ? "center" : "top")};
  background-size: 4rem;
  background-repeat: no-repeat;
  background-color: ${(props) => props.theme.mediumMain};
`;

export const MDName = styled(Link)`
  color: ${(props) => props.theme.dark};
  font-size: 18px;
  text-decoration: none;
  font-weight: 500;
  text-transform: capitalize;
`;

export const MDFollowBtn = styled.p`
  color: ${(props) => props.theme.main};
  font-size: 12px;
  background: rgba(224, 0, 37, 0.18);
  padding: 2px 10px;
  border-radius: 100px;
  color: ${(props) => props.theme.postStarYellow};
  fill: ${(props) => props.theme.postStarYellow};
  background: ${(props) => props.theme.postStarBg};
  cursor: pointer;
`;

export const MDAuthorRating = styled.div`
  display: grid;
  place-items: center;
  @media screen and (max-width: 650px) {
    display: none;
  }
`;

export const MDRatingsd = styled.div`
  display: none;
  @media screen and (max-width: 650px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: -8px;
    p {
      color: ${(props) => props.theme.main};
      font-size: 13px;
    }
  }
`;

export const MDSkill = styled.p`
  color: ${(props) => props.theme.main};
  font-size: 12px;
  padding: 8px;
  border-radius: 5px;
  background: ${(props) => props.theme.mediumBg};
`;

export const MDRatingWrap = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1rem;
  width: 32rem;
  @media screen and (max-width: 1350px) {
    width: 30rem;
  }
  @media screen and (max-width: 1050px) {
    width: 28rem;
  }
  @media screen and (max-width: 550px) {
    width: 100%;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
`;

// post vertical overview styles ------------------------------------------------

export const PostVertiWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const PostVertiMediaLink = styled(Link)`
  width: 14rem;
  height: auto;
  border-radius: 15px;
  cursor: pointer;
`;

// talents card styles -------------------------------------------------------------
