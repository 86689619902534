import React from "react";
import { HalfStarIcon, StarIcon } from "../../Assets/SVG";
import styled, { useTheme } from "styled-components";

const StarDisplayWrapper = styled.div`
  width: 16rem;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.main};
  @media screen and (max-width: 550px) {
    width: max-content;
  }
`;

const HalfStarWrap = styled.span`
  display: grid;
  place-items: center;
  position: relative;

  span {
    display: grid;
    place-items: center;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 2;
  }
`;

const SingleStar = styled.span`
  margin-right: 5px;
  display: grid;
  place-items: center;
`;

const RatingDesc = styled.p`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  font-size: 14px;
  margin-left: 5px;
  span {
    font-size: 12px;
    &:nth-child(2n) {
      display: none;
    }
  }
  @media screen and (max-width: 1450px) {
    gap: 5px;
    span {
      &:nth-child(1n) {
        font-size: 11px;
        margin-left: 2px;
      }
    }
  }
  @media screen and (max-width: 450px) {
    gap: 5px;
    span {
      &:nth-child(1n) {
        display: none;
      }
      &:nth-child(2n) {
        display: grid;
        place-content: center;
        font-size: 11px;
      }
    }
  }
`;

const StarDisplay = ({ rating, peopleCount, isAllPost }) => {
  const theme = useTheme();
  // to get the exact first floating num
  let ratingValue = Math.floor(rating * 10) / 10;

  const star_ratings = Array.from({ length: 5 }, (element, index) => {
    const number = index + 0.1;
    let starComponent;
    const lastDecimal = ratingValue % 1;
    const tenthsPlace = ((lastDecimal * 10) % 10).toFixed(0);
    const tenthPlaceFloat = tenthsPlace / 10;

    if (number <= rating) {
      if (number <= Math.floor(rating)) {
        starComponent = <StarIcon width={"16px"} fill={theme.yellow} />;
      } else if (tenthPlaceFloat <= 0.9) {
        starComponent = (
          <HalfStarWrap>
            <StarIcon width={"16px"} fill={theme.mediumMain} />
            <span>
              <HalfStarIcon width={"16px"} fill={theme.yellow} />
            </span>
          </HalfStarWrap>
        );
      }
    } else {
      starComponent = <StarIcon width={"16px"} fill={theme.mediumMain} />;
    }

    return <SingleStar key={index}>{starComponent}</SingleStar>;
  });

  return (
    <StarDisplayWrapper>
      {star_ratings}
      <RatingDesc isAllPost={isAllPost}>
        {Math.floor(ratingValue) === 0 ? "N/A" : ratingValue.toFixed(1)}

        <span>{peopleCount}&nbsp;Ratings</span>
        <span>({peopleCount})</span>
      </RatingDesc>
    </StarDisplayWrapper>
  );
};

export default StarDisplay;
