import styled from "styled-components";
import { IoMdClose } from "react-icons/io";
import { Link, Link as LinkR } from "react-router-dom";

export const ModalBg = styled.main`
  position: fixed;
  display: flex;
  align-items: ${({ isBottom }) => (isBottom ? "flex-end" : "center")};
  justify-content: ${({ isBottom }) => (isBottom ? "flex-end" : "center")};
  top: 0;
  left: 0;
  background: ${({ theme, isOtp }) => (isOtp ? theme.otpBg : theme.modalBg)};
  z-index: 99;
  width: 100%;
  height: 100%;
  transition: 0.3s ease-in-out;
  @media screen and (max-width: 700px) {
    background: ${({ theme, isOtp }) => (isOtp ? "#061029" : theme.modalBg)};
  }
`;

export const CloseButton = styled(IoMdClose)`
  font-size: 1.5rem;
  font-weight: bold;
  color: ${(props) => props.theme.dark};
  cursor: pointer;
  display: none;
  @media screen and (max-width: 650px) {
    display: grid;
  }
`;

export const EmptyModalDataWrap = styled.div`
  width: 100%;
  height: ${({ height }) => (height ? height : "40vh")};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0;
  color: ${(props) => props.theme.main};
  fill: ${(props) => props.theme.main};
  font-size: 13px;
  p {
    font-size: 12px;
  }
`;

// Crop Photo Modal Styles ----------------------------------

export const CropPhotoModal = styled.div`
  display: flex;
  flex-direction: column;
  width: 40rem;
  height: max-content;
  border-radius: 20px;
  background: ${(props) => props.theme.mainBg};
  position: relative;
  gap: 0.3rem;
  @media screen and (max-width: 1450px) {
    width: 35rem;
  }
  @media screen and (max-width: 950px) {
    width: 32rem;
  }
  @media screen and (max-width: 550px) {
    width: 100%;
    border-radius: 0;
    gap: 0;
  }
`;

export const ModalHeader = styled.header`
  display: flex;
  justify-content: ${({ isCenter }) => (isCenter ? "center" : "space-between")};
  align-items: center;
  font-size: 16px;
  padding: 0.5rem 0.8rem 0.5rem 1rem;
  color: ${(props) => props.theme.dark};
  font-weight: 500;
  border-bottom: 1px solid ${(props) => props.theme.mediumMain};
  @media screen and (max-width: 650px) {
    justify-content: space-between;
  }
`;

export const EditingToolsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.3rem;
  }
`;

export const CropPhotoContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 450px;
  width: 94%;
  border: none;
  margin: 1rem;
  @media screen and (max-width: 1450px) {
    height: 320px;
    margin-bottom: 0rem;
  }
  @media screen and (max-width: 550px) {
    width: 100%;
    margin: 0 1rem 0 0;
  }
`;

export const SliderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 1rem 0;
`;

export const ModalButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  padding: 0 1rem 1rem;
  @media screen and (max-width: 1050px) {
    justify-content: center;
    padding: 0.5rem 0 1rem 0;
  }
`;

// Error Modal Styles ----------------------------------

export const ErrorModalContainer = styled.div`
  width: 25rem;
  max-width: 30rem;
  min-height: 5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.5rem;
  border-radius: 10px;
  background: ${(props) => props.theme.mainBg};
`;

export const ErrorMessageHeader = styled.div`
  width: 100%;
  padding: 0.5rem 0 0.2rem 0.5rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  border-bottom: 1px solid ${(props) => props.theme.borderColor};
  color: ${(props) => props.theme.dark};
  fill: ${(props) => props.theme.primary};
`;

export const ErrorMessage = styled.div`
  display: flex;
  width: 100%;
  padding: 0 1rem;
  height: auto;
  justify-content: flex-start;
  text-align: justify;
  color: ${(props) => props.theme.dark};
`;

export const ErrorButtonWrapper = styled.div`
  padding: 0 1rem 1rem 0;
`;

// Upgrade Account Type Modal Styles ---------------------------------------------------------------

export const UpgradeAccountModal = styled.div`
  width: 90%;
  height: 90vh;
  background: var(--white);
  border-radius: 20px;
`;

// Alert Modal Styles -------------------------------------------------------------------------------

export const AlertWrapper = styled.div`
  display: grid;
  place-items: center;
  position: fixed;
  width: max-content;
  height: 4.5rem;
  z-index: 100;
  transform: translate(-50%, -50%);
  top: 2rem;
  left: 50%;
`;

export const AlertModalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 0.5rem 1rem;
  font-size: 13px;
  background: ${(props) => props.theme.light};
  color: ${(props) => props.theme.dark};
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid ${(props) => props.theme.mediumBg};
  border-radius: 100px;
  width: max-content;
  /* position: fixed; */
  /* transform: translate(-50% -50%); */

  @media screen and (max-width: 1200px) {
    margin-left: 0;
  }
  @media screen and (max-width: 650px) {
    margin-top: 4rem;
  }
  @media screen and (max-width: 450px) {
    height: 4rem;
    width: 90vw;
    border-radius: 10px;
    margin-top: 1rem;
    justify-content: flex-start;
  }
`;

export const AlertIcon = styled.div`
  display: grid;
  place-items: center;
  font-size: 1.5rem;
  @media screen and (max-width: 450px) {
    font-size: 2.5rem;
  }
`;

// photo view modal ----------------------------------

export const PhotoViewContainer = styled.div`
  position: relative;
  width: max-content;
  height: max-content;
  @media screen and (max-width: 1000px) {
    width: 80%;
    height: auto;
  }
  @media screen and (max-width: 650px) {
    width: 100%;
  }
`;
export const PhotoImg = styled.img`
  width: 100%;
  height: 100%;
  max-height: 70vh;
  border-radius: 10px;
  object-fit: cover;
  -o-object-fit: cover;
  @media screen and (max-width: 650px) {
    border-radius: 0;
  }
`;

export const CloseButtonWrapper = styled.div`
  position: absolute;
  display: grid;
  place-items: center;
  right: -4rem;
  top: -2rem;
  background: ${(props) => props.theme.mainBg};
  border-radius: 100px;
  padding: 0.5rem;
  color: ${(props) => props.theme.main};
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: bold;
  &:hover {
    color: ${(props) => props.theme.dark};
    background: ${(props) => props.theme.mediumBg};
  }
  @media screen and (max-width: 1000px) {
    right: -3rem;
    top: -2rem;
  }
  @media screen and (max-width: 650px) {
    display: ${({ isCorner }) => (isCorner ? "grid" : "none")};
    right: 8px;
    top: 8px;
    background: rgba(59, 59, 59, 0.59);
    font-size: 1.2rem;
    padding: 5px;
    color: white;
    &:hover {
      color: white;
      background: rgba(59, 59, 59, 0.59);
      font-size: 1.2rem;
    }
  }
`;

// counting modal styles --------------------------------------------------------------------

export const CountingModalWapper = styled.section`
  position: relative;
  width: 32rem;
  height: 70vh;
  border-radius: 15px;
  background: ${(props) => props.theme.mainBg};
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;

  section {
    display: flex;
    flex-direction: column;
    color: ${(props) => props.theme.main};
    overflow-y: auto;
  }
  @media screen and (max-width: 1450px) {
    width: 28rem;
    height: 85vh;
  }
  @media screen and (max-width: 650px) {
    width: 100%;
    height: 100%;
    border-radius: 0;
    position: fixed;
    top: 0;
    left: 0;
  }
`;

export const CountingSearchWrapper = styled.div`
  width: 100%;
  display: grid;
  place-items: center;
  padding: 1rem 1rem;
  position: relative;
  input {
    width: 100%;
    padding: 0.5rem 1rem;
    border: 1px solid ${(props) => props.theme.borderColor};
    border-radius: 10px;
    outline: none;
    background: none;
    color: ${(props) => props.theme.dark};
    font-weight: 300;
    font-size: 12px;
  }
  span {
    position: absolute;
    top: 1.1rem;
    right: 1.1rem;
    padding: 0.3rem 0.6rem;
    border-radius: 100px;
    background: ${(props) => props.theme.mainBg};
    display: grid;
    place-items: center;
    font-size: 1.3rem;
    fill: ${(props) => props.theme.main};
    color: ${(props) => props.theme.main};
  }
`;

export const MobileClose = styled.div`
  display: none;
  @media screen and (max-width: 650px) {
    height: 3.5rem;
    background: ${(props) => props.theme.mainBg};
    padding: 0.5rem 1rem;
    color: ${(props) => props.theme.main};
    border-bottom: 1px solid ${(props) => props.theme.borderColor};
    font-size: 1.8rem;
    display: flex;
    align-items: center;
    span {
      cursor: pointer;
      display: grid;
      place-items: center;
      &:hover {
        color: ${(props) => props.theme.dark};
      }
    }
  }
`;

export const CountingModalHeader = styled.header`
  border-bottom: 1px solid ${(props) => props.theme.borderColor};
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 0.5rem 0 0 0rem;
`;

export const CountingTitle = styled.h2`
  font-size: 15px;
  font-weight: 500;
  padding: 0.5rem 2rem 1rem 2rem;
  width: 100%;
  display: grid;
  place-items: center;
  cursor: pointer;
  color: ${({ isActive, theme }) =>
    isActive
      ? theme.mode === "light"
        ? theme.primary
        : theme.dark
      : theme.main};
  border-bottom: ${({ isActive, theme }) =>
    isActive
      ? theme.mode === "light"
        ? `1px solid ${theme.primary}`
        : `1px solid ${theme.dark}`
      : theme.main};
  @media screen and (max-width: 1450px) {
    font-size: 14px;
  }
`;

export const UserOverallRatingListWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 1rem 0;
  /* margin-top: 10px; */
`;

export const UserListContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 2.5rem;
  margin: 5px 10px;
  border-radius: 5px;
  color: ${(props) => props.theme.main};
  h3 {
    font-size: 13px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    align-items: start;
    text-transform: capitalize;
    color: ${(props) => props.theme.dark};
    p {
      font-size: 12px;
      font-weight: 400;
      text-transform: lowercase;
    }
    &:hover {
      color: ${(props) => props.theme.dark};
    }
  }
  &:hover {
    background: ${(props) => props.theme.mediumBg};
  }
  @media screen and (max-width: 1450px) {
    padding: 0.5rem 1rem;
  }
  @media screen and (max-width: 950px) {
    p {
      margin-top: -2px;
    }
  }
`;

export const UserProfileThumb = styled.div`
  width: ${({ isTag, width }) => (width ? width : isTag ? "2.5rem" : "3rem")};
  height: ${({ isTag, height }) =>
    height ? height : isTag ? "2.5rem" : "3rem"};
  border-radius: ${({ borderRadius }) =>
    borderRadius ? borderRadius : "100px"};

  background-position: ${({ bgPosition }) => (bgPosition ? "center" : "top")};
  background-size: ${({ isTag, bgSize }) =>
    bgSize ? bgSize : isTag ? "2.5rem" : "3rem"};
  background-repeat: no-repeat;
  background-color: ${(props) => props.theme.mediumMain};

  @media screen and (max-width: 950px) {
    background-size: ${({ isExplore }) => isExplore && "2.2rem"};
    width: ${({ isExplore }) => (isExplore ? "2.2rem" : "2.5rem")};
    height: ${({ isExplore }) => (isExplore ? "2.2rem" : "2.5rem")};
  }
`;

export const UserNameDpWrapper = styled(LinkR)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  cursor: pointer;
  text-decoration: none;
  width: max-content;
  p {
    display: flex;
    align-items: center;
    font-size: 13px;
    color: ${(props) => props.theme.main};
    width: max-content;
    text-decoration: none;
    &::first-letter {
      text-transform: uppercase;
    }
  }
`;

// add professional info modal styles and delete confirmation modal

export const AddProInfoContainer = styled.div`
  background: ${(props) => props.theme.mainBg};
  border-radius: 15px;
  position: relative;
  min-width: 26rem;
  footer {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    padding: 0 1rem 1rem 0;
  }
  @media screen and (max-width: 650px) {
    width: 100%;
    height: 100%;
    border-radius: 0;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    footer {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding: 0 1rem 1rem 1rem;
      button {
        &:nth-child(2n) {
          display: flex;
        }
      }
    }
  }
`;

export const AddProInfoForm = styled.div`
  padding: 1.2rem 1rem 0.8rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  @media screen and (max-width: 650px) {
    justify-content: space-between;
  }
`;

export const ConfirmationContianer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.mainBg};
  border-radius: 15px;
  position: relative;
  width: 30rem;
  height: max-content;
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    padding: 0.5rem 0.8rem 0.5rem 1rem;
    color: ${(props) => props.theme.dark};
    font-weight: 500;
    border-bottom: 1px solid ${(props) => props.theme.mediumMain};
    text-transform: capitalize;
  }
  footer {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 0 0.8rem 0.8rem 0;
  }
  @media screen and (max-width: 650px) {
    height: max-content;
    max-width: 90%;
    justify-content: space-between;
    header {
      padding: 0.8rem 0.8rem 0.8rem 1rem;
    }
    footer {
      flex-direction: column;
      padding: 12px;
    }
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ConfirmationMessage = styled.div`
  padding: 0.8rem 0.8rem 1rem 1rem;
  color: ${(props) => props.theme.dark};
  font-size: 13px;
`;

//post actions modal header =---------------------------------------------

export const PostActionModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${(props) => props.theme.mainBg};
  border-radius: 15px;
  position: relative;
  width: 32rem;
  height: 80vh;
  footer {
    padding: 14px 10px;
    border-top: 1px solid ${(props) => props.theme.mediumMain};
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  @media screen and (max-width: 1450px) {
    width: 30rem;
    height: 85vh;
  }
  @media screen and (max-width: 650px) {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    border-radius: 0;
  }
`;

export const PAMBodyWrap = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 90%;
  @media screen and (max-width: 1450px) {
    max-height: 85%;
  }
`;

export const PAMHeader = styled.header`
  display: flex;
  justify-content: ${({ isCenter }) => (isCenter ? "center" : "space-between")};
  align-items: center;
  font-size: 16px;
  padding: 0.5rem 0.8rem 0.5rem 1rem;
  color: ${(props) => props.theme.dark};
  font-weight: 500;
  border-bottom: 1px solid ${(props) => props.theme.mediumMain};
  height: 2.5rem;
`;

export const PAMBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 20px;
  gap: 1.5rem;
  overflow-y: auto;
`;

// comment modal styles =--------------------------------------------------

export const CmtloaderWrap = styled.div`
  height: 100vh;
  width: 100%;
  display: grid;
  place-items: center;
`;

export const SingleCmtWrap = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
`;

export const CmtDPLink = styled(Link)`
  display: grid;
  place-items: center;
  text-decoration: none;
  cursor: pointer;
  border-radius: 100px;
`;

export const CmtUserDp = styled.div`
  width: 2.2rem;
  height: 2.2rem;
  border-radius: ${({ borderRadius }) =>
    borderRadius ? borderRadius : "100px"};
  background-position: ${({ bgPosition }) => (bgPosition ? "top" : "top")};
  background-size: ${({ bgPosition }) => (bgPosition ? "2.1rem" : "2.2rem")};
  background-repeat: no-repeat;
  background-color: ${(props) => props.theme.mediumMain};
  @media screen and (max-width: 1450px) {
    width: 2rem;
    height: 2rem;
    background-size: ${({ bgPosition }) => (bgPosition ? "1.8rem" : "2rem")};
  }
`;

export const CmtDetailWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1px;
  width: 100%;
  margin-top: -3px;
  p {
    text-align: left;
    font-size: 12px;
    width: 100%;
    color: ${(props) => props.theme.dark};
  }
`;

export const CmtUserNameWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  span {
    color: ${(props) => props.theme.main};
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
`;

export const CmtUserName = styled(Link)`
  color: ${({ isMD, theme }) => (isMD ? theme.main : theme.dark)};
  text-decoration: none;
  font-size: 11px;
`;

// save to collection modal container styles --------------------

export const SCContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 32rem;
  height: 70vh;
`;

export const SCnameWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-radius: 5px;
  cursor: pointer;
  color: ${(props) => props.theme.dark};
  &:hover {
    background: ${(props) => props.theme.mediumBg};
  }
`;

export const BeforeSaveStatus = styled.div`
  height: 1.3rem;
  width: 1.3rem;
  border-radius: 100px;
  border: 1px solid ${(props) => props.theme.mediumMain};
  content: "";
`;

export const AfterSaveStatus = styled.div`
  display: grid;
  place-items: center;
  height: 1.3rem;
  width: 1.3rem;
  border-radius: 100px;
  color: ${(props) => props.theme.dark};
  font-size: 1.4rem;
`;

export const PhotoActionContainer = styled.div`
  position: relative;
  padding: 1rem;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 20rem;
  background: ${(props) => props.theme.mainBg};
  header {
    color: ${(props) => props.theme.dark};
    margin: 0 0 10px 5px;
    font-size: 16px;
  }
  @media screen and (max-width: 650px) {
    width: 80%;
  }
`;

export const PhotoActionButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  gap: 10px;
  color: ${({ isRed, theme }) => (isRed ? theme.primary : theme.main)};
  padding: 10px 1rem;
  border-radius: 10px;
  background: ${(props) => props.theme.mediumBg};
  cursor: pointer;
  p {
    font-size: 14px;
  }
  &:hover {
    color: ${(props) => props.theme.dark};
  }
`;

export const UploadModalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  border-radius: 15px;
  width: 20rem;
  background: ${(props) => props.theme.mainBg};
  header {
    display: grid;
    place-items: center;
    width: 100%;
    padding: 8px 10px;
    color: ${(props) => props.theme.dark};
    border-bottom: 1px solid ${(props) => props.theme.borderColor};
    font-weight: 500;
    font-size: 14px;
  }
  section {
    padding: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`;

export const UplaodOptions = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 8px 10px;
  border-radius: 10px;
  background: ${(props) => props.theme.mediumBg};
  cursor: pointer;
  color: ${(props) => props.theme.main};
  fill: ${(props) => props.theme.main};
  p {
    font-size: 14px;
  }
  &:hover {
    color: ${(props) => props.theme.dark};
    fill: ${(props) => props.theme.dark};
  }
`;
