import React from "react";
import { PrimaryBtn } from "../Common/CommonStyle";
import {
  ErrorMessage,
  ErrorMessageHeader,
  ErrorModalContainer,
  ErrorButtonWrapper,
  ModalBg,
} from "./ModalStyles";
import { ErrorIcon } from "../../Assets/SVG";

const ErrorModal = ({ error, setError, image, setImage }) => {
  const handleOKClick = () => {
    setError("");
    if (image) {
      setImage("");
    }
  };

  return (
    <ModalBg>
      <ErrorModalContainer>
        <ErrorMessageHeader>
          <ErrorIcon width={"1.2rem"} />
          Error
        </ErrorMessageHeader>
        <ErrorMessage>{error}</ErrorMessage>
        <ErrorButtonWrapper>
          <PrimaryBtn onClick={handleOKClick} width={"5rem"}>
            OK
          </PrimaryBtn>
        </ErrorButtonWrapper>
      </ErrorModalContainer>
    </ModalBg>
  );
};

export default ErrorModal;
