import React from "react";
import {
  Answer,
  Heading,
  PageWrapper,
  Paragraph,
  ParahLink,
  PlusButton,
  QAWrapper,
  Question,
} from "./PoliciesStyles";
import {
  Parah,
  PointsIcon,
  PointsWrapper,
  ThirdTitle,
} from "../../Documents/ContentsStyles";
import { Helmet } from "react-helmet";
import useHandleEmailClick from "../../Hooks/useEmail";
// import useHandleEmailClick from "../Components/useHandleEmailClick";

const CookiesPolicy = () => {
  const handleEmailClick = useHandleEmailClick;

  return (
    <>
      <Helmet>
        <title>Cookies Policy | Talents Explore</title>
      </Helmet>
      <PageWrapper>
        <Heading>Cookies Policy</Heading>
        <Paragraph>
          This Cookies Policy applies whenever you see our Platform. “Platform”
          includes any services provided by Talents Explore, including the
          advertiser and developer products available via Talents Explore for
          Business and Talents Explore for Developers, with the exception of
          those described in our separate Website Cookies Policy. Where we refer
          to “Talents Explore”, “we” or “us” in this Cookies Policy, we mean
          Talents Explore Limited 13169329.
        </Paragraph>
        <Paragraph>
          Like many websites and apps, our Platform uses cookies and other
          tracking technologies such as software development kits (SDKs), pixels
          and local storage. These technologies allow us to store information or
          gain access to information stored on your device to enable certain
          features and distinguish your device from others. Tracking
          technologies are used by most apps to recognise that a device is the
          same as one used previously and to understand some of its
          characteristics so that we can serve you advertising, measure the
          performance of those ads, and to improve your experience. This policy
          explains how we use these technologies, and the choices you must
          control them. Unless otherwise stated in this policy, our{" "}
          <ParahLink to="/privacy-policy">Privacy Policy</ParahLink> applies to
          the data we collect.
        </Paragraph>
        <QAWrapper>
          <Question isActive={true}>
            1. What Technologies are Used ?
            <PlusButton />
          </Question>
          <Answer>
            <ThirdTitle>
              Our Platform uses the following kinds of technologies:
            </ThirdTitle>
            <Parah>
              Cookies: Cookies are small text files that enable us and our
              service providers and partners to uniquely identify your browser
              or device for various purposes, including those described above.
              Cookies normally work by assigning a unique number to your device
              and are stored on your browser by the websites that you visit as
              well as third party service providers and partners to those
              websites.{" "}
            </Parah>
            <Parah>
              Cookies can remain on your computer or mobile device for different
              periods of time. Some cookies are "session cookies", meaning that
              they exist only while your browser is open and are deleted
              automatically once you close your browser. Session cookies cannot
              be used to track your browser or device from website to website.
            </Parah>
            <Parah>
              Other cookies are "persistent cookies", meaning that they survive
              after your browser is closed. Most persistent cookies are set to
              automatically expire after a defined duration (for example, a few
              days, weeks or months). When you return to that website (or visit
              other websites that use the same cookies) the website provider or
              its service providers can recognise the persistent cookies they
              have set and recognise your browsing device.
            </Parah>
            <Parah>
              Local storage: Local storage files are created by apps and
              websites to store information locally on your device. They are
              very similar to cookies, but can have slightly different
              properties (for example, local storage can be used to store more
              information and can be stored in a different location on your
              device to cookies). Local storage is normally used to speed up app
              and site functionality and remember your preferences.
            </Parah>
            <Parah>
              Application Programmable Interface (API): An API is a piece of
              software which helps two or more applications to talk to one
              another. We use them to communicate between Talents Explore and
              our service providers.
            </Parah>
            <Parah>
              Software Development Kits (SDKs): SDKs are pieces of code that
              enable data to be collected about your device, network and
              interaction with a website. Like cookies, they generally operate
              by assigning your device a unique number.
            </Parah>
            <Parah>
              Pixels: Pixels are small 1 pixel x 1pixel invisible image files
              that are embedded in certain website pages. They can be used to
              collect information about your visit to that website page.
            </Parah>
            <Parah>
              When you open a website page with an embedded pixel, the page will
              automatically run simple code that downloads the 1x1 image file to
              your device. At the same time, the code also passes certain
              information about your device and your actions on the website.
              Your interest in the website can then3 be logged and used for
              analytics or advertising purposes.
            </Parah>
            <Parah>
              Mobile advertising IDs: Your device has a unique number used by
              Talents Explore and our service providers to recognise your
              device. Where you have provided your consent, this allows
              personalised ads to be delivered to your device and to measure the
              effectiveness of those ads.
            </Parah>
          </Answer>
        </QAWrapper>
        <QAWrapper>
          <Question isActive={true}>
            2. Categories of Tracking Technologies <PlusButton />
          </Question>
          <Answer>
            <Parah>
              The types of tracking technologies used by us and our partners in
              connection with the provision of the Platform can be classified
              into the following categories. Some of these categories are
              “essential” to provide you with the services you have requested,
              such as to allow you to log into secure areas of the Platform. The
              following is a list of the categories of tracking technologies we
              use:
            </Parah>
            <Parah>
              Identification and security: We recognise you when you return to
              our Platform to make sure that you are the right user and to
              prevent activity that violates our policies.
            </Parah>
            <Parah>
              For example, we check what country you are using the Talents
              Explore app from to detect if someone may be trying to
              fraudulently access your account.
            </Parah>
            <Parah>
              Content personalisation: We use these technologies to ensure that
              we can serve you with personalised content.
            </Parah>
            <Parah>
              For example, each User is given a unique Talents Explore ID
              number. We use this to provide you with tailored content that will
              be of interest to you.
            </Parah>
            <Parah>
              Remembering your preferences: We need to remember the settings you
              have chosen on the Platform so that they work how you want them
              to.
            </Parah>
            <Parah>
              For example, we use these technologies in order to remember what
              languages you want to see content in, the privacy settings you
              apply to your account and/or videos and your Digital Wellbeing
              choices.
            </Parah>
            <Parah>
              Functionality: Functionality tracking technologies help to make
              the Platform work more efficiently and to improve and optimize
              your experience.
            </Parah>
            <Parah>
              For example, we determine which device you use, the software
              version and the screen dimensions so that we can ensure the
              Talents Explore app functions correctly. We also determine whether
              your battery is running low so that we can optimise the Talents
              Explore app and we make sure that content loads and plays
              correctly.
            </Parah>
            <Parah>
              Analytics: We use analytics tracking technologies to analyse how
              you use the Platform, including which pages you view most often,
              how you interact with the content, measure any errors that occur
              and test different design ideas. The information is used to report
              and evaluate your activities and patterns as a user of the
              Platform.
            </Parah>
            <Parah>
              Advertising and measurement: Our Platform use advertising and
              measurement tracking technologies. These tracking technologies
              help us and our advertisers show you relevant advertising and
              measure the performance of ad campaigns. We also use these
              technologies to promote Talents Explore on the other platforms and
              websites and measure their effectiveness.
            </Parah>
          </Answer>
        </QAWrapper>
        <QAWrapper>
          <Question isActive={true}>
            3. Do other parties use these technologies on our Platform?
            <PlusButton />
          </Question>
          <Answer>
            <Parah>
              Tracking technologies can either be “first-party” or
              “third-party”. First-party tracking technologies refers to
              tracking technologies that we set and use directly. Third-party
              tracking technologies refer to tracking technologies set by third
              parties (such as our analytics or advertising service providers).
            </Parah>
            <Parah>
              Our partners may use these technologies on our Platform to help us
              and our advertisers understand how effective their ads are and to
              compare this to their campaigns on other websites and apps.
            </Parah>
          </Answer>
        </QAWrapper>
        <QAWrapper>
          <Question isActive={true}>
            4. How Can I Control These Technologies?
            <PlusButton />
          </Question>
          <Answer>
            <ThirdTitle>
              You can control how these technologies are used:
            </ThirdTitle>
            <PointsWrapper>
              <PointsIcon />
              Personalised Ads in the Talents Explore app. You are in full
              control of whether Talents Explore serves you with personalised
              ads in the Talents Explore app. We do not serve personalised ads
              without your consent. You can switch on personalised ads by
              clicking on “Me” in the Talents Explore app, visiting the settings
              page by pressing on the three dots in the top right corner, then
              clicking on “Personalization and data” then using the toggle. You
              can change this setting at any time.
            </PointsWrapper>
            <PointsWrapper>
              <PointsIcon />
              Advertising ID. You can tailor your device settings to control
              your advertising ID: On Apple devices, you can enable the “Limit
              Ad Tracking” setting in your device settings. On Android devices,
              you can enable the “opt out of Ads Personalization” in your device
              settings.
            </PointsWrapper>
            <PointsWrapper>
              <PointsIcon />
              Partner opt-outs. Many of the partners we work with also provide
              opt-outs on their own websites. You can find details of this in
              their own policies, which are linked to from our Cookies Partner
              List.
            </PointsWrapper>
            <Parah>
              As mentioned above, some uses of these technologies are essential
              for the functioning of the Platform and so you cannot deactivate
              them.
            </Parah>
          </Answer>
        </QAWrapper>
        <QAWrapper>
          <Question isActive={true}>
            5. Where Can I Find More Information About These Technologies?
            <PlusButton />
          </Question>
          <Answer>
            <Parah>
              If you would like to find out more about these technologies, you
              may find the following links useful
            </Parah>
            <PointsWrapper>
              <PointsIcon />
              <a
                href="https://www.allaboutcookies.org/"
                style={{
                  color: " #ff2146f0",
                  cursor: " pointer",
                }}
              >
                All About Cookies
              </a>
            </PointsWrapper>
            <PointsWrapper>
              <PointsIcon />
              <a
                href="https://wikis.ec.europa.eu/display/WEBGUIDE/04.+Cookies+and+similar+technologies/"
                style={{
                  color: " #ff2146f0",
                  cursor: " pointer",
                }}
              >
                EU Guide to Cookies
              </a>
            </PointsWrapper>
            <PointsWrapper>
              <PointsIcon />
              <a
                href="https://www.youronlinechoices.com/"
                style={{
                  color: " #ff2146f0",
                  cursor: " pointer",
                }}
              >
                Your Online Choices
              </a>
            </PointsWrapper>
          </Answer>
        </QAWrapper>
        <QAWrapper>
          <Question isActive={true}>
            6. Changes to this Policy
            <PlusButton />
          </Question>
          <Answer>
            <Parah>
              We will occasionally update this policy to reflect changes in our
              practices. When we post changes to this policy, we will revise the
              "Last Updated" date at the top of this policy. If we make any
              material changes in the way we collect, use, and/or share
              information using these technologies, we will notify you by
              prominently posting notice of the changes in places where you use
              the Platform. We recommend that you check this page from time to
              time to inform yourself of any changes in this policy.
            </Parah>
          </Answer>
        </QAWrapper>
        <QAWrapper>
          <Question isActive={true}>
            7. Contact Us
            <PlusButton />
          </Question>
          <Answer>
            <Parah>
              If you have any questions or comments about this policy, or
              privacy matters generally, contact us at:{" "}
            </Parah>
            <a
              href="/"
              onClick={handleEmailClick}
              style={{
                color: " #ff2146f0",
                cursor: " pointer",
              }}
            >
              info@talentsexplore
            </a>{" "}
          </Answer>
        </QAWrapper>
      </PageWrapper>
    </>
  );
};

export default CookiesPolicy;
