import React, { useState } from "react";
import { StarIcon } from "../../../Assets/SVG";
import { useRateFollowCountFormat } from "../../../Hooks/useCountFormat";
import {
  ProfileHeaderContainer,
  ProfileHeaderWrapper,
  CoverPhotoBg,
  CountBoxLine,
  ProfileHeaderDetails,
  ProfilePhotoContainer,
  ProfileNameWrapper,
  ProfileCountsContainer,
  ProfilePhoto,
  ProfileCountsBox,
  ProfileQuotesWrapper,
  ProfileHeaderDetailWrapper,
  ProfilePhotoNameWrapper,
} from "../ProfileStyle";
import { useTheme } from "styled-components";
import { useSelector } from "react-redux";
import { CountingModal, PhotoViewModal } from "../../../Components";
import defaultImg from "../../../Assets/Images/default_profile_pic.png";
import { DefaultCoverImg } from "../../../Assets/Images";

const ProfileHeader = () => {
  const theme = useTheme();
  const user = useSelector((state) => state.auth.user);
  const [isDPOpen, setIsDPOpen] = useState(false);
  const handleDPImageOpen = () => {
    if (user?.personalInfo?.profileImage) setIsDPOpen(true);
  };

  const formatCount = useRateFollowCountFormat();
  const [openCountingModal, setOpenCountingModal] = useState(false);
  const [currentBox, setCurrentBox] = useState(0);

  const handleCountClick = (id) => {
    setCurrentBox(parseInt(id));
    setOpenCountingModal(true);
    console.log(id);
  };

  return (
    <ProfileHeaderContainer>
      {user && (
        <ProfileHeaderWrapper theme={theme} isTalent={user?.isTalent}>
          <CoverPhotoBg
            isTalent={user?.isTalent}
            src={
              user?.personalInfo?.coverImage
                ? user?.personalInfo?.coverImage
                : DefaultCoverImg
            }
            alt="cover_photo"
          />

          <ProfileHeaderDetailWrapper isTalent={user?.isTalent}>
            <ProfileHeaderDetails>
              <ProfilePhotoNameWrapper>
                <ProfilePhotoContainer
                  isTalent={user?.isTalent}
                  isCursor={true}
                >
                  <ProfilePhoto
                    src={
                      user?.personalInfo?.profileImage
                        ? user?.personalInfo?.profileImage
                        : defaultImg
                    }
                    alt=""
                    onClick={handleDPImageOpen}
                  />
                </ProfilePhotoContainer>
                <ProfileNameWrapper>
                  <h1>
                    {user?.personalInfo?.firstName}&nbsp;
                    {user?.personalInfo?.lastName}
                  </h1>
                  <div>
                    <h3>@{user?.userName}</h3>

                    <span>
                      {user?.personalInfo?.professional
                        ? user?.personalInfo?.professional
                        : "\u00A0"}
                    </span>
                  </div>

                  {!user?.isTalent && (
                    <ProfileQuotesWrapper isTalent={user?.isTalent}>
                      {user?.personalInfo?.shortBio}
                    </ProfileQuotesWrapper>
                  )}
                </ProfileNameWrapper>
              </ProfilePhotoNameWrapper>

              <ProfileCountsContainer isTalent={user?.isTalent}>
                {user?.isTalent ? (
                  <>
                    <ProfileCountsBox
                      isTalent={user?.isTalent}
                      onClick={() => handleCountClick(1)}
                    >
                      <span>
                        <StarIcon fill={"var(--yellow)"} width="16px" />
                        {user?.avgRating === null ? (
                          <span style={{ fontSize: "13px" }}>N/A</span>
                        ) : (
                          (Math.round(user?.avgRating * 10) / 10).toFixed(1)
                        )}
                      </span>
                      {formatCount(user?.numberOfRating)}&nbsp;Ratings
                    </ProfileCountsBox>
                    <CountBoxLine />
                    <ProfileCountsBox
                      isTalent={user?.isTalent}
                      onClick={() => handleCountClick(2)}
                    >
                      <span>{formatCount(user?.followers)}</span>
                      Followers
                    </ProfileCountsBox>
                    <CountBoxLine />
                    <ProfileCountsBox
                      isTalent={user?.isTalent}
                      onClick={() => handleCountClick(3)}
                    >
                      <span>{formatCount(user?.following)}</span>
                      Followings
                    </ProfileCountsBox>
                  </>
                ) : (
                  <ProfileCountsBox
                    isTalent={user?.isTalent}
                    onClick={() => handleCountClick(3)}
                  >
                    {formatCount(user?.following)}
                    <CountBoxLine style={{ height: "20px" }} />
                    Following
                  </ProfileCountsBox>
                )}
              </ProfileCountsContainer>
              {user?.isTalent && (
                <ProfileQuotesWrapper isTalent={user?.isTalent}>
                  {user?.personalInfo?.shortBio}
                </ProfileQuotesWrapper>
              )}
            </ProfileHeaderDetails>
          </ProfileHeaderDetailWrapper>
        </ProfileHeaderWrapper>
      )}
      {openCountingModal && (
        <CountingModal
          currentBox={currentBox}
          setCurrentBox={setCurrentBox}
          openModal={openCountingModal}
          setOpenModal={setOpenCountingModal}
        />
      )}
      {isDPOpen && user?.personalInfo?.profileImage && (
        <PhotoViewModal
          src={user?.personalInfo?.profileImage}
          setPhotoOpen={() => setIsDPOpen(false)}
        />
      )}
    </ProfileHeaderContainer>
  );
};

export default ProfileHeader;
