import {
  personalInfoViewAction,
  talentAwardsViewAction,
  talentEducationViewAction,
  talentLanguageViewAction,
  talentSkillsViewAction,
  userInfoPublicViewAction,
} from "../ReduxToolKit/APIs/AuthAPI";
import {
  followersViewAction,
  followingsViewAction,
  profileRatingsOverallViewAction,
  profileRatingsViewAction,
  taggingSearchAction,
} from "../ReduxToolKit/APIs/ConnectAPI";
import {
  countryViewAction,
  ethnicViewAction,
  languageViewAction,
  mainCatViewAction,
  professionViewAction,
  searchAction,
  skillViewAction,
  stateViewAction,
  subCatViewAction,
} from "../ReduxToolKit/APIs/ContentAPI";
import { setCurrentUser, setUser } from "../ReduxToolKit/Slices/authSlice";
import { setIsAbout, setIsTalent } from "../ReduxToolKit/Slices/utilsSlice";
import {
  setEthnicities,
  setLanguages,
  setMainCategories,
  setProfessions,
  setSkills,
  setSubCategories,
  setCountries,
  setStates,
} from "../ReduxToolKit/Slices/contentSlice";
import {
  commentViewAction,
  homeFeedViewAction,
  homeSharedFeedViewAction,
  pinnedPostViewAction,
  postMoreDetailsViewAction,
  postSaveCollectionViewAction,
  profileAllpostViewAction,
  savedFoldersPostViewAction,
} from "../ReduxToolKit/APIs/PostAPI";
import {
  setAddPosts,
  setUserAllPostsById,
  setUserPinnedPostsById,
} from "../ReduxToolKit/Slices/postSlice";

// -------------get user profile info details-------------

export async function getCurrentUserPersonalDetails(
  currentUserId,
  dispatch,
  setIsNoUser,
  setIsLoading
) {
  const actionResult = await dispatch(personalInfoViewAction(currentUserId));
  const responseData = actionResult.payload;
  console.log(responseData, responseData.response, "get current user details");
  if (responseData && parseInt(responseData.status) === 7400) {
    dispatch(setCurrentUser({ currentUser: responseData.response }));
    dispatch(setUser({ user: responseData.response }));
    setIsLoading(false);
  } else if (responseData && parseInt(responseData.status) === 400) {
    setIsNoUser && setIsNoUser(true);
    setIsLoading(false);
  }
}

export const getUserPersonalDetails = async (
  userId,
  currentUserId,
  dispatch,
  setIsNoUser,
  isAuthor,
  setAuthorData
) => {
  if (userId === currentUserId) {
    const actionResult = await dispatch(personalInfoViewAction(userId));
    const responseData = actionResult.payload;
    console.log(responseData.response);
    console.log("-------------get same user profile info details-------------");
    if (responseData && parseInt(responseData.status) === 7400) {
      setIsNoUser && setIsNoUser(false);
      if (isAuthor) {
        setAuthorData(responseData.response);
      } else {
        dispatch(setUser({ user: responseData.response }));
      }
    } else if (responseData && parseInt(responseData.status) === 400) {
      setIsNoUser && setIsNoUser(true);
    }
  } else {
    const actionResult = await dispatch(
      userInfoPublicViewAction({ userId, currentUserId })
    );
    const responseData = actionResult.payload;
    console.log(responseData.response);
    console.log("-------------another user public info details-------------");
    console.log("--------------------------", userId, currentUserId);
    setIsNoUser && setIsNoUser(false);
    if (responseData && parseInt(responseData.status) === 7400) {
      if (isAuthor) {
        setAuthorData(responseData.response);
      } else {
        dispatch(setUser({ user: responseData.response }));
      }
    } else if (responseData && parseInt(responseData.status) === 400) {
      setIsNoUser && setIsNoUser(true);
    }
  }
};

export const getUserRatingList = async (
  userId,
  dispatch,
  setRatersList,
  setIsLoading
) => {
  const actionResult = await dispatch(profileRatingsViewAction(userId));
  const responseData = actionResult.payload;
  if (responseData && parseInt(responseData.status) === 7400) {
    console.log(responseData, "res ratings list");
    setRatersList(responseData.response);
  }
  setIsLoading(false);
};

export const getUserRatingOverall = async (
  userId,
  dispatch,
  setOverallList,
  setIsLoading
) => {
  const actionResult = await dispatch(profileRatingsOverallViewAction(userId));
  const responseData = actionResult.payload;
  if (responseData && parseInt(responseData.status) === 7400) {
    console.log(responseData, "res overall ratings list");
    setOverallList(responseData.response);
  }
  setIsLoading(false);
};

export const getUserFollowerList = async (
  userId,
  currentUserId,
  dispatch,
  setConnectList,
  setIsLoading
) => {
  const actionResult = await dispatch(
    followersViewAction({ userId, currentUserId })
  );
  const responseData = actionResult.payload;
  if (responseData && parseInt(responseData.status) === 7400) {
    console.log(responseData, "res follower list");
    setConnectList(responseData.response);
  }
  setIsLoading(false);
};

export const getUserFollowingList = async (
  userId,
  currentUserId,
  dispatch,
  setConnectList,
  setIsLoading
) => {
  const actionResult = await dispatch(
    followingsViewAction({ userId, currentUserId })
  );
  const responseData = actionResult.payload;
  if (responseData && parseInt(responseData.status) === 7400) {
    console.log(responseData, "res following list");
    setConnectList(responseData.response);
  }
  setIsLoading(false);
};

export const getCheckIsAbout = async (currentUser, dispatch) => {
  if (currentUser && currentUser.personalInfo) {
    const personalInfo = currentUser.personalInfo;
    const firstName = personalInfo.firstName;
    console.log(Boolean(firstName), "firstname");
    dispatch(setIsAbout({ isAbout: Boolean(firstName) }));
  }
};

export const getCheckIsTalent = async (currentUser, dispatch) => {
  if (currentUser) {
    const talent = currentUser.isTalent;
    console.log(Boolean(talent), "isTalent");
    dispatch(setIsTalent({ isTalent: Boolean(talent) }));
  }
};

export const getTalentSkill = async (
  userId,
  dispatch,
  setSkillArray,
  setIsLoading
) => {
  const actionResult = await dispatch(talentSkillsViewAction(userId));
  const responseData = actionResult.payload;
  setSkillArray(responseData.response);
  setIsLoading(false);
};

export const getTalentAward = async (userId, dispatch, setAwardArray) => {
  const actionResult = await dispatch(talentAwardsViewAction(userId));
  const responseData = actionResult.payload;
  setAwardArray(responseData.response);
};

export const getTalentLanguage = async (userId, dispatch, setLangArray) => {
  const actionResult = await dispatch(talentLanguageViewAction(userId));
  const responseData = actionResult.payload;
  setLangArray(responseData.response);
};

export const getTalentEducation = async (userId, dispatch, setEduArray) => {
  const actionResult = await dispatch(talentEducationViewAction(userId));
  const responseData = actionResult.payload;
  setEduArray(responseData.response);
};

// -------- getting common contents from backend --------------

export const getEthnicities = async (dispatch) => {
  const actionResult = await dispatch(ethnicViewAction());
  const responseData = actionResult.payload;
  console.log(responseData, responseData.response, "get ethnicities");
  if (responseData && parseInt(responseData.status) === 7400) {
    dispatch(setEthnicities({ ethnicities: responseData.response }));
  }
};

export const getProfessions = async (dispatch) => {
  const actionResult = await dispatch(professionViewAction());
  const responseData = actionResult.payload;
  console.log(responseData, responseData.response, "get professions");
  if (responseData && parseInt(responseData.status) === 7400) {
    dispatch(setProfessions({ professions: responseData.response }));
  }
};

export const getLanguages = async (dispatch) => {
  const actionResult = await dispatch(languageViewAction());
  const responseData = actionResult.payload;
  console.log(responseData, responseData.response, "get languages");
  if (responseData && parseInt(responseData.status) === 7400) {
    dispatch(setLanguages({ languages: responseData.response }));
  }
};

export const getMainCategories = async (dispatch) => {
  const actionResult = await dispatch(mainCatViewAction());
  const responseData = actionResult.payload;
  if (parseInt(responseData.status) === 7400) {
    dispatch(setMainCategories({ mainCategories: responseData.response }));
  }
};

export const getSubCategories = async (mainCategoryId, dispatch) => {
  const actionResult = await dispatch(subCatViewAction(mainCategoryId));
  const responseData = actionResult.payload;
  if (responseData && parseInt(responseData.status) === 7400) {
    dispatch(setSubCategories({ subCategories: responseData.response }));
  }
};

export const getSkills = async (subCategoryId, dispatch) => {
  const actionResult = await dispatch(skillViewAction(subCategoryId));
  const responseData = actionResult.payload;
  if (responseData && parseInt(responseData.status) === 7400) {
    dispatch(setSkills({ skills: responseData.response }));
    console.log(responseData.response, "Skills from backend");
  }
};

export const getCountries = async (dispatch) => {
  const actionResult = await dispatch(countryViewAction());
  const responseData = actionResult.payload;
  if (responseData && parseInt(responseData.status) === 7400) {
    dispatch(setCountries({ countries: responseData.response }));
    console.log(responseData.response, "countries from backend");
  }
};

export const getStates = async (country, dispatch) => {
  const actionResult = await dispatch(stateViewAction(country));
  const responseData = actionResult.payload;
  if (responseData && parseInt(responseData.status) === 7400) {
    dispatch(setStates({ states: responseData.response }));
    console.log(responseData.response, "states from backend");
  }
};

export const getPeopleTags = async (
  currentUserId,
  filter,
  dispatch,
  setProfileList
) => {
  const actionResult = await dispatch(
    taggingSearchAction({ currentUserId, filter })
  );
  const responseData = actionResult.payload;

  if (responseData && parseInt(responseData.status) === 7400) {
    setProfileList(responseData.response);
    console.log(responseData.response);
  }
};

export const getViewAllPost = async (
  currentUserId,
  userId,
  pageNo,
  pageSize,
  dispatch
) => {
  const actionResult = await dispatch(
    homeFeedViewAction({ currentUserId, userId, pageNo, pageSize })
  );
  const responseData = actionResult.payload;
  if (responseData && parseInt(responseData.status) === 7400) {
    const posts = responseData.response;
    dispatch(setAddPosts({ postsById: posts }));
  }
};

export const getViewAllShared = async (
  currentUserId,
  userId,
  pageNo,
  pageSize,
  dispatch,
  setSharesById
) => {
  const actionResult = await dispatch(
    homeSharedFeedViewAction({ currentUserId, userId, pageNo, pageSize })
  );
  const responseData = actionResult.payload;
  if (responseData && parseInt(responseData.status) === 7400) {
    const posts = responseData.response;
    setSharesById(posts);
  }
};

// export const getViewAllPost = async (
//   currentUserId,
//   userId,
//   pageNo,
//   pageSize,
//   dispatch,
//   posts
//   // setPosts
// ) => {
//   console.log("get-view-all--posts", posts);
//   const actionResult = await dispatch(
//     homeFeedViewAction({ currentUserId, userId, pageNo, pageSize })
//   );
//   const responseData = actionResult.payload;
//   if (responseData && parseInt(responseData.status) === 7400) {
//     const newPosts = responseData.response; // newPosts is an array
//     if (posts.length > 0) {
//       const updatedPostsById = [...posts, ...newPosts];
//       // setPosts(updatedPostsById);
//       dispatch(setAddPosts({ postsById: updatedPostsById }));
//     } else {
//       console.log(newPosts, "newposts--");
//       dispatch(setAddPosts({ postsById: newPosts }));
//     }
//   }
// };

export const getProfilePinnedPost = async (
  currentUserId,
  userId,
  pageNo,
  pageSize,
  dispatch,
  setIsLoading
) => {
  setIsLoading && setIsLoading(true);
  const actionResult = await dispatch(
    pinnedPostViewAction({ currentUserId, userId, pageNo, pageSize })
  );
  const responseData = actionResult.payload;
  if (responseData && parseInt(responseData.status) === 7400) {
    const pinnedPosts = responseData.response;
    console.log(pinnedPosts, "pinned post get fetch function");
    dispatch(setUserPinnedPostsById({ userPinnedPostsById: pinnedPosts }));
  }
  setIsLoading && setIsLoading(false);
};

export const getProfileAllposts = async (
  currentUserId,
  userId,
  pageNo,
  pageSize,
  dispatch,
  setIsLoading
) => {
  const actionResult = await dispatch(
    profileAllpostViewAction({ currentUserId, userId, pageNo, pageSize })
  );
  const responseData = actionResult.payload;
  if (responseData && parseInt(responseData.status) === 7400) {
    const posts = responseData.response;
    console.log(posts, "user all posts");
    dispatch(setUserAllPostsById({ userAllPostsById: posts }));
  }
  setIsLoading && setIsLoading(false);
};

export const getPostMoreDetails = async (
  postId,
  userId,
  dispatch,
  setPostData
) => {
  const actionResult = await dispatch(
    postMoreDetailsViewAction({ postId, userId })
  );
  const responseData = actionResult.payload;
  if (responseData && parseInt(responseData.status) === 7400) {
    const posts = responseData.response;
    console.log(posts, "post more details");
    setPostData(posts);
    // dispatch(setUserAllPostsById({ userAllPostsById: posts }));
  }
};

export const getPostcomments = async (
  currentUserId,
  postId,
  pageNo,
  pageSize,
  dispatch,
  setCommentData,
  setCommentLoading
) => {
  setCommentLoading && setCommentLoading(true);
  const actionResult = await dispatch(
    commentViewAction({ currentUserId, postId, pageNo, pageSize })
  );
  const responseData = actionResult.payload;
  if (responseData && parseInt(responseData.status) === 7400) {
    const commentsData = responseData.response;
    console.log(commentsData, "comment details details");
    setCommentData(commentsData);
    setCommentLoading && setCommentLoading(false);
  }
};

export const getPostSaveCollection = async (
  currentUserId,
  postId,
  dispatch,
  setSaveCollectionList,
  setSaveCollectionLoading
) => {
  setSaveCollectionLoading && setSaveCollectionLoading(true);
  const actionResult = await dispatch(
    postSaveCollectionViewAction({ currentUserId, postId })
  );
  const responseData = actionResult.payload;
  if (responseData && parseInt(responseData.status) === 7400) {
    const SCList = responseData.response;
    // console.log(SCList, "SCList details details");
    setSaveCollectionList(SCList);
    setSaveCollectionLoading && setSaveCollectionLoading(false);
  }
};

export const getSavedFolderFirstImage = async (
  collectionId,
  currentUserId,
  pageNo,
  pageSize,
  dispatch,
  setFolderImages
) => {
  const actionResult = await dispatch(
    savedFoldersPostViewAction({
      collectionId,
      currentUserId,
      pageNo,
      pageSize,
    })
  );
  const responseData = actionResult.payload;
  if (responseData && parseInt(responseData.status) === 7400) {
    const SCFirstImgList = responseData.response;
    if (SCFirstImgList.length === 1) {
      console.log(SCFirstImgList, "imageUrl");
      setFolderImages((prevImages) => [
        ...prevImages,
        {
          id: SCFirstImgList[0].media[0]._id,
          imgUrl: SCFirstImgList[0].media[0].url,
          mediaType: SCFirstImgList[0].media[0].mediaType,
          thumbnailUrl: SCFirstImgList[0].media[0].thumbnailUrl
            ? SCFirstImgList[0].media[0].thumbnailUrl
            : null,
        },
      ]);
    } else {
      console.log(SCFirstImgList, "imageUrl");
      setFolderImages((prevImages) => [
        ...prevImages,
        {
          id: null,
          imgUrl: null,
          mediaType: null,
          thumbnailUrl: null,
        },
      ]);
    }
  }
};

export const getSavedFolderPosts = async (
  collectionId,
  currentUserId,
  pageNo,
  pageSize,
  dispatch,
  setFolderItems
) => {
  const actionResult = await dispatch(
    savedFoldersPostViewAction({
      collectionId,
      currentUserId,
      pageNo,
      pageSize,
    })
  );
  const responseData = actionResult.payload;
  if (responseData && parseInt(responseData.status) === 7400) {
    const SCFirstImgList = responseData.response;
    console.log(SCFirstImgList, "imageUrl");
    setFolderItems(SCFirstImgList);
  }
};

export const getSearchResults = async (
  searchType,
  currentUserId,
  pageNo,
  pageSize,
  dispatch,
  setSearchLoading,
  setSearchResults,
  searchText,
  mainCategoryId,
  subCategoryId,
  skillId,
  level,
  primaryLanguage,
  secondaryLanguage,
  publicRating
) => {
  const actionResult = await dispatch(
    searchAction({
      searchType,
      currentUserId,
      pageSize,
      searchText,
      mainCategoryId,
    })
  );
  const responseData = actionResult.payload;
  if (responseData && parseInt(responseData.status) === 7400) {
    const searchResults = responseData.response;
    console.log(searchResults, "list of search");
    setSearchResults(searchResults);
    setSearchLoading(false);
  }
};

export const getTalentSearchResults = async (
  searchType,
  currentUserId,
  pageNo,
  pageSize,
  dispatch,
  setSearchLoading,
  setAccountResults,
  searchText,
  publicRating
) => {
  const actionResult = await dispatch(
    searchAction({
      searchType,
      currentUserId,
      pageSize,
      searchText,
      publicRating,
    })
  );
  const responseData = actionResult.payload;
  if (responseData && parseInt(responseData.status) === 7400) {
    const talentsList = responseData.response;
    console.log(talentsList, "list of talentsList");
    setAccountResults(talentsList);
    setSearchLoading(false);
  }
};
