import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { contentApiCache } from "../Slices/contentSlice";

export const languageViewAction = createAsyncThunk("/language", async () => {
  try {
    if (contentApiCache.languages) {
      return { response: contentApiCache.languages };
    }
    const response = await axios.get("/language");
    console.log(response, "axiosLangViewRes");
    contentApiCache.languages = response.data;
    return response.data;
  } catch {
    const response = {
      data: { status: 400 },
    };
    return response.data;
  }
});

export const professionViewAction = createAsyncThunk(
  "/private-info/professional",
  async () => {
    try {
      if (contentApiCache.professions) {
        return { response: contentApiCache.professions };
      }
      const response = await axios.get("/private-info/professional");
      console.log(response, "axiosProfViewRes");
      contentApiCache.professions = response.data;
      return response.data;
    } catch {
      const response = {
        data: { status: 400 },
      };
      return response.data;
    }
  }
);

export const ethnicViewAction = createAsyncThunk(
  "/private-info/ethnic",
  async () => {
    try {
      if (contentApiCache.ethnicities) {
        return { response: contentApiCache.ethnicities };
      }
      const response = await axios.get("/private-info/ethnic");
      console.log(response, "axiosethnicViewRes");
      contentApiCache.ethnicities = response.data;
      return response.data;
    } catch {
      const response = {
        data: { status: 400 },
      };
      return response.data;
    }
  }
);

export const mainCatViewAction = createAsyncThunk(
  "/category/mainCategory",
  async () => {
    try {
      if (contentApiCache.mainCategories) {
        return { response: contentApiCache.mainCategories };
      }
      const response = await axios.get("/category/mainCategory");
      contentApiCache.mainCategories = response.data;
      return response.data;
    } catch {
      const response = {
        data: { status: 400 },
      };
      return response.data;
    }
  }
);

export const subCatViewAction = createAsyncThunk(
  "/category/subCategory/{mainCategory}",
  async (categoryData) => {
    try {
      const response = await axios.get(`/category/subCategory/${categoryData}`);
      console.log(response, "axiosSubViewRes");
      return response.data;
    } catch {
      const response = {
        data: { status: 400 },
      };
      return response.data;
    }
  }
);

export const skillViewAction = createAsyncThunk(
  "/category/skill/{subCategory}",
  async (categoryId) => {
    try {
      const response = await axios.get(`/category/skill/${categoryId}`);
      console.log(response, "axiosSkillViewRes");
      return response.data;
    } catch {
      const response = {
        data: { status: 400 },
      };
      return response.data;
    }
  }
);

export const countryViewAction = createAsyncThunk(
  "/country/countries",
  async () => {
    try {
      if (contentApiCache.countries) {
        return { response: contentApiCache.countries };
      }
      const response = await axios.get("country/countries");
      contentApiCache.countries = response.data;
      return response.data;
    } catch {
      const response = {
        data: { status: 400 },
      };
      return response.data;
    }
  }
);

export const stateViewAction = createAsyncThunk(
  "/country/states/{country}",
  async (countryData) => {
    try {
      const response = await axios.get(`country/states/${countryData}`);
      console.log(response, "axiosStateViewAction");
      return response.data;
    } catch {
      const response = {
        data: { status: 400 },
      };
      return response.data;
    }
  }
);

export const searchAction = createAsyncThunk(
  "/search",
  async ({
    searchType,
    currentUserId,
    pageSize,
    searchText,
    mainCategoryId,
    subCategoryId,
    skillId,
    level,
    primaryLanguage,
    secondaryLanguage,
    publicRating,
  }) => {
    try {
      // Constructing the URL dynamically based on the existence of parameters
      const url =
        `search?searchType=${searchType}&userId=${currentUserId}&viewerId=${currentUserId}&pageNo=1&pageSize=${pageSize}` +
        `${searchText ? `&searchText=${searchText}` : ""}` +
        `${
          mainCategoryId !== undefined
            ? `&mainCategoryId=${mainCategoryId}`
            : ""
        }` +
        `${
          subCategoryId !== undefined ? `&subCategoryId=${subCategoryId}` : ""
        }` +
        `${skillId !== undefined ? `&skillId=${skillId}` : ""}` +
        `${level !== undefined ? `&level=${level}` : ""}` +
        `${
          primaryLanguage !== undefined
            ? `&primaryLanguage=${primaryLanguage}`
            : ""
        }` +
        `${
          secondaryLanguage !== undefined
            ? `&secondaryLanguage=${secondaryLanguage}`
            : ""
        }` +
        `${publicRating !== undefined ? `&publicRating=${publicRating}` : ""}`;

      const response = await axios.get(url);
      return response.data;
    } catch (error) {
      const response = {
        data: { status: 400 },
      };
      return response.data;
    }
  }
);
