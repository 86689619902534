import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const FooterContainer = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  color: ${({ theme, isSticky }) => (isSticky ? "#969696" : theme.main)};
  background: ${({ isSticky, theme }) => (isSticky ? "none" : theme.mainBg)};
  min-height: 5rem;
  width: 100%;
  position: ${({ isSticky }) => isSticky && "absolute"};
  bottom: ${({ isSticky }) => isSticky && 0};
  p {
    font-size: 12px;
  }
  @media screen and (max-width: 650px) {
    gap: 8px;
    padding-bottom: 1rem;
    p {
      font-size: 11px;
    }
  }
`;

const FooterLinkWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  @media screen and (max-width: 650px) {
    gap: 8px;
    padding: 0 10px;
  }
`;

const FooterLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme, isSticky }) => (isSticky ? "#969696" : theme.main)};
  font-size: 12px;
  cursor: pointer;
  &:hover {
    color: ${({ theme, isSticky }) => (isSticky ? "#fafafa" : theme.main)};
  }
  @media screen and (max-width: 650px) {
    font-size: 11px;
  }
`;

const Footer = ({ isSticky }) => {
  return (
    <FooterContainer isSticky={isSticky}>
      <FooterLinkWrapper>
        <FooterLink to="/terms-and-conditions" isSticky={isSticky}>
          Terms & Conditions
        </FooterLink>
        <FooterLink to="/privacy-policy" isSticky={isSticky}>
          Privacy Policy
        </FooterLink>
        <FooterLink to="/branded-policy" isSticky={isSticky}>
          Branded Policy
        </FooterLink>
        <FooterLink to="/cookies-policy" isSticky={isSticky}>
          Cookies
        </FooterLink>
        <FooterLink to="/community-guidelines" isSticky={isSticky}>
          Community
        </FooterLink>
        <FooterLink to="/intellectual-property-policy" isSticky={isSticky}>
          Intellectual Property
        </FooterLink>
      </FooterLinkWrapper>
      <FooterLinkWrapper>
        <p>Talents Explore © {new Date().getFullYear()}</p>
      </FooterLinkWrapper>
    </FooterContainer>
  );
};

export default Footer;
