import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {},
  userId: null,
  currentUser: {},
  currentUserId: null,
  token: null,
  refreshToken: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLogin: (state, action) => {
      state.currentUserId = action.payload.currentUserId;
      state.token = action.payload.token;
      state.refreshToken = action.payload.refreshToken;
      // store the credintials in local storage
      localStorage.setItem("token", action.payload.token);
      localStorage.setItem("currentUserId", action.payload.currentUserId);
      localStorage.setItem(
        "TalentsExploreCurrentUserId",
        action.payload.currentUserId
      );
    },
    setLogout: (state) => {
      state.currentUser = null;
      state.currentUserId = null;
      state.user = null;
      state.userId = null;
      state.token = null;
      state.refreshToken = null;
      localStorage.removeItem("token");
      localStorage.removeItem("currentUserId");
      localStorage.removeItem("TalentsExploreCurrentUserId");
      localStorage.removeItem("persist: root");
    },
    setUser: (state, action) => {
      state.user = {
        ...state.user,
        ...action.payload.user,
      };
    },
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload.currentUser;
    },
  },
});

export const { setLogin, setLogout, setUser, setCurrentUser, setUserLang } =
  authSlice.actions;
export default authSlice.reducer;
