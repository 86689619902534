import React, { useEffect, useRef, useState } from "react";
import {
  PostActionIconsSection,
  PostActionWrap,
  PostAvgRating,
  PostBody,
  PostBodyContent,
  PostContentWrap,
  PostDesc,
  PostHashtags,
  PostHeader,
  PostHeaderActionWrap,
  PostHeaderDetail,
  PostMediaWrap,
  PostMoreDetails,
  PostTitle,
  PostUserDpWrap,
  PostUserName,
  PostUserNameWrap,
  PostWebsiteLink,
  PostWrapper,
  PostsubTitle,
  SeeMoreBtn,
} from "./PostStyle";
import { UserProfileThumb } from "../Modals/ModalStyles";
import defaultImg from "../../Assets/Images/default_profile_pic.png";
import { CommentIcon, SaveIcon, ShareIcon, StarIcon } from "../../Assets/SVG";
import StarRating from "../StarRating/StarRating";
import { HiOutlineArrowNarrowRight, HiOutlineLink } from "react-icons/hi";
import { BsDot } from "react-icons/bs";
import { handlePostRating } from "../../Functions/getPostShareHandler";
import { useDispatch, useSelector } from "react-redux";
import ActionMenu from "./ActionMenu";
import { useHandleCreateShare } from "../../Hooks/useCreateShareHandler";
import { useCommonHandle } from "../../Hooks/useEventHandler";
import CommentInput from "../Inputs/CommentInput";
import CommentModal from "../Modals/CommentModal";
import MediaContainer from "./MediaContainer";
import { sampleMedia } from "../../Utils/Others/Data";
import SaveCollectionModal from "../Modals/SaveCollectionModal";
import { setBackNavigate } from "../../ReduxToolKit/Slices/utilsSlice";
import { addLineBreaks } from "../../Hooks/useCountFormat";

const Post = ({ data }) => {
  const currentUserId = localStorage.getItem("currentUserId"); //tediueucet //tbasieet
  const dispatch = useDispatch();
  const backNavigate = useSelector((state) => state.utils.backNavigate);
  const [activeIndex, setActiveIndex] = useState(
    new Array(data.length).fill(0)
  );
  const [seeMore, setSeeMore] = useState(
    new Array(data.length).fill({
      show: false,
      isMoreOpen: false,
    })
  );
  const [isCmtOpen, setIsCmtOpen] = useState(
    new Array(data.length).fill(false)
  );
  // I use this for open outside the entire screen - if it is not use it is showing inside the post body.
  const [cmtIndex, setCmtIndex] = useState(
    new Array(data.length).fill({
      postId: null,
      isOpen: false,
    })
  );

  // comment modal handling and values
  const [commentData, setCommentData] = useState([]);
  const [commentLoading, setCommentLoading] = useState(true);

  const [isFullScreen, setIsFullScreen] = useState(
    new Array(data.length).fill({
      postId: null,
      isFScr: false,
    })
  );

  //save to collection SC open close
  const [isSCOpen, setIsSCOpen] = useState(new Array(data.length).fill(false));
  const [scIndex, setSCIndex] = useState(
    //same usage as comment modal above
    new Array(data.length).fill({
      postId: null,
      isOpen: false,
    })
  );
  const [saveCollectionList, setSaveCollectionList] = useState([]);
  const [saveCollectionLoading, setSaveCollectionLoading] = useState(true);
  const tempTags = [
    "#test",
    "#qwet",
    "#kjdfns",
    "#setRatethePost",
    "#qwet",
    "#kjdfns",
    "#test",
    "#qwet",
    "#kjdfns",
    "#qwet",
    "#ratingValue",
    "#test",
    "#initialRatings",
  ];

  const initialRatings = data.map((post) => post.yourRating);
  const [rateThePost, setRatethePost] = useState(initialRatings);
  const [isCommonMute, setIsCommonMute] = useState(false);
  const [isStopScrollPlay, setIsStopScrollPlay] = useState(false);
  const posts = useRef([]);

  // handling scroll for a post to position sticky ------------------
  const handleScroll = () => {
    const postElements = posts.current;
    postElements.forEach((post, index) => {
      const rect = post.getBoundingClientRect();
      if (
        rect.top <= window.innerHeight / 2 &&
        rect.bottom >= window.innerHeight / 2
      ) {
        post.classList.add("sticky"); // Add a CSS class for sticky/fixed positioning
      } else {
        post.classList.remove("sticky");
      }
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // handling rate the individual post ------------------------------
  const handleRateThePost = (ratingValue, postId, postOwnerId) => {
    const index = data.findIndex((post) => post._id === postId);
    if (index !== -1) {
      const newRatings = [...rateThePost];
      newRatings[index] = ratingValue;
      setRatethePost(newRatings);
    }
    handlePostRating(
      currentUserId,
      postId,
      postOwnerId,
      ratingValue,
      dispatch,
      1,
      10
    );
  };

  const [commentValues, setCommentValues] = useState(
    new Array(data.length).fill({
      postId: null,
      comment: "",
    })
  );

  const {
    handleNextMultiPostMedia,
    handlePrevMultiPostMedia,
    handleBestWorkClick,
    handleDeleteClick,
    handleMultiCommentChange,
    handleMultiCommentSubmit,
    handleMultiCommentOpen,
    handleMultiCommentClose,
    handleMultiFullScreen,
    handleMultiMinScreen,
    handleMultiSaveColelctionOpen,
    handleMultiSaveColelctionClose,
  } = useHandleCreateShare({
    data,
    activeIndex,
    setActiveIndex,
    dispatch,
    isFullScreen,
    setIsFullScreen,
    //comments
    commentValues,
    setCommentValues,
    setCommentLoading,
    currentUserId,
    setIsCmtOpen,
    setCmtIndex,
    setCommentData,
    //save collection
    scIndex,
    setSCIndex,
    setSaveCollectionList,
    setSaveCollectionLoading,
    isSCOpen,
    setIsSCOpen,
  });

  useEffect(() => {
    console.log(isFullScreen, "isfullScreen");
  }, [isFullScreen]);

  const { handleCopyLink } = useCommonHandle();

  useEffect(() => {
    console.log(data, "data");
  }, [data]);

  const handleSeeMore = (index) => {
    const updatedSeeMore = [...seeMore];
    updatedSeeMore[index] = {
      ...updatedSeeMore[index],
      isMoreOpen: !updatedSeeMore[index].isMoreOpen,
    };
    setSeeMore(updatedSeeMore);
  };

  const postDescRefs = useRef([]);
  postDescRefs.current = Array.from(
    Array.from({ length: data.length }, () => React.createRef())
  );

  useEffect(() => {
    const updatedSeeMore = [...seeMore];

    postDescRefs.current.forEach((ref, index) => {
      if (ref.current) {
        const postDescElement = ref.current;
        if (postDescElement.scrollHeight > 48) {
          updatedSeeMore[index] = { ...updatedSeeMore[index], show: true };
        }
      }
    });
    setSeeMore(updatedSeeMore);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {data.map((item, index) => (
        <PostWrapper
          key={index}
          ref={(el) => (posts.current[index] = el)}
          className="post"
        >
          <PostHeader>
            <PostUserDpWrap
              to={`/profile/${item.author.firstName}-${item.author.lastName}/${item.author._id}`}
              onClick={() =>
                dispatch(setBackNavigate({ backNavigate: backNavigate + 1 }))
              }
            >
              <UserProfileThumb
                bgPosition={item.author.profileImage === null}
                borderRadius={"8px"}
                isTag={true}
                style={{
                  backgroundImage: `url(${
                    item.author.profileImage === null
                      ? defaultImg
                      : item.author.profileImage
                  })`,
                }}
              />
            </PostUserDpWrap>

            <PostHeaderDetail>
              <PostUserNameWrap>
                <PostUserName
                  to={`/profile/${item.author.firstName}-${item.author.lastName}/${item.author._id}`}
                  onClick={() =>
                    dispatch(
                      setBackNavigate({ backNavigate: backNavigate + 1 })
                    )
                  }
                >
                  {item.author.userName}
                </PostUserName>
                <span>
                  {item.timeAgo}
                  <BsDot style={{ fontSize: "1.2rem" }} />
                  <p>{item.about.country}</p>
                </span>
              </PostUserNameWrap>
              <PostHeaderActionWrap>
                <PostAvgRating>
                  <StarIcon width={"12px"} />

                  {item.postRating === null
                    ? "N/A"
                    : (Math.round(item.postRating * 10) / 10).toFixed(1)}
                </PostAvgRating>
                <ActionMenu
                  postId={item._id}
                  authorId={item.author._id}
                  isBestWork={item.isBestWork}
                  forPost={true}
                  onCopyLink={handleCopyLink}
                  onPin={handleBestWorkClick}
                  onDelete={handleDeleteClick}
                />
              </PostHeaderActionWrap>
            </PostHeaderDetail>
          </PostHeader>
          <PostBody>
            {/* -------------------------- media prev of a post ------------------------- */}
            <PostMediaWrap isFScr={isFullScreen[index].isFScr}>
              <MediaContainer
                source={
                  item._id === "6557d109c0c61d5c89fec781"
                    ? sampleMedia[activeIndex[index]]?.url
                    : item.media[activeIndex[index]]?.url
                }
                isImage={
                  item._id === "6557d109c0c61d5c89fec781"
                    ? sampleMedia[activeIndex[index]]?.mediaType === "image"
                    : item.media.length > 0
                    ? item.media[activeIndex[index]]?.mediaType === "image"
                    : true
                }
                isMultiple={true}
                mediaLength={
                  item._id === "6557d109c0c61d5c89fec781"
                    ? sampleMedia.length
                    : item.media.length
                }
                postId={item._id}
                postIndex={index}
                activeIndex={activeIndex[index]}
                onPrevClick={handlePrevMultiPostMedia}
                onNextClick={handleNextMultiPostMedia}
                onFullScreen={handleMultiFullScreen}
                onMinimizeScreen={handleMultiMinScreen}
                isFullScreen={isFullScreen[index].isFScr}
                isSampleNav={item._id === "6557d109c0c61d5c89fec781"}
                isCommonMute={isCommonMute}
                setIsCommonMute={setIsCommonMute}
                isStopScrollPlay={isStopScrollPlay}
                setIsStopScrollPlay={setIsStopScrollPlay}
                onStopPlay={() => setIsStopScrollPlay(!isStopScrollPlay)}
                isShareLinkThumbnail={
                  Boolean(item.shareLinkThumbnail?.url)
                    ? Boolean(item.shareLinkThumbnail?.url)
                    : false
                }
                shareLinkThumbnailSrc={
                  Boolean(item.shareLinkThumbnail?.url)
                    ? item.shareLinkThumbnail?.url
                    : null
                }
              />
            </PostMediaWrap>

            {/* -------------- media actions - share comment save and rate --------------- */}
            <PostBodyContent>
              <PostActionWrap>
                <PostActionIconsSection>
                  <span onClick={() => handleMultiCommentOpen(item._id)}>
                    <CommentIcon width={"20px"} />
                  </span>
                  <span>
                    <ShareIcon width={"20px"} />
                  </span>
                  <span onClick={() => handleMultiSaveColelctionOpen(item._id)}>
                    <SaveIcon width={"20px"} />
                  </span>
                </PostActionIconsSection>
                <StarRating
                  gaps={"0.35rem"}
                  width={"18px"}
                  onClick={handleRateThePost}
                  values={
                    rateThePost[index] ? rateThePost[index] : item.yourRating
                  }
                  postId={item._id}
                  postOwnerId={item.author._id}
                />
              </PostActionWrap>

              {/* ---------------------------- post desc location and more ----------------- */}
              <PostsubTitle style={{ fontSize: "11px" }}>
                {item.numberOfComments}&nbsp;Comments&nbsp;and&nbsp;
                {item.numberOfRating}&nbsp;Ratings
              </PostsubTitle>
              <PostTitle
                to={`/post/${item._id}`}
                onClick={() =>
                  dispatch(setBackNavigate({ backNavigate: backNavigate + 1 }))
                }
              >
                {item.about.title}
              </PostTitle>

              <PostsubTitle>
                {item.about.mainCategory}-{item.about.skill}
                <PostMoreDetails
                  to={`/post/${item._id}`}
                  onClick={() =>
                    dispatch(
                      setBackNavigate({ backNavigate: backNavigate + 1 })
                    )
                  }
                >
                  More Details
                  <HiOutlineArrowNarrowRight />
                </PostMoreDetails>
              </PostsubTitle>

              {Boolean(item.website) && (
                <PostWebsiteLink href={item.website.url} target="_blank">
                  <span>
                    <HiOutlineLink />
                  </span>
                  {item.website?.title}
                </PostWebsiteLink>
              )}

              {/* <iframe
                title="External Website"
                width="100%"
                height="600"
                src={item.website.url}
                frameBorder="0"
                allowFullScreen
              ></iframe> */}

              <PostContentWrap
                maxHeight={seeMore[index].isMoreOpen ? "none" : "43px"}
                ref={postDescRefs.current[index]}
              >
                <PostDesc>{addLineBreaks(item.about.description)}</PostDesc>
                <p
                  style={{
                    display: "flex",
                    width: "100%",
                    flexWrap: "wrap",
                  }}
                >
                  {tempTags.map((word, index) => (
                    <PostHashtags to="/" key={index}>
                      {word}
                    </PostHashtags>
                  ))}
                </p>
              </PostContentWrap>

              {seeMore[index].show && (
                <SeeMoreBtn
                  onClick={() => handleSeeMore(index)}
                  margin={seeMore[index].isMoreOpen}
                >
                  {seeMore[index].isMoreOpen ? "show less" : "see more..."}
                </SeeMoreBtn>
              )}

              <CommentInput
                id="comment"
                name="comment"
                isEmoji={true}
                placeHolder="Add a comment"
                postId={item._id}
                value={
                  commentValues[index].postId === item._id
                    ? commentValues[index].comment
                    : ""
                }
                onChange={handleMultiCommentChange}
                onSubmit={handleMultiCommentSubmit}
              />
            </PostBodyContent>
          </PostBody>
        </PostWrapper>
      ))}
      {isCmtOpen[cmtIndex] && (
        <CommentModal
          commentData={commentData}
          setCommentData={setCommentData}
          postId={isCmtOpen[cmtIndex].postId}
          isOpen={isCmtOpen}
          onClose={handleMultiCommentClose}
          cmtIndex={cmtIndex}
          commentLoading={commentLoading}
        />
      )}
      {isSCOpen[scIndex] && (
        <SaveCollectionModal
          isMulti={true}
          onClose={handleMultiSaveColelctionClose}
          postId={isSCOpen[scIndex].postId}
          saveCollectionList={saveCollectionList}
          saveCollectionLoading={saveCollectionLoading}
          setSaveCollectionList={setSaveCollectionList}
          setSaveCollectionLoading={setSaveCollectionLoading}
        />
      )}
    </>
  );
};

export default Post;
