import React from "react";

function EmojiIcon({ fill, width, height }) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width || "24px"}
      height={height || "24px"}
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill={fill || "var(--dark-primary)"}
        stroke="none"
      >
        <path
          d="M2270 4834 c-803 -103 -1493 -622 -1815 -1366 -136 -316 -193 -619
-182 -982 9 -322 68 -579 196 -867 297 -662 917 -1160 1626 -1304 213 -43 502
-57 713 -34 820 88 1535 620 1862 1382 243 568 241 1235 -5 1803 -328 758
-1040 1285 -1857 1373 -130 15 -408 12 -538 -5z m520 -314 c456 -57 861 -258
1181 -586 163 -168 261 -306 359 -509 337 -691 245 -1508 -237 -2105 -72 -89
-243 -257 -328 -321 -282 -216 -622 -355 -975 -399 -109 -13 -351 -13 -460 0
-352 44 -693 183 -975 399 -90 68 -269 246 -340 336 -232 297 -372 636 -416
1009 -15 126 -6 431 15 546 38 202 81 335 167 518 122 257 292 476 524 672
259 218 623 382 955 429 41 6 86 13 100 15 57 8 352 5 430 -4z"
        />
        <path
          d="M1607 3459 c-135 -18 -304 -103 -354 -178 -62 -91 3 -223 117 -238
37 -5 54 0 146 48 101 52 105 53 186 52 92 -1 145 -16 219 -64 62 -39 107 -46
164 -25 110 42 136 181 49 263 -42 40 -179 108 -255 127 -78 20 -192 26 -272
15z"
        />
        <path
          d="M3315 3459 c-80 -12 -146 -34 -229 -77 -89 -46 -131 -87 -145 -140
-22 -81 16 -158 94 -188 57 -21 102 -14 164 25 74 48 127 63 219 64 81 1 85 0
186 -52 92 -48 109 -53 146 -48 107 14 173 134 126 226 -34 64 -163 143 -286
174 -76 20 -203 27 -275 16z"
        />
        <path
          d="M1320 2488 c-71 -36 -90 -74 -90 -177 0 -236 85 -500 233 -721 65
-98 251 -281 352 -348 229 -152 473 -225 745 -225 354 0 665 125 920 369 255
246 410 594 410 925 0 103 -19 141 -90 177 l-44 22 -1196 0 -1196 0 -44 -22z
m2234 -335 c-11 -68 -64 -217 -101 -283 -198 -362 -598 -576 -993 -531 -341
40 -616 221 -784 515 -42 74 -97 222 -110 299 l-7 37 1001 0 1001 0 -7 -37z"
        />
      </g>
    </svg>
  );
}

export default EmojiIcon;
