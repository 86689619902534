import React from "react";

function LinkOutlineIcon({ width, height, fill }) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width || "24px"}
      height={height || "24px"}
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill={fill || "var(--dark-primary)"}
        stroke="none"
      >
        <path
          d="M2145 4804 c-161 -16 -381 -51 -552 -89 -483 -107 -864 -353 -1143
-740 -252 -349 -382 -825 -383 -1405 0 -497 89 -913 268 -1240 261 -478 701
-801 1258 -925 413 -91 927 -136 1217 -105 628 66 909 129 1181 265 357 180
649 473 827 831 315 632 314 1704 -3 2334 -257 512 -707 856 -1288 985 -181
40 -386 72 -566 89 -203 20 -618 19 -816 0z m639 -334 c291 -19 693 -88 873
-151 222 -77 389 -184 563 -358 174 -175 281 -343 358 -563 97 -274 147 -680
124 -1006 -41 -580 -176 -926 -482 -1233 -234 -234 -455 -349 -818 -423 -597
-123 -1087 -123 -1684 0 -257 53 -421 118 -605 240 -99 66 -252 208 -334 312
-119 149 -231 373 -278 558 -29 115 -68 350 -81 484 -33 375 26 840 145 1133
165 403 490 714 898 856 170 59 591 134 851 150 181 12 294 12 470 1z"
        />
        <path
          d="M2685 3650 c-129 -27 -271 -100 -365 -187 -40 -37 -79 -82 -86 -100
-31 -73 -7 -176 51 -221 59 -47 168 -55 217 -16 161 125 219 153 333 159 190
11 357 -98 423 -275 57 -155 28 -309 -83 -439 -68 -79 -78 -104 -73 -176 5
-76 38 -127 102 -156 89 -40 172 -16 255 77 176 197 249 484 186 729 -84 322
-324 549 -644 609 -108 21 -204 19 -316 -4z"
        />
        <path
          d="M2715 2966 c-16 -8 -150 -135 -297 -282 -296 -300 -304 -310 -279
-401 16 -62 44 -99 94 -125 54 -27 120 -27 172 0 56 29 540 517 559 564 33 79
8 172 -59 224 -30 23 -50 29 -99 31 -38 2 -72 -2 -91 -11z"
        />
        <path
          d="M1766 2885 c-80 -28 -209 -188 -260 -323 -31 -82 -66 -238 -66 -299
0 -23 9 -87 19 -141 52 -269 210 -477 452 -594 116 -57 193 -73 349 -73 110 0
149 4 213 23 137 39 280 123 363 214 52 56 72 127 54 185 -27 85 -101 142
-183 143 -52 0 -93 -19 -157 -74 -226 -193 -554 -132 -681 129 -33 67 -34 74
-34 185 0 109 2 118 32 180 18 36 53 88 78 117 48 55 75 107 75 145 0 75 -47
149 -115 179 -48 22 -86 23 -139 4z"
        />
      </g>
    </svg>
  );
}

export default LinkOutlineIcon;
