const useHandleEmailClick = () => {
  const email = "info@talentsexplore.com";
  const subject =
    "[Your Subject ex: Collaboration Opportunity or Reporting Inappropriate Content]";
  const body = `Hi Talents Explore Team,
    
    I am impressed with the incredible talent showcased on your platform! 
    I would like to explore a potential collaboration opportunity. 
    Please let me know how we can proceed.
    Looking forward to hearing from you!

    or 

    I came across some content on the platform that I believe violates the community guidelines. 
    I wanted to report it and bring it to your attention. 
    Please take appropriate action. 
    Thank you for maintaining a safe and inclusive environment for all users! 

    or

    [Include any relevant content related to your issues or concerns]


    Best regards, 
    [Your Name]
    
    
    `;

  const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(
    subject
  )}&body=${encodeURIComponent(body)}`;

  window.location.href = mailtoLink;
};

export default useHandleEmailClick;
