import {
  handleEduEditSubmit,
  handleLangEditSubmit,
} from "../Functions/getEditHandler";

export function useProfileEdit({
  dispatch,
  currentUserId,
  type,
  langArray,
  setLangArray,
  eduArray,
  selectedIndex,
  values,
}) {
  const handleLangCreate = () => {
    const newLangVal = {
      languageId: values.languageId,
      languageName: values.languageName,
      level: values.level,
    };
    const finalArray = [newLangVal, ...langArray];
    handleLangEditSubmit(
      currentUserId,
      dispatch,
      finalArray,
      type,
      setLangArray
    );
  };

  const handleLangEdit = async () => {
    setLangArray((prevLangArray) => {
      const updatedLangArray = [...prevLangArray]; // Create a copy of the previous state array
      updatedLangArray[selectedIndex] = {
        languageId: values?.languageId,
        languageName: values?.languageName,
        level: values?.level,
      };
      console.log(updatedLangArray, "updates Language");
      handleLangEditSubmit(
        currentUserId,
        dispatch,
        updatedLangArray,
        type,
        setLangArray
      );
      return updatedLangArray;
    });
  };

  const handleLanguageDelete = () => {
    const finalArray = [
      ...langArray.slice(0, selectedIndex),
      ...langArray.slice(selectedIndex + 1),
    ];

    handleLangEditSubmit(
      currentUserId,
      dispatch,
      finalArray,
      type,
      setLangArray
    );
    console.log(selectedIndex, "lang deleted", finalArray);
  };

  // handling education create, delete & edit change ---------------
  const handleEducationDelete = () => {
    const newArray = [
      ...eduArray.slice(0, selectedIndex),
      ...eduArray.slice(selectedIndex + 1),
    ];

    handleEduEditSubmit(currentUserId, dispatch, newArray);
    console.log(selectedIndex, "education deleted");
  };

  const handleEduSubmit = (values) => {
    console.log(values);
  };

  return {
    // language
    handleLangCreate,
    handleLangEdit,
    handleLanguageDelete,
    // education
    handleEducationDelete,
    handleEduSubmit,
  };
}
