import React, { useEffect, useState } from "react";
import {
  getPostSaveCollection,
  getSavedFolderFirstImage,
} from "../../Functions/getFetchFunctions";
import { useDispatch, useSelector } from "react-redux";
import { FadeLoader } from "react-spinners";
import { Helmet } from "react-helmet";
import {
  SavedContainer,
  SavedFolder,
  SavedFolderContainer,
  SavedFolderTilte,
  SavedFolderWrap,
  SavedLoader,
  SavedTitle,
  SavedTitleWrap,
} from "./SavedStyles";
import { setBackNavigate } from "../../ReduxToolKit/Slices/utilsSlice";
import {
  AddBtn,
  Image100,
  Video100,
} from "../../Components/Common/CommonStyle";
import { BiPlus } from "react-icons/bi";

const Saved = () => {
  const dispatch = useDispatch();
  const [saveCollectionList, setSaveCollectionList] = useState([]);
  const [folderImages, setFolderImages] = useState([]);
  const currentUserId = localStorage.getItem("currentUserId");
  const [saveCollectionLoading, setSaveCollectionLoading] = useState(true);
  const [folderImgLoading, setFolderImgLoading] = useState(true);
  const backNavigate = useSelector((state) => state.utils.backNavigate);

  useEffect(() => {
    getPostSaveCollection(
      currentUserId,
      0,
      dispatch,
      setSaveCollectionList,
      setSaveCollectionLoading
    );
  }, [currentUserId, dispatch]);

  useEffect(() => {
    if (
      saveCollectionList?.length > 0 &&
      folderImages?.length <= saveCollectionList?.length
    ) {
      for (let i = 0; i < saveCollectionList?.length; i++) {
        let collectionId = saveCollectionList[i]._id;
        getSavedFolderFirstImage(
          collectionId,
          currentUserId,
          1,
          1,
          dispatch,
          setFolderImages
        );
      }
    } else {
      setFolderImgLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveCollectionList]);

  useEffect(() => {
    console.log(folderImages);
  }, [folderImages]);

  const LoaderStyles = {
    margin: "0 0 0 1.2rem",
  };

  const sortedCollectionList = [...saveCollectionList];

  // Sort the copied array based on the availability of folderImages
  sortedCollectionList.sort((a, b) => {
    const hasImageA = folderImages[a.id] !== null;
    const hasImageB = folderImages[b.id] !== null;

    if (hasImageA && !hasImageB) {
      return -1; // If a has an image and b does not, a should come first
    } else if (!hasImageA && hasImageB) {
      return 1; // If b has an image and a does not, b should come first
    } else {
      return 0; // If both have images or both do not have images, maintain their order
    }
  });

  return (
    <SavedContainer>
      <Helmet>
        <title>Saved Collection | Talents Explore</title>
      </Helmet>
      <SavedTitleWrap>
        <SavedTitle>Saved Collection</SavedTitle>

        <AddBtn>
          <BiPlus />
          <p>Create a collection</p>
        </AddBtn>
      </SavedTitleWrap>
      {!saveCollectionLoading && !folderImgLoading ? (
        <SavedFolderContainer>
          {saveCollectionList?.map((item, index) => (
            <SavedFolderWrap key={index}>
              <SavedFolder
                to={`/saved/collections/${item.collectionName}/${item._id}`}
                onClick={() =>
                  dispatch(setBackNavigate({ backNavigate: backNavigate + 1 }))
                }
              >
                {folderImages[index]?.mediaType === "image" ? (
                  <Image100
                    src={folderImages[index]?.imgUrl}
                    alt=""
                    br={"15px"}
                  />
                ) : (
                  <Video100
                    src={folderImages[index]?.imgUrl}
                    alt=""
                    loop={false}
                    autoPlay={false}
                    muted
                    br={"15px"}
                  />
                )}
              </SavedFolder>
              <SavedFolderTilte
                to={`/saved/collections/${item.collectionName}/${item._id}`}
                onClick={() =>
                  dispatch(setBackNavigate({ backNavigate: backNavigate + 1 }))
                }
              >
                {item.collectionName}
              </SavedFolderTilte>
            </SavedFolderWrap>
          ))}
        </SavedFolderContainer>
      ) : (
        <SavedLoader>
          <FadeLoader
            color="#747474"
            height={8.5}
            width={2}
            radius={1}
            margin={-9}
            speedMultiplier={1.5}
            loading={true}
            cssOverride={LoaderStyles}
          />
        </SavedLoader>
      )}
    </SavedContainer>
  );
};

export default Saved;
