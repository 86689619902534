export const PersonalFormValidation = (values, year, month, day) => {
  let errors = [];
  const currentYear = new Date().getFullYear();

  if (!values.firstName) {
    errors.firstName = "First name is required";
  } else if (values.firstName.length < 2) {
    errors.firstName = "First name must be at least 2 characters";
  } else if (values.firstName.length > 25) {
    errors.firstName = "First name must be less than 25 characters";
  } else if (!/^[a-zA-Z ]+$/.test(values.firstName)) {
    errors.firstName = "First name must contain only letters and spaces";
  }

  if (!values.lastName) {
    errors.lastName = "Last Name is required";
  } else if (values.lastName.length < 2) {
    errors.lastName = "Last name must be at least 2 characters";
  } else if (values.lastName.length > 25) {
    errors.lastName = "Last name must be less than 25 characters";
  } else if (!/^[a-zA-Z ]+$/.test(values.lastName)) {
    errors.lastName = "Last name must contain only letters and spaces";
  }

  if (!year && !month && !day) {
    errors.dob = "Date of birth is required";
  } else if (year && !month && !day) {
    errors.dob = "Please select the month and day";
  } else if (year && month && !day) {
    errors.dob = "Please select the day";
  } else if (!year && !month && day) {
    errors.dob = "Please select the year and month";
  } else if (!year && month && !day) {
    errors.dob = "Please select the year and day";
  } else if (!year && month && day) {
    errors.dob = "Please select the year";
  } else if (year && !month && day) {
    errors.dob = "Please select the month";
  } else if (parseInt(currentYear) - parseInt(year) <= 13) {
    errors.dob = "Your age must be above 13 years or equal";
  }

  if (!values.gender) {
    errors.gender = "Gender is required";
  }

  if (!values.knownLanguage) {
    errors.knownLanguage = "Known Languages is required";
  }

  if (!values.profession) {
    errors.profession = "Profession is required";
  }
  return errors;
};

export const MoreInfoFormValidation = (values) => {
  let errors = [];

  if (!values.description) {
    errors.description = "Description is required.";
  }
  return errors;
};

export const SocialMediaValidation = (values, mediaType) => {
  let errors = [];

  if (values && values[0].default === false) {
    if (!values[0].url) {
      errors.url = "Profile link is required";
    }
    if (!values[0].mediaType) {
      errors.mediaType = "Select the media type";
    }
  }
  return errors;
};

export const DefaultSocialMediaValidation = (link, mediaType) => {
  let errors = [];

  if (!/^https?:\/\//i.test(link)) {
    errors[
      mediaType
    ] = `Please include 'http://' or 'https://' before the ${mediaType} link`;
  } else {
    const regexMap = {
      facebook: /^(https?:\/\/)?(www\.)?facebook\.com\/.*$/,
      snapchat: /^(https?:\/\/)?(www\.)?snapchat\.com\/add\/.*$/,
      tiktok: /^(https?:\/\/)?(www\.)?tiktok\.com\/@.*$/,
      youtube: /^(?<=\d\/|\.be\/|v[=/])([\w-]{11,})|^([\w-]{11})$/gm,
      // /^(https?:\/\/)?(www\.)?youtube\.com\/.*$/,
      github: /^(https?:\/\/)?(www\.)?github\.com\/.*$/,
      twitter: /^(https?:\/\/)?(www\.)?twitter\.com\/.*$/,
      instagram:
        /^(https?:\/\/(www\.)?instagram\.com\/([A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_]))?))^/,

      // /^(https?:\/\/)?(www\.)?instagram\.com\/.*$/,
      linkedin:
        /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)\/([-a-zA-Z0-9]+)\/*/gm,
    };

    // const URL =
    //   /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})(\.[a-zA-Z0-9]{2,})?/;

    // if (URL.test(link)) {
    //   errors[mediaType] = `The provided link is not a valid ${mediaType} link`;
    // }

    // const URL =
    //   /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})(\.[a-zA-Z0-9]{2,})?/;

    const platformRegex = regexMap[mediaType];

    if (!platformRegex.test(link)) {
      errors[mediaType] = `The provided link is not a valid ${mediaType} link`;
    }
  }

  return errors;
};

export const SkillsFormValidation = (values) => {
  let errors = [];

  if (!values.mainCategory) {
    errors.mainCategory = "Select a main category";
  }
  if (!values.subCategory) {
    errors.subCategory = "Select a sub category";
  }
  if (!values.skillsHave[0].skill) {
    errors.skill = "Select a skill";
  }
  if (!values.skillsHave[0].level) {
    errors.level = "Select the level";
  }
  if (!values.skillDescription) {
    errors.skillDescription = "Description is required";
  }
  return errors;
};

export const LanguageFormValidation = (values) => {
  let errors = [];

  if (!values.language) {
    errors.language = "Select the language";
  }
  if (!values.level) {
    errors.level = "Select the level";
  }
  return errors;
};

export const EducationFormValidation = (values) => {
  let errors = [];

  if (!values.course) {
    errors.course = "You must enter the course or subject name";
  }
  if (!values.institution) {
    errors.institution = "University or Insitution name is required";
  }
  if (!values.from) {
    errors.from = "Select the year";
  }
  if (!values.to) {
    errors.to = "Select the year";
  } else if (values.from > values.to) {
    errors.from = "Year 'From' cannot be greater than 'To'";
    // errors.to = "Year 'From' cannot be greater than 'To'";
  }
  return errors;
};

export const AwardsFormValidation = (values) => {
  let errors = [];

  if (!values.awardName) {
    errors.awardName = "Name of the award or certificate is required";
  }
  if (!values.givenBy) {
    errors.givenBy = "Given by whom is required";
  }
  if (!values.when) {
    errors.when = "Select the year";
  }
  return errors;
};
