import React from "react";
import { PrimaryBtn } from "../../Components/Common/CommonStyle";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Settings = () => {
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.auth.currentUser);

  return (
    <div style={{ marginTop: "15rem" }}>
      <PrimaryBtn
        onClick={() =>
          navigate(
            `/upgrade-account-as-talent/${currentUser?.personalInfo.firstName}_${currentUser?.personalInfo.lastName}`
          )
        }
      >
        Wanna become a Talent
      </PrimaryBtn>
    </div>
  );
};

export default Settings;
