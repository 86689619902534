import React, { useCallback, useRef, useState } from "react";
import {
  MinusButton,
  PlusButton,
  PrimaryBtn,
  SecondaryBtn,
} from "../Common/CommonStyle";
import {
  ModalBg,
  CropPhotoModal,
  CropPhotoContainer,
  ModalButtonWrapper,
  SliderContainer,
  ModalHeader,
  CloseButtonWrapper,
} from "./ModalStyles";
import { SliderInput } from "../Inputs/InputStyle";
import Cropper from "react-easy-crop";
import getCroppedImg from "../../Functions/getCroppedImg";
import { IoMdClose } from "react-icons/io";
import {
  RatioContainer,
  RatioOptionButton,
  RatioSpanIcon,
} from "../../Pages/Upload/UploadStyle";
import { RatioOptions } from "../../Utils/Others/Data";

const CropProfileCoverPhoto = ({
  image,
  setImage,
  handleCropComplete,
  setIsOpen,
  isCover,
  width,
  isPost,
  isProfile,
  handleRatioChange,
  isRatio,
  setIsRatio,
  values,
  isValues,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);
  const slider = useRef(null);

  const zoomIn = () => {
    slider.current.stepUp();
    setZoom(slider.current.value);
  };
  const zoomOut = () => {
    slider.current.stepDown();
    setZoom(slider.current.value);
  };

  const getCroppedImage = useCallback(
    async (show) => {
      try {
        const img = await getCroppedImg(image, croppedAreaPixels);

        if (show) {
          setZoom(1);
          setCrop({ x: 0, y: 0 });
          setIsOpen(false);
          handleCropComplete(img);
          setImage("");

          console.log(img, "cropped image---------");
        } else {
          return img;
        }
      } catch (error) {
        console.log(error);
      }
    },
    [croppedAreaPixels, handleCropComplete, image, setImage, setIsOpen]
  );

  return (
    <ModalBg>
      <CropPhotoModal>
        <ModalHeader>
          {isCover && "Set Cover Photo"}
          {isProfile && "Set Profile Photo"}
          {isPost && "Adjust & Crop Photo for Post"}

          {/* <CloseButton onClick={() => setImage("")} /> */}
        </ModalHeader>
        <CloseButtonWrapper onClick={() => setImage("")} isCorner={true}>
          <IoMdClose />
        </CloseButtonWrapper>

        <CropPhotoContainer>
          {isCover && (
            <Cropper
              image={image.preview}
              crop={crop}
              zoom={zoom}
              aspect={3.8 / 1}
              objectFit="horizontal-cover"
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              showGrid={true}
              maxZoom={4.6}
              minZoom={1}
            />
          )}
          {isProfile && (
            <Cropper
              image={image.preview}
              crop={crop}
              zoom={zoom}
              aspect={3 / 4}
              cropShape="rectangle"
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              showGrid={true}
              maxZoom={4.6}
              minZoom={1}
            />
          )}
          {isPost && (
            <Cropper
              image={image.preview}
              crop={crop}
              zoom={zoom}
              aspect={
                isRatio.is16to9
                  ? 16 / 11
                  : isRatio.is4to3
                  ? 4 / 3
                  : isRatio.is3to2
                  ? 3 / 2
                  : isRatio.is1to1
                  ? 1 / 1
                  : isRatio.is3to4
                  ? 3.6 / 4.495
                  : 3.6 / 4.495
              }
              cropShape="rectangle"
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              showGrid={true}
              maxZoom={4.6}
              minZoom={1}
            />
          )}
        </CropPhotoContainer>

        <SliderContainer>
          <MinusButton onClick={zoomOut} />
          <SliderInput
            type="range"
            min={1}
            max={4.6}
            step={0.1}
            ref={slider}
            value={zoom}
            onChange={(e) => setZoom(e.target.value)}
          />
          <PlusButton onClick={zoomIn} />
        </SliderContainer>

        {isValues !== true && isPost && (
          <RatioContainer>
            {RatioOptions.map((item, index) => (
              <RatioOptionButton
                key={index}
                onClick={() => handleRatioChange(item.num)}
              >
                <p>{item.text}</p>
                <RatioSpanIcon width={item.width} height={item.height} />
              </RatioOptionButton>
            ))}
          </RatioContainer>
        )}

        <ModalButtonWrapper>
          <SecondaryBtn onClick={() => setImage("")}>Cancel</SecondaryBtn>
          <PrimaryBtn onClick={() => getCroppedImage("show")}>Done</PrimaryBtn>
        </ModalButtonWrapper>
      </CropPhotoModal>
    </ModalBg>
  );
};

export default CropProfileCoverPhoto;
