import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  postsById: [],
  userPinnedPostsById: [],
  userAllPostsById: [],
  moreDetailPost: {},
};

export const postSlice = createSlice({
  name: "posts",
  initialState,
  reducers: {
    // home feed
    setAddPosts: (state, action) => {
      const newPostsById = {};
      if (action.payload.postsById) {
        action.payload.postsById.forEach((post) => {
          newPostsById[post._id] = post;
        });
      }
      state.postsById = newPostsById;
    },
    setUpdatePost: (state, action) => {
      const post = action.payload;
      state.postsById[post._id] = post;
    },

    // user pinned post
    setUserPinnedPostsById: (state, action) => {
      const newPostsById = {};
      if (action.payload.userPinnedPostsById) {
        action.payload.userPinnedPostsById.forEach((post) => {
          newPostsById[post._id] = post;
        });
      }
      state.userPinnedPostsById = newPostsById;
    },

    // user all post view
    setUserAllPostsById: (state, action) => {
      const newPostsById = {};
      if (action.payload.userAllPostsById) {
        action.payload.userAllPostsById.forEach((post) => {
          newPostsById[post._id] = post;
        });
      }
      state.userAllPostsById = newPostsById;
    },
    setUpdateUserAllPost: (state, action) => {
      const post = action.payload;
      state.userAllPostsById[post._id] = post;
    },
  },
});

export const {
  setAddPosts,
  setUpdatePost,
  setUserPinnedPostsById,
  setUserAllPostsById,
  setUpdateUserAllPost,
} = postSlice.actions;
export default postSlice.reducer;
