import { Link } from "react-router-dom";
import styled from "styled-components";

export const SidebarContainer = styled.aside`
  height: 100vh;
  width: 15rem;
  min-width: 15rem;
  /* max-width: 15rem; */
  background: ${(props) => props.theme.altBg};
  display: flex;
  padding: 0.5rem 0.5rem 2rem 0.5rem;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 20;
  @media screen and (max-width: 1450px) {
    padding: 0.7rem 0.5rem;
  }
  @media screen and (max-width: 1200px) {
    display: none;
  }
`;

export const SidebarHeader = styled.header`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  margin-top: 1.2rem;
  @media screen and (max-width: 1450px) {
    margin-top: 0.5rem;
    gap: 0.5rem;
  }
`;

export const SbarLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const SidebarLogo = styled.img`
  object-fit: cover;
  -o-object-fit: cover;
  height: 2.5rem;
  width: 10rem;
`;

export const SidebarMenu = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  margin-top: 2rem;
  gap: 1.2rem;

  h4 {
    color: ${(props) => props.theme.sidebarText};
    font-size: 13px;
    margin-bottom: 5px;
    font-weight: 500;
  }
  @media screen and (max-width: 1450px) {
    margin-top: 2rem;
    gap: 1rem;
  }
`;

export const SidebarListWrapper = styled.div`
  // to categorize the side menu
  display: flex;
  flex-direction: column;
  /* border-bottom: 1px solid var(--l-gray); */
  width: 100%;
  margin-bottom: 0.8rem;
`;

export const SidebarList = styled.li`
  list-style: none;
  width: 100%;
  &:hover {
    color: ${(props) => props.theme.defaultLight};
    fill: ${(props) => props.theme.defaultLight};
  }
`;

export const SidebarLinks = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  text-decoration: none;
  padding: 0.7rem 1rem;
  width: 100%;
  border-radius: 5px;
  position: relative;
  font-size: 14px;
  transition: 0.1s all ease-in-out;

  fill: ${({ isActive, theme }) =>
    isActive ? theme.defaultLight : theme.sidebarText};
  color: ${({ isActive, theme }) =>
    isActive ? theme.defaultLight : theme.sidebarText};
  background: ${({ isActive, theme }) => (isActive ? theme.hoverBg : "")};
  &:hover {
    background: ${(props) => props.theme.hoverBg};
    color: ${(props) => props.theme.defaultLight};
    fill: ${(props) => props.theme.defaultLight};
  }
  @media screen and (max-width: 1450px) {
    padding: 0.6rem 1rem;
  }
`;

export const SidebarModalLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  padding: 0.7rem 1rem;
  width: 100%;
  border-radius: 5px;
  position: relative;
  font-size: 14px;
  transition: 0.1s all ease-in-out;
  cursor: pointer;
  fill: ${({ isActive, theme }) =>
    isActive ? theme.defaultLight : theme.sidebarText};
  color: ${({ isActive, theme }) =>
    isActive ? theme.defaultLight : theme.sidebarText};
  background: ${({ isActive, theme }) => (isActive ? theme.hoverBg : "")};
  &:hover {
    background: ${(props) => props.theme.hoverBg};
    color: ${(props) => props.theme.defaultLight};
    fill: ${(props) => props.theme.defaultLight};
  }
  @media screen and (max-width: 1450px) {
    padding: 0.6rem 1rem;
  }
`;

export const RedIndicator = styled.span`
  position: absolute;
  width: 4px;
  height: 1.5rem;
  background: ${(props) => props.theme.primary};
  border-radius: 10px;
  top: 25%;
  right: -2px;
`;

export const SidebarIcons = styled.img`
  width: 22px;
  height: 22px;
  object-fit: cover;
`;

export const LogoutBtn = styled.button`
  width: 100%;
  padding: 0.7rem 2.3rem 0.7rem 1.6rem;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  font-size: 1.3rem;
  background: none;
  cursor: pointer;
  transition: 0.1s all ease-in-out;
  p {
    font-size: 14px;
  }

  border: 1px solid
    ${({ theme }) => (theme.mode === "light" ? "#484883" : theme.borderColor)};
  fill: ${(props) => props.theme.sidebarText};
  color: ${(props) => props.theme.sidebarText};
  &:hover {
    background: ${(props) => props.theme.hoverBg};
    color: ${(props) => props.theme.defaultLight};
    fill: ${(props) => props.theme.defaultLight};
    border: 1px solid ${(props) => props.theme.hoverBg};
  }
  @media screen and (max-width: 1450px) {
    padding: 0.6rem 2.3rem 0.6rem 1.6rem;
  }
`;
