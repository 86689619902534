import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BotContainer,
  BotLinks,
  NavbarIcons,
  ProfileLink,
  RedIndicator,
} from "./NavbarStyle";
import { BottombarItemList } from "../../Utils/Others/Data";
import ProfileIcon from "../../Assets/Images/default_profile_pic.png";
import { setBackNavigate } from "../../ReduxToolKit/Slices/utilsSlice";

const Bottombar = ({ activeLink }) => {
  const currentUserId = localStorage.getItem("currentUserId");
  // const currentUserId = localStorage.getItem("TalentsExploreCurrentUserId");
  const currentUser = useSelector((state) => state.auth.currentUser);
  const profilePath = `/profile/${currentUser?.personalInfo?.firstName}_${currentUser?.personalInfo?.lastName}/${currentUserId}`;
  const dispatch = useDispatch();
  return (
    <BotContainer>
      {BottombarItemList.map((item, index) => (
        <BotLinks
          key={index}
          to={item.link}
          isActive={activeLink === `${item.link}`}
          onClick={() => dispatch(setBackNavigate({ backNavigate: 0 }))}
        >
          {item.icon}
          {activeLink === `${item.link}` && <RedIndicator />}
        </BotLinks>
      ))}
      <ProfileLink
        to={profilePath}
        active={activeLink === profilePath}
        onClick={() => dispatch(setBackNavigate({ backNavigate: 0 }))}
      >
        <NavbarIcons
          src={
            currentUser?.personalInfo?.profileImage
              ? currentUser.personalInfo?.profileImage
              : ProfileIcon
          }
          alt="profile_icon"
        />
        {activeLink === profilePath && <RedIndicator />}
      </ProfileLink>
    </BotContainer>
  );
};

export default Bottombar;
