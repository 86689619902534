import React, { useState } from "react";
import {
  CloseButtonWrapper,
  MobileClose,
  ModalBg,
  PAMBodyWrap,
  PostActionModalContainer,
  PAMHeader,
  PAMBody,
  CmtloaderWrap,
  SCnameWrap,
  BeforeSaveStatus,
  AfterSaveStatus,
  EmptyModalDataWrap,
} from "./ModalStyles";
import { IoMdClose } from "react-icons/io";
import { HiCheckCircle, HiOutlineArrowSmLeft } from "react-icons/hi";
import CommentInput from "../Inputs/CommentInput";
import { useDispatch } from "react-redux";
import { useHandleCreateShare } from "../../Hooks/useCreateShareHandler";
import { FadeLoader } from "react-spinners";
import { SaveIcon } from "../../Assets/SVG";

const SaveCollectionModal = ({
  isMulti,
  onClose,
  postId,
  saveCollectionList,
  saveCollectionLoading,
  setSaveCollectionList,
  setSaveCollectionLoading,
}) => {
  const [collectionName, setCollectionName] = useState("");
  const currentUserId = localStorage.getItem("currentUserId");
  const dispatch = useDispatch();
  const handleClose = () => {
    if (isMulti) {
      onClose && onClose(postId);
    } else {
      onClose && onClose(postId, true);
    }
  };

  const {
    handleSaveCollectionChange,
    handleSaveCollectionSubmit,
    handleSaveToSpecificCollection,
  } = useHandleCreateShare({
    dispatch,
    currentUserId,
    collectionName,
    setCollectionName,
    saveCollectionList,
    setSaveCollectionList,
    setSaveCollectionLoading,
  });
  const reversedList = saveCollectionList?.slice().reverse();
  const initialStatus = reversedList.map((post) => post.status);
  const [statusIndi, setStatusIndi] = useState(initialStatus);

  const handleSave = (id, name, status) => {
    const index = reversedList.findIndex((post) => post._id === id);
    if (index !== -1) {
      const newStatus = [...statusIndi];
      newStatus[index] = !status;
      setStatusIndi(newStatus);
    }
    handleSaveToSpecificCollection(
      id,
      name,
      postId,
      status,
      onClose,
      setStatusIndi
    );
  };

  const LoaderStyles = {
    margin: "0 0 0 1.2rem",
  };

  return (
    <ModalBg>
      <PostActionModalContainer>
        <PAMBodyWrap>
          <MobileClose onClick={handleClose}>
            <span>
              <HiOutlineArrowSmLeft />
            </span>
          </MobileClose>

          <PAMHeader isCenter={true}>Save to Collection</PAMHeader>
          <PAMBody style={{ gap: "1px", padding: "10px" }}>
            {!saveCollectionLoading ? (
              <>
                {reversedList.length > 0 ? (
                  <>
                    {reversedList.map((item, index) => (
                      <SCnameWrap
                        key={index}
                        onClick={() =>
                          handleSave(item._id, item.collectionName, item.status)
                        }
                      >
                        {item.collectionName}
                        {item.status || statusIndi[index] ? (
                          <AfterSaveStatus>
                            <HiCheckCircle />
                          </AfterSaveStatus>
                        ) : (
                          <BeforeSaveStatus />
                        )}
                      </SCnameWrap>
                    ))}
                  </>
                ) : (
                  <EmptyModalDataWrap>
                    <SaveIcon width={"2.5rem"} height={"2.5rem"} />
                    <span style={{ marginTop: "5px" }} />
                    No Collection
                    <p>Create a folder to save posts and shares</p>
                  </EmptyModalDataWrap>
                )}
              </>
            ) : (
              <CmtloaderWrap>
                <FadeLoader
                  color="#747474"
                  height={8.5}
                  width={2}
                  radius={1}
                  margin={-9}
                  speedMultiplier={1.5}
                  loading={true}
                  cssOverride={LoaderStyles}
                />
              </CmtloaderWrap>
            )}
          </PAMBody>
        </PAMBodyWrap>

        <footer>
          <CommentInput
            id={"saveCollection"}
            name={"saveCollection"}
            value={collectionName}
            setValues={setCollectionName}
            isModal={true}
            placeHolder={"Create a new collection..."}
            onChange={handleSaveCollectionChange}
            onSubmit={handleSaveCollectionSubmit}
            isEmoji={false}
          />
        </footer>
        <CloseButtonWrapper onClick={handleClose}>
          <IoMdClose />
        </CloseButtonWrapper>
      </PostActionModalContainer>
    </ModalBg>
  );
};

export default SaveCollectionModal;
