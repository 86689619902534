import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "styled-components";
import { getProfilePinnedPost } from "../../../../Functions/getFetchFunctions";
import { PinIcon } from "../../../../Assets/SVG";
import {
  PinnedPostCard,
  PinnedPostContent,
  PinnedPostContentWrap,
  PinnedPostNoContent,
  PinnedPostSection,
  PinnedPostTitle,
  PinnedPostWrap,
  UserDetailHeading,
} from "../OverviewStyle";
import { StarDisplay } from "../../../../Components";
import { useCommonHandle } from "../../../../Hooks/useEventHandler";
import { ActionMenu } from "../../../../Components";
import { useHandleCreateShare } from "../../../../Hooks/useCreateShareHandler";
import { NoPinned } from "../../../../Assets/SVGImages";
import { Image100, Video100 } from "../../../../Components/Common/CommonStyle";

const PinnedPosts = () => {
  const user = useSelector((state) => state.auth.user);
  let userId = user._id;
  const dispatch = useDispatch();
  const theme = useTheme();
  const currentUserId = localStorage.getItem("currentUserId");
  let pageNo = 1;
  let pageSize = 5;

  const userPinnedPostsById = useSelector(
    (state) => state.posts.userPinnedPostsById
  );
  const userPinPostIds = Object.keys(userPinnedPostsById);
  const userPinnedPosts = userPinPostIds.map(
    (pinnedPostId) => userPinnedPostsById[pinnedPostId]
  );

  useEffect(() => {
    getProfilePinnedPost(currentUserId, userId, pageNo, pageSize, dispatch);
  }, [userId, dispatch, currentUserId, pageNo, pageSize]);

  const { handleCopyLink } = useCommonHandle();
  const { handleBestWorkClick } = useHandleCreateShare({
    dispatch,
  });

  return (
    <>
      {userPinnedPosts.length !== 0 ? (
        <PinnedPostSection>
          <UserDetailHeading isWidthFull={true}>
            <span>
              <PinIcon width={"18px"} /> Pinned Posts
            </span>
          </UserDetailHeading>
          <PinnedPostWrap>
            {userPinnedPosts.map((item, index) => (
              <PinnedPostCard key={index}>
                {item.media[0].mediaType === "image" ? (
                  <Image100 src={item.media[0].url} br={"15px"} alt="" />
                ) : (
                  <Video100
                    src={item.media[0].url}
                    alt=""
                    loop={false}
                    // autoPlay={false}
                    // autoPlay
                    controls={false}
                    muted
                    br={"15px"}
                    playsInline
                    webkit-playsinline
                    controlsList="nofullscreen"
                    preload="yes"
                    // poster="video_thumbnail/thumbanil.jpg"
                  />
                )}
                <PinnedPostContentWrap to={`/post/${item._id}`}>
                  <PinnedPostContent>
                    <PinnedPostTitle>{item.about.title}</PinnedPostTitle>
                    <StarDisplay
                      peopleCount={item.numberOfRating}
                      rating={item.postRating ? item.postRating : null}
                    />
                  </PinnedPostContent>
                </PinnedPostContentWrap>
                <ActionMenu
                  postId={item._id}
                  authorId={item.author._id}
                  isBestWork={item.isBestWork}
                  forPinned={true}
                  onCopyLink={handleCopyLink}
                  onPin={handleBestWorkClick}
                  top={"1.1rem"}
                  right={"3rem"}
                />
              </PinnedPostCard>
            ))}
          </PinnedPostWrap>
        </PinnedPostSection>
      ) : currentUserId === userId ? (
        <PinnedPostSection>
          <UserDetailHeading>
            <span>
              <PinIcon width={"18px"} /> Pinned Posts
            </span>
          </UserDetailHeading>
          <PinnedPostNoContent>
            <NoPinned
              width={"12rem"}
              height={"12rem"}
              pmClr={theme.primary}
              subClr={theme.mediumMain}
              terClr={theme.mainBg}
            />

            <p>
              You Haven't Pinned Any Post as Your Best
              <span>
                <PinIcon width={"16px"} />
              </span>
            </p>
          </PinnedPostNoContent>
        </PinnedPostSection>
      ) : null}
    </>
  );
};

export default PinnedPosts;
