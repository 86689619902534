import React from "react";
import {
  Answer,
  Heading,
  PageWrapper,
  Paragraph,
  ParahLink,
  PlusButton,
  QAWrapper,
  Question,
} from "./PoliciesStyles";
import {
  Parah,
  PointsIcon,
  PointsWrapper,
} from "../../Documents/ContentsStyles";
import { Helmet } from "react-helmet";

const BrandedPolicy = () => {
  return (
    <>
      <Helmet>
        <title>Branded Content Policy | Talents Explore</title>
      </Helmet>

      <PageWrapper>
        <Heading>Branded Content Policy</Heading>
        <Paragraph>
          Branded content on Talents Explore (“Branded Content”) is content that
          promotes goods or services where you will receive (or have already
          received) something of value from a third party, such as a brand, in
          exchange for your post, or which you might otherwise need to disclose
          to comply with laws or regulations.
        </Paragraph>
        <Paragraph style={{ marginBottom: "-1rem" }}>
          Branded Content could include content posted by you, which features or
          references:
        </Paragraph>
        <QAWrapper>
          <Answer>
            <PointsWrapper>
              <PointsIcon />A product or service that has been gifted to you by
              a brand, or that you have been paid to post about (whether in the
              form of money or a gift), or for which you will receive a
              commission on any sales (for instance, via an affiliate link or
              using a promotional code); or
            </PointsWrapper>
            <PointsWrapper>
              <PointsIcon />A brand that you have or have had a commercial
              relationship with, such as where you are acting as a brand
              ambassador.
            </PointsWrapper>
          </Answer>
        </QAWrapper>
        <QAWrapper>
          <Question isActive={true}>
            Rules for posting Branded Content <PlusButton />
          </Question>
          <Answer>
            <Parah>
              You are responsible for ensuring that any Branded Content that you
              post complies with all applicable laws and regulations. While the
              rules in this Branded Content Policy are intended to help you to
              comply with relevant laws and regulations, they are not exhaustive
              and you should be aware of any other applicable legal
              requirements.
            </Parah>
            <Parah>
              As well as following these rules when you post Branded Content,
              you must also comply with Talents Explore’s{" "}
              <ParahLink to="/terms-and-conditions">Terms of Service</ParahLink>{" "}
              and{" "}
              <ParahLink to="/community-guidelines">
                Community Guidelines
              </ParahLink>{" "}
            </Parah>
          </Answer>
        </QAWrapper>
        <QAWrapper>
          <Question isActive={true}>
            What do you need to do when you post Branded Content? <PlusButton />
          </Question>
          <Answer>
            <PointsWrapper>
              <PointsIcon />
              You must enable the branded content toggle when posting Branded
              Content (unless you are located in a country where it is not
              available).
            </PointsWrapper>
            <PointsWrapper>
              <PointsIcon />
              You must ensure that the product or service you are promoting is
              sufficiently clear, without requiring other users to access your
              profile page or any links. For instance, you should explicitly
              identify the product or service verbally and/or in the text
              caption.
            </PointsWrapper>
            <PointsWrapper>
              <PointsIcon />
              You must not post Branded Content which promotes products or
              services relating to any of the 🚫Prohibited Industries listed
              below.
            </PointsWrapper>
            <PointsWrapper>
              <PointsIcon />
              We also recommend that you do not post Branded Content that
              violates any of the Country-Specific Requirements available via
              the link below. Otherwise, we may be required to prevent the
              content from being accessed in a specific country or countries
              where it may be contrary to local laws or regulations.
            </PointsWrapper>
            <Parah>
              To the extent that your Branded Content does not comply with these
              rules, we may remove the content or impose other restrictions. We
              may update these rules periodically (for instance, if we need to
              reflect any changes to applicable laws or regulations) and in some
              instances we may need to apply them to content you have already
              posted (for example, if a new law requires us to no longer display
              certain types of content).
            </Parah>
          </Answer>
        </QAWrapper>
        <QAWrapper>
          <Question isActive={true}>
            Prohibited Industries
            <PlusButton />
          </Question>
          <Answer>
            <Parah>
              You must not post Branded Content which promotes products or
              services from the following 🚫 Prohibited Industries.
            </Parah>
            <PointsWrapper>
              {/* <PointsIcon /> */}
              <span>🚫</span>
              Alcohol - Alcoholic beverages (wine, beer, spirits, etc.), alcohol
              clubs/subscription services, alcohol-making kits, or
              alcohol-sponsored events. This includes alcohol-free or no-alcohol
              alternatives and soft drinks presented as mixers for alcohol.
            </PointsWrapper>
            <PointsWrapper>
              <span>🚫</span>
              Cigarettes and tobacco products - Cigarettes, cigars, tobacco,
              e-cigarettes, cigarette containers, shisha, and other related
              products and services.
            </PointsWrapper>
            <PointsWrapper>
              <span>🚫</span>
              Drug-related products and services - Illegal drugs, drug
              paraphernalia, recreational and prescription drugs, CBD
              supplements.
            </PointsWrapper>
            <PointsWrapper>
              <span>🚫</span>
              Weapons - Dangerous weapons, including guns, explosives, knives,
              swords, and other objects that are designed to cause harm to
              individuals.
            </PointsWrapper>
            <PointsWrapper>
              <span>🚫</span>
              Gambling - Online gambling, casinos, sports betting, and Branded
              Content promoting fantasy sports, bingo, lotteries, or other
              gambling-related content.
            </PointsWrapper>
            <PointsWrapper>
              <span>🚫</span>
              Sexual products and services - Adult entertainment and related
              paraphernalia including pornography, sex toys, lubricants, and
              fetish costumes, including some underwear like corsets and
              micro-thongs.
            </PointsWrapper>
            <PointsWrapper>
              <span>🚫</span>
              Political content - Referencing, promoting, or opposing, a
              candidate for public office, current or former political leader,
              political party, or political organization. You must not advocate
              a stance (for or against) on a local, regional, or national issue
              of public importance to influence a political outcome.
            </PointsWrapper>
            <PointsWrapper>
              <span>🚫</span>
              Products and services that enable dishonest behaviour - Products
              that may infringe upon individual privacy, products that may steal
              other people's personal information, products that may infringe
              upon a third party's properties, products that may steal other
              people's properties, or products or services assisting with
              falsification.
            </PointsWrapper>
            <PointsWrapper>
              <span>🚫</span>
              Animals - Buying or selling animals, live animals, livestock, and
              pets. Animal body parts/products from rhino, orangutan, elephant,
              or any endangered or threatened animals, including but not limited
              to organs, horns, ivory, bone, skin, fur, wool, leather, or teeth.
            </PointsWrapper>
            <PointsWrapper>Exception</PointsWrapper>
            <PointsWrapper>
              <PointsIcon />
              Products for animals (toys, clothes, collars, cages, food, etc.)
            </PointsWrapper>
            <PointsWrapper>
              <PointsIcon />
              Pet services like dog walking, pet care, pet cafes, pet grooming,
              training, etc.
            </PointsWrapper>
            <PointsWrapper>
              <PointsIcon />
              Advertising content related to the adoption of animals is
              permitted for NGOs, NPOs, and stray animal shelters (no adoption
              services to be promoted for high breed pets or any invitation to
              join into mix-breeding of pets).
            </PointsWrapper>
            <PointsWrapper>
              <span>🚫</span> Professional services - Accounting and taxation
              services, legal services, and immigration services.
            </PointsWrapper>
            <PointsWrapper>
              <span>🚫</span> Pharmaceuticals, healthcare and medicine products
              - Including any pharmacological product claiming health benefits,
              over-the-counter medicines, prescription drugs, vitamins, and
              powders, shakes or gummies claiming to have vitamin or health
              benefits.
            </PointsWrapper>
            <PointsWrapper>
              <span>🚫</span> Financial products or services or opportunities -
              Including foreign exchange, loans, credit cards, buy now pay later
              services, debt consolidation services, investment services,
              lending and management of money assets, trading platforms, pyramid
              schemes, multi-level marketing opportunities, credit repair, bail
              bonds, penny auctions, virtual currencies and “get rich quick”
              schemes.
            </PointsWrapper>
            <PointsWrapper>
              <span>🚫</span> Contraceptive products - Any products, artificial
              methods, or other techniques to prevent pregnancy as a consequence
              of sexual intercourse, such as condoms except for family planning.
            </PointsWrapper>
            <PointsWrapper>
              <span>🚫</span> Dating and live video applications or services -
              Dating services are services that are focused on generating
              connections between people who are interested in romance, casual
              sex, or friendship. Live video services are services where the
              primary feature is a live video chat.
            </PointsWrapper>
            <PointsWrapper>
              <span>🚫</span> Age rated films, TV shows and games - Films, TV
              shows, and games that are restricted to certain audiences.
            </PointsWrapper>
            <PointsWrapper>
              <span>🚫</span> Other prohibited products or services -
              International brides, hazardous chemical products, trading of
              human organs and transplants, abortion, services of prenatal sex
              determination, protected wildlife, wild flora, and funeral
              services.
            </PointsWrapper>
          </Answer>
        </QAWrapper>
      </PageWrapper>
    </>
  );
};

export default BrandedPolicy;
