import React from "react";
import { PuffLoader } from "react-spinners";
import styled from "styled-components";

const LoaderContainer = styled.div`
  display: grid;
  place-items: center;
  position: ${({ isFull }) => isFull && "fixed"};
  height: ${({ is80 }) => (is80 ? "80vh" : "100vh")};
  z-index: ${({ isFull }) => (isFull ? "99" : "1")};
  top: ${({ isFull }) => isFull && "0"};
  left: ${({ isFull }) => isFull && "0"};
  width: 100%;
  background: ${(props) => props.theme.mainBg};
  color: ${(props) => props.theme.dark};
  /* border: 1px solid red; */
`;

const Loader = ({ isFull, is80 }) => {
  return (
    <LoaderContainer isFull={isFull} is80={is80}>
      <PuffLoader color="#ea3148" loading={true} />
    </LoaderContainer>
  );
};

export default Loader;

export const ImageLoader = () => {
  return <p>loading</p>;
};
