import React from "react";

function FBIcon({ width, height, fill }) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width || "24px"}
      height={height || "24px"}
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill={fill || "var(--dark-primary)"}
        stroke="none"
      >
        <path
          d="M2370 5113 c-371 -35 -653 -114 -961 -269 -406 -203 -782 -548 -1029
-944 -179 -286 -309 -655 -362 -1025 -17 -118 -17 -512 0 -630 42 -295 120
-553 242 -800 137 -280 272 -468 494 -691 221 -220 412 -357 681 -489 188 -92
309 -137 500 -185 500 -126 1002 -102 1490 71 149 53 407 182 540 271 365 243
667 578 866 963 181 348 271 694 286 1090 15 426 -73 832 -263 1214 -124 250
-263 447 -458 648 -216 224 -428 378 -711 518 -296 146 -572 225 -900 255
-102 9 -333 11 -415 3z m868 -1410 l2 -251 -182 -4 c-277 -5 -278 -6 -278
-283 l0 -175 235 0 c129 0 235 -1 235 -3 0 -2 -11 -110 -25 -241 -14 -131 -25
-247 -25 -257 0 -18 -10 -19 -210 -19 l-210 0 0 -740 0 -740 -310 0 -310 0 0
740 0 740 -145 0 -145 0 0 260 0 260 144 0 144 0 5 233 c5 263 15 319 82 445
82 157 233 256 427 281 34 5 176 8 313 7 l250 -1 3 -252z"
        />
      </g>
    </svg>
  );
}

export default FBIcon;
