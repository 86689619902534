import React from "react";

function SearchOutlineIcon({ width, height, fill }) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width || "24px"}
      height={height || "24px"}
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill={fill || "var(--dark-primary)"}
        stroke="none"
      >
        <path
          d="M2100 5049 c-1000 -101 -1809 -834 -2004 -1815 -57 -290 -52 -626 15
-926 105 -476 390 -935 774 -1248 562 -458 1316 -617 2015 -425 261 72 515
195 727 352 40 29 76 53 80 53 5 0 220 -212 478 -471 259 -258 489 -482 512
-495 50 -30 131 -41 189 -25 59 16 125 70 154 127 32 62 35 169 6 224 -10 19
-235 252 -499 518 -369 369 -480 485 -472 496 262 361 406 701 460 1086 22
154 22 456 0 610 -105 744 -570 1387 -1240 1715 -368 180 -809 263 -1195 224z
m335 -499 c163 -13 267 -34 415 -82 367 -119 693 -366 914 -693 59 -87 166
-305 199 -405 127 -382 120 -804 -19 -1178 -128 -344 -365 -644 -674 -849
-443 -295 -1010 -368 -1530 -197 -98 32 -317 140 -405 200 -652 440 -935 1256
-694 2000 88 271 221 488 428 695 330 329 724 497 1216 517 17 1 84 -3 150 -8z"
        />
      </g>
    </svg>
  );
}

export default SearchOutlineIcon;
