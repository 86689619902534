import React from "react";
import useHandleEmailClick from "../../Hooks/useEmail";

const PP_10 = () => {
  const handleEmailClick = useHandleEmailClick;

  return (
    <>
      <p
        onClick={handleEmailClick}
        style={{
          color: " #ff2146f0",
          cursor: " pointer",
        }}
      >
        info@talentsexplore.com
      </p>
    </>
  );
};

export default PP_10;
