import axios from "axios";

export async function getFileUpload(file) {
  if (file) {
    let image = file.name;
    const ext = image.split(".")[1];
    const key = `user/${Date.now()}.${ext}`;

    return new Promise(async (resolve, reject) => {
      const formData = {
        fileName: key,
        contentType: file.type,
      };
      const signedUrlResult = await axios.post("/s3/signedUrl", formData);
      console.log(signedUrlResult, "signed--url--result");

      if (signedUrlResult.status === 201) {
        const signedUrl = signedUrlResult.data.url;
        fetch(signedUrl, { method: "PUT", body: file })
          .then((res) => {
            console.log(res, "res of signed url");
            if (res.ok) {
              resolve(signedUrlResult.data.key);
            } else {
              // handle error when the file upload fails
            }
          })
          .catch((error) => {
            reject(error.message);
          });
      } else {
        // handle error when getting the signed URL fails
        reject("");
      }
    });
  } else {
    return null;
  }
}

export async function getMediaUpload(file) {
  const currentUserId = localStorage.getItem("currentUserId");
  if (file) {
    let image = file.name;
    const ext = image.split(".")[1];
    const fileName = currentUserId;
    const key = `${fileName}-${Date.now()}.${ext}`;

    return new Promise(async (resolve, reject) => {
      const formData = {
        fileName: key,
        contentType: file.type,
      };
      const signedUrlResult = await axios.post("/s3/signedUrl", formData);
      console.log(signedUrlResult, "signed--url--result");

      if (signedUrlResult.status === 201) {
        const signedUrl = signedUrlResult.data.url;
        fetch(signedUrl, { method: "PUT", body: file })
          .then((res) => {
            console.log(res, "res of signed url");
            if (res.ok) {
              const ResolveData = {
                url: signedUrlResult.data.key,
                key: key,
              };
              resolve(ResolveData);
            } else {
              // handle error when the file upload fails
            }
          })
          .catch((error) => {
            reject(error.message);
          });
      } else {
        // handle error when getting the signed URL fails
        reject("");
      }
    });
  } else {
    return null;
  }
}
