import { talentLanguageEditAction } from "../ReduxToolKit/APIs/AuthAPI";
import { setAlertDetails } from "../ReduxToolKit/Slices/utilsSlice";
import { getTalentLanguage } from "./getFetchFunctions";

export function handleLangEditSubmit(
  currentUserId,
  dispatch,
  finalArray,
  type,
  setLangArray
) {
  console.log(finalArray, "lang submit delete");
  try {
    const formData = [];

    for (let i = 0; i < finalArray.length; i++) {
      const languageKnown = {
        languageId: finalArray[i].languageId,
        level: finalArray[i].level,
      };
      formData.push(languageKnown);
    }

    dispatch(talentLanguageEditAction({ currentUserId, formData })).then(
      (actionResult) => {
        const responseData = actionResult.payload;
        console.log(responseData.status);
        if (parseInt(responseData.status) === 7400) {
          getTalentLanguage(currentUserId, dispatch, setLangArray);
          dispatch(
            setAlertDetails({
              alertDetails: {
                isOpen: true,
                type: "success",
                message: `${
                  type === "langEdit"
                    ? "Language Updated Successfully"
                    : type === "languages"
                    ? "Language Added Successfully"
                    : "Language Deleted Succcessfully"
                }`,
              },
            })
          );
        } else {
          dispatch(
            setAlertDetails({
              alertDetails: {
                isOpen: true,
                type: "danger",
                message: "Something went wrong. Please try again later.",
              },
            })
          );
        }
      }
    );
  } catch {}
}

// dispatchj education create delete and edit function
export function handleEduEditSubmit(
  userId,
  currentUserId,
  dispatch,
  finalArray
) {
  console.log(currentUserId, dispatch, finalArray, "submit delete");
  // try {
  //   const formData = [];
  //   for (let i = 0; i < newArray.length; i++) {
  //     const languageKnown = {
  //       course: newArray[i].languageId,
  //       institution: newArray[i].level,
  //       from: newArray[i].languageId,
  //       to: newArray[i].level,
  //     };
  //     formData.push(languageKnown);
  //   }
  // } catch {}
}
