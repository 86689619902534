import React from "react";

function AtomicIcon({ fill, width, height }) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height || "24px"}
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill={fill || "var(--dark-primary)"}
        stroke="none"
      >
        <path
          d="M2456 5108 c-119 -22 -268 -117 -374 -236 -196 -221 -344 -544 -451
-980 -54 -220 -54 -220 -33 -226 47 -13 184 -37 189 -33 3 3 22 80 43 171 108
462 292 840 488 1002 126 103 251 125 372 64 80 -40 186 -144 260 -254 85
-127 228 -443 254 -563 l7 -30 -150 -52 c-680 -233 -1428 -679 -1952 -1162
l-106 -98 -30 32 c-63 66 -175 211 -239 308 -131 200 -198 378 -197 529 0 127
42 205 143 270 106 68 231 91 490 90 l187 0 7 46 c3 26 6 73 6 104 l0 57 -67
6 c-266 26 -548 -15 -707 -101 -64 -35 -156 -120 -195 -181 -132 -206 -96
-503 102 -841 75 -128 172 -260 319 -432 l31 -38 -31 -37 c-147 -173 -244
-305 -319 -433 -198 -338 -234 -635 -102 -841 39 -61 131 -146 195 -181 160
-86 429 -125 707 -102 l77 7 -5 36 c-2 20 -7 66 -10 102 l-6 66 -202 0 c-230
1 -317 14 -432 67 -77 36 -136 93 -165 160 -91 206 51 553 383 939 l58 68 107
-99 c519 -478 1245 -914 1912 -1147 91 -31 171 -59 178 -61 18 -5 10 -40 -40
-173 -53 -145 -145 -332 -208 -427 -74 -110 -180 -214 -260 -254 -121 -61
-246 -39 -372 64 -196 162 -380 540 -488 1002 -21 91 -40 168 -43 171 -5 4
-142 -20 -189 -33 -21 -6 -21 -6 33 -226 177 -724 488 -1166 858 -1220 106
-16 196 1 305 55 65 32 100 59 176 136 52 53 119 130 147 172 101 147 204 366
277 586 15 46 31 83 34 83 4 0 36 -7 72 -16 301 -72 642 -81 852 -23 295 82
465 307 443 589 -21 279 -140 546 -373 840 -73 92 -345 370 -362 370 -5 0 -39
-35 -76 -77 l-67 -77 98 -90 c364 -337 579 -734 563 -1040 -3 -66 -10 -91 -34
-135 -93 -172 -376 -242 -769 -190 -70 9 -287 49 -293 54 -2 1 11 68 27 148
37 175 64 355 88 572 25 231 25 959 0 1190 -24 217 -51 397 -88 572 -16 80
-29 146 -27 148 6 5 214 44 298 56 119 17 360 17 444 0 183 -37 300 -116 342
-233 22 -62 20 -203 -5 -303 -27 -104 -97 -245 -171 -344 -33 -43 -60 -81 -60
-84 0 -3 34 -36 76 -73 l76 -68 29 34 c60 71 159 227 192 305 177 407 98 748
-208 900 -225 111 -573 123 -978 35 -50 -11 -92 -19 -93 -17 -2 1 -22 56 -45
122 -118 337 -259 580 -433 742 -150 140 -316 195 -490 161z m818 -1306 c8
-14 64 -311 80 -427 89 -644 73 -1287 -45 -1895 -17 -85 -35 -159 -40 -165
-12 -12 -200 51 -416 141 -575 239 -1204 637 -1621 1028 l-81 75 102 95 c329
304 767 602 1227 835 229 116 354 171 585 257 171 63 200 71 209 56z"
        />
        <path
          d="M2423 3075 c-88 -24 -171 -72 -236 -138 -113 -113 -158 -221 -158
-377 0 -156 45 -264 158 -377 100 -101 227 -153 371 -153 439 0 688 500 423
850 -73 97 -173 165 -288 194 -74 19 -200 20 -270 1z m263 -224 c56 -25 128
-90 154 -141 31 -61 43 -142 30 -207 -16 -76 -39 -119 -92 -170 -64 -62 -127
-88 -219 -88 -67 0 -84 4 -145 35 -111 57 -174 158 -174 281 0 226 238 381
446 290z"
        />
      </g>
    </svg>
  );
}

export default AtomicIcon;
