import React, { useState } from "react";
import {
  CloseButtonWrapper,
  PAMBody,
  CmtDPLink,
  CmtDetailWrap,
  CmtUserDp,
  CmtUserName,
  CmtUserNameWrap,
  CmtloaderWrap,
  MobileClose,
  ModalBg,
  PAMBodyWrap,
  PostActionModalContainer,
  PAMHeader,
  SingleCmtWrap,
  EmptyModalDataWrap,
} from "./ModalStyles";
import defaultImg from "../../Assets/Images/default_profile_pic.png";
import CommentInput from "../Inputs/CommentInput";
import { BsDot } from "react-icons/bs";
import { IoMdClose } from "react-icons/io";
import { HiOutlineArrowSmLeft } from "react-icons/hi";
import { useHandleCreateShare } from "../../Hooks/useCreateShareHandler";
import { useDispatch } from "react-redux";
import { getFormattedDate } from "../../Hooks/useDateSelector";
import { FadeLoader } from "react-spinners";
import { CommentIcon } from "../../Assets/SVG";

const CommentModal = ({
  commentData,
  setCommentData,
  postId,
  onClose,
  commentLoading,
}) => {
  const [commentValues, setCommentValues] = useState("");
  const currentUserId = localStorage.getItem("currentUserId");
  const dispatch = useDispatch();
  const handleClose = () => {
    onClose && onClose(postId);
  };

  const { handleCommentChange, handleCommentSubmit } = useHandleCreateShare({
    commentValues,
    setCommentValues,
    dispatch,
    currentUserId,
    commentData,
    setCommentData,
  });

  const LoaderStyles = {
    margin: "0 0 0 1.2rem",
  };

  return (
    <ModalBg>
      <PostActionModalContainer>
        <PAMBodyWrap>
          <MobileClose onClick={handleClose}>
            <span>
              <HiOutlineArrowSmLeft />
            </span>
          </MobileClose>
          <PAMHeader isCenter={true}>
            {commentData && commentData.length !== 0 && commentData.length < 10
              ? `0${commentData.length}`
              : commentData.length !== 0 && commentData.length}
            {commentData.length !== 0 && <>&nbsp;&nbsp;</>}
            {commentData.length === 1 ? "Comment" : "Comments"}
          </PAMHeader>
          <PAMBody>
            {!commentLoading ? (
              <>
                {commentData.length > 0 ? (
                  <>
                    {commentData?.map((item, index) => (
                      <SingleCmtWrap key={index}>
                        <CmtDPLink
                          to={`/profile/${item.author.firstName}-${item.author.lastName}/${item.author._id}`}
                        >
                          <CmtUserDp
                            bgPosition={item.author.profileImage === null}
                            borderRadius={"100px"}
                            isTag={true}
                            style={{
                              backgroundImage: `url(${
                                item.author.profileImage === null
                                  ? defaultImg
                                  : item.author.profileImage
                              })`,
                            }}
                          />
                        </CmtDPLink>
                        <CmtDetailWrap>
                          <CmtUserNameWrap>
                            <CmtUserName
                              to={`/profile/${item.author.firstName}-${item.author.lastName}/${item.author._id}`}
                            >
                              @{item.author.userName}
                            </CmtUserName>
                            <span>
                              <BsDot style={{ fontSize: "1.2rem" }} />
                              {getFormattedDate(item.createdAt)}
                            </span>
                          </CmtUserNameWrap>
                          <p>{item.comment}</p>
                        </CmtDetailWrap>
                      </SingleCmtWrap>
                    ))}
                  </>
                ) : (
                  <EmptyModalDataWrap>
                    <CommentIcon width={"2.6rem"} height={"2.6rem"} />
                    No Comments
                    <p>Be the first one to add a comment</p>
                  </EmptyModalDataWrap>
                )}
              </>
            ) : (
              <CmtloaderWrap>
                <FadeLoader
                  color="#747474"
                  height={8.5}
                  width={2}
                  radius={1}
                  margin={-9}
                  speedMultiplier={1.5}
                  loading={true}
                  cssOverride={LoaderStyles}
                />
              </CmtloaderWrap>
            )}
          </PAMBody>
        </PAMBodyWrap>
        <footer>
          <CommentInput
            id="comment"
            name="comment"
            isEmoji={true}
            placeHolder="Add a comment"
            postId={postId}
            value={commentValues}
            setValues={setCommentValues}
            isModal={true}
            onChange={handleCommentChange}
            onSubmit={handleCommentSubmit}
          />
        </footer>
        <CloseButtonWrapper onClick={handleClose}>
          <IoMdClose />
        </CloseButtonWrapper>
      </PostActionModalContainer>
    </ModalBg>
  );
};

export default CommentModal;
