import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, Outlet, useLocation } from "react-router-dom";
import { getCurrentUserPersonalDetails } from "../Functions/getFetchFunctions";
import { AlertModal, Bottombar, Footer, Navbar, Sidebar } from "../Components";
import styled from "styled-components";
import { IoIosArrowBack } from "react-icons/io";

export const AuthLayout = () => {
  const dispatch = useDispatch();
  const currentUserId = localStorage.getItem("currentUserId");
  const { pathname } = useLocation();
  let isSticky =
    pathname === "/login" || pathname === "/joinus" || pathname === "/";

  useEffect(() => {
    if (currentUserId !== null) {
      getCurrentUserPersonalDetails(currentUserId, dispatch);
    }
  }, [currentUserId, dispatch]);

  return (
    <main style={{ minHeight: "100vh", position: "relative" }}>
      <AlertModal />
      <Navbar
        showLogo={true}
        activeLink={pathname}
        isSticky={isSticky}
        isPolicy={true}
      />
      <Outlet />
      <Footer isSticky={isSticky} />
    </main>
  );
};

export const UpdateAccountLayout = () => {
  return (
    <main>
      <Navbar isSticky={true} showLogo={true} />
      <Outlet />
    </main>
  );
};

// user layout styles

const UserLayoutMain = styled.main`
  display: flex;
`;

const UserLayoutContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${(props) => props.theme.mainBg};
  min-height: 100vh;
`;

const UserLayoutSection = styled.section`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const UserLayout = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const currentUserId = localStorage.getItem("currentUserId");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getCurrentUserPersonalDetails(currentUserId, dispatch);
    setIsLoading(false);
  }, [currentUserId, dispatch]);

  return (
    <>
      {!isLoading && (
        <UserLayoutMain>
          <Sidebar activeLink={pathname} />
          <UserLayoutContainer>
            <AlertModal />
            <Navbar showLogo={false} activeLink={pathname} isSticky={false} />
            <UserLayoutSection>
              <Outlet />
            </UserLayoutSection>
            <Bottombar activeLink={pathname} />
          </UserLayoutContainer>
        </UserLayoutMain>
      )}
    </>
  );
};

export const ErrorRoutes = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "10px",
        flexDirection: "column",
        color: "white",
        background: "#1c1c24",
        height: "100vh",
      }}
    >
      <p>404 | Page not found</p>
      <p>Design development in progress</p>
      <Link
        to="/feed"
        style={{
          textDecoration: "none",
          color: "#c92437",
          display: "flex",
          alignItems: "center",
        }}
      >
        <IoIosArrowBack />
        &nbsp;Go Back
      </Link>
    </div>
  );
};
