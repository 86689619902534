import React from "react";

function EmptyFolder({ pmClr, subClr, terClr, width, height }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "10rem"}
      height={height || "10rem"}
      viewBox="180 80 350 350"
    >
      {/* <path
        d="M669.31574,602.10039c-14.62543,1.28906-29.05133-4.09235-39.25928-14.6452-6.59052-6.94855-11.11322-15.89233-15.48706-24.54163-1.27319-2.51691-2.58929-5.11969-3.92822-7.61426-3.05396-5.56403-2.41583-12.42633,1.61176-17.33179,3.85968-4.90411,10.31482-6.96655,16.30298-5.2088l.63824,.18439,5.7442,22.41406c.85693-3.34326,2.69629-10.87592,3.85785-18.2561l.0976-.61816,.57202,.25476c5.9978,2.65387,11.63757,6.05237,16.78625,10.11536,18.46057,14.80768,29.47321,36.98608,30.11212,60.64307l.0152,.69702-17.06366-6.09271Z"
        fill="#f2f2f2"
      /> */}
      {/* <path
        d="M856.56342,609.69084l-278.75,.30731c-.65759-.00342-1.18787-.53931-1.18445-1.1969,.00342-.65271,.53168-1.18103,1.18445-1.18445l278.75-.30731c.65759-.00214,1.19238,.52924,1.19446,1.18683,.0022,.65759-.52917,1.19238-1.18677,1.19452h-.00769Z"
        fill="#cacaca"
      /> */}
      {/* <path
        d="M117.39185,111.50542h-10.41522c-2.07126,0-3.75037,1.67909-3.75037,3.75037h0c0,2.07126-1.67911,3.75037-3.75037,3.75037h-34.72314c-2.07127,0-3.75037-1.67911-3.75037-3.75037l-.68657-3.80186,.04463,.05148-.04463-.05148-6.2941-8.02425c-.41786-.54137-1.06514-.8558-1.74899-.84962h-11.80385c-1.22699,.00146-2.2213,.99577-2.22276,2.22276l-.24371,6.65112h-4.46246c-3.94171,0-7.1371,3.19539-7.1371,7.13708v46.48383c0,3.49796,2.83565,6.33359,6.33359,6.33359H117.39189c3.49588-.0043,6.32849-2.83771,6.33185-6.33359v-47.23758c0-3.49699-2.83487-6.33185-6.33188-6.33185h0Z"
        fill="#f2f2f2"
      /> */}
      {/* <path
        d="M60.31924,111.28229h-22.07314v-6.65112c.00146-1.22699,.99577-2.2213,2.22276-2.22276h11.80382c.68385-.00618,1.33113,.30826,1.74899,.84962l6.29756,8.02426Z"
        fill="#e6e6e6"
      /> */}
      {/* <path
        d="M71.62121,166.29347H34.76483c-2.4292-.00275-4.39777-1.97131-4.4005-4.40051v-10.07956c.00273-2.4292,1.9713-4.39777,4.4005-4.40051h44.23631c2.4292,.00275,4.39777,1.97131,4.4005,4.40051v2.69968c-.00737,6.50308-5.27734,11.77304-11.78043,11.7804Z"
        fill="#fff"
      /> */}
      {/* <path
        d="M45.37431,155.01604h-9.097c-1.23233,.00394-2.23453-.99188-2.23846-2.22421-.00395-1.23233,.99187-2.23453,2.2242-2.23846h9.11127c1.23233-.00394,2.23453,.99188,2.23846,2.22421,.00395,1.23233-.99187,2.23453-2.2242,2.23846h-.01427Z"
        fill="#f2f2f2"
      /> */}
      {/* <path
        d="M76.26983,162.05336H36.27729c-1.23233,.00394-2.23453-.99188-2.23846-2.22421-.00395-1.23233,.99187-2.23453,2.2242-2.23846h40.00679c1.23233-.00394,2.23453,.99188,2.23846,2.22421,.00395,1.23233-.99187,2.23453-2.2242,2.23846h-.01425Z"
        fill="#f2f2f2"
      /> */}
      {/* <path
        d="M117.39185,257.50542h-10.41522c-2.07126,0-3.75037,1.67911-3.75037,3.75037h0c0,2.07126-1.67911,3.75037-3.75037,3.75037h-34.72314c-2.07127,0-3.75037-1.67911-3.75037-3.75037l-.68657-3.80188,.04463,.05148-.04463-.05148-6.2941-8.02426c-.41786-.54138-1.06514-.8558-1.74899-.84964h-11.80385c-1.22699,.00146-2.22128,.99576-2.22276,2.22275l-.24371,6.65112h-4.46246c-3.94171,0-7.1371,3.19537-7.1371,7.13708v46.48383c0,3.49796,2.83565,6.33359,6.33359,6.33359H117.39189c3.49588-.0043,6.32849-2.83771,6.33185-6.33359v-47.23752c0-3.49698-2.83487-6.33185-6.33188-6.33185h0Z"
        fill="#f2f2f2"
      /> */}
      {/* <path
        d="M60.31924,257.2823h-22.07314v-6.65115c.00146-1.22699,.99577-2.22128,2.22276-2.22275h11.80382c.68385-.00616,1.33113,.30826,1.74899,.84964l6.29756,8.02426Z"
        fill="#e6e6e6"
      /> */}
      {/* <path
        d="M71.62121,312.29347H34.76483c-2.4292-.00275-4.39777-1.97131-4.4005-4.40051v-10.07956c.00273-2.4292,1.9713-4.39777,4.4005-4.40051h44.23631c2.4292,.00275,4.39777,1.97131,4.4005,4.40051v2.69968c-.00737,6.50308-5.27734,11.77304-11.78043,11.7804Z"
        fill="#fff"
      /> */}
      {/* <path
        d="M45.37431,301.01604h-9.097c-1.23233,.00394-2.23453-.99188-2.23846-2.22421-.00395-1.23233,.99187-2.23453,2.2242-2.23846h9.11127c1.23233-.00394,2.23453,.99188,2.23846,2.22421,.00395,1.23233-.99187,2.23453-2.2242,2.23846h-.01427Z"
        fill="#f2f2f2"
      /> */}
      {/* <path
        d="M76.26983,308.05336H36.27729c-1.23233,.00394-2.23453-.99188-2.23846-2.22421-.00395-1.23233,.99187-2.23453,2.2242-2.23846h40.00679c1.23233-.00394,2.23453,.99188,2.23846,2.22421,.00395,1.23233-.99187,2.23453-2.2242,2.23846h-.01425Z"
        fill="#f2f2f2"
      /> */}
      {/* <path
        d="M117.39185,403.50542h-10.41522c-2.07126,0-3.75037,1.67908-3.75037,3.75037h0c0,2.07129-1.67911,3.75037-3.75037,3.75037h-34.72314c-2.07127,0-3.75037-1.67908-3.75037-3.75037l-.68657-3.80188,.04463,.05151-.04463-.05151-6.2941-8.02423c-.41788-.54138-1.06514-.85577-1.74899-.84961h-11.80385c-1.22699,.00146-2.2213,.99579-2.22276,2.22278l-.24371,6.65112h-4.46246c-3.94171,0-7.13708,3.19537-7.1371,7.13708v46.48376c-.00002,3.49792,2.83562,6.33362,6.33356,6.33362H117.39189c3.49588-.00433,6.32852-2.83771,6.33185-6.33362v-47.23755c0-3.49701-2.83487-6.33185-6.33188-6.33185h0Z"
        fill="#f2f2f2"
      /> */}
      {/* <path
        d="M60.31924,403.28227h-22.07314v-6.65112c.00145-1.22699,.99577-2.22131,2.22276-2.22278h11.80382c.68385-.00616,1.33112,.30823,1.74899,.84961l6.29756,8.02429Z"
        fill="#e6e6e6"
      /> */}
      {/* <path
        d="M71.62121,458.2935H34.76483c-2.4292-.00275-4.39777-1.97131-4.4005-4.40051v-10.07959c.00273-2.4292,1.9713-4.39777,4.4005-4.40051h44.23631c2.4292,.00275,4.39777,1.97131,4.4005,4.40051v2.69965c-.00734,6.50311-5.27731,11.77307-11.78043,11.78046Z"
        fill="#fff"
      /> */}
      {/* <path
        d="M45.37431,447.01604h-9.097c-1.23235,0-2.23135-.99902-2.23135-2.23132s.99901-2.23138,2.23135-2.23138h9.097c1.23235,0,2.23135,.99902,2.23135,2.23138s-.99901,2.23132-2.23135,2.23132h0Z"
        fill="#f2f2f2"
      /> */}
      {/* <path
        d="M76.26983,454.05333H36.27729c-1.23235,0-2.23135-.99902-2.23135-2.23138s.99901-2.23132,2.23135-2.23132h39.99252c1.23235,0,2.23135,.99902,2.23135,2.23132s-.99901,2.23138-2.23135,2.23138h.00002Z"
        fill="#f2f2f2"
      /> */}
      <path
        d="M305.89143,136.4084c-3.39874,.00388-6.15295,2.75818-6.15668,6.15692v107.8909c.00372,3.39874,2.75793,6.15305,6.15668,6.15692h152.60834c3.39874-.00388,6.15295-2.75818,6.15668-6.15692v-107.8909c-.00372-3.39874-2.75793-6.15305-6.15668-6.15692h-152.60834Z"
        fill={terClr || "#e6e6e6"}
      />
      <path
        d="M403.61561,251.3625h-93.41199c-3.65799-.00412-6.62234-2.96857-6.62634-6.62656v-97.85611c.004-3.65799,2.96835-6.62244,6.62634-6.62656h143.9848c3.65808,.00409,6.6225,2.96851,6.62659,6.62656v47.28311c-.03571,31.57559-25.62384,57.16379-57.1994,57.19955Z"
        fill="#fff"
      />
      <path
        d="M367.02442,167.33415h-22.19794c-1.17072,0-2.11981-.94907-2.11981-2.11981s.9491-2.11981,2.11981-2.11981h22.19788c1.17072,0,2.11981,.94907,2.11981,2.11981s-.9491,2.11981-2.11981,2.11981h.00006Z"
        fill="#EA3148"
      />
      <path
        d="M416.78474,176.00423h-71.95819c-1.17072,0-2.11981-.94907-2.11981-2.11981s.9491-2.11981,2.11981-2.11981h71.95819c1.17072,0,2.11981,.94907,2.11981,2.11981s-.9491,2.11981-2.11981,2.11981Z"
        fill={terClr || "#e6e6e6"}
      />
      <path
        d="M416.78474,185.95628h-71.95819c-1.17072,0-2.11981-.94907-2.11981-2.11981s.9491-2.11981,2.11981-2.11981h71.95819c1.17072,0,2.11981,.94907,2.11981,2.11981s-.9491,2.11981-2.11981,2.11981Z"
        fill={terClr || "#e6e6e6"}
      />
      <path
        d="M416.78474,195.90834h-71.95819c-1.17072,0-2.11981-.94907-2.11981-2.11981s.9491-2.11981,2.11981-2.11981h71.95819c1.17072,0,2.11981,.94907,2.11981,2.11981s-.9491,2.11981-2.11981,2.11981Z"
        fill={terClr || "#e6e6e6"}
      />
      <path
        d="M416.78474,205.8604h-71.95819c-1.17072,0-2.11981-.94907-2.11981-2.11981s.9491-2.11981,2.11981-2.11981h71.95819c1.17072,0,2.11981,.94907,2.11981,2.11981s-.9491,2.11981-2.11981,2.11981Z"
        fill={terClr || "#e6e6e6"}
      />
      <path
        d="M474.48042,211.73543h-30.12396c-5.99072,0-10.84717,4.85645-10.84717,10.84717h0c0,5.99072-4.85645,10.8472-10.84717,10.8472h-100.42966c-5.99072,0-10.8472-4.85645-10.8472-10.84717v-.00003l-1.98575-10.99612,.12909,.14893-.12909-.14893-18.20441-23.20853c-1.20862-1.56583-3.08078-2.47525-5.05872-2.45737h-34.14011c-3.54883,.00424-6.42465,2.88007-6.42889,6.42889l-.70493,19.237h-12.90677c-11.4006,0-20.64261,9.242-20.64261,20.64261v134.44495c0,10.1171,8.20151,18.31863,18.3186,18.31863h244.84872c10.11108-.01245,18.30389-8.20752,18.3136-18.3186V230.04906c0-10.11432-8.19928-18.31363-18.3136-18.31363h0Z"
        fill={terClr || "#e6e6e6"}
      />
      <path
        d="M309.40946,211.09004h-63.84207v-19.237c.00424-3.54883,2.88007-6.42465,6.42889-6.42889h34.14014c1.97794-.01788,3.8501,.89154,5.05872,2.45737l18.21432,23.20853Z"
        fill="#ccc"
      />
      <path
        d="M342.09809,370.19875h-106.59964c-7.02594-.00793-12.7196-5.7016-12.72754-12.72754v-29.1532c.00793-7.02594,5.7016-12.7196,12.72754-12.72754h127.94455c7.02594,.00793,12.7196,5.7016,12.72754,12.72754v7.80826c-.0213,18.80887-15.26355,34.05115-34.07245,34.07248Z"
        fill="#fff"
      />
      <path
        d="M266.18424,337.58104h-26.31128c-3.56427,0-6.4537-2.88943-6.4537-6.4537s2.88943-6.4537,6.4537-6.4537h26.31128c3.56427-.00461,6.45743,2.8811,6.46204,6.44537,.00458,3.5643-2.8811,6.45743-6.4454,6.46204h-.01663Z"
        fill="#EA3148"
      />
      <path
        d="M355.54328,357.93504h-115.67032c-3.56427,0-6.4537-2.88943-6.4537-6.4537s2.88943-6.4537,6.4537-6.4537h115.67032c3.56427-.00461,6.45746,2.8811,6.46204,6.44537,.00458,3.5643-2.8811,6.45743-6.44537,6.46204h-.01666Z"
        fill="#EA3148"
      />
      {/* <path
        d="M570.06141,42.76831H1.76838c-.99776-.02133-1.78929-.84746-1.76796-1.84521,.02069-.96771,.80025-1.74727,1.76796-1.76796H570.06141c.99774,.02133,1.78931,.84746,1.76794,1.84521-.02069,.96771-.80023,1.74727-1.76794,1.76796Z"
        fill="#cacaca"
      /> */}
      {/* <polygon
        points="790.50419 596.91809 781.12321 596.91809 776.6602 560.73212 790.5062 560.73309 790.50419 596.91809"
        fill="#9e616a"
      /> */}
      {/* <path
        d="M790.83467,606.705l-28.84882-.0011v-.36487c.00049-6.20123,5.02753-11.22815,11.22876-11.22852h.00067l5.26959-3.9978,9.83191,3.99841,2.51831,.00006-.00043,11.59381Z"
        fill="#2f2e41"
      /> */}
      {/* <polygon
        points="761.53422 564.39911 752.55222 567.10614 737.83421 533.74915 751.09123 529.75311 761.53422 564.39911"
        fill="#9e616a"
      />
      <path
        d="M764.67561,573.67375l-27.62115,8.32611-.10529-.3493c-1.78955-5.93744,1.57251-12.20148,7.5097-13.99176l.00067-.00018,3.89136-5.34869,10.56757,.99023,2.41113-.72681,3.34601,11.1004Z"
        fill="#2f2e41"
      />
      <path
        d="M748.6518,367.65184s-10.0365,15.3208-13.58716,25.74402c-8.70142,25.54413-12.86292,19.41571-22.88361,81.52277,13.59625,45.87488,27.06036,76.1178,27.06036,76.1178,0,0,20.2345-5.69098,20.2345-9.48492s-12.90991-50.05963-14.80688-55.43445-5.05865-7.27179-2.21313-11.69806,26.21899-43.12219,26.21899-43.12219c0,0-.63232,65.95587,0,67.22058s3.67297,77.21692,3.67297,77.21692c0,0,20.80975,10.65155,22.07098-.69995,.31616-2.84546,.71509-78.11212,.71509-79.37683s19.26373-119.10669,10.09491-123.84918-56.57703-4.15649-56.57703-4.15649Z"
        fill="#2f2e41"
      /> */}
      {/* <path
        d="M706.59864,378.57072c.53528-4.82611,4.88147-8.3045,9.70758-7.76926,.51465,.05707,1.02325,.15952,1.51984,.30609l18.17957-25.41058,5.82275,15.15601-18.15625,21.80679c-1.68872,4.58069-6.77106,6.92511-11.35175,5.23639-3.83282-1.41302-6.19824-5.26825-5.72168-9.32538l-.00006-.00006Z"
        fill="#9e616a"
      />
      <path
        d="M731.67054,374.63237l-12.08752-9.16602,25.37549-39.07471,20.383-78.52182,16.13751,15.0264c2.79358,.86105,5.10419,2.84351,6.37988,5.47375,1.29797,2.64984,4.05536-1.78732,3.02417,.97745l-22.69989,61.29208-.03247,.04266-36.48016,43.9502Z"
        fill="#3f3d56"
      /> */}
      {/* <path
        d="M774.39588,244.65254l-17.19214,6.68057-7.06586,9.76059-17.12927,87.85385,.6817,2.96539-1.70563,2.28644-.66986,3.4357,1.6604,2.85919-2.91626,3.582s.77094,2.854,3.63141,27.16785c1.76501,15.00238,30.31909,5.76178,30.31909,5.76178l12.96344-133.23865-2.57703-19.11472Z"
        fill="#3f3d56"
      />
      <polygon
        points="811.0922 256.00412 796.26017 242.73611 774.32719 244.05812 761.70017 259.41312 737.21018 386.24512 788.69822 390.53513 811.0922 256.00412"
        fill={terClr || "#e6e6e6"}
      />
      <path
        d="M798.09138,244.87038l13.49036,10.35699,5.17456,7.55017c9.0686,5.18433,13.26129,16.01584,10.04663,25.9548l-16.38538,57.76001-.38281,1.1803c-.39014,1.14386,1.47705,10.2009,.54797,19.95621-.242,2.54135,2.70654,5.37424,2.48499,7.88284-.17328,1.9624-3.52295,3.63153-3.67084,5.43475-.13342,1.62653,1.62946,3.17432,1.52551,4.57678-.26556,2.73315-1.01068,5.39813-2.20117,7.87268-13.95874,5.77399-40.9137,1.58545-40.9137,1.58545l24.4248-131.62073,5.85907-18.49026Z"
        fill="#3f3d56"
      /> */}
      {/* <circle cx="783.01103" cy="213.35089" r="24.28004" fill="#9e616a" />
      <path
        d="M814.4922,214.38899c-1.40881,7.98065-7.10822,15.87549-15.06744,17.39154l-.15021-.20026c.44312-3.45941,.63672-6.94626,.57922-10.43347-.03027-2.23529-1.86694-4.02283-4.10223-3.99252-1.66724,.02258-3.15009,1.06531-3.73535,2.62665,.14301-3.03922-1.14423-6.14279-1.97369-9.07477-.82959-2.9248-1.39447-5.69943-4.28351-6.65771-2.40991-.79376-5.03442-.2431-7.50867,.32181-3.41827,.77234-5.15601,.59357-5.46344-.27173-1.26428,.46289-2.52936,.92499-3.79529,1.38632-.98236,.35718-2.08246,.01907-2.69458-.82822l-.0105-.01459c-2.20966,.57208-4.41937,1.1394-6.62909,1.702-.41925-1.41522-.76062-2.85239-1.02258-4.30499,1.89282-1.54541,3.24719-3.65088,3.86871-6.01407-1.32458,.76212-2.76025,1.31226-4.25494,1.63043-.10425-3.38773,1.36993-6.63196,3.99036-8.78156,5.22748-4.19769,10.46924-6.77213,17.16272-6.436,8.09509,.40762,10.51935,9.11768,17.87781,5.72089,9.29645-4.29074,18.62146,18.24957,17.21271,26.23026Z"
        fill="#2f2e41"
      />
      <path
        d="M678.37873,229.19133c-2.12604-1.70987-5.23492-1.3761-6.94958,.74609l-54.35626,67.42804c-1.70996,2.12598-1.37628,5.23492,.74585,6.94965l95.37488,76.88519c2.12604,1.70984,5.23492,1.3761,6.94958-.74609l54.3562-67.42804c1.70996-2.12598,1.37628-5.23492-.74585-6.94965l-95.37482-76.88519Z"
        fill="#ccc"
      />
      <path
        d="M681.53822,350.26784l-58.37927-47.06168c-2.28406-1.84549-2.64319-5.19162-.80273-7.47977l49.3006-61.15668c1.8454-2.28412,5.19153-2.64334,7.47974-.80301l89.98547,72.54059c2.28406,1.84552,2.64325,5.19168,.80286,7.47989l-23.82159,29.55029c-15.9303,19.71564-44.81342,22.81592-64.56506,6.93036Z"
        fill="#fff"
      />
      <path
        d="M701.00404,279.31807l-13.87292-11.18347c-.73401-.58691-.85321-1.65775-.2663-2.39175,.58691-.73398,1.65771-.85321,2.39172-.2663l.0105,.00845,13.87292,11.18347c.72931,.59271,.84009,1.66446,.24738,2.39377-.58942,.72525-1.65338,.83948-2.3833,.25583Z"
        fill="#EA3148"
      />
      <path
        d="M727.73451,309.80617l-44.97137-36.25302c-.73401-.58691-.85321-1.65775-.2663-2.39175,.58691-.73398,1.65771-.85321,2.39172-.2663l.0105,.00845,44.97137,36.25308c.72931,.59271,.84009,1.66446,.24738,2.39377-.58942,.72525-1.65338,.83948-2.3833,.25583v-.00006Z"
        fill={terClr || "#e6e6e6"}
      />
      <path
        d="M722.72059,316.02589l-44.97137-36.25308c-.73401-.58691-.85321-1.65775-.2663-2.39175,.58691-.73398,1.65771-.85321,2.39172-.2663l.0105,.00845,44.97137,36.25308c.72931,.59271,.84009,1.66446,.24738,2.39377-.58942,.72525-1.65338,.83948-2.3833,.25583Z"
        fill="#e6e6e6"
      />
      <path
        d="M717.70661,322.24556l-44.97137-36.25308c-.73401-.58691-.85321-1.65775-.2663-2.39175,.58691-.73398,1.65771-.85321,2.39172-.2663l.0105,.00845,44.9715,36.25308c.72931,.59271,.84009,1.66446,.24738,2.39377-.58942,.72525-1.65338,.83948-2.3833,.25583h-.00012Z"
        fill="#e6e6e6"
      />
      <path
        d="M712.6927,328.46526l-44.97137-36.25308c-.73401-.58691-.85321-1.65775-.2663-2.39175,.58691-.73398,1.65771-.85321,2.39172-.2663l.0105,.00845,44.97137,36.25308c.72931,.59271,.84009,1.66446,.24738,2.39377-.58942,.72525-1.65338,.83948-2.3833,.25583Z"
        fill="#e6e6e6"
      /> */}
      {/* <path
        d="M702.89961,327.2661c3.11908-3.72156,8.66443-4.20996,12.38599-1.09091,.39679,.33255,.76343,.69949,1.09564,1.09656l29.2113-11.08575-3.54761,15.84375-27.19568,8.09824c-3.94336,2.87823-9.47333,2.0148-12.35162-1.92856-2.40826-3.29953-2.24213-7.81949,.40198-10.93326v-.00006Z"
        fill="#9e616a"
      />
      <path
        d="M725.95515,338.59032l-4.98633-14.3269,42.7713-18.47601,53.14771-39.48581,6.70898,8.28183c3.69788,4.56534,2.99469,11.26401-1.57062,14.96191-.14893,.12064-.30109,.23721-.4563,.34961l-40.8468,32.29388-.05066,.01755-12.67847,3.7963-2.83868,.1662-3.02966,1.591-2.69122-.87585-3.72577,2.79727-4.27814,1.28101-25.47534,7.62802Z"
        fill="#3f3d56"
      /> */}
      {/* <path
        d="M482.85755,401.08263c-14.44989,0-26.16382-11.71393-26.16382-26.16382s11.71393-26.16382,26.16382-26.16382,26.16382,11.71393,26.16382,26.16382h0c0,14.44989-11.71393,26.16382-26.16382,26.16382Z"
        fill="#EA3148"
      />
      <path
        d="M493.1811,372.33794h-7.74261v-7.74271c0-1.42542-1.15552-2.58093-2.58093-2.58093s-2.58093,1.15552-2.58093,2.58093v7.74271h-7.74274c-1.42542,0-2.58087,1.15552-2.58087,2.58093s1.15546,2.58087,2.58087,2.58087h7.74274v7.74274c0,1.42542,1.15552,2.58093,2.58093,2.58093s2.58093-1.15552,2.58093-2.58093v-7.74274h7.74261c1.42542,0,2.58087-1.15552,2.58087-2.58087s-1.15546-2.58093-2.58087-2.58093Z"
        fill="#fff"
      /> */}
      {/* <path
        d="M121.40498,183.42915c-6.9093,0-12.51041-5.60107-12.51041-12.51038s5.60107-12.51041,12.51038-12.51041,12.51041,5.60107,12.51041,12.51038v.00003c0,6.90927-5.6011,12.51038-12.51038,12.51038Z"
        fill="#e6e6e6"
      /> */}
      {/* <path
        d="M126.34126,169.68474h-9.87262c-.68155,.00027-1.23383,.55304-1.23355,1.23459,.00027,.68115,.5524,1.23328,1.23355,1.23355h9.87259c.68155,.00027,1.23431-.552,1.23459-1.23355s-.552-1.23431-1.23355-1.23459h-.00101Z"
        fill="#fff"
      /> */}
      {/* <path
        d="M121.40498,327.42915c-6.9093,0-12.51041-5.60107-12.51041-12.51038s5.60107-12.51041,12.51038-12.51041,12.51041,5.60107,12.51041,12.51038v.00003c0,6.90927-5.6011,12.51038-12.51038,12.51038Z"
        fill="#e6e6e6"
      />
      <path
        d="M126.34126,313.68474h-9.87262c-.68155,.00027-1.23383,.55304-1.23355,1.23459,.00027,.68115,.5524,1.23328,1.23355,1.23355h9.87259c.68155,.00027,1.23431-.552,1.23459-1.23355s-.552-1.23431-1.23355-1.23459h-.00101Z"
        fill="#fff"
      /> */}
      {/* <path
        d="M121.40498,471.42918c-6.9093,0-12.51038-5.60107-12.51038-12.51038s5.60107-12.51038,12.51038-12.51038,12.51038,5.60107,12.51038,12.51038h0c0,6.9093-5.6011,12.51038-12.51038,12.51038Z"
        fill="#e6e6e6"
      />
      <path
        d="M126.34126,457.68474h-9.87262c-.68155,.00031-1.23383,.55304-1.23355,1.23456,.00027,.68115,.5524,1.23328,1.23355,1.23358h9.87259c.68155,.00031,1.23431-.552,1.23459-1.23358,.00027-.68152-.552-1.23425-1.23355-1.23456h-.00101Z"
        fill="#fff"
      /> */}
      {/* <g>
        <ellipse
          cx="76.87462"
          cy="8.64507"
          rx="8.45661"
          ry="8.64507"
          fill="#3f3d56"
        />
        <ellipse
          cx="106.08838"
          cy="8.64507"
          rx="8.45661"
          ry="8.64507"
          fill="#3f3d56"
        />
        <ellipse
          cx="135.30213"
          cy="8.64507"
          rx="8.45661"
          ry="8.64507"
          fill="#3f3d56"
        />
        <path
          d="M168.61355,15.47786c-.25977,0-.51904-.10059-.71484-.30078l-5.70605-5.83301c-.38037-.38867-.38037-1.00977,0-1.39844l5.70605-5.83252c.38721-.39453,1.021-.40088,1.41406-.01562,.39502,.38623,.40186,1.01953,.01562,1.41406l-5.02197,5.1333,5.02197,5.13379c.38623,.39453,.37939,1.02783-.01562,1.41406-.19434,.19043-.44678,.28516-.69922,.28516Z"
          fill="#3f3d56"
        />
        <path
          d="M178.20193,15.47786c-.25244,0-.50488-.09473-.69922-.28516-.39502-.38623-.40186-1.01904-.01562-1.41406l5.02148-5.13379-5.02148-5.1333c-.38623-.39453-.37939-1.02783,.01562-1.41406,.39404-.38672,1.02783-.37939,1.41406,.01562l5.70557,5.83252c.38037,.38867,.38037,1.00977,0,1.39844l-5.70557,5.83301c-.1958,.2002-.45508,.30078-.71484,.30078Z"
          fill="#3f3d56"
        />
        <path
          d="M476.71175,1.0009h-10.60999c-1.21002,0-2.19,.97998-2.19,2.19V13.8109c0,1.21002,.97998,2.19,2.19,2.19h10.60999c1.21002,0,2.20001-.97998,2.20001-2.19V3.1909c0-1.21002-.98999-2.19-2.20001-2.19Z"
          fill="#3f3d56"
        />
        <path
          d="M450.71175,1.0009h-10.60999c-1.21002,0-2.19,.97998-2.19,2.19V13.8109c0,1.21002,.97998,2.19,2.19,2.19h10.60999c1.21002,0,2.20001-.97998,2.20001-2.19V3.1909c0-1.21002-.98999-2.19-2.20001-2.19Z"
          fill="#3f3d56"
        />
        <path
          d="M501.21175,1.5009h-10.60999c-1.21002,0-2.19,.97998-2.19,2.19V14.3109c0,1.21002,.97998,2.19,2.19,2.19h10.60999c1.21002,0,2.20001-.97998,2.20001-2.19V3.6909c0-1.21002-.98999-2.19-2.20001-2.19Z"
          fill="#3f3d56"
        />
        <path
          d="M341.29006,5.54088h-84.81c-1.48004,0-2.67004,1.20001-2.67004,2.67004s1.19,2.66998,2.67004,2.66998h84.81c1.46997,0,2.66998-1.20001,2.66998-2.66998s-1.20001-2.67004-2.66998-2.67004Z"
          fill="#3f3d56"
        />
      </g> */}
    </svg>
  );
}

export default EmptyFolder;
