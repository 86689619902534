export function convertLanguagesToArray(languages) {
  if (!languages || languages.length === 0) {
    return [];
  }

  return languages.map((item) => ({
    id: item._id,
    value: item.language,
  }));
}

export function convertProfessionsToArray(professions) {
  if (!professions || professions.length === 0) {
    return [];
  }
  return professions.map((item) => ({
    id: item._id,
    value: item.professional,
  }));
}

export function convertEthnicitiesToArray(ethnicities) {
  if (!ethnicities || ethnicities.length === 0) {
    return [];
  }
  return ethnicities.map((item) => ({
    id: item._id,
    value: item.ethnic,
  }));
}

export function mainCategoriesArrayConvert(mainCategories) {
  if (!mainCategories || mainCategories.length === 0) {
    return [];
  }

  return mainCategories.map((item) => ({
    id: item._id,
    value: item.name,
  }));
}

export function subCategoriesArrayConvert(subCategories) {
  if (!subCategories || subCategories.length === 0) {
    return [];
  }

  return subCategories.map((item) => ({
    id: item._id,
    value: item.name,
  }));
}

export function skillsArrayConvert(skills) {
  if (!skills || skills.length === 0) {
    return [];
  }

  return skills.map((item) => ({
    id: item._id,
    value: item.name,
  }));
}

export function countryArrayConvert(countries) {
  if (!countries || countries.length === 0) {
    return [];
  }

  return countries.map((item) => ({
    id: item._id,
    value: item.country,
    countryCode: item.countryCode,
    countryShortName: item.countryShortName,
    countryValidationLength: item.countryValidationLength,
  }));
}

export function stateArrayConvert(states) {
  if (!states || states.length === 0) {
    return [];
  }

  return states.map((item) => ({
    id: item._id,
    value: item.state,
    country: item.country,
  }));
}
