import React from "react";

function InstaIcon({ fill, width, height }) {
  return (
    <div>
      {" "}
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width={width || "24px"}
        height={height || "24px"}
        viewBox="0 0 512.000000 512.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
          fill={fill || "var(--dark-primary)"}
          stroke="none"
        >
          <path
            d="M2315 5109 c-800 -83 -1501 -518 -1927 -1196 -604 -960 -491 -2229
272 -3065 423 -463 951 -740 1585 -830 118 -17 511 -16 635 0 579 80 1097 337
1491 739 130 132 157 163 243 277 256 341 423 757 488 1211 17 118 17 512 0
630 -43 303 -122 561 -247 814 -134 268 -270 459 -483 674 -395 400 -889 649
-1457 733 -123 18 -478 26 -600 13z m848 -1089 c288 -19 456 -82 610 -228 156
-149 228 -332 247 -629 13 -195 13 -1006 0 -1204 -19 -300 -93 -485 -253 -636
-149 -141 -321 -205 -604 -223 -192 -12 -1014 -12 -1206 0 -288 19 -456 82
-610 228 -156 149 -228 332 -247 629 -13 194 -12 1028 0 1212 20 293 92 475
247 623 148 140 325 210 582 227 169 12 1061 12 1234 1z"
          />
          <path
            d="M2195 3759 c-300 -6 -349 -9 -416 -27 -213 -58 -334 -178 -391 -391
-18 -67 -21 -117 -27 -429 -3 -193 -3 -511 0 -705 6 -311 9 -361 27 -428 57
-213 178 -334 391 -391 67 -18 117 -21 429 -27 193 -3 511 -3 705 0 313 6 361
9 430 28 207 55 333 180 389 388 18 70 21 116 27 431 3 193 3 511 0 705 -6
312 -9 361 -27 428 -57 213 -179 335 -391 391 -78 20 -98 21 -661 31 -80 1
-298 -1 -485 -4z m1212 -250 c38 -14 83 -57 99 -96 49 -113 -38 -243 -161
-243 -94 0 -175 80 -175 174 0 121 124 208 237 165z m-604 -236 c121 -42 188
-85 283 -178 92 -91 149 -181 191 -300 26 -74 28 -91 28 -235 0 -144 -2 -161
-28 -235 -40 -115 -100 -209 -186 -296 -87 -86 -181 -146 -296 -186 -74 -26
-91 -28 -235 -28 -144 0 -161 2 -235 28 -119 42 -209 99 -300 191 -93 95 -135
162 -179 283 -30 85 -31 94 -31 243 1 141 3 162 28 235 52 155 163 301 297
390 146 97 251 126 440 122 120 -3 144 -7 223 -34z"
          />
          <path
            d="M2442 3034 c-263 -70 -423 -334 -357 -588 48 -183 178 -313 362 -361
344 -90 678 244 588 588 -70 267 -337 430 -593 361z"
          />
        </g>
      </svg>
    </div>
  );
}

export default InstaIcon;
