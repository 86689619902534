import { configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { composeWithDevTools } from "redux-devtools-extension";
import authReducer from "./Slices/authSlice";
import contentReducer from "./Slices/contentSlice";
import utilsReducer from "./Slices/utilsSlice";
import postReducer from "./Slices/postSlice";

const persistConfig = {
  key: "root",
  storage,
  version: 1,
};

const persistedAuthReducer = persistReducer(persistConfig, authReducer);
const persistedContentReducer = persistReducer(persistConfig, contentReducer);
const persistedUtilsReducer = persistReducer(persistConfig, utilsReducer);
const persistedPostsReducer = persistReducer(persistConfig, postReducer);

const store = configureStore(
  {
    reducer: {
      auth: persistedAuthReducer,
      content: persistedContentReducer,
      utils: persistedUtilsReducer,
      posts: persistedPostsReducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
  },
  composeWithDevTools()
);

export const persistor = persistStore(store);

export default store;
