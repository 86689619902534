import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import {
  convertLanguagesToArray,
  convertProfessionsToArray,
} from "../../Hooks/useArrayConvertor";
import { useSelector } from "react-redux";
import {
  BioLabelWrapper,
  DetailLabel,
  DetailSectionContainer,
  UserDetailHeading,
} from "../Profile/Overview/OverviewStyle";
import { DropDown, RadioInput, TextArea } from "../../Components";
import { QuillIcon } from "../../Assets/SVG";
import { Gender } from "../../Utils/Others/Data";
import { EditFormWrapper, GenderOptionWrap } from "./EditProfileStyles";
import { FormInput } from "../../Components";

const BioEdit = ({
  values,
  handleInputChange,
  handleGenderChange,
  handleDropdownChange,
}) => {
  const languages = useSelector((state) => state.content.languages);
  const professions = useSelector((state) => state.content.professions);
  const [langValues, setLangValues] = useState([]);
  const [professionValues, setProfessionValues] = useState([]);
  useEffect(() => {
    const convertedValues = convertLanguagesToArray(languages);
    setLangValues(convertedValues);
  }, [languages]);

  useEffect(() => {
    const convertedValues = convertProfessionsToArray(professions);
    setProfessionValues(convertedValues);
  }, [professions]);

  return (
    <DetailSectionContainer>
      <UserDetailHeading>
        <span>
          <QuillIcon width={"21px"} /> Bio
        </span>
      </UserDetailHeading>

      <EditFormWrapper>
        <FormInput
          isHorz={true}
          name="firstName"
          label="First Name"
          type="text"
          placeholder="First Name"
          value={values.firstName}
          onChange={handleInputChange}
        />

        <FormInput
          isHorz={true}
          name="lastName"
          label="Last Name"
          type="text"
          placeholder="Last Name"
          value={values.lastName}
          onChange={handleInputChange}
        />

        <FormInput
          isHorz={true}
          name="location"
          label="Location"
          type="text"
          placeholder="Location"
          value={values.location}
          onChange={handleInputChange}
        />
        <DropDown
          name="knownLanguage"
          label="Language"
          idName="knownLanguageId"
          defaultOption="Select Language"
          options={langValues}
          value={values.knownLanguage}
          onChange={handleDropdownChange}
          isHorz={true}
          isSingle={true}
        />

        {/* {user?.personalInfo?.dob && (
        <BioLabelWrapper>
          <DetailLabel>Age</DetailLabel>
          <SingleDetailElem>Born on </SingleDetailElem>
        </BioLabelWrapper>
      )} */}

        <DropDown
          name="profession"
          label="Profession"
          idName="professionId"
          defaultOption="Select your profession"
          options={professionValues}
          value={values.profession}
          onChange={handleDropdownChange}
          isHorz={true}
          isSingle={true}
        />

        <BioLabelWrapper style={{ alignItems: "flex-start" }}>
          <DetailLabel>Gender</DetailLabel>
          <GenderOptionWrap>
            {Gender.map((gender) => (
              <RadioInput
                key={gender.id}
                label={gender.label}
                name={gender.name}
                checked={values.gender === gender.val}
                onChange={handleGenderChange}
                value={gender.val}
              />
            ))}
          </GenderOptionWrap>
        </BioLabelWrapper>

        <TextArea
          name="quotes"
          label="Quotes"
          placeholder="What's on your mind...?"
          maxLength={100}
          value={values.quotes}
          onChange={handleInputChange}
          height={"4.5rem"}
          invert={true}
        />
      </EditFormWrapper>
    </DetailSectionContainer>
  );
};

export default BioEdit;
