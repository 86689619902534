import React, { useEffect, useState } from "react";
import { ProfileDetails, ProfileActions, ProfileTabs } from "./ProfileHeader";
import { NoUserFoundContainer, ProfileWrap } from "./ProfileStyle";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentUserPersonalDetails,
  getUserPersonalDetails,
} from "../../Functions/getFetchFunctions";
import { useParams } from "react-router-dom";
import { Footer } from "../../Components";
import { NoUserFoundSVG } from "../../Assets/SVGImages";
import { useTheme } from "styled-components";
import { MdClose } from "react-icons/md";
import { Helmet } from "react-helmet";
import { DarkLogo } from "../../Assets/Images";

const Profile = () => {
  const { userId } = useParams();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const theme = useTheme();
  const currentUserId = localStorage.getItem("currentUserId"); //tediuucet

  const [isLoading, setIsLoading] = useState(true);
  const [isNoUser, setIsNoUser] = useState(false);

  useEffect(() => {
    console.log("getUserPersonalDetails", userId, currentUserId);

    if (userId === currentUserId) {
      getCurrentUserPersonalDetails(
        currentUserId,
        dispatch,
        setIsNoUser,
        setIsLoading
      )
        .then(() => {
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching current user", userId);
        });
    } else {
      getUserPersonalDetails(userId, currentUserId, dispatch, setIsNoUser)
        .then(() => {
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching user", userId);
        });
    }
  }, [userId, dispatch, currentUserId, setIsLoading, setIsNoUser]);

  return (
    <>
      <Helmet>
        <title>
          {user
            ? `${user?.personalInfo?.firstName} ${user?.personalInfo?.lastName} | Profile`
            : "Profile"}
        </title>
        <meta
          property="og:image"
          content={user ? `${user?.personalInfo?.profileImage}` : `${DarkLogo}`}
        />
        <meta name="description" content={`${user?.personalInfo?.shortBio}`} />
        <link
          rel="canonical"
          content={`${process.env.REACT_APP_API_URL}/profile/${user?.personalInfo?.firstName}_${user?.personalInfo?.lastName}/${userId}`}
        />
        {/* <meta property="og:type" content="website" /> */}
      </Helmet>
      <ProfileWrap isTalent={user?.isTalent}>
        {isLoading ? (
          <p style={{ color: "red" }}>Loading...</p>
        ) : (
          <>
            {isNoUser ? (
              <NoUserFoundContainer>
                <h1>OOPS!</h1>
                <NoUserFoundSVG
                  width={"20rem"}
                  height={"8rem"}
                  pmClr={theme.primary}
                  subClr={theme.mediumMain}
                />
                <p>
                  No user found
                  <span>
                    <MdClose />
                  </span>
                </p>
              </NoUserFoundContainer>
            ) : (
              <>
                <ProfileDetails user={user} />
                <ProfileActions />
                <ProfileTabs />
                <Footer />
              </>
            )}
          </>
        )}
      </ProfileWrap>
    </>
  );
};

export default Profile;
