import React from "react";
import {
  Parah,
  PointsIcon,
  PointsWrapper,
  SubTitle,
  ParahScrollLink,
} from "../ContentsStyles";

const PP_3 = () => {
  return (
    <>
      <SubTitle>Service Providers</SubTitle>
      <Parah>
        We engage service providers that help us provide, support, and develop
        the Platform and understand how it is used. They provide services such
        as cloud hosting, content delivery, customer and technical support,
        content moderation, marketing, analytics, and online payment. We share{" "}
        <ParahScrollLink
          to="whatInformationWeCollect"
          smooth={true}
          duration={500}
        >
          Information You Provide,
        </ParahScrollLink>{" "}
        <ParahScrollLink
          to="whatInformationWeCollect"
          smooth={true}
          duration={500}
        >
          Automatically Collected Information,
        </ParahScrollLink>{" "}
        and{" "}
        <ParahScrollLink
          to="whatInformationWeCollect"
          smooth={true}
          duration={500}
        >
          Information From Other Sources
        </ParahScrollLink>{" "}
        with these service providers as necessary to enable them to provide
        their services.
      </Parah>
      <SubTitle>Partners</SubTitle>
      <PointsWrapper>
        <PointsIcon /> Third Party Platforms and Partners
      </PointsWrapper>
      <Parah>
        We share limited information which may include{" "}
        <ParahScrollLink
          to="whatInformationWeCollect"
          smooth={true}
          duration={500}
        >
          Information You Provide,
        </ParahScrollLink>{" "}
        <ParahScrollLink
          to="whatInformationWeCollect"
          smooth={true}
          duration={500}
        >
          Technical Information,
        </ParahScrollLink>{" "}
        and{" "}
        <ParahScrollLink
          to="whatInformationWeCollect"
          smooth={true}
          duration={500}
        >
          Usage Information
        </ParahScrollLink>{" "}
        with third party platforms and partners whose platform or services are
        integrated with the Platform. We do this to give you a seamless
        experience, allow your content to be shared on other platforms, and/or
        enable third party platforms and partners to better authenticate users.
        Some examples include if you:
      </Parah>
      <PointsWrapper>
        <PointsIcon />
        log-in to a third-party platform using your account, we will share your
        basic account information and any other information you provide,
      </PointsWrapper>
      <PointsWrapper>
        <PointsIcon />
        <div>
          sign-up or log-in to the Platform using your account details from a
          third party platform (such as Facebook or Google), we will share
          certain{" "}
          <ParahScrollLink
            to="whatInformationWeCollect"
            smooth={true}
            duration={500}
          >
            Technical Information,
          </ParahScrollLink>{" "}
          to facilitate this,
        </div>
      </PointsWrapper>
      <PointsWrapper>
        <PointsIcon />
        <div>
          share{" "}
          <ParahScrollLink
            to="whatInformationWeCollect"
            smooth={true}
            duration={500}
          >
            User Content,
          </ParahScrollLink>{" "}
          you publish on the Platform on other social media platforms, we will
          share your{" "}
          <ParahScrollLink
            to="whatInformationWeCollect"
            smooth={true}
            duration={500}
          >
            User Content,
          </ParahScrollLink>{" "}
          and related information.
        </div>
      </PointsWrapper>
      <PointsWrapper>
        <PointsIcon /> Advertisers
      </PointsWrapper>
      <Parah>
        We provide advertisers with aggregate information about the performance
        of their ads and other content on the Platform in order to help them
        measure their effectiveness. We create this aggregate information using{" "}
        <ParahScrollLink
          to="whatInformationWeCollect"
          smooth={true}
          duration={500}
        >
          Information You Provide,
        </ParahScrollLink>{" "}
        <ParahScrollLink
          to="whatInformationWeCollect"
          smooth={true}
          duration={500}
        >
          Automatically Collected Information,
        </ParahScrollLink>{" "}
        and{" "}
        <ParahScrollLink
          to="whatInformationWeCollect"
          smooth={true}
          duration={500}
        >
          Information From Other Sources.
        </ParahScrollLink>{" "}
        We share your information directly with advertisers where you give us
        your permission.
      </Parah>
      <PointsWrapper>
        <PointsIcon />
        Measurement and Data Partners
      </PointsWrapper>
      <Parah>
        We also share{" "}
        <ParahScrollLink
          to="whatInformationWeCollect"
          smooth={true}
          duration={500}
        >
          Technical Information,
        </ParahScrollLink>{" "}
        and{" "}
        <ParahScrollLink
          to="whatInformationWeCollect"
          smooth={true}
          duration={500}
        >
          Usage Information
        </ParahScrollLink>{" "}
        with third party measurement providers who help us measure advertising
        served on the Platform and help our advertisers determine how effective
        their ads have been.
      </Parah>
      <SubTitle>Our Corporate Group</SubTitle>
      <Parah>
        As a global company, the Platform is supported by certain entities
        within our corporate group (“Corporate Group”). These entities process
        <ParahScrollLink
          to="whatInformationWeCollect"
          smooth={true}
          duration={500}
        >
          Information You Provide,
        </ParahScrollLink>{" "}
        <ParahScrollLink
          to="whatInformationWeCollect"
          smooth={true}
          duration={500}
        >
          Automatically Collected Information,
        </ParahScrollLink>{" "}
        and{" "}
        <ParahScrollLink
          to="whatInformationWeCollect"
          smooth={true}
          duration={500}
        >
          Information From Other Sources
        </ParahScrollLink>{" "}
        for us as necessary to provide important functions, such as storage,
        content delivery, security, research and development, analytics, online
        payments, customer and technical support, and content moderation. See{" "}
        <ParahScrollLink
          to="ourGlobalOperationsandDataTransfers"
          smooth={true}
          duration={500}
        >
          Our Global Operations and Data Transfers
        </ParahScrollLink>{" "}
        for additional information.
      </Parah>
      <SubTitle>Others</SubTitle>
      <Parah>
        We share your information in other limited scenarios as follows:
      </Parah>
      <PointsWrapper>
        <PointsIcon />
        Other Users and the Public
      </PointsWrapper>
      <Parah>
        Based on your privacy settings, your{" "}
        <ParahScrollLink
          to="whatInformationWeCollect"
          smooth={true}
          duration={500}
        >
          Profile Information
        </ParahScrollLink>{" "}
        and{" "}
        <ParahScrollLink
          to="whatInformationWeCollect"
          smooth={true}
          duration={500}
        >
          User Content
        </ParahScrollLink>{" "}
        may be visible to other users and the public. You can learn about
        account types and privacy settings,
        {/* including how to limit the audience
        for your videos here.  */}
        Your profile and content may also be visible to third parties, such as
        search engines, content aggregators, and news sites. You and other
        individuals may be able to share your{" "}
        <ParahScrollLink
          to="whatInformationWeCollect"
          smooth={true}
          duration={500}
        >
          User Content
        </ParahScrollLink>{" "}
        both on and off the Platform, depending on the choices you make in your
        privacy settings.
      </Parah>
      <PointsWrapper>
        <PointsIcon />
        Users of Our Analytics Services
      </PointsWrapper>
      <Parah>
        We provide aggregated statistics and insights to help people and
        businesses understand how people are engaging with the Platform. For
        example, creators and advertisers can receive information about the
        number of views, likes, comments and shares of their videos, as well as
        aggregate demographic information about their followers and the viewers
        of their videos. We create this aggregate information using{" "}
        <ParahScrollLink
          to="whatInformationWeCollect"
          smooth={true}
          duration={500}
        >
          Information You Provide,
        </ParahScrollLink>{" "}
        and{" "}
        <ParahScrollLink
          to="whatInformationWeCollect"
          smooth={true}
          duration={500}
        >
          Automatically Collected Information.
        </ParahScrollLink>{" "}
      </Parah>
      <PointsWrapper>
        <PointsIcon />
        Corporate Transactions
      </PointsWrapper>
      <Parah>
        Your information may be disclosed to third parties in connection with a
        corporate transaction, such as a merger, sale of assets or shares,
        reorganisation, financing, change of control, or acquisition of all or a
        portion of our business.
      </Parah>
      <PointsWrapper>
        <PointsIcon />
        Legal Obligations and Rights
      </PointsWrapper>
      <Parah>
        We may access, preserve, and share the information described in{" "}
        <ParahScrollLink
          to="whatInformationWeCollect"
          smooth={true}
          duration={500}
        >
          "What Information We Collect"
        </ParahScrollLink>{" "}
        with law enforcement agencies, public authorities, copyright holders, or
        other third parties if we have good faith belief that it is necessary
        to:
      </Parah>
      <PointsWrapper>
        <PointsIcon />
        Comply with applicable law, legal process or government requests, as
        consistent with internationally recognised standards,
      </PointsWrapper>
      <PointsWrapper>
        <PointsIcon /> Protect the rights, property, and safety of our users,
        copyright holders, and others, including to protect life or prevent
        imminent bodily harm. For example, we may provide information (such as
        your IP address) to law enforcement in the event of an emergency where
        someone’s life or safety is at ris
      </PointsWrapper>
      <PointsWrapper>
        <PointsIcon /> Investigate potential violations of and enforce our
        Terms, Guidelines, or any other applicable terms, policies, or
        standards, or
      </PointsWrapper>
      <PointsWrapper>
        <PointsIcon />
        Detect, investigate, prevent, or address misleading activity, copyright
        infringement, or other illegal activity
      </PointsWrapper>
      <Parah>
        To learn more about how we handle requests from law enforcement agencies
        and public authorities, see our Law Enforcement Guidelines.
      </Parah>
    </>
  );
};

export default PP_3;
