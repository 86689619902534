import React from "react";
import { PrimaryBtn, SecondaryBtn } from "../Common/CommonStyle";
import { ModalBg, UpgradeAccountModal } from "./ModalStyles";
import { useNavigate } from "react-router-dom";

const AccountOption = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/upgrade-account-as-talent/:userid");
  };
  return (
    <ModalBg>
      <UpgradeAccountModal>
        AccountOption
        <PrimaryBtn onClick={handleClick}>Become a Talent</PrimaryBtn>
        <SecondaryBtn>Skip to Your Profile</SecondaryBtn>
      </UpgradeAccountModal>
    </ModalBg>
  );
};

export default AccountOption;
