import React from "react";
import {
  IndicatorWrapper,
  StatusContainer,
  StatusIconWrapper,
  StatusLine,
  StatusTitleWrapper,
  StatusWrapper,
} from "./CommonStyle";

const FormStatusIndicator = ({ statusData, page }) => {
  return (
    <IndicatorWrapper>
      {statusData.map((item, index) => (
        <StatusContainer key={index}>
          <StatusWrapper>
            <StatusTitleWrapper
              isActive={index === page}
              isComplete={item.complete === true}
            >
              <h2>{item.title}</h2>
              <p>{item.subTitle}</p>
            </StatusTitleWrapper>
            <StatusIconWrapper
              isActive={index === page}
              isComplete={item.complete === true}
            >
              {item.icon}
            </StatusIconWrapper>
          </StatusWrapper>
          {index < statusData.length - 1 && <StatusLine />}
        </StatusContainer>
      ))}
    </IndicatorWrapper>
  );
};

export default FormStatusIndicator;
