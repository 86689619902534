import React from "react";

function UserIcon({ fill, width, height }) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width || "24px"}
      height={height || "24px"}
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill={fill}
        stroke="none"
      >
        <path
          d="M2349 4835 c-347 -66 -643 -266 -827 -559 -241 -383 -250 -867 -24
-1260 251 -436 748 -676 1245 -601 480 72 880 432 1002 901 89 344 30 696
-168 992 -171 256 -417 428 -726 509 -121 32 -380 41 -502 18z m436 -329 c265
-73 480 -254 595 -499 60 -128 83 -237 83 -387 -1 -133 -18 -219 -67 -340
-101 -252 -339 -459 -611 -532 -115 -31 -336 -30 -450 1 -274 74 -510 279
-611 531 -49 121 -66 207 -67 340 -1 243 81 453 242 624 132 139 299 233 481
272 87 18 323 12 405 -10z"
        />
        <path
          d="M2385 2074 c-16 -2 -73 -9 -125 -15 -295 -33 -638 -151 -887 -307
-357 -221 -617 -537 -732 -889 -71 -214 -92 -463 -46 -525 30 -40 86 -68 137
-68 93 0 158 81 158 196 0 140 58 353 133 486 165 294 443 528 791 666 593
235 1304 170 1811 -166 363 -240 576 -578 603 -958 6 -82 14 -120 28 -145 61
-104 209 -107 269 -4 30 51 33 133 9 293 -109 737 -827 1332 -1722 1427 -123
13 -348 18 -427 9z"
        />
      </g>
    </svg>
  );
}

export default UserIcon;
