import React from "react";

function StarIcon({ fill, width, height }) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width || "24px"}
      height={height || "24px"}
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill={fill || "var(--dark-primary)"}
        stroke="none"
      >
        <path
          d="M2502 5009 c-29 -4 -77 -19 -106 -33 -109 -53 -120 -72 -437 -713
-159 -323 -300 -598 -312 -611 -12 -13 -34 -30 -48 -38 -15 -7 -309 -54 -655
-104 -346 -50 -647 -96 -669 -101 -100 -27 -189 -99 -238 -194 -30 -56 -32
-67 -32 -160 0 -90 3 -106 28 -158 34 -68 27 -60 570 -592 230 -224 425 -421
433 -436 18 -36 18 -96 -1 -195 -8 -44 -55 -320 -105 -614 -103 -605 -106
-642 -57 -742 36 -74 101 -139 175 -175 82 -41 204 -45 288 -11 33 14 304 154
604 312 300 157 562 292 582 298 28 8 48 8 75 0 21 -6 261 -129 533 -272 735
-387 664 -355 784 -355 91 0 107 3 158 28 123 61 200 175 206 307 4 76 -6 140
-158 1022 -54 310 -58 354 -35 397 8 16 223 232 477 481 255 249 478 473 496
498 121 171 55 434 -134 530 -64 32 -92 37 -744 132 -344 49 -637 95 -651 101
-15 6 -38 23 -52 37 -14 15 -156 291 -316 614 -305 619 -322 648 -415 702 -67
39 -161 57 -244 45z"
        />
      </g>
    </svg>
  );
}

export default StarIcon;
