import React from "react";
import { TopBarTabs, TopbarWrapper } from "./ExploreStyles";
import { ExploreTabsItems } from "../../Utils/Others/Data";
import { useDispatch } from "react-redux";
import {
  getSearchResults,
  getTalentSearchResults,
} from "../../Functions/getFetchFunctions";

const ExploreTopBar = ({
  setSearchResults,
  setAccountResults,
  setSearchLoading,
  activeTab,
  setActiveTab,
  setMainCatId,
  searchText,
  setIsSearched,
  setSearchResultType,
}) => {
  const currentUserId = localStorage.getItem("currentUserId");
  const dispatch = useDispatch();

  const handleTabClick = (index, mainCatId) => {
    setActiveTab(index);
    let searchType = null;
    if (index === 0) {
      searchType = "ALL";
      if (searchText === null || searchText === "") {
        setIsSearched(false);
      }
    } else if (index === 1) {
      searchType = "FEED";
      setIsSearched(true);
    } else if (index === 2) {
      searchType = "SHARE";
      setIsSearched(true);
    } else if (index === 3) {
      searchType = "TALENT";
      setIsSearched(true);
    } else {
      searchType = "ALL";
      setIsSearched(true);
    }

    if (index === 3) {
      setSearchResultType("TALENT");
    } else {
      setSearchResultType("ALL");
    }

    setMainCatId(mainCatId);

    if (searchType !== null) {
      let pageNo = 1;
      let pageSize = 11;
      if (searchType === "TALENT") {
        console.log("searching-talents");
        getTalentSearchResults(
          "TALENT",
          currentUserId,
          pageNo,
          20,
          dispatch,
          setSearchLoading,
          setAccountResults,
          searchText
        );
      } else {
        console.log("searching-results");
        getSearchResults(
          searchType,
          currentUserId,
          pageNo,
          pageSize,
          dispatch,
          setSearchLoading,
          setSearchResults,
          searchText,
          mainCatId
        );
      }
    }
  };

  return (
    <TopbarWrapper>
      {ExploreTabsItems.map((item, index) => (
        <TopBarTabs
          key={index}
          onClick={() => handleTabClick(index, item.id)}
          isActive={activeTab === index}
        >
          {item.name}
        </TopBarTabs>
      ))}
    </TopbarWrapper>
  );
};

export default ExploreTopBar;
