import React, { useEffect, useState } from "react";
import {
  SkillCard,
  SkillCardContent,
  SkillCardContentWrapper,
  SkillCardHeader,
  SkillCardIcon,
  SkillCardIconBg,
  SkillCardLabel,
  SkillCardLevel,
  SkillDescription,
  SkillDetailsWrapper,
  SkillsCardContainer,
} from "./SkillsStyle";
import { CodingIcon } from "../../../Assets/Icons";
import { AtomicIcon } from "../../../Assets/SVG";
import { DetailLabel, UserDetailHeading } from "../Overview/OverviewStyle";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getTalentSkill } from "../../../Functions/getFetchFunctions";
import { AddBtn, LoaderStyles } from "../../../Components/Common/CommonStyle";
import { BiPlus } from "react-icons/bi";
import { EmptyModalDataWrap } from "../../../Components/Modals/ModalStyles";
import { FadeLoader } from "react-spinners";

const Skills = () => {
  const { userId } = useParams();
  const dispatch = useDispatch();
  const currentUserId = localStorage.getItem("currentUserId"); //tediuucet
  let isEdit = currentUserId === userId;

  const [skillArray, setSkillArray] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    getTalentSkill(userId, dispatch, setSkillArray, setIsLoading);
  }, [dispatch, userId]);

  return (
    <div style={{ maxWidth: "1300px" }}>
      <UserDetailHeading>
        <span>
          <AtomicIcon /> Skills
        </span>
        {isEdit && (
          <AddBtn>
            <BiPlus />
            <p>Add</p>
          </AddBtn>
        )}
      </UserDetailHeading>
      {isLoading ? (
        <EmptyModalDataWrap height={"20rem"}>
          <FadeLoader
            color="#747474"
            height={8.5}
            width={2}
            radius={1}
            margin={-9}
            speedMultiplier={1.5}
            loading={true}
            cssOverride={LoaderStyles}
          />
        </EmptyModalDataWrap>
      ) : (
        <SkillsCardContainer>
          {skillArray.map((item, index) => (
            <SkillCard key={index}>
              <SkillCardHeader>
                <SkillCardIconBg>
                  <SkillCardIcon src={CodingIcon} />
                </SkillCardIconBg>
                {item.mainCategoryName}
              </SkillCardHeader>

              <SkillCardContentWrapper>
                <DetailLabel>{item.subCategoryName}</DetailLabel>
                <SkillDetailsWrapper>
                  {item.skillsHave.map((skills, index) => (
                    <SkillCardContent key={index}>
                      <SkillCardLabel>{skills.skillName}</SkillCardLabel>
                      <SkillCardLevel>{skills.level}</SkillCardLevel>
                    </SkillCardContent>
                  ))}
                </SkillDetailsWrapper>

                <SkillDetailsWrapper>
                  <SkillCardLabel>Description</SkillCardLabel>
                  <SkillDescription>{item.description}</SkillDescription>
                </SkillDetailsWrapper>
              </SkillCardContentWrapper>
            </SkillCard>
          ))}
        </SkillsCardContainer>
      )}
    </div>
  );
};

export default Skills;
