import styled from "styled-components";
import { BiMinus, BiPlus } from "react-icons/bi";
import { BsSymmetryHorizontal, BsSymmetryVertical } from "react-icons/bs";
import { RxRotateCounterClockwise } from "react-icons/rx";

// loader styles
export const LoaderStyles = {
  margin: "0 0 0 1.2rem",
};

// Button Styles
const BtnStyles = `
  font-size: 14px;
  font-weight: 500;
  height: 2.3rem;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  padding: 0 1.2rem;

  @media screen and (max-width: 650px) {
    font-size: 13px
  }
`;

export const PrimaryBtn = styled.button`
  ${BtnStyles}
  min-width: ${(props) => props.width || "8rem"};
  color: var(--white);
  background: ${(props) => props.theme.primary};
  opacity: ${({ disabled }) => (disabled ? "0.7" : "1")};
  @media screen and (max-width: 650px) {
    width: ${({ isWidthFull }) => isWidthFull && "100%"};
    min-width: ${({ isWidthShort }) => isWidthShort && "6rem"};
  }
`;

export const SmallPrimaryBtn = styled.button`
  ${BtnStyles}
  width: ${(props) => props.width || "6.5rem"};
  height: 2.2rem;
  padding: 0;
  font-size: 13px;
  color: var(--white);
  background: ${(props) => props.theme.primary};
`;

export const SecondaryBtn = styled.button`
  ${BtnStyles}
  min-width: ${(props) => props.width || "8rem"};
  color: ${(props) => props.theme.dark};
  background: ${(props) => props.theme.mediumBg};
  &:hover {
    color: ${(props) => props.theme.defaultLight};
    background: ${(props) => props.theme.altBg};
  }
  @media screen and (max-width: 650px) {
    width: ${({ isWidthFull }) => isWidthFull && "100%"};
    min-width: ${({ isWidthShort }) => isWidthShort && "6rem"};
  }
`;

export const SmallSecondaryBtn = styled.button`
  ${BtnStyles}
  width: ${(props) => props.width || "6.5rem"};
  height: 2.2rem;
  padding: 0;
  font-size: 13px;
  color: ${(props) => props.theme.dark};
  background: ${(props) => props.theme.mediumBg};
  &:hover {
    color: ${(props) => props.theme.defaultLight};
    background: ${(props) => props.theme.altBg};
  }
`;

export const OutlineBtn = styled.button`
  ${BtnStyles}
  min-width: ${(props) => props.width || "8rem"};
  color: ${(props) => props.theme.red};
  background: none;
  border: 1px solid ${(props) => props.theme.red};
`;

export const TerritoryBtn = styled.button`
  min-width: ${(props) => props.width || "8rem"};
  padding: ${({ isEdit }) => (isEdit ? "0 0.5rem" : "0 1.2rem")};
  height: ${({ isEdit }) => (isEdit ? "2rem" : "2.3rem")};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  border-radius: 50px;
  color: ${({ isLight, theme }) => (isLight ? "var(--white)" : theme.dark)};
  border: none;
  /* border: 1px solid ${(props) => props.theme.dark}; */
  background: ${({ isLight, theme }) =>
    isLight ? theme.altBg : theme.mediumBg};
  font-weight: 500;
  cursor: pointer;
  font-size: 14px;
  p {
    font-size: ${({ isEdit }) => (isEdit ? "13px" : "14px")};
  }

  &:hover {
    color: ${(props) => props.theme.defaultLight};
    background: ${(props) => props.theme.altBg};
    /* border: 1px solid ${(props) => props.theme.altBg}; */
  }
`;

export const AddBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 1rem;
  gap: 2px;
  font-weight: ${(props) => (props.theme.mode === "light" ? 500 : 400)};
  color: ${(props) =>
    props.theme.mode === "light" ? props.theme.secondary : props.theme.yellow};
  p {
    font-size: 14px;
  }
`;

export const CameraButton = styled.div`
  position: absolute;
  border-radius: 5px;
  background: var(--d-gray);
  padding: ${(props) => props.padding || "0.5rem"};
  bottom: ${(props) => props.isLow || "0.5rem"};
  right: ${(props) => props.isLow || "0.5rem"};
  gap: 0.5rem;
  cursor: pointer;
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  font-size: 1.4rem;
  p {
    font-size: 14px;
  }
  @media screen and (max-width: 650px) {
    padding: 0.3rem;
    bottom: 0.3rem;
    right: 0.3rem;
    p {
      display: none;
    }
  }
`;

const PlusMinusStyles = `
  cursor: pointer;
  font-size: 16px;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 5px;
`;
export const MinusButton = styled(BiMinus)`
  ${PlusMinusStyles}
  color: ${(props) => props.theme.dark};
  &:hover {
    background: ${(props) => props.theme.lightGrayBg};
  }
`;

export const PlusButton = styled(BiPlus)`
  ${PlusMinusStyles}
  color: ${(props) => props.theme.dark};
  &:hover {
    background: ${(props) => props.theme.lightGrayBg};
  }
`;

export const FlipVertical = styled(BsSymmetryVertical)``;

export const FlipHorizontal = styled(BsSymmetryHorizontal)``;

export const Rotate = styled(RxRotateCounterClockwise)``;

export const EditToolButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.color || "var(--black)"};
  font-size: ${(props) => props.fontSize || "12px"};
  gap: 0.1rem;
  cursor: pointer;
  border-radius: 5px;
  padding: 0.1rem 0.3rem;
  &:hover {
    background: var(--l-gray);
  }
`;

export const MyPhotoButton = styled.button`
  height: 7.5rem;
  width: 7.5rem;
  border-radius: 8px;
  background: ${(props) => props.theme.mediumBg};
  color: ${(props) => props.theme.dark};
  fill: ${(props) => props.theme.dark};
  border: 1px solid ${(props) => props.theme.mediumMain};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 1.5rem;
  p {
    font-size: 13px;
  }
  &:hover {
    color: ${(props) => props.theme.defaultLight};
    background: ${(props) => props.theme.altBg};
  }
  @media screen and (max-width: 1450px) {
    /* width: 7rem;
    height: 7rem; */
  }
  @media screen and (max-width: 450px) {
    height: 6.6rem;
    width: 6.6rem;
  }
`;

export const SocialIcon = styled.div`
  display: grid;
  place-items: center;
  border-radius: 100px;
  border: 2px solid ${(props) => props.theme.mediumMain};
  fill: ${(props) => props.theme.darkGray};
`;

// 3 image styles -----------------------------------------------

export const MyPhotoWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
`;

export const MyPhotoImg = styled.img`
  height: 7.5rem;
  width: 7.5rem;
  border-radius: 8px;
  background: ${(props) => props.theme.mediumBg};
  object-fit: cover;
  -o-object-fit: cover;
  @media screen and (max-width: 1450px) {
    /* width: 7.1rem;
    height: 7.1rem; */
  }
`;

export const MyPhotoContent = styled.section`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  position: 8px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.defaultLight};
  font-size: 1.5rem;
  cursor: pointer;
  background: linear-gradient(
    0deg,
    rgba(5, 11, 24, 0.726),
    rgba(92, 92, 92, 0.1)
  );

  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.25s;
  transition-duration: 0.5s;
  &:hover {
    opacity: 1;
  }
`;

// personal and profession details form --------------------------------

export const FormError = styled.div`
  color: ${(props) => props.theme.red};
  display: flex;
  align-items: flex-start;
  font-size: 12px;
  width: 100%;
  @media screen and (max-width: 1450px) {
    font-size: 11px;
  }
`;

export const FormInputContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 1rem;
  gap: 2rem;
  border-bottom: 1px solid ${(props) => props.theme.mediumMain};
  padding: 1rem 0;
  width: 70%;
  label {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 3px;
    color: ${(props) => props.theme.dark};
    span {
      color: ${(props) => props.theme.main};
      font-size: 11px;
      margin-left: 0.4rem;
    }
  }

  @media screen and (max-width: 1450px) {
    width: 90%;
  }
  @media screen and (max-width: 1190px) {
    width: 90%;
    gap: 1rem;
  }
  @media screen and (max-width: 900px) {
    width: 90%;
  }
  @media screen and (max-width: 750px) {
    width: 98%;
    gap: 0.5rem;
    label {
      margin-bottom: 1px;
    }
  }
`;

export const FormInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: ${({ isPost, width }) => isPost && width};
  width: 50%;
  p {
    font-size: 12px;
    /* color: ${(props) => props.theme.defaultMain}; */
    /* color: var(--gray); */
    font-weight: 500;
    width: 80%;
    margin-top: 2px;
  }
  border: 1px solid red;
  @media screen and (max-width: 900px) {
    gap: 1px;
    p {
      width: 95%;
    }
    @media screen and (max-width: 650px) {
      width: 100%;
    }
  }
`;

// multi step form styles -----------------------------------------------------
export const MultiFormPage = styled.main`
  display: flex;
  align-items: start;
  justify-content: center;
  background: ${(props) => props.theme.mainBg};
  min-height: 100vh;
  height: max-content;
`;

export const MultiFormContainer = styled.section`
  display: flex;
  justify-content: center;
  align-items: start;
  width: 100%;
  max-width: 1500px;
  color: ${(props) => props.theme.dark};
`;

export const FormIndicatorWrapper = styled.section`
  width: 27%;
  padding: 1.5rem 3rem 0 0;
  position: sticky;
  top: 78px;
  height: 100%;
  @media screen and (max-width: 1450px) {
    padding-top: 5px;
  }
  @media screen and (max-width: 1050px) {
    display: none;
  }
`;

export const FormContainerWrapper = styled.section`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  padding: 5rem 3rem 1rem 3rem;
  border-left: 1px solid ${(props) => props.theme.borderColor};
  @media screen and (max-width: 1450px) {
    padding-top: 65px;
  }
  @media screen and (max-width: 950px) {
    padding: 5rem 10px 10px 10px;
  }
`;

export const FormSectionWrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  max-width: 1100px;
`;

export const FormHeader = styled.header`
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0;
  border-bottom: 1px solid ${(props) => props.theme.mediumMain};
  width: 100%;
  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  p {
    max-width: 80%;
    color: ${(props) => props.theme.main};
  }
  @media screen and (max-width: 1450px) {
    h1 {
      font-size: 22px;
    }
    p {
      font-size: 13px;
    }
  }
  @media screen and (max-width: 950px) {
    p {
      margin-top: 10px;
      max-width: 100%;
    }
  }
`;

export const StepWrapper = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  min-width: 6rem;
  padding: ${(props) => props.padding || "0.3rem 0.5rem"};
  border-radius: 5px;
  background-color: ${(props) => props.theme.mediumBg};
`;

export const IndicatorWrapper = styled.section`
  display: flex;
  flex-direction: column;
`;

export const FormPageDataWrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 10px;
  width: ${({ width }) => (width ? width : "60%")};
  @media screen and (max-width: 1450px) {
  }
  @media screen and (max-width: 1100px) {
    width: 100%;
    padding-right: 1rem;
  }
  @media screen and (max-width: 950px) {
    width: 100%;
    padding: 0;
  }
`;

export const MoreInfoFormWrap = styled.div``;

export const LabelInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  width: ${(props) => props.width || "60%"};
  width: 60%;
  label {
    width: 100%;
    max-width: 12rem;
    color: ${(props) => props.theme.dark};
    font-size: 13px;
  }
  @media screen and (max-width: 1450px) {
    width: 60%;
    label {
      max-width: 10rem;
    }
  }
`;

export const LevelContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

export const DropdownFlexWrap = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  width: ${({ width }) => (width ? width : "100%")};
  position: relative;
  gap: 1rem;
  @media screen and (max-width: 950px) {
    width: 100%;
  }
`;

export const LevelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  gap: 1rem;
`;

// multi step form button footer styles --------------------------------

export const FormFooter = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 3rem;
  gap: 0.5rem;
  max-width: 1100px;
`;

export const FormFooterButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid ${(props) => props.theme.borderColor};
  @media screen and (max-width: 650px) {
    flex-direction: column-reverse;
  }
`;

export const MultiArrayDelete = styled.span`
  position: absolute;
  top: 5px;
  right: -2.5rem;
  display: grid;
  place-items: center;
  padding: 5px;
  border-radius: 100px;
  font-size: 1rem;
  border: 1px solid ${(props) => props.theme.borderColor};
  color: ${(props) => props.theme.main};
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.dark};
    background: ${(props) => props.theme.mediumBg};
  }
  @media screen and (max-width: 950px) {
    position: relative;
    top: 5px;
    right: 10px;
  }
`;

export const MyPhotoInputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* gap: 1.5rem; */
`;

// form status indicator ----------------------------------------

export const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const StatusWrapper = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  align-items: center;
  margin: 0.3rem;
`;

export const StatusTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  color: ${({ isActive, isComplete, theme }) =>
    isActive || isComplete ? theme.dark : theme.main};
  h2 {
    font-size: 14px;
  }
  p {
    font-size: 12px;
    color: ${(props) => props.theme.main};
    text-align: right;
  }
`;

export const StatusIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: min-content;
  fill: ${({ isActive, isComplete, theme }) =>
    isActive || isComplete ? theme.dark : theme.main};
  border: 1px solid
    ${({ isComplete, isActive, theme }) =>
      isComplete
        ? isActive
          ? theme.main
          : theme.green
        : isActive
        ? theme.main
        : theme.mediumMain};
  background: ${({ isComplete, isActive, theme }) =>
    isComplete
      ? isActive
        ? theme.mediumBg
        : theme.green
      : isActive
      ? theme.mediumBg
      : null};
  padding: 0.5rem;
  border-radius: 100px;
`;

export const StatusLine = styled.div`
  content: "";
  height: 2rem;
  width: 1px;
  background: ${({ isActive, theme }) =>
    isActive ? theme.main : theme.borderColor};
  margin-right: 1.5rem;
`;

export const Image100 = styled.img`
  object-fit: cover;
  -o-object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: ${({ br }) => br && br};
`;

export const Video100 = styled.video`
  object-fit: cover;
  -o-object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: ${({ br }) => br && br};
`;

export const SectionFooterWrapper = styled.main`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
