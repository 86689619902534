import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  languages: [],
  ethnicities: [],
  professions: [],
  mainCategories: [],
  subCategories: [],
  skills: [],
  countries: [],
  states: [],
};

export const contentApiCache = {};

export const contentSlice = createSlice({
  name: "content",
  initialState,
  reducers: {
    setLanguages: (state, action) => {
      state.languages = action.payload.languages;
    },
    setEthnicities: (state, action) => {
      state.ethnicities = action.payload.ethnicities;
    },
    setProfessions: (state, action) => {
      state.professions = action.payload.professions;
    },
    setMainCategories: (state, action) => {
      state.mainCategories = action.payload.mainCategories;
    },
    setSubCategories: (state, action) => {
      state.subCategories = action.payload.subCategories;
    },
    setSkills: (state, action) => {
      state.skills = action.payload.skills;
    },
    setCountries: (state, action) => {
      state.countries = action.payload.countries;
    },
    setStates: (state, action) => {
      state.states = action.payload.states;
    },
  },
});

export const {
  setLanguages,
  setEthnicities,
  setMainCategories,
  setSubCategories,
  setSkills,
  setProfessions,
  setCountries,
  setStates,
} = contentSlice.actions;
export default contentSlice.reducer;
