import React from "react";

function AllPostIcon({ fill, width, height }) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width || "24px"}
      height={height || "24px"}
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill={fill || "var(--dark-primary)"}
        stroke="none"
      >
        <path
          d="M1300 4953 c-187 -20 -408 -101 -565 -206 -83 -56 -108 -77 -203
-170 -172 -169 -293 -394 -348 -647 -18 -81 -19 -153 -19 -1370 0 -1217 1
-1289 19 -1370 113 -515 491 -893 1006 -1006 81 -18 153 -19 1370 -19 1217 0
1289 1 1370 19 509 111 875 472 1003 986 21 84 21 104 24 1221 4 1251 6 1213
-57 1347 -16 35 -46 87 -67 114 -20 27 -233 234 -474 459 -587 548 -571 534
-664 580 -145 71 -97 69 -1290 67 -588 -1 -1086 -3 -1105 -5z m2060 -585 c0
-316 8 -369 71 -498 58 -117 161 -219 280 -278 135 -67 173 -72 577 -72 l353
0 -3 -1117 c-3 -1113 -3 -1118 -25 -1194 -87 -309 -296 -547 -574 -658 -176
-70 -77 -66 -1474 -66 -1184 0 -1265 1 -1335 18 -358 87 -620 342 -723 706
-22 76 -22 77 -25 1310 -2 1064 0 1246 13 1322 60 351 310 639 654 754 128 42
151 43 1204 44 l1007 1 0 -272z m675 -193 c171 -158 321 -299 335 -312 l25
-24 -240 3 -240 3 -63 34 c-69 37 -109 80 -144 151 -21 41 -23 62 -26 260 l-4
215 24 -21 c12 -11 163 -150 333 -309z"
        />
        <path
          d="M1440 3593 c-49 -7 -145 -46 -185 -73 -80 -56 -143 -136 -178 -227
-22 -58 -22 -64 -25 -796 -2 -482 1 -758 8 -795 29 -160 139 -291 295 -353 59
-24 64 -24 492 -27 277 -2 453 1 490 7 184 34 340 185 373 360 6 33 10 333 10
775 0 609 -3 731 -15 785 -41 172 -185 308 -359 340 -56 10 -845 14 -906 4z
m912 -328 c14 -11 35 -33 45 -50 17 -28 18 -69 18 -757 0 -722 0 -727 -21
-755 -11 -15 -36 -38 -54 -50 -33 -23 -34 -23 -454 -23 -402 0 -422 1 -454 20
-18 11 -41 34 -52 52 -20 32 -20 48 -18 768 3 717 3 736 23 762 45 60 30 58
505 55 417 -2 436 -3 462 -22z"
        />
        <path
          d="M3110 3428 c-116 -59 -113 -217 5 -269 43 -19 66 -20 609 -17 552 3
565 3 592 24 51 38 69 71 69 129 0 58 -18 91 -69 129 -27 21 -40 21 -594 24
l-567 3 -45 -23z"
        />
        <path
          d="M3093 2589 c-45 -29 -77 -98 -68 -149 8 -49 47 -98 92 -116 26 -11
142 -14 594 -14 559 0 561 0 600 22 43 24 79 85 79 134 0 41 -31 95 -70 122
l-33 22 -581 0 c-568 0 -582 -1 -613 -21z"
        />
        <path
          d="M3114 1766 c-42 -19 -82 -73 -89 -121 -8 -53 27 -122 75 -150 34 -19
51 -20 605 -20 552 0 571 1 604 20 55 32 76 69 76 130 0 58 -18 91 -69 129
-27 21 -39 21 -599 23 -466 2 -578 0 -603 -11z"
        />
      </g>
    </svg>
  );
}

export default AllPostIcon;
