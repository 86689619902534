import React from "react";
import {
  Parah,
  SubTitle,
  ThirdTitle,
  PointsWrapper,
  PointsIcon,
} from "../ContentsStyles";

const CG_1 = () => {
  return (
    <>
      <Parah>
        We are deeply committed to protecting the safety of minors on our
        platform. Talents Explore defines a minor as any person under the age of
        18. We prohibit activities that perpetuate the abuse, harm,
        endangerment, or exploitation of minors on Talents Explore. Any content,
        including animation or digitally created or manipulated media, that
        depicts abuse, exploitation, or endangerment of minors is a violation on
        our platform and will be removed when detected. We report child sexual
        abuse material with supporting evidence to relevant legal authorities.
      </Parah>
      <Parah>
        Our users must meet the minimum age requirements to use Talents Explore,
        as stipulated in our Terms of Service. When an underage account is
        identified, we remove it. Given our platform is designed with the safety
        of minors in mind, some of our features are age restricted. Account
        holders who are under the age of 16 cannot use direct messaging and
        their content is not eligible to appear in the For You feed. Account
        holders must be at least 18 years old to host a livestream or use our
        virtual gifting features.
      </Parah>
      <SubTitle>Sexual Exploitation of Minors</SubTitle>
      <Parah>
        Talents Explore will take action on any content or accounts involving
        CSAM or sexual exploitation of a minor. Sexual exploitation of minors
        includes any abuse of a position of power or trust for sexual purposes,
        including profiting financially, socially, sexually, or politically from
        the exploitation of a minor. CSAM is defined as any visual depiction of
        sexually explicit nudity or conduct of a minor, whether captured by
        predatory adults, peers, or self-generated by minors.
      </Parah>
      <ThirdTitle>Do not post, upload, download, stream, or share:</ThirdTitle>
      <PointsWrapper>
        <PointsIcon />
        Content that shares, reshares, offers to trade or sell, or directs users
        off platform to obtain or distribute CSAM.
      </PointsWrapper>
      <PointsWrapper>
        <PointsIcon />
        Content that engages with minors in a sexualized way, or otherwise
        sexualizes a minor (e.g., via product features like duets).
      </PointsWrapper>
      <PointsWrapper>
        <PointsIcon />
        Content that depicts, solicits, glorifies, or encourages child abuse
        imagery including nudity, sexualized minors, or sexual activity with
        minors.
      </PointsWrapper>
      <PointsWrapper>
        <PointsIcon />
        Content that depicts, promotes, normalizes, or glorifies pedophilia or
        the sexual assault of a minor.
      </PointsWrapper>
      <PointsWrapper>
        <PointsIcon />
        Content that re-victimized or capitalizes on minor victims of abuse by
        third party reshares or re-enactments of assault or confessions.
      </PointsWrapper>
      <SubTitle>Grooming Behavior</SubTitle>
      <Parah>
        Grooming behaviours are those in which an adult builds an emotional
        relationship with a minor in order to gain the minor's trust for the
        purposes of future or ongoing sexual contact, sexual abuse, trafficking,
        or other exploitation. These behaviours may include: flattery, requests
        for contact on or off platform, requests for personal information,
        solicitation of minor sexual abuse material, sexual solicitations or
        comments, and gift-giving.
      </Parah>
      <ThirdTitle>Do not post, upload, stream, or share:</ThirdTitle>
      <PointsWrapper>
        <PointsIcon />
        Grooming advances.
      </PointsWrapper>
      <PointsWrapper>
        <PointsIcon />
        Content that depicts, promotes, normalizes, or glorifies grooming
        behaviours.
      </PointsWrapper>
      <PointsWrapper>
        <PointsIcon />
        Content that solicits real-world contact between a minor and an adult or
        between minors with a significant age difference.
      </PointsWrapper>
      <PointsWrapper>
        <PointsIcon />
        Content that displays or offers nudity to minors.
      </PointsWrapper>
      <PointsWrapper>
        <PointsIcon />
        Content that solicits minors to connect with an adult on another
        platform, website, or other digital space.
      </PointsWrapper>
      <PointsWrapper>
        <PointsIcon />
        Any solicitation of nude imagery or sexual contact, through blackmail or
        other means of coercion.
      </PointsWrapper>
      <SubTitle>Nudity and Sexual Activity Involving Minors</SubTitle>
      <Parah>
        Nudity and sexual activity involving minors include content that is
        overtly revealing of breasts, genitals, anus, or buttocks, or behaviours
        that mimic, imply, or display sex acts involving minors. We do not allow
        the depiction, including digitally created or manipulated content, of
        nudity or sexual activity.
      </Parah>
      <ThirdTitle>Do not post, upload, stream, or share:</ThirdTitle>
      <PointsWrapper>
        <PointsIcon />
        Content that depicts or implies minor sexual activities including
        penetrative and non-penetrative sex, oral sex, or intimate kissing.
      </PointsWrapper>
      <PointsWrapper>
        <PointsIcon />
        Content that depicts sexual arousal or sexual stimulation involving a
        minor.
      </PointsWrapper>
      <PointsWrapper>
        <PointsIcon />
        Content that depicts a sexual fetish involving a minor.
      </PointsWrapper>
      <PointsWrapper>
        <PointsIcon />
        Content that depicts exposed genitals, buttocks, the pubic region, or
        female nipples of a minor.
      </PointsWrapper>
      <PointsWrapper>
        <PointsIcon />
        Content that contains sexually explicit language depicting or describing
        a minor.
      </PointsWrapper>
      <PointsWrapper>
        <PointsIcon />
        Content depicting a minor that contains sexually explicit song lyrics.
      </PointsWrapper>
      <PointsWrapper>
        <PointsIcon />
        Content with sexually explicit dancing of a minor, including twerking,
        breast shaking, pelvic thrusting, or fondling the groin or breasts of
        oneself or another.
      </PointsWrapper>
      <PointsWrapper>
        <PointsIcon />
        Content depicting a minor undressing.
      </PointsWrapper>
      <PointsWrapper>
        <PointsIcon />
        Content depicting a minor in minimal clothing that is not situationally
        relevant to the location.
      </PointsWrapper>
      <PointsWrapper>
        <PointsIcon />
        Sexualized comments, emojis, text, or other graphics used to veil or
        imply nudity or sexual activity of a minor.
      </PointsWrapper>
      <SubTitle>Harmful Activities by Minors</SubTitle>
      <Parah>
        Harmful minor activities include the possession or consumption of
        substances prohibited for minors, the misuse of legal substances,
        engagement in illegal activities, and participation in activities,
        physical challenges, or dares that may threaten the well-being of
        minors. We remove any such content from our platform.
      </Parah>
      <ThirdTitle>Do not post, upload, stream, or share:</ThirdTitle>
      <PointsWrapper>
        <PointsIcon />
        Content that suggests, depicts, imitates, or promotes the possession or
        consumption of alcoholic beverages, tobacco, or drugs by a minor.
      </PointsWrapper>
      <PointsWrapper>
        <PointsIcon />
        Content that offers instruction targeting minors on how to buy, sell, or
        trade alcohol, tobacco, or controlled substances.
      </PointsWrapper>
      <PointsWrapper>
        <PointsIcon />
        Content that depicts or promotes activities that may jeopardize youth
        well-being, including physical challenges, dares, or stunts.
      </PointsWrapper>
      <SubTitle>Physical and Psychological Harm of Minors</SubTitle>
      <Parah>
        Behaviours that place minors at risk of physical or psychological harm
        include physical abuse, neglect, child endangerment, and psychological
        disparagement. We remove any such content from our platform.
      </Parah>
      <ThirdTitle>Do not post, upload, stream, or share:</ThirdTitle>
      <PointsWrapper>
        <PointsIcon />
        Content that depicts or promotes physical abuse, neglect, endangerment,
        or psychological disparagement of minors.
      </PointsWrapper>
      <SubTitle>Crimes Against Children</SubTitle>
      <Parah>
        We do not allow users who have been convicted of crimes against children
        to have an account on our platform. These crimes include: sexual
        assault, molestation, murder, physical abuse or neglect, abduction,
        international parental kidnapping, trafficking, exploitation of minors
        for prostitution, live online sexual abuse of a minor, sexual
        exploitation of minors in the context of travel and tourism, attempts to
        obtain or distribute CSAM, and the production, possession, or
        distribution of CSAM. If we discover any such users, we ban the account.
        Any self-disclosed user information that states the account holder is a
        pedophile or minor sex offender will be taken at face value and the
        account will be deleted.
      </Parah>
    </>
  );
};

export default CG_1;
