import { useState, useEffect } from "react";

function useTimer() {
  const [isTimerRunning, setIsTimerRunning] = useState(true);
  const [countdown, setCountdown] = useState(
    parseInt(localStorage.getItem("countdown") || 10 * 60)
  );

  useEffect(() => {
    setIsTimerRunning(true);
    if (isTimerRunning) {
      const intervalId = setInterval(() => {
        setCountdown((prevCountdown) => {
          const newCountdown = prevCountdown - 1;
          localStorage.setItem("countdown", newCountdown.toString());
          if (newCountdown === 0) {
            setIsTimerRunning(false);
            localStorage.removeItem("countdown");
          }
          return newCountdown;
        });
      }, 1000);
      return () => {
        clearInterval(intervalId);
        if (countdown === 0) {
          setIsTimerRunning(false);
          localStorage.setItem("countdown", "00:00");
        }
      };
    } else {
      localStorage.setItem("countdown", "00:00");
    }
  }, [countdown, isTimerRunning]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60)
      .toString()
      .padStart(2, "0");
    const seconds = (time % 60).toString().padStart(2, "0");

    if (countdown === 0) {
      setIsTimerRunning(false);
      localStorage.removeItem("countdown");
      return "00:00";
    } else {
      return `${minutes}:${seconds}`;
    }
  };

  return [
    isTimerRunning,
    countdown,
    formatTime,
    setIsTimerRunning,
    setCountdown,
  ];
}

export default useTimer;
