import React, { useState } from "react";
import {
  PageWrapper,
  Answer,
  Heading,
  Paragraph,
  PlusButton,
  QAWrapper,
  Question,
  ParahLink,
} from "./PoliciesStyles";
import { TACQandA } from "../../Utils/Others/Data";
import { motion as m } from "framer-motion";
import { Helmet } from "react-helmet";

const TermsConditions = () => {
  const [qaOpen, setQAOpen] = useState({});

  const handleClick = (id) => {
    setQAOpen((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  return (
    <>
      <Helmet>
        <title>Terms and Conditions | Talents Explore</title>
      </Helmet>
      <PageWrapper>
        <Helmet>Terms and Conditions | Talents Explore</Helmet>
        <Heading>Terms and Conditions</Heading>
        <Paragraph>
          These Terms of Service (“Terms”) contain the agreement between you and
          Talents Explore in respect of the Platform described below. Please
          read these Terms carefully. Please also read our{" "}
          <ParahLink to="/privacy-policy">Privacy Policy</ParahLink>,{" "}
          <ParahLink to="/cookies-policy">Cookies Policy</ParahLink> and{" "}
          <ParahLink to="/community-guidelines">
            Community Guidelines.
          </ParahLink>
        </Paragraph>
        <Paragraph>
          You form a contract with us when you confirm that you accept these
          Terms or when you otherwise use the Platform. In short: "This is a
          contract between you and Talents Explore. You should read it."
        </Paragraph>
        {TACQandA.map((qa) => {
          return (
            <QAWrapper
              key={qa.id}
              id={qa.id === 9 ? (qa.id = "contactUs") : qa.id}
            >
              <Question
                onClick={() => handleClick(qa.id)}
                isActive={qaOpen[qa.id]}
              >
                {qa.question}
                <PlusButton isActive={qaOpen[qa.id]} />
              </Question>
              {qaOpen[qa.id] && (
                <m.div
                  initial={{ opacity: 0, y: "-1%" }}
                  animate={{ opacity: 1, y: "0%" }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.2, ease: "easeInOut" }}
                  key={qa.id}
                >
                  <Answer>{qa.answer}</Answer>
                </m.div>
              )}
            </QAWrapper>
          );
        })}
      </PageWrapper>
    </>
  );
};

export default TermsConditions;
