import React from "react";
import { Parah, ParahScrollLink } from "../ContentsStyles";

const PP_6 = () => {
  return (
    <>
      <Parah>
        Security of your information is important to us. We maintain appropriate
        technical, administrative, and physical security measures that are
        designed to protect your information from unauthorised access, theft,
        disclosure, modification, or loss. We regularly review our security
        measures to consider available new technology and methods.
      </Parah>
      <Parah>
        We retain information for as long as necessary to provide the Platform
        and for the other purposes set out in this Privacy Policy. We also
        retain information when necessary to comply with contractual and legal
        obligations, when we have a legitimate business interest to do so (such
        as improving and developing the Platform, and enhancing its safety,
        security, and stability), and for the exercise or defence of legal
        claims.
      </Parah>
      <Parah>
        The retention periods will be different depending on the type of
        information and the purposes for which we use the information. For
        example, when we process your information to provide you with the
        Platform, we keep this information for as long as you have an account.
        This information includes your{" "}
        <ParahScrollLink
          to="whatInformationWeCollect"
          smooth={true}
          duration={500}
        >
          Profile Information,
        </ParahScrollLink>{" "}
        <ParahScrollLink
          to="whatInformationWeCollect"
          smooth={true}
          duration={500}
        >
          User Content,
        </ParahScrollLink>{" "}
        and{" "}
        <ParahScrollLink
          to="whatInformationWeCollect"
          smooth={true}
          duration={500}
        >
          Direct Messages.
        </ParahScrollLink>{" "}
        If you violate our Guidelines or Terms, we may remove your{" "}
        <ParahScrollLink
          to="whatInformationWeCollect"
          smooth={true}
          duration={500}
        >
          Profile Information
        </ParahScrollLink>{" "}
        and/or{" "}
        <ParahScrollLink
          to="whatInformationWeCollect"
          smooth={true}
          duration={500}
        >
          User Content,
        </ParahScrollLink>{" "}
        from public view immediately but keep your information as is necessary
        to process the violation.
      </Parah>
    </>
  );
};

export default PP_6;
