import React from "react";

function AwardsIcon({ fill, width, height }) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width || "24px"}
      height={height || "24px"}
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill={fill || "var(--dark-primary)"}
        stroke="none"
      >
        <path
          d="M482 4674 c-156 -34 -325 -169 -395 -314 -20 -41 -41 -95 -46 -120
-7 -30 -11 -489 -11 -1350 l0 -1305 28 -81 c73 -208 236 -347 459 -390 98 -18
1579 -21 1634 -3 148 50 190 231 77 336 -52 49 -34 48 -885 53 -790 5 -802 5
-829 26 -15 11 -42 37 -58 58 l-31 38 -3 1262 -2 1262 23 45 c16 29 40 54 72
74 l48 30 1686 0 1686 0 40 -21 c22 -11 54 -40 70 -63 l30 -43 5 -396 5 -397
24 -42 c14 -24 42 -55 64 -70 34 -24 50 -28 107 -28 85 0 136 31 171 102 24
47 24 52 27 408 3 448 -6 522 -85 653 -56 92 -142 174 -233 221 -144 75 -24
71 -1918 70 -1406 -1 -1708 -3 -1760 -15z"
        />
        <path
          d="M1095 3726 c-150 -66 -159 -277 -16 -353 l44 -23 973 0 c663 0 982 3
1001 11 15 5 41 22 56 37 108 102 80 270 -55 327 -33 13 -154 15 -1005 15
-783 -1 -974 -3 -998 -14z"
        />
        <path
          d="M3747 2955 c-21 -7 -107 -58 -190 -113 l-152 -100 -145 -12 c-80 -6
-169 -18 -197 -26 -58 -16 -115 -60 -140 -107 -12 -25 -103 -326 -103 -343 0
-3 -57 -64 -126 -137 -202 -209 -205 -232 -79 -512 15 -33 36 -78 46 -99 18
-38 19 -47 4 -216 -15 -178 -13 -207 18 -266 29 -56 73 -87 232 -164 l160 -77
95 -134 c109 -154 137 -183 199 -204 64 -22 77 -20 269 27 l172 42 176 -43
c273 -68 294 -60 458 165 l106 147 169 83 c248 122 256 136 239 426 l-11 183
72 157 c63 140 71 164 71 218 0 33 -7 77 -15 96 -8 20 -71 93 -141 164 l-126
128 -49 172 c-27 94 -49 173 -49 176 0 2 -17 22 -37 45 -62 68 -96 80 -293 98
l-175 17 -143 98 c-179 123 -232 141 -315 111z m174 -491 c133 -92 159 -102
315 -116 l134 -11 14 -46 c8 -25 24 -81 36 -124 28 -109 38 -124 150 -244 l99
-106 -13 -31 c-8 -17 -33 -74 -57 -126 -43 -94 -44 -96 -44 -205 0 -60 4 -136
8 -168 l7 -58 -117 -55 c-65 -31 -132 -66 -149 -79 -24 -19 -132 -157 -186
-238 -4 -7 -51 0 -135 21 -70 18 -149 32 -174 32 -25 0 -99 -14 -163 -31 -65
-17 -124 -28 -131 -25 -7 2 -45 50 -84 104 -39 55 -87 113 -106 129 -19 16
-86 54 -149 84 l-114 54 1 175 2 175 -56 120 -57 120 88 90 c48 50 98 108 112
130 14 22 40 94 58 160 18 66 38 125 44 131 6 6 69 17 140 23 149 14 186 28
316 120 47 33 92 60 101 60 8 1 58 -28 110 -65z"
        />
        <path
          d="M4013 2083 c-23 -16 -99 -94 -169 -175 -70 -82 -131 -148 -136 -148
-5 0 -31 18 -59 40 -66 52 -117 66 -187 50 -90 -20 -152 -100 -152 -196 0 -16
11 -52 25 -80 26 -50 51 -73 230 -210 87 -67 91 -69 156 -72 56 -3 74 0 107
18 46 26 438 471 464 528 40 86 9 193 -70 243 -64 41 -150 41 -209 2z"
        />
        <path
          d="M1095 2886 c-150 -67 -159 -277 -15 -353 l44 -23 571 2 572 3 36 27
c65 50 82 84 82 165 0 64 -3 75 -31 110 -16 21 -50 48 -74 61 l-44 22 -556 0
c-439 -1 -561 -4 -585 -14z"
        />
      </g>
    </svg>
  );
}

export default AwardsIcon;
