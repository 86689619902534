import { useState } from "react";

function useImageInputType() {
  const [error, setError] = useState("");
  const [image, setImage] = useState({
    data: null,
    preview: null,
    type: null,
    fileName: null,
  });

  const handleImageInput = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    if (
      !["image/jpeg", "image/jpg", "image/png", "image/webp"].includes(
        file.type
      )
    ) {
      setError(
        "Invalid file type. Please choose a file in JPEG, JPG, PNG or WEB format and try again."
      );
      return;
    }

    if (file.size > 1024 * 1024 * 8) {
      setError(
        `${file.name} is too large, maximum 8mb allowed. Please select another image or compress it.`
      );
      return;
    }

    setImage({
      ...image,
      data: file,
      preview: URL.createObjectURL(file),
      type: file.type,
      fileName: file.name,
    });
  };

  return {
    handleImageInput,
    image,
    setImage,
    error,
    setError,
  };
}

export default useImageInputType;

export function useMediaUploadType() {
  // const [error, setError] = useState("");
  const [media, setMedia] = useState({
    data: null,
    preview: null,
    fileType: null,
    fileName: null,
    mediaType: null,
    thumbnail: null,
  });
  const handleMediaInput = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setMedia({
      ...media,
      data: file,
      preview: URL.createObjectURL(file),
      type: file.type,
      fileName: file.name,
      mediaType: "image",
    });
  };
  return {
    media,
    setMedia,
    handleMediaInput,
  };
}

export function useCoverProfilePhoto(values, setValues) {
  const handleProfileChange = (croppedImage) => {
    setValues({
      ...values,
      profilePhoto: croppedImage,
    });
  };
  const handleCoverChange = (croppedImage) => {
    setValues({
      ...values,
      coverPhoto: croppedImage,
    });
  };
  return {
    handleProfileChange,
    handleCoverChange,
  };
}
