import React from "react";
import {
  ArrowIcon,
  ArrowWrapper,
  DropdownContainer,
  DropdownHeader,
  DropdownList,
  DropdownListItem,
  FormInputWrapper,
  InputValidation,
  InputValidationWrapper,
  StyledLabel,
} from "./InputStyle";
import { useModalOpen } from "../../Hooks/useEventHandler";

const DropDown = ({
  label,
  defaultOption,
  options,
  value,
  onChange,
  width,
  isOptionShort, //
  isDate, //
  isPost, //
  isPostLevel, //
  isScrBottom,
  isFor,

  name,
  idName,
  index,
  childIndex,

  isHasLevel,
  isMultiple,
  validation,

  isHorz,
  labelWidth,
  isItemStart,
  isOptional,
  isShort,
  arrayName,
}) => {
  const { isOpen, setIsOpen, clickOutRef } = useModalOpen();
  const handleOptionClick = (e, selectedOption) => {
    console.log(selectedOption, "dropdown");
    if (isFor) {
      onChange({
        isMultiple,
        isFor,
        selectedOption,
        index,
        childIndex,
        isHasLevel,
      });
    } else {
      onChange({
        selectedOption,
        name,
        idName,
        index,
        childIndex,
        isHasLevel,
        // isMultiple,
        arrayName,
      });
    }
    setIsOpen(false);
  };

  return (
    <FormInputWrapper
      isHorz={isHorz}
      isItemStart={isItemStart}
      isLevel={isShort}
    >
      {label && (
        <StyledLabel labelWidth={labelWidth}>
          {label} <span>{isOptional && "(Optional)"}</span>
        </StyledLabel>
      )}

      <InputValidationWrapper>
        <DropdownContainer
          ref={clickOutRef}
          width={width}
          isDate={isDate}
          isPost={isPost}
          isPostLevel={isPostLevel}
          isScrBottom={isScrBottom}
        >
          <DropdownHeader
            onClick={() => setIsOpen(!isOpen)}
            width={width}
            error={validation && validation}
            isDate={isDate}
            isPost={isPost}
            isPostLevel={isPostLevel}
            isScrBottom={isScrBottom}
          >
            {!value ? (
              <div value="">{defaultOption}</div>
            ) : (
              <span>{value}</span>
            )}
          </DropdownHeader>
          {isOpen && options.length !== 0 && (
            <DropdownList isOptionShort={isOptionShort}>
              {options.map((option, index) => (
                <DropdownListItem
                  validId={option.id !== 400}
                  key={index}
                  onClick={(e) => handleOptionClick(e, option)}
                >
                  {option.value}
                </DropdownListItem>
              ))}
            </DropdownList>
          )}
          <ArrowWrapper>
            <ArrowIcon />
          </ArrowWrapper>
        </DropdownContainer>
        {validation && <InputValidation>{validation}</InputValidation>}
      </InputValidationWrapper>
    </FormInputWrapper>
  );
};

export default DropDown;
