import React from "react";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import theme from "./Theme";
import { useSelector } from "react-redux";

const ThemeProvider = ({ children }) => {
  const mode = useSelector((state) => state.utils.mode);
  // const mode = "dark";
  // const mode = "light";

  return (
    <StyledThemeProvider theme={theme[mode]}>{children}</StyledThemeProvider>
  );
};

export default ThemeProvider;
