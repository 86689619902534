import React, { useEffect, useState } from "react";
import { AiOutlineLogout } from "react-icons/ai";
import { HiOutlineMenuAlt2 } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import {
  // BackButton,
  DarkModeButton,
  LoginLink,
  MoonIcon,
  Nav,
  NavbarIcons,
  NavContainer,
  NavImg,
  NavItems,
  NavLinks,
  NavLogo,
  NavLogout,
  NavMenu,
  NavMobileLogo,
  NavTitleWrapper,
  ProfileLink,
  SignupLink,
  SunIcon,
} from "./NavbarStyle";
import ProfileIcon from "../../Assets/Images/default_profile_pic.png";
import { MessageIcon, NotificationIcon } from "../../Assets/SVG";
import { useTheme } from "styled-components";
import { setBackNavigate, setMode } from "../../ReduxToolKit/Slices/utilsSlice";
import DarkLogo from "../../Assets/Images/teBlackLogo.png";
import LightLogo from "../../Assets/Images/teWhiteLogo.png";
import { setLogout } from "../../ReduxToolKit/Slices/authSlice";
import MobileSidebar from "../Sidebar/MobileSidebar";
// import { useNavigate } from "react-router-dom";
// import { IoIosArrowBack } from "react-icons/io";

const Navbar = ({ showLogo, activeLink, isSticky, isPolicy }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const isAbout = Boolean(useSelector((state) => state.utils.isAbout));
  // const backNavigate = useSelector((state) => state.utils.backNavigate);
  const token = localStorage.getItem("token");
  const isAuth = Boolean(token);
  const currentUser = useSelector((state) => state.auth.currentUser);
  const currentUserId = localStorage.getItem("currentUserId");
  // const currentUserId = localStorage.getItem("TalentsExploreCurrentUserId");
  const [loading, setLoading] = useState(true);
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  let isHome = activeLink === "/";

  useEffect(() => {
    if (!currentUser === null) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [currentUserId, currentUser]);

  // const handleBackNavigate = () => {
  //   dispatch(setBackNavigate({ backNavigate: backNavigate - 1 }));
  //   navigate(-1);
  // };

  return (
    <>
      {loading && (
        <Nav
          showLogo={showLogo}
          isAuth={isAuth}
          isHome={isHome}
          isSticky={isSticky}
          isPolicy={isPolicy}
        >
          <NavContainer showLogo={showLogo}>
            {showLogo ? (
              isAuth ? (
                <NavLogo>
                  <NavImg src={theme.mode === "dark" ? LightLogo : DarkLogo} />
                </NavLogo>
              ) : (
                <NavLogo>
                  <NavImg
                    src={
                      theme.mode === "dark"
                        ? LightLogo
                        : isSticky
                        ? LightLogo
                        : DarkLogo
                    }
                  />
                </NavLogo>
              )
            ) : (
              <NavTitleWrapper>
                <span onClick={() => setIsMobileOpen(true)}>
                  <HiOutlineMenuAlt2 />
                </span>

                <NavMobileLogo
                  src={theme.mode === "dark" ? LightLogo : DarkLogo}
                />

                {/* {backNavigate > 0 && (
                  <BackButton onClick={handleBackNavigate}>
                    {" "}
                    <IoIosArrowBack /> Back{" "}
                  </BackButton>
                )} */}
              </NavTitleWrapper>
            )}
            {isAuth ? (
              <>
                <NavMenu showLogo={showLogo}>
                  {!isHome && (
                    <NavItems>
                      <DarkModeButton
                        onClick={() => dispatch(setMode())}
                        showLogo={showLogo}
                      >
                        {theme.mode === "light" ? (
                          <>
                            <SunIcon
                              active={theme.mode === "light" && "true"}
                            />
                            <p>Light Mode</p>
                          </>
                        ) : (
                          <>
                            <MoonIcon
                              active={theme.mode === "dark" && "true"}
                            />
                            <p>Dark Mode</p>
                          </>
                        )}
                      </DarkModeButton>
                    </NavItems>
                  )}
                  {!showLogo && (
                    <>
                      <NavItems active={activeLink === "/notifications"}>
                        <NavLinks to="/notifications">
                          <NotificationIcon width={"20px"} />
                        </NavLinks>
                      </NavItems>
                      <NavItems active={activeLink === "/messages"}>
                        <NavLinks to="/messages">
                          <MessageIcon width={"20px"} />
                        </NavLinks>
                      </NavItems>
                    </>
                  )}
                  {showLogo && !isAbout && (
                    <NavItems>
                      <NavLogout onClick={() => dispatch(setLogout())}>
                        <AiOutlineLogout />
                      </NavLogout>
                    </NavItems>
                  )}
                  {isAbout && currentUser && (
                    <NavItems isTopNav={true} isPolicy={isPolicy}>
                      <ProfileLink
                        isTopNav={true}
                        isPolicy={isPolicy}
                        to={`/profile/${currentUser?.personalInfo?.firstName}_${currentUser?.personalInfo?.lastName}/${currentUserId}`}
                        active={
                          activeLink ===
                          `/profile/${currentUser?.personalInfo?.firstName}_${currentUser?.personalInfo?.lastName}/${currentUserId}`
                        }
                        onClick={() =>
                          dispatch(setBackNavigate({ backNavigate: 0 }))
                        }
                      >
                        <NavbarIcons
                          src={
                            currentUser?.personalInfo?.profileImage
                              ? currentUser.personalInfo?.profileImage
                              : ProfileIcon
                          }
                          alt="profile_icon"
                        />
                        {(!showLogo || isHome) && (
                          <p>{currentUser?.personalInfo?.firstName}</p>
                        )}
                      </ProfileLink>
                    </NavItems>
                  )}
                </NavMenu>
              </>
            ) : (
              <NavMenu>
                <NavItems>
                  <LoginLink to="/login" isSticky={isSticky}>
                    Sign in
                  </LoginLink>
                </NavItems>
                <NavItems>
                  <SignupLink to="/joinus" isSticky={isSticky}>
                    Join us
                  </SignupLink>
                </NavItems>
              </NavMenu>
            )}
          </NavContainer>
        </Nav>
      )}
      <MobileSidebar setIsOpen={setIsMobileOpen} isOpen={isMobileOpen} />
    </>
  );
};

export default Navbar;
